import { Form, Space } from 'antd'
const Gap = (props) => {
  const gaps = props.gapLines * 10
  return (
    <Form.Item className="gap_field">
      <Space size={gaps} direction={'vertical'}>
        <div style={{ height: 1 }}></div>
        <div style={{ height: 1 }}></div>
      </Space>
    </Form.Item>
  )
}

export default Gap
