import React, { useEffect, useState } from 'react'
import Tab from '../Tab'
import { ReactSortable } from 'react-sortablejs'
import { useNavigate } from 'react-router-dom'

function TabsGroup({
  data,
  addTab,
  addGroup,
  addElement,
  index,
  handleTabName,
  handleTabDescription,
  setGroupModal,
  setFieldModal,
  handleChartTypes,
  setFormModal,
  deleteElement,
  shuffle,
  forms,
  pdfTemplates,
  groups,
  handleViewOnly,
  shuffleTabElements,
  handleShowHeading,
  handleSortingEnable,
  formId,
  pageId,
  questionnaires,
}) {
  let tabs = JSON.parse(JSON.stringify(data.tabs))
  let navigate = useNavigate()
  return (
    <div>
      <span className="element_type">{'Tabs'}</span>
      <div style={{ background: '#d8ebfd', padding: '20px' }}>
        <button title="Add tab" className="add_tab" onClick={addTab}>
          <span className="material-icons-outlined">add</span>
        </button>
        <ReactSortable
          list={tabs}
          setList={(list) => {
            shuffle(list, index)
          }}
        >
          {tabs.map((item, i) => (
            <div className="drag_item ">
              <button className="delete_tab" onClick={() => deleteElement(index, i)}>
                <span className="material-icons-outlined">delete</span>
              </button>
              <button
                style={{
                  display: item.id ? 'inline-block' : 'none',
                  right: 225,
                }}
                className="condition_tab"
                onClick={() => {
                  if (pageId)
                    navigate(`/admin/conditions?page=${pageId}&fieldId=${item.id}`, { push: true })
                  if (formId)
                    navigate(`/admin/conditions?form=${formId}&fieldId=${item.id}`, { push: true })
                }}
              >
                <span className="material-icons-outlined" style={{ fontSize: '20px' }}>
                  help
                </span>
              </button>

              <Tab
                key={i}
                deleteElement={deleteElement}
                forms={forms}
                groups={groups}
                pdfTemplates={pdfTemplates}
                questionnaires={questionnaires}
                data={item}
                addGroup={() => addGroup(index, i)}
                addElement={() => addElement(index, i)}
                index={i}
                tabGroupIndex={index}
                handleTabName={handleTabName}
                handleTabDescription={handleTabDescription}
                setGroupModal={() => setGroupModal(true, index, i)}
                setFieldModal={() => setFieldModal(true, index, i)}
                setFormModal={() => setFormModal(true, index, i)}
                showFormButton={setFormModal ? true : false}
                handleChartTypes={handleChartTypes}
                handleViewOnly={handleViewOnly}
                handleShowHeading={handleShowHeading}
                handleSortingEnable={handleSortingEnable}
                shuffleTabElements={shuffleTabElements}
                formId={formId}
                pageId={pageId}
              />
            </div>
          ))}
        </ReactSortable>
      </div>
    </div>
  )
}

export default TabsGroup
