import React, { useState, useEffect, useMemo } from 'react'
import { AdminLayout } from 'Components'
import { Select, InputNumber } from 'antd'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title,
} from 'chart.js'
import { Bar, Line, Scatter, Bubble } from 'react-chartjs-2'
import './investment.scss'

// Register necessary Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Title
)

const InvestmentPortfolio = (props) => {
  const [years, setYears] = useState(1) // Default to 5 years
  const [viewType, setViewType] = useState('monthly') // View type (Daily, Weekly, Monthly, Yearly)
  const [chartWidth, setChartWidth] = useState('100%') // Default width
  const [readyToRender, setReadyToRender] = useState(false) // Render control
  const [dates, setDates] = useState([])
  const [timeFrame, setTimeFrame] = useState('1') // Default to 5 years
  const [customYears, setCustomYears] = useState(5) // Default custom value to 5 years

  useEffect(() => {
    if (timeFrame !== 'custom') {
      setYears(parseInt(timeFrame, 10))
    }
  }, [timeFrame])

  useEffect(() => {
    updateViewTypeOptions()
    updateChartDimensions(years, viewType)
    setDates(generateDates(2023, 1, years * 12))
  }, [years])

  useEffect(() => {
    updateChartDimensions(years, viewType)
    setDates(generateDates(2023, 1, years * 12))
  }, [viewType])

  const updateViewTypeOptions = () => {
    if (years <= 2) {
      setViewType('monthly')
    } else if (years <= 10) {
      setViewType('yearly')
    } else {
      setViewType('yearly')
    }
    setReadyToRender(false)
  }

  const generateDates = (startYear, startMonth, months) => {
    let dates = []
    for (let i = 0; i < months; i++) {
      let month = startMonth - 1 + i
      let date = new Date(startYear, month, 1)
      let daysInMonth = new Date(startYear, month + 1, 0).getDate()
      let randomDay = Math.floor(Math.random() * daysInMonth) + 1
      date.setDate(randomDay)
      dates.push(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
    }
    return dates
  }

  const updateChartDimensions = (years, viewType) => {
    let widthMultiplier = 100 // Default multiplier for monthly
    let months = 12 * years
    let height = '400px' // Default height for monthly

    if (viewType === 'daily') {
      widthMultiplier = 500
      height = '400px'
    } else if (viewType === 'weekly') {
      widthMultiplier = 220
      height = '400px'
    } else if (viewType === 'yearly') {
      widthMultiplier = 10
      height = '400px'
    }

    setChartWidth(`${months * widthMultiplier}px`)
    setReadyToRender(true)
  }

  const handleYearsChange = (value) => {
    setCustomYears(value)
    setYears(value)
    setReadyToRender(false)
    setViewType('yearly')
  }

  const handleViewTypeChange = (value) => {
    setViewType(value)
    setReadyToRender(false)
    updateChartDimensions(years, value)
  }

  const dataForLineChart = useMemo(
    () => ({
      labels: generateLabels(years * 12, viewType),
      datasets: [
        {
          label: 'Assets',
          borderColor: 'rgb(54, 162, 235)',
          data: generateData(years * 12, 100, 200, viewType),
          fill: false,
        },
        {
          label: 'Liabilities',
          borderColor: 'rgb(255, 99, 132)',
          data: generateData(years * 12, 50, 150, viewType),
          fill: false,
        },
      ],
    }),
    [years, viewType]
  )

  const dataForBarChart = useMemo(
    () => ({
      labels: generateLabels(years * 12, viewType),
      datasets: [
        {
          label: 'Income',
          backgroundColor: 'rgb(75, 192, 192)',
          data: generateData(years * 12, 150, 250, viewType),
        },
        {
          label: 'Expenses',
          backgroundColor: 'rgb(255, 159, 64)',
          data: generateData(years * 12, 100, 200, viewType),
        },
      ],
    }),
    [years, viewType]
  )

  const generateScatterDataFromDates = (dates) => {
    return dates.map((dateStr) => {
      const date = new Date(dateStr)
      // Collect dataa and elevate events on same date by number of previous events encountered
      return { x: date.getMonth() + 1, y: date.getDate() }
    })
  }

  const generateBubbleDataFromDates = (dates) => {
    return dates.map((dateStr) => {
      const date = new Date(dateStr)
      return { x: date.getMonth() + 1, y: 5, r: 4 }
    })
  }

  const optionsForScatterChart = useMemo(
    () => ({
      scales: {
        x: {
          type: 'category',
          labels: generateLabels(years * 12, viewType),
          position: 'bottom',
          offset: true,
        },
        y: {
          type: 'linear',
          min: 1,
          max: 31,
          ticks: {
            stepSize: 1,
            callback: function (value) {
              return value
            },
          },
        },
      },
      maintainAspectRatio: false,
    }),
    [years, viewType]
  )

  const dataForScatterChartStrategy = useMemo(
    () => ({
      labels: generateLabels(years * 12, viewType),
      datasets: [
        {
          label: 'Strategy Completion',
          backgroundColor: 'rgba(153, 102, 255, 0.8)',
          data: generateScatterDataFromDates(dates),
          pointRadius: 4,
          pointStyle: 'circle',
        },
        {
          label: 'Life Events',
          backgroundColor: 'rgba(100, 150, 50, 0.6)',
          data: generateScatterDataFromDates(dates),
          pointRadius: 7,
          pointStyle: 'rectRot',
        },
      ],
    }),
    [dates, years, viewType]
  )

  const dataForBubbleChart = useMemo(
    () => ({
      labels: generateLabels(years * 12, viewType, true),
      datasets: [
        {
          label: 'Bubble Data',
          backgroundColor: 'rgba(45, 49, 54, 0.3)',
          borderColor: 'rgb(45, 49, 54)',
          data: generateBubbleDataFromDates(dates),
        },
        {
          label: 'Life Events',
          backgroundColor: 'rgba(166, 213, 255, 0.8)',
          borderColor: 'rgb(166, 213, 255)',
          data: generateBubbleDataFromDates(dates),
        },
      ],
    }),
    [dates, years, viewType]
  )

  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Wealth Portfolio</span>
        <div>
          <span style={{ paddingRight: 15 }}>Number of Years</span>
          <Select
            defaultValue="1"
            style={{ width: 200 }}
            onChange={setTimeFrame}
            options={[
              { value: '1', label: '1 Year' },
              { value: '2', label: '2 Years' },
              { value: '5', label: '5 Years' },
              { value: '10', label: '10 Years' },
              { value: 'custom', label: 'Custom' },
            ]}
          />
          {timeFrame === 'custom' && (
            <div style={{ display: 'inline-block', marginLeft: 15 }}>
              <span style={{ paddingRight: 15 }}>Number of Years</span>
              <InputNumber
                min={1}
                value={customYears}
                defaultValue={5}
                onChange={handleYearsChange}
              />
            </div>
          )}
          <span style={{ paddingRight: 15, marginLeft: 15 }}>Select View</span>
          <Select
            value={viewType}
            style={{ width: 120 }}
            onChange={handleViewTypeChange}
            options={[
              { value: 'daily', label: 'Daily', disabled: years >= 2 },
              { value: 'weekly', label: 'Weekly', disabled: years > 2 },
              { value: 'monthly', label: 'Monthly', disabled: years > 10 },
              { value: 'yearly', label: 'Yearly', disabled: years < 5 },
            ]}
          />
        </div>
      </h1>
      {readyToRender && (
        <div className="investment_chart_container">
          <div className="super_container">
            <div className="chart-container" style={{ width: chartWidth }}>
              <div className="chart">
                <Line
                  options={{
                    maintainAspectRatio: false,
                    scales: { x: { offset: true } },
                  }}
                  data={dataForLineChart}
                />
              </div>
              <div className="lefe_and_expenses_chart">
                <Bar options={{ maintainAspectRatio: false }} data={dataForBarChart} />
              </div>
              <div className="lefe_and_expenses_chart">
                <Scatter data={dataForScatterChartStrategy} options={optionsForScatterChart} />
              </div>
            </div>
          </div>
          <div className="stratergy_sidebar"></div>
        </div>
      )}
    </div>
  )

  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

function generateLabels(months, viewType, isDetailed = false) {
  const monthsArray = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  const labels = []
  if (viewType === 'yearly') {
    for (let year = 0; year < Math.ceil(months / 12); year++) {
      labels.push(`${2000 + year}`)
    }
  } else if (viewType === 'monthly') {
    for (let year = 0; year < Math.ceil(months / 12); year++) {
      for (let month = 0; month < 12; month++) {
        if (year * 12 + month < months) {
          labels.push(`${monthsArray[month]} ${2000 + year}`)
        }
      }
    }
  } else if (viewType === 'weekly') {
    for (let i = 0; i < months * 4; i++) {
      labels.push(`Week ${i + 1}`)
    }
  } else {
    for (let i = 0; i < months * 30; i++) {
      labels.push(`Day ${i + 1}`)
    }
  }
  return labels
}

function generateData(months, min, max, viewType) {
  const count =
    viewType === 'yearly'
      ? Math.ceil(months / 12)
      : viewType === 'monthly'
        ? months
        : viewType === 'weekly'
          ? months * 4
          : months * 30
  return Array.from({ length: count }, () => Math.floor(Math.random() * (max - min + 1) + min))
}

export default InvestmentPortfolio
