import React, { Component } from 'react'
import AdminLayout from '../../Components/Layout/AdminLayout'

import './theme.scss'
import ThemeManager from './themeManager'

export default class UserTheme extends Component {
  render() {
    return (
      <AdminLayout preview={this.props.preview}>
        <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
          <h1 className="flex_title">
            <span className="title">Manage Theme</span>
          </h1>
          <ThemeManager />
        </div>
      </AdminLayout>
    )
  }
}
