import moment from 'moment'

export const flattenPages = (items = [], arr = []) => {
  for (let i = 0; i < items.length; i++) {
    let item = items[i]
    if (item?.page?.type == 'menu' && item?.page?.show) {
      flattenPages(item?.children, arr)
    } else {
      if (item?.page?.show) {
        arr.push(item)
      }
    }
  }
  return arr
}

export const getFirstPage = (items = []) => {
  return flattenPages(items)[0]
}

export const common_date_format = (date_str) => {
  return moment(date_str).format('DD-MM-YYYY')
}
