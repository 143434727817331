import { HomeLayout } from 'Components'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { AuthService } from 'services'
import { AuthUtils } from 'utils'
import { useState } from 'react'

export default function ResetPassword() {
  const navigate = useNavigate()
  let { token } = useParams()
  const [showPassword, SetShowPassword] = useState(false)
  const clickPassword = () => {
    SetShowPassword(!showPassword)
  }
  let schema = yup.object().shape({
    password: yup
      .string()
      .required('password is required fields')
      .matches(
        AuthUtils.password_regex(),
        'Minimum 8 characters, at least 1 letter, 1 number and 1 special character'
      ),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    onSubmit: async (values) => {
      await AuthService.forgot_password_verify({ ...values, token })
      navigate('/login')
    },
    validationSchema: schema,
  })

  return (
    <HomeLayout>
      <div className="auth">
        <div className="container">
          <div className="base_form login">
            <h1>Reset password</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_group">
                <label className="form_label">New Password</label>
                <div className="input_mask">
                  <input
                    type="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                  />
                </div>
                {formik.touched.password && formik.errors.password && (
                  <span className="input-error-message">{formik.errors.password}</span>
                )}
              </div>
              <div className="form_group">
                <label className="form_label">Confirm New Password</label>
                <div className="input_mask">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="confirm_password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                  />
                  <button onClick={(e) => (e.preventDefault(), clickPassword())}>
                    <span className="material-icons-outlined">visibility</span>
                  </button>
                  {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <span className="input-error-message">{formik.errors.confirm_password}</span>
                  )}
                </div>
              </div>
              <button type="submit" className="primary_form">
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}
