import React, { Component, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Layout, Menu, Button } from 'antd'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import './admin_layout.scss'
import { HomeHead } from 'Components'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { AuthService } from 'services'
import Cookies from 'js-cookie'
import { deleteUser, setUser } from 'Store/reducers/userSlice'
import Chatbox from 'Components/Chat/chat'
import questions from 'Components/Chat/questions.json'
const { Sider } = Layout
const { SubMenu } = Menu

const AdminLayout = (props) => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const user = useSelector((state) => get(state, 'user.details'))
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const configPages = useSelector((state) =>
    props.preview
      ? get(state, 'routeSlice.routes.page_data', '') ||
        get(state, 'user.details.current_page_config.page_data', '')
      : get(state, 'user.details.current_page_config.page_data', '')
  )
  const role = useSelector((state) =>
    props.preview
      ? get(state, 'previewUser.details.user_type', '')
      : get(state, 'user.details.user_type', '')
  )
  const state = useSelector((state) => state)
  const previewEntity = useSelector((state) => get(state, 'previewUser.details', ''))
  const entity = params.get('entity') || user?.id
  const entity_type = params.get('entity_type') || user?.user_type
  const flattenPages = (pages, flat = []) => {
    for (let i = 0; i < pages.length; i++) {
      let el = pages[i]
      if (el.children && el.page.type == 'page') {
        let cpy = { ...el }
        delete cpy.children
        flat.push(cpy)
        flattenPages(el.children, flat)
      } else {
        flat.push(el)
      }
    }
    return flat
  }

  const renderElemets = (pages) => {
    if (!pages || !pages.length) return null
    let arr = flattenPages(pages)
    return arr
      .filter((page) => page.page.show)
      .map((item) => {
        if (item.page.type == 'page') {
          return renderPageLink(item)
        }
        if (item.page.type == 'menu') {
          return renderMenu(item)
        }
      })
  }
  const renderMenu = (item) => {
    return (
      <SubMenu
        key={item.id}
        icon={<span className="material-icons-outlined"> {item?.page?.icon || 'description'}</span>}
        title={item.page.display_name || item.page.name}
      >
        {renderElemets(item.children)}
      </SubMenu>
    )
  }
  const renderPageLink = (item) => {
    let page = item.page

    return (
      <Menu.Item
        key={item.page_config_id}
        icon={<span className="material-icons-outlined"> {item?.page?.icon || 'description'}</span>}
      >
        <NavLink
          to={
            props.preview
              ? `${props.preview ? '/preview/user/page/' : '/user/page/'}` +
                page.id +
                `?entity=${entity}&entity_type=${entity_type}`
              : `${props.preview ? '/preview/user/page/' : '/user/page/'}` + page.id
          }
        >
          {page.display_name || page.name}
        </NavLink>
      </Menu.Item>
    )
  }

  const [collapsed, setCollapsed] = useState(window.innerWidth < 901)

  return (
    <Layout>
      <HomeHead />
      <Chatbox questions={questions} />
      <Button
        onClick={() => setCollapsed(!collapsed)}
        className={collapsed ? 'mobileCollapsable' : 'mobileCollapsable show'}
        type="text"
        size={'large'}
      >
        {' '}
        {collapsed ? <MenuOutlined /> : <CloseOutlined />}
      </Button>
      <Layout>
        <Sider
          width={230}
          className="site-layout-background"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[props?.pathname]}
            selectedKeys={[props?.pathname]}
            style={{ height: '100%', borderRight: 0 }}
          >
            {/* <Menu.Item
              key={`a/${role}/dashboard`}
              icon={<span className="material-icons-outlined">dashboard</span>}
            >
              <NavLink to={`/${role}/dashboard`}>Dashboard</NavLink>
            </Menu.Item> */}
            {renderElemets(configPages)}

            {/* {role == "admin" || role == "business" ? (
              <Menu.Item
                key={props.preview ? "/preview/user/theme" : "/user/theme"}
                icon={
                  <span className="material-icons-outlined">format_paint</span>
                }
              >
                <NavLink
                  to={
                    props.preview
                      ? "/admin/entity-management/business/edit/" +
                        previewEntity.id
                      : "/user/theme"
                  }
                >
                  Theme Manager
                </NavLink>
              </Menu.Item>
            ) : null} */}
            {role == 'admin' && (
              <>
                {/* <Menu.Item
                  key="/admin/fields"
                  icon={
                    <span className="material-icons-outlined">text_fields</span>
                  }
                >
                  <NavLink to="/admin/fields">Fields</NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/admin/groups"
                  icon={
                    <span className="material-icons-outlined">view_agenda</span>
                  }
                >
                  <NavLink to="/admin/groups">Groups</NavLink>
                </Menu.Item>
                <Menu.Item
                  key="/admin/forms"
                  icon={
                    <span className="material-icons-outlined">list_alt</span>
                  }
                >
                  <NavLink to="/admin/forms">Forms</NavLink>
                </Menu.Item> */}
                <Menu.Item
                  key="/admin/pages"
                  icon={<span className="material-icons-outlined">description</span>}
                >
                  <NavLink to="/admin/pages">Pages</NavLink>
                </Menu.Item>
                {/* <Menu.Item
                  key={`/${role}/questionnaire`}
                  icon={
                    <span className="material-icons-outlined">
                      dynamic_form
                    </span>
                  }
                >
                  <NavLink to={`/admin/questionnaire`}>Questionnaire</NavLink>
                </Menu.Item>
                <Menu.Item
                  key={`/workbook`}
                  icon={<span className="material-icons-outlined">ballot</span>}
                >
                  <NavLink to={`/workbook`}>Workbook</NavLink>
                </Menu.Item> */}
              </>
            )}
            {/*
            <Menu.Item
              key={`/risk-profile`}
              icon={
                <span className="material-icons-outlined">warning_amber</span>
              }
            >
              <NavLink to={`/risk-profile`}>Risk Profile</NavLink>
            </Menu.Item>
            */}
            {/* {role == "admin" && (
              <SubMenu
                key="page-portfolio"
                icon={
                  <span className="material-icons-outlined">
                    home_repair_service
                  </span>
                }
                title="Portfolio"
              >
                <Menu.Item key="99">
                  <NavLink to="/portfolio">Portfolio</NavLink>
                </Menu.Item>
                <Menu.Item key="103">
                  <NavLink to="/portfolio-recommendation">
                    Recommendation
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )} */}
            {/* {(role == "admin" || role == "user") && (
              <SubMenu
                key="page-config"
                icon={
                  <span className="material-icons-outlined">
                    room_preferences
                  </span>
                }
                title="Page Config"
              >
                {role == "admin" && (
                  <Menu.Item key="12">
                    <NavLink to="/admin/page-config/admin">Admin</NavLink>
                  </Menu.Item>
                )}
                <Menu.Item key="25">
                  <NavLink to="/admin/page-config/business">Business</NavLink>
                </Menu.Item>
                <Menu.Item key="13">
                  <NavLink to="/admin/page-config/user">User</NavLink>
                </Menu.Item>
                <Menu.Item key="14">
                  <NavLink to="/admin/page-config/client">Client</NavLink>
                </Menu.Item>
              </SubMenu>
            )} */}

            {/* {role == "admin" && (
              <SubMenu
                key="pdf-config"
                icon={
                  <span className="material-icons-outlined">
                    picture_as_pdf
                  </span>
                }
                title="PDF Config"
              >
                <Menu.Item key="101">
                  <NavLink to="/admin/pdf-config">Template</NavLink>
                </Menu.Item>
                <Menu.Item key="102">
                  <NavLink to="/admin/pdf-config/pages">Config Pages</NavLink>
                </Menu.Item>
                <Menu.Item key="134">
                  <NavLink to="/admin/pdf-config/page-type">
                    Default Pages
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )} */}
            {/* {(role == "admin" || role == "user") && (
              <>
                <SubMenu
                  key="entity-management"
                  icon={<span className="material-icons-outlined">domain</span>}
                  title="Entities"
                >
                  {role == "admin" && (
                    <Menu.Item key="15">
                      <NavLink to="/admin/entity-management/admin">
                        Admin
                      </NavLink>
                    </Menu.Item>
                  )}
                  <Menu.Item key="16">
                    <NavLink to="/admin/entity-management/user">User</NavLink>
                  </Menu.Item>
                  <Menu.Item key="17">
                    <NavLink to="/admin/entity-management/client">
                      Client
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="18">
                    <NavLink to="/admin/entity-management/business">
                      Business
                    </NavLink>
                  </Menu.Item>
                </SubMenu>
              </>
            )} */}
            {/* admin */}
            {/* {role == "admin" && (
              <SubMenu
                key="pages"
                icon={<span className="material-icons-outlined">settings</span>}
                title="Site Settings"
              >
                <Menu.Item key="19">
                  <NavLink to="/admin/pages/contact-us">Contact Us</NavLink>
                </Menu.Item>
                <Menu.Item key="20">
                  <NavLink to="/admin/pages/about-us">About Us</NavLink>
                </Menu.Item>
                <Menu.Item key="21">
                  <NavLink to="/admin/pages/privacy-policy">
                    Privacy Policy
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="22">
                  <NavLink to="/admin/pages/terms-and-conditions">
                    Terms &amp; Conditions
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="23">
                  <NavLink to="/admin/setting/capability">Capabilities</NavLink>
                </Menu.Item>
                <Menu.Item key="24">
                  <NavLink to="/admin/setting/pricing-plan">
                    Pricing Plan
                  </NavLink>
                </Menu.Item>
              </SubMenu>
            )} */}

            {/* {(role == "user" || role == "business" || role == "client") && (
              <Menu.Item
                key={`/wizard`}
                icon={
                  <span className="material-icons-outlined">description</span>
                }
              >
                <NavLink to={"/wizard"}>Wizard</NavLink>
              </Menu.Item>
            )}
            {(role == "user" || role == "business" || role == "client") && (
              <Menu.Item
                key={`/reports`}
                icon={
                  <span className="material-icons-outlined">description</span>
                }
              >
                <NavLink to={"/reports"}>Reports</NavLink>
              </Menu.Item>
            )}
            {(role == "user" || role == "business" || role == "client") && (
              <Menu.Item
                key={`/investment-option`}
                icon={
                  <span className="material-icons-outlined">description</span>
                }
              >
                <NavLink to={"/investment-option"}>Investment option</NavLink>
              </Menu.Item>
            )} */}
            <li className="mobile_logged_in" style={{ marginTop: 35 }} key="/user/profile">
              <NavLink to={`/user/profile`}>My Profile</NavLink>
            </li>
            <li className="mobile_logged_in" key="/change-password">
              <NavLink to={`/change-password`}>Change Password</NavLink>
            </li>
            <li className="mobile_logged_in" key="/logout">
              <div
                onClick={async () => {
                  navigate('/')

                  await AuthService.logout()
                  dispatch(deleteUser())
                  Cookies.remove('user_id')
                  Cookies.remove('user_type')
                  Cookies.remove('token')
                  Cookies.remove('refresh_token')
                  Cookies.remove('user_id')
                  Cookies.remove('user_type')
                  Cookies.remove('token')
                  Cookies.remove('refresh_token')
                }}
              >
                Logout
              </div>
            </li>
            {props.preview && (
              <Menu.Item
                key={`b/${get(state, 'user.details.user_type', '')}/dashboard`}
                icon={<span className="material-icons-outlined">back</span>}
              >
                <NavLink to={`/${get(state, 'user.details.user_type', '')}/dashboard`}>
                  Go Back
                </NavLink>
              </Menu.Item>
            )}
          </Menu>
        </Sider>
        <div
          className={collapsed ? 'sider_backdrop hidden' : 'sider_backdrop'}
          onClick={() => setCollapsed(!collapsed)}
        ></div>
        <Layout className="main_layout">
          {/* maker it true or false to show loading */}
          {/* <div className="custom_loader"><Spin size="large" /></div> */}
          <div className="main_web_content" style={{ minHeight: 'calc(100vh - 90px)' }}>
            {props.children}
          </div>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default AdminLayout
