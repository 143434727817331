import React, { useState, useEffect } from 'react'
import { AdminLayout } from 'Components'
import { Form, Select, Checkbox, Table, InputNumber, Button, Space, Input } from 'antd'
import './add.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { SubscriptionService } from 'services'
import { get } from 'lodash'
const { TextArea } = Input
const { Option } = Select

export default function AddPricingPlan(props) {
  let { id } = useParams()
  const [plan_name, SetPlan_name] = useState('')
  const [plan_type, SetPlan_type] = useState('')
  const [description, SetDescription] = useState('')
  const [price, SetPrice] = useState(0)
  const [user_access_limit, SetUserAccessLimit] = useState(1)
  const [currency, SetCurrency] = useState('AUD')
  const [active, SetActive] = useState(true)
  const [period, SetPeriod] = useState(0)
  const [currency_list, SetCurrency_list] = useState({})
  const [data, SetData] = useState([])
  const [disable_user_access_limit, SetDisableUserAccessLimit] = useState(true)

  let navigate = useNavigate()

  const RenderCapabilityListData = async (capabilities = {}) => {
    const res = await SubscriptionService.GetCapabilityList()
    let capability_data = get(res, 'data.data', [])
    capability_data = capability_data.map((item) => ({
      ...item,
      add: get(capabilities, item.id + '.add', false),
      edit: get(capabilities, item.id + '.edit', false),
      delete: get(capabilities, item.id + '.delete', false),
      read: get(capabilities, item.id + '.read', false),
    }))
    await SetData(capability_data)
  }

  const onchangeCheck = (e, type, record) => {
    let capability_data = data
    if (type === 'add') {
      capability_data.find((item) => item.id === record.id).add = e.target.checked
    } else if (type === 'edit') {
      capability_data.find((item) => item.id === record.id).edit = e.target.checked
    } else if (type === 'read') {
      capability_data.find((item) => item.id === record.id).read = e.target.checked
    } else {
      capability_data.find((item) => item.id === record.id).delete = e.target.checked
    }
    SetData(capability_data)
  }

  const columns = [
    {
      title: 'Capability Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Capability Name</h6>
          <a>{text}</a>
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Description</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Add',
      key: 'add',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Add</h6>
          <Checkbox checked={record.add} onChange={(e) => onchangeCheck(e, 'add', record)} />
        </div>
      ),
    },
    {
      title: 'Read',
      key: 'read',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Read</h6>
          <Checkbox checked={record.read} onChange={(e) => onchangeCheck(e, 'read', record)} />
        </div>
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Edit</h6>
          <Checkbox checked={record.edit} onChange={(e) => onchangeCheck(e, 'edit', record)} />
        </div>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Delete</h6>
          <Checkbox checked={record.delete} onChange={(e) => onchangeCheck(e, 'delete', record)} />
        </div>
      ),
    },
  ]

  const PlanDetailApi = async () => {
    const plan_detail_res = await SubscriptionService.GetPricingPlanDetail(id)
    SetPlan_name(get(plan_detail_res, 'data.data.plan_name'))
    SetPlan_type(get(plan_detail_res, 'data.data.plan_type'))
    SetDescription(get(plan_detail_res, 'data.data.description'))
    SetPrice(get(plan_detail_res, 'data.data.price'))
    SetUserAccessLimit(get(plan_detail_res, 'data.data.user_access_limit'))
    SetCurrency(get(plan_detail_res, 'data.data.currency'))
    SetActive(get(plan_detail_res, 'data.data.currency'))
    SetPeriod(get(plan_detail_res, 'data.data.period'))
    if (get(plan_detail_res, 'data.data.plan_type') === 'business') {
      SetDisableUserAccessLimit(false)
    }

    RenderCapabilityListData(get(plan_detail_res, 'data.data.capabilities'))
  }

  useEffect(() => {
    async function fetchData() {
      const res = await SubscriptionService.GetCurrencyList()
      if (id) {
        await PlanDetailApi()
      } else {
        await RenderCapabilityListData()
      }

      await SetCurrency_list(get(res, 'data.data'))
    }
    fetchData()
  }, [])

  const currency_options = () => {
    let options = []
    for (const [key, value] of Object.entries(currency_list)) {
      options.push(
        <Option key={key} value={key}>
          {key}-{value}
        </Option>
      )
    }
    return options
  }

  const OnclickSubmit = async () => {
    const payload = {
      plan_name,
      plan_type,
      description,
      price,
      user_access_limit,
      currency,
      active,
      period,
      capabilities: data,
    }
    if (id) {
      const res = await SubscriptionService.UpdatePricingPlan(id, payload)
      navigate('/admin/setting/pricing-plan')
    } else {
      const res = await SubscriptionService.CreatePricingPlan(payload)
      navigate('/admin/setting/pricing-plan')
    }
  }

  const OnChangePlanType = (value) => {
    SetPlan_type(value)
    if (value === 'business') {
      SetDisableUserAccessLimit(false)
    } else {
      SetDisableUserAccessLimit(true)
      SetUserAccessLimit(1)
    }
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">{id ? 'Edit' : 'Add'} Plan</span>
        </h1>
        <Form className="add_plan" layout="vertical">
          <Form.Item label="Name">
            <Input
              placeholder="Enter name"
              value={plan_name}
              onChange={(e) => SetPlan_name(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Checkbox checked={active} onChange={(e) => SetActive(e.target.checked)}>
              Active
            </Checkbox>
          </Form.Item>
          <Form.Item label="Plan Type and Period in months">
            <Select style={{ width: '50%' }} value={plan_type} onChange={OnChangePlanType}>
              <Option value=""></Option>
              <Option value="individual">Individual</Option>
              <Option value="business">Business</Option>
            </Select>
            <InputNumber
              style={{ width: '50%' }}
              placeholder="Enter period (months)"
              value={period}
              onChange={(e) => SetPeriod(e)}
            />
          </Form.Item>
          <Form.Item label="Currency, Pricing and User Access Limit">
            <Select
              defaultValue="AUD"
              style={{ width: '34%' }}
              value={currency}
              onChange={(value) => SetCurrency(value)}
            >
              {currency_options()}
            </Select>
            <InputNumber
              style={{ width: '33%' }}
              placeholder="Enter amount"
              value={price}
              onChange={(e) => SetPrice(e)}
            />
            <InputNumber
              style={{ width: '33%' }}
              placeholder="Enter number of user access"
              disabled={disable_user_access_limit}
              value={user_access_limit}
              onChange={(e) => SetUserAccessLimit(e)}
            />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              placeholder="Describe this capability"
              rows={4}
              value={description}
              onChange={(e) => SetDescription(e.target.value)}
            />
          </Form.Item>
          {/*
                        <Form.Item label="Capabilities">
                            <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select" className='hide_selected'
                            >
                            <Option value="user_management" label="User Management">
                                <div>
                                    <h3>User management</h3>
                                    <p>Allows to manage users</p>
                                </div>
                            </Option>
                            <Option value="user_management" label="User Management">
                                <div>
                                    <h3>User management</h3>
                                    <p>Allows to manage users</p>
                                </div>
                            </Option>
                            </Select>
                        </Form.Item>
                        */}
          <h3>Please enter capability operations</h3>
          <Table
            className="custom_responsive_table"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <Button
            size="large"
            style={{ width: '50%', marginTop: 45 }}
            onClick={() => navigate('/admin/setting/pricing-plan')}
          >
            Cancel{' '}
          </Button>
          <Button
            size="large"
            style={{ width: '50%', marginTop: 45 }}
            type="primary"
            htmlType="submit"
            onClick={OnclickSubmit}
          >
            Save
          </Button>
        </Form>
      </div>
    </AdminLayout>
  )
}
