import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { HomeFoot, HomeNav } from 'Components'
import './home.scss'
import { SubscriptionService } from '../../services'
import { get } from 'lodash'

export default function Home() {
  const [individualPlan, SetIndividualPlan] = useState([])
  const [businessPlan, SetBusinessPlan] = useState([])
  const PricingPlanList = async () => {
    const res = await SubscriptionService.GetPricingPlanList({ public: true })
    SetIndividualPlan(get(res, 'data.data', []).filter((item) => item.plan_type === 'individual'))
    SetBusinessPlan(get(res, 'data.data', []).filter((item) => item.plan_type === 'business'))
  }

  useEffect(() => {
    PricingPlanList()
  }, [])

  return (
    <Fragment>
      <header>
        <HomeNav />
        <div className="container">
          <div className="header_content">
            <div>
              <h1>A Leading professional financial management system</h1>
              <Link className="btn primary" to="/signup">
                SIGN UP NOW!
              </Link>
            </div>
            <img alt="MetAdvice professional financial services" src="/header_img.svg" />
          </div>
        </div>
      </header>
      <section className="features">
        <div className="container">
          <h2>Key Features of our financial system</h2>
          <p className="subheading">
            Monitor your day-to-day income, expenses, cash flow with real-time reporting and a
            customisable.
          </p>

          <div className="features_box_cont">
            <div className="features_box">
              <img alt="feature 1" src="/circle.png" />
              <h3>Feature 1</h3>
              <p>lorem ipsum is simply dummy text, which is used to replace placeholder content</p>
            </div>
            <div className="features_box">
              <img alt="feature 1" src="/circle.png" />
              <h3>Feature 2</h3>
              <p>lorem ipsum is simply dummy text, which is used to replace placeholder content</p>
            </div>
            <div className="features_box">
              <img alt="feature 1" src="/circle.png" />
              <h3>Feature 3</h3>
              <p>lorem ipsum is simply dummy text, which is used to replace placeholder content</p>
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="highlight_features">
        <h2>Why choose us?</h2>
        <p className="subheading">
          Monitor your day-to-day income, expenses, cash flow with real-time reporting and a
          customisable.
        </p>
        <div className="container">
          <div className="highlight">
            <img alt="feature 1" src="/rectangle.png" />
            <div>
              <h3>Dummy heading for any major feature</h3>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                demonstrate the visual form of a document or a typeface without relying on
                meaningful content
              </p>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                demonstrate the visual form of a document or a typeface without relying on
                meaningful content
              </p>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                demonstrate the visual form of a document or a typeface without relying on
                meaningful content
              </p>
              <Link to="/">Learn more ...</Link>
            </div>
          </div>
          <div className="highlight reverse">
            <img alt="feature 2" src="/rectangle.png" />
            <div>
              <h3>Dummy heading for any major feature</h3>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                demonstrate the visual form of a document or a typeface without relying on
                meaningful content
              </p>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                demonstrate the visual form of a document or a typeface without relying on
                meaningful content
              </p>
              <p>
                In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to
                demonstrate the visual form of a document or a typeface without relying on
                meaningful content
              </p>
              <Link to="/">Learn more ...</Link>
            </div>
          </div>
        </div>
      </section>
      <section id="pricing" className="pricing">
        <div className="container">
          <div className="pricing_section">
            <h2>Get started for just $8/month</h2>
            <p>
              We scale our pricing per feature, not on the number of users, which allows you to grow
              your business with confidence.
            </p>
          </div>
          <input
            className="pricing_tab_input"
            id="individual"
            type="radio"
            name="pricing"
            checked
            hidden
          />
          <label className="pricing_tabs" htmlFor="individual">
            <h3>Individual</h3>
            <p>For small financial management</p>
          </label>
          <input className="pricing_tab_input" id="business" type="radio" name="pricing" hidden />
          <label className="pricing_tabs" htmlFor="business">
            <h3>Business</h3>
            <p>Multi-User finance management</p>
          </label>
          <div className="pricing_cont individual_price">
            {individualPlan.map((item) => (
              <div className="pricing_model" key={item.key}>
                <div className="pricing_head">
                  <h3>{item.plan_name}</h3>
                  <p>
                    <b>${item.price}/month</b>
                  </p>
                  <p>{item.description}</p>
                </div>
                <div className="pricing_features">
                  <ul>
                    {get(item, 'capabilities', []).map((obj) => (
                      <li key={obj.id}>
                        <span className="material-icons">check_circle</span>
                        {obj.capability.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="pricing_foot">
                  <Link to={`/signup/?plan_type=${item.id}&user_type=client`}>
                    Select Plan &amp; Continue
                  </Link>
                </div>
              </div>
            ))}
          </div>
          <div className="pricing_cont business_price">
            {businessPlan.map((item) => (
              <div className="pricing_model" key={item.id}>
                <div className="pricing_head">
                  <h3>{item.plan_name}</h3>
                  <p>
                    <b>${item.price}/month</b>
                  </p>
                  <p>{item.description}</p>
                </div>
                <div className="pricing_features">
                  <ul>
                    {get(item, 'capabilities', []).map((obj) => (
                      <li key={obj.id}>
                        <span className="material-icons">check_circle</span>
                        {obj.capability.name}
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="pricing_foot">
                  <Link to={`/signup/?plan_type=${item.id}&user_type=user`}>
                    Select Plan &amp; Continue
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <HomeFoot />
    </Fragment>
  )
}
