import { Table, Button, Modal, Space, Steps } from 'antd'
import { useNavigate } from 'react-router-dom'
import { EditOutlined, EyeOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import TinyEditor from 'Components/TinyEditor'
import React, { useEffect, useState } from 'react'
import { PdfConfigService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import PreviewModal from '../PreviewModal'
const { Step } = Steps

const WizardHistory = ({ preview }) => {
  const historyListColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (data) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          <span>{data}</span>
        </div>
      ),
    },
    {
      title: 'Modified By/On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Modified By/On</h6>
          <span className="sep_time">{moment(text).format('ddd , D MMMM , YYYY')}</span>
          <span className="sep_time">{moment(text).format('h:mm:ss A')}</span>
        </div>
      ),
      width: '190px',
    },

    {
      title: 'Created By/On',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (data, record) => (
        <div className="table_responsive_cell">
          <h6>Created By/On</h6>
          <b>{data.name}</b>
          <div>
            <span className="sep_time">
              {moment(record.created_on).format('ddd , D MMMM , YYYY')}
            </span>
            <span className="sep_time">{moment(record.created_on).format('h:mm:ss A')}</span>
          </div>
        </div>
      ),
      width: '190px',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <Space size="middle">
          {!record.is_locked ? (
            <Button icon={<EditOutlined />} size={'medium'} onClick={() => {}} />
          ) : (
            <Button icon={<LockOutlined />} size={'medium'} />
          )}
          <Button
            onClick={() => {
              // getQuestionnaire();
              // setViewOnly(true);
              navigate(
                '/preview/user/wizard?view=true&wizard=' +
                  params.wizard +
                  `&wizard_history=${record.id}&is_locked=${record.is_locked}` +
                  `&entity_id=${entity_id}&entity_type=${entity_type}`,
                { push: true }
              )
            }}
            icon={<EyeOutlined />}
            size={'medium'}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={() =>
              PdfConfigService.deleteWizardHistory(record.id).then((res) => getHistoryList())
            }
          />
        </Space>
      ),
    },
  ]
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  const [historyList, setHistoryList] = useState([])
  const [historyDetail, setHistoryDetail] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [template, setTemplate] = useState({})
  const [questionnaire, setQuestionnaire] = useState({})
  const [viewHistory, setViewHistory] = useState(false)
  const [viewOnly, setViewOnly] = useState(false)
  const [current, setCurrent] = useState(0)
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)

  const entity_id = previewUser?.details?.id || user?.details?.id
  const entity_type = previewUser?.details?.user_type || user?.details?.user_type

  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  useEffect(() => {
    getHistoryList()
  }, [])
  const getHistoryList = () => {
    PdfConfigService.getWizardHistoryList({
      questionnaire: params.wizard,
      entity: previewUser?.details?.id || user?.details?.id,
    })
      .then((res) => {
        setHistoryList(get(res, 'data.data', []))
      })
      .catch((err) => console.log('err'))
  }

  const getQuestionnaire = (id) => {
    PdfConfigService.getQuestionnaire(params.wizard, { detail: true })
      .then((res) => {
        setQuestionnaire(get(res, 'data.data', {}))
        showModal()
      })
      .catch((err) => {})
  }

  const showModal = () => {
    setIsModalVisible(true)
  }
  const navigate = useNavigate()
  return (
    <AdminLayout preview={preview}>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Wizard scenario</span>
          {/*<Button*/}
          {/*  type="primary"*/}
          {/*  onClick={() => {*/}
          {/*    // getQuestionnaire();*/}
          {/*    // setViewOnly(false);*/}
          {/*    navigate("/preview/user/wizard?wizard=" + params.wizard);*/}
          {/*  }}*/}
          {/*>*/}
          {/*  Create History*/}
          {/*</Button>*/}
          <Button
            onClick={() => {
              PdfConfigService.createWizardHistory({
                entity: previewUser?.details?.id || user?.details.id,
                questionnaire: params.wizard,
              }).then((res) => {
                navigate(
                  '/preview/user/wizard?view=true&wizard=' +
                    params.wizard +
                    `&wizard_history=${get(res, 'data.data.id')}&is_locked=${get(res, 'data.data.is_locked')}` +
                    `&entity_id=${entity_id}&entity_type=${entity_type}`,
                  { push: true }
                )
              })
            }}
            type="primary"
          >
            Create scenario
          </Button>
        </h1>
        <Table
          className="custom_responsive_table"
          columns={historyListColumns}
          dataSource={historyList}
        />
      </div>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title="Template Quick Edit"
        visible={viewHistory}
        onOk={() => setViewHistory(false)}
        onCancel={() => setViewHistory(false)}
      >
        {get(historyDetail, 'data.pages', []).map((page) => (
          <div
            style={{
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              padding: '10px',
              margin: '10px',
              height: '1000px',
              overflow: 'auto',
            }}
          >
            {page.children.map((child) => (
              <div dangerouslySetInnerHTML={{ __html: child.value }}></div>
            ))}
          </div>
        ))}
      </Modal>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title={viewOnly ? 'History' : 'Create History'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          viewOnly
            ? undefined
            : [
                <Button key="back" onClick={handleCancel}>
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  onClick={() => {
                    PdfConfigService.createWizardHistory({
                      entity: previewUser?.details?.id || user?.details.id,
                      questionnaire: questionnaire.id,
                      data: questionnaire,
                    }).then((res) => {
                      getHistoryList()
                      handleOk()
                    })
                  }}
                >
                  Create History
                </Button>,
              ]
        }
      >
        <div>
          <Steps current={current}>
            {[...(questionnaire?.pages || []), { name: 'Files' }]?.map((item, index) => {
              return (
                <Step
                  key={item.title}
                  title={item.display_name || item.name}
                  onClick={() => setCurrent(index)}
                  style={{ cursor: 'pointer' }}
                />
              )
            })}
          </Steps>
          <div style={{ marginTop: '20px' }}>
            {questionnaire?.pages?.length == current ? (
              <Table
                className="custom_responsive_table"
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'display_name',
                    key: 'display_name',
                    render: (data) => (
                      <div className="table_responsive_cell">
                        <h6>Name</h6>
                        <b>{data}</b>
                      </div>
                    ),
                    width: '50%',
                  },
                ]}
                dataSource={questionnaire.pdfs}
              />
            ) : (
              <PreviewModal
                noModal
                showSave
                name=""
                pageId={questionnaire?.pages?.[current]?.id}
                items={questionnaire?.pages?.[current]?.items}
              />
            )}
          </div>
          <div style={{ margin: 10, padding: '15px 20px' }}>
            <div className="steps-action">
              {current > 0 && (
                <Button size="large" className="wizard_buttons" onClick={() => prev()}>
                  Previous
                </Button>
              )}
              {current < questionnaire?.pages?.length && (
                <Button
                  size="large"
                  className="wizard_buttons"
                  type="primary"
                  onClick={() => next()}
                >
                  Next
                </Button>
              )}
              {current === questionnaire?.pages?.length && (
                <Button size="large" className="wizard_buttons" type="primary">
                  Done
                </Button>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title="Edit History"
        visible={editModal}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
      >
        <div className="page_fake_cont">
          <div className="fake_page">
            {get(historyDetail, 'data.pages', []).map((page) => (
              <div>
                {get(page, 'children', []).map((item) => (
                  <TinyEditor value={item.value || '<div></div>'}></TinyEditor>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </AdminLayout>
  )
}

export default WizardHistory
