import React, { useEffect, useState } from 'react'
import { Tooltip, Form, Button } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import TinyEditor from 'Components/TinyEditor'

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'], // remove formatting button
  ],
}

const RichText = (props) => {
  useEffect(() => {
    if (first && props.value) {
      setValue(props.value)
      setFirst(false)
    }
  }, [props.value])

  const [value, setValue] = useState('')
  const [first, setFirst] = useState(true)

  useEffect(() => {
    if (!first && props.dependantValue && props.dependsOn) {
      setValue(props.filtering.values[props.dependantValue].defaultValue)
    }
  }, [props.dependantValue])

  useEffect(() => {
    if (props.onChange) props.onChange(value)
  }, [value])
  return (
    <Form.Item>
      {props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <div
              style={{
                backgroundColor: '#fff',
              }}
            >
              <TinyEditor
                {...props}
                readOnly={props.isReadOnly}
                onChange={setValue}
                value={value || '<div></div>'}
              />
            </div>
            {/* {props.dynamicFields ?
                        <Button type="link" icon={<span className="material-icons-outlined" style={{fontSize:25,display:"inline-block",marginLeft:-13}}>add_box</span>}/>
                    :null} */}
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <div
              style={{
                backgroundColor: '#fff',
              }}
            >
              <TinyEditor
                {...props}
                readOnly={props.isReadOnly}
                onChange={setValue}
                value={value || '<div></div>'}
              />
              <div></div>
            </div>
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default RichText
