import { Input, Table, Button, Space } from 'antd'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { QuestionnaireService } from 'services'
import { get } from 'lodash'

const data = [
  {
    key: '1',
    name: 'Business 1',
    version: 1,
    pages: 2,
    createdOn: 'Thursday, 22nd March, 2022',
    entries: 2,
  },
  {
    key: '2',
    name: 'Business 2',
    version: 2,
    pages: 3,
    createdOn: 'Thursday, 22nd March, 2022',
    entries: 0,
  },
  {
    key: '3',
    name: 'Business 3',
    version: 3,
    pages: 5,
    createdOn: 'Thursday, 22nd March, 2022',
    entries: 2,
  },
]
const QuestionnaireListing = (props) => {
  const navigate = useNavigate()

  const [data, SetData] = useState([])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: 'Display name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Display name</h6>
          <b>{text || '-'}</b>
        </div>
      ),
    },
    {
      title: 'Number of pages',
      dataIndex: 'page_count',
      key: 'page_count',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Number of pages</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Number of pdf',
      dataIndex: 'pdf_count',
      key: 'pdf_count',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Number of pdf</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/questionnaire/edit/' + record.id)}
          />
          <Button
            icon={<EyeOutlined />}
            size={'medium'}
            onClick={() => navigate(`/admin/questionnaire/wizard?wizard=${record.id}`)}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={(e) => DeleteQuestionnaire(record.id, record.name)}
          />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    get_data()
  }, [])

  const DeleteQuestionnaire = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} Questionnaire ?`)
    if (result) {
      await QuestionnaireService.deleteQuestionnaire(id)
      await get_data()
    }
  }

  const get_data = async () => {
    const res = await QuestionnaireService.getQuestionnaires()
    SetData(get(res, 'data.data', []))
  }

  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Manage Questionnaire</span>
        <Button
          icon={<PlusOutlined />}
          type="primary"
          onClick={() => navigate('/admin/questionnaire/add')}
        >
          Add Questionnaire
        </Button>
      </h1>
      <Input.Group compact style={{ width: '100%' }}>
        <Input.Search allowClear style={{ width: '100%' }} />
      </Input.Group>
      <br />
      <Table className="custom_responsive_table" columns={columns} dataSource={data} />
    </div>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default QuestionnaireListing
