import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
  current_theme: {
    chart_color: {
      chart1: '#1890ff',
      chart2: '#ff4d4f',
      chart3: '#1466b5',
      chart4: '#52c41a',
      chart5: '#9c27b0',
      chart6: '#dcbf15',
      chart7: '#0E8E89',
      chart8: '#E19348',
      chart9: '#F383A2',
      chart10: '#247FEA',
    },
    theme_color: {
      primaryColor: '#1890ff',
      errorColor: '#ff4d4f',
      warningColor: '#faad14',
      successColor: '#52c41a',
      infoColor: '#1890ff',
    },
  },
}

export const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, { payload }) => {
      state.current_theme = get(payload, 'data', null)
    },
  },
})

// Action creators are generated for each case reducer function
export const { setTheme } = themeSlice.actions

export default themeSlice.reducer
