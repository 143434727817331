import React, { useEffect, useState } from 'react'
import {Button, Form, Input, Modal, Space, Table, Tabs, Select} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {ProductService, AuthService} from 'services'
import { get } from 'lodash'
import moment from 'moment'

const { TabPane } = Tabs
const { Option } = Select

export default function AssetTransaction({ preview}) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [investment_options_list, setInvestmentOptionsList] = useState([])
  const [entity_data, setEntityData] = useState({})
  const [product_list, setProductList] = useState([])
  const [account, setAccount] = useState("")

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let entity = null
  if (entity_type == 'client') {
    entity = params.get('entity')
  }

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    // getInvestmentOptionList(new_data.business, false)
    getProductList(new_data.business, false)
    setEntityData(new_data)
    return new_data
  }

  useEffect(() => {
    getData()
    getEntityData()
  }, [])

   useEffect(() => {
    getInvestmentOptionList(entity_data.business, false, account)
  }, [entity_data, account])

  const getInvestmentOptionList = async (business, is_for_investment, product) => {
    const res = await ProductService.getProvider({business, is_for_investment, product})
    setInvestmentOptionsList(get(res, 'data.data', []))
  }

  const getProductList = async (business, is_for_investment) => {
    const res = await ProductService.getProduct({business, is_for_investment})
    setProductList(get(res, 'data.data', []))
  }

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text && moment(text).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      title: 'Settlement date',
      dataIndex: 'settlement_date',
      key: 'settlement_date',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text && moment(text).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      title: 'Position id',
      dataIndex: 'position_id',
      key: 'position_id',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Option',
      dataIndex: 'option',
      key: 'option',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.option && e.option.subject}
        </div>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.account && e.account.subject}
        </div>
      ),
    },
    {
      title: 'Account number',
      dataIndex: 'account_number',
      key: 'account_number',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.account_number}
        </div>
      ),
    },
    {
      title: 'Units',
      dataIndex: 'units',
      key: 'units',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Net amount',
      dataIndex: 'net_amount',
      key: 'net_amount',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Fees',
      dataIndex: 'fees',
      key: 'fees',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Gross amount',
      dataIndex: 'gross_amount',
      key: 'gross_amount',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Cost base',
      dataIndex: 'cost_base',
      key: 'cost_base',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
    setAccount("")
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    const res = await AuthService.getDetailAssetTransaction(id)
    setAccount(get(res, 'data.data.account'))
    formik.setValues(get(res, 'data.data'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this transaction?')
    if (result) {
      await AuthService.deleteAssetTransaction(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await AuthService.getAssetTransaction({
      user: entity,
      // account: params.get('account'), account_number: params.get('account_number'), option:params.get('option')
      position_id: params.get('position_id')
    })
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    date: yup.date().required('date Required'),
    settlement_date: yup.date().required('settlement date Required'),
    position_id: yup.string().required("position_id required"),
    option: yup.string().required("option required"),
    account: yup.string().required("account required"),
    account_number: yup.string().required("account_number required"),
    units: yup.number().required("units required"),
    price: yup.number().required("price required"),
    net_amount: yup.number().required("net_amount required"),
    gross_amount: yup.number().required("gross_amount required"),
    fees: yup.number().required("fees required"),
    cost_base: yup.number().required("cost_base required"),
    source: yup.string().required("source required"),
    type: yup.string().required("type required")
  })

  const initialValues = {
    date: "",
    settlement_date: "",
    position_id: params.get('position_id') || "",
    option: "",
    account: "",
    account_number: "",
    units: 0,
    price: 0,
    net_amount: 0,
    gross_amount: 0,
    fees: 0,
    cost_base: 0,
    source: "",
    type: ""
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['user'] = entity
      if (id) {
          await AuthService.updateAssetTransaction(values, id)
      } else {
        await AuthService.saveAssetTransaction(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const get_net_amount  = ()=> {
    const net_amount  = formik.values.units * formik.values.price
    formik.setFieldValue('net_amount', net_amount)
    return net_amount
  }

  const get_gross_amount  = ()=> {
    const gross_amount  = Number(formik.values.units * formik.values.price) + Number(formik.values.fees)
    formik.setFieldValue('gross_amount', gross_amount)
    return gross_amount
  }

  useEffect(() => {
    get_net_amount();
    get_gross_amount();
  }, [formik.values.fees, formik.values.price, formik.values.units]);


  const onChangeAccount  = (value)=>{
    setAccount(value)
    formik.setFieldValue("account", value)
  }

  const onChangeInvestmentOption  = (value, option)=>{
    formik.setFieldValue("option", value)
    formik.setFieldValue("price", option.latest_price)
  }

  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add transaction
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit transaction' : 'Add transaction'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Date">
                <Input
                  style={{ width: '100%' }}
                  placeholder="date"
                  name="date"
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.date}
                />
                {formik.touched.date && formik.errors.date && (
                  <span className="input-error-message">{formik.errors.date}</span>
                )}
              </Form.Item>
              <Form.Item label="Settlement date">
                <Input
                  style={{ width: '100%' }}
                  placeholder="settlement_date"
                  name="settlement_date"
                  type="date"
                  onChange={formik.handleChange}
                  value={formik.values.settlement_date}
                />
                {formik.touched.settlement_date && formik.errors.settlement_date && (
                  <span className="input-error-message">{formik.errors.settlement_date}</span>
                )}
              </Form.Item>
              <Form.Item label="Type">
                <Select
                  showSearch
                  mode="single"
                  name="type"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select type"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.type}
                  onChange={(value) => formik.setFieldValue("type", value)}
                >
                  <Option value="buy">Buy</Option>
                  <Option value="sell">Sell</Option>
                </Select>
                  {formik.touched.type && formik.errors.type && (
                    <span className="input-error-message">{formik.errors.type}</span>
                  )}
              </Form.Item>
              <Form.Item label="Position id">
                <Input
                  style={{ width: '100%' }}
                  placeholder="position_id"
                  name="position_id"
                  onChange={formik.handleChange}
                  value={formik.values.position_id}
                  disabled={params.get('position_id') ? true : false}
                />
                {formik.touched.position_id && formik.errors.position_id && (
                  <span className="input-error-message">{formik.errors.position_id}</span>
                )}
              </Form.Item>
              <Form.Item label="Account">
                <Select
                  showSearch
                  mode="single"
                  name="account"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select account"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.account}
                  onChange={(value) => onChangeAccount(value)}
                >
                  {product_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.account && formik.errors.account && (
                  <span className="input-error-message">{formik.errors.account}</span>
                )}
              </Form.Item>
              <Form.Item label="Account number">
                <Input
                  style={{ width: '100%' }}
                  placeholder="account_number"
                  name="account_number"
                  onChange={formik.handleChange}
                  value={formik.values.account_number}
                />
                {formik.touched.account_number && formik.errors.account_number && (
                  <span className="input-error-message">{formik.errors.account_number}</span>
                )}
              </Form.Item>
              <Form.Item label="Investment option">
                <Select
                  showSearch
                  mode="single"
                  name="investment_options"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select investment option"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.option}
                  onChange={(value, option) => onChangeInvestmentOption(value, option)}
                >
                  {investment_options_list.map((item) => (
                    <Option key={item.id} value={item.id} latest_price={item.latest_price}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.option && formik.errors.option && (
                  <span className="input-error-message">{formik.errors.option}</span>
                )}
              </Form.Item>
              <Form.Item label="Units">
                <Input
                  style={{ width: '100%' }}
                  placeholder="units"
                  type="number"
                  name="units"
                  onChange={formik.handleChange}
                  value={formik.values.units}
                />
                {formik.touched.units && formik.errors.units && (
                  <span className="input-error-message">{formik.errors.units}</span>
                )}
              </Form.Item>
              <Form.Item label="Price">
                <Input
                  style={{ width: '100%' }}
                  placeholder="price"
                  type="number"
                  name="price"
                  onChange={formik.handleChange}
                  value={formik.values.price}
                />
                {formik.touched.price && formik.errors.price && (
                  <span className="input-error-message">{formik.errors.price}</span>
                )}
              </Form.Item>
              <Form.Item label="Net amount">
                <Input
                  style={{ width: '100%' }}
                  placeholder="net_amount"
                  type="number"
                  name="net_amount"
                  // onChange={formik.handleChange}
                  value={formik.values.net_amount}
                  disabled={true}
                />
                {formik.touched.net_amount && formik.errors.net_amount && (
                  <span className="input-error-message">{formik.errors.net_amount}</span>
                )}
              </Form.Item>
              <Form.Item label="Fees">
                <Input
                  style={{ width: '100%' }}
                  placeholder="fees"
                  type="number"
                  name="fees"
                  onChange={formik.handleChange}
                  value={formik.values.fees}
                />
                {formik.touched.fees && formik.errors.fees && (
                  <span className="input-error-message">{formik.errors.fees}</span>
                )}
              </Form.Item>
              <Form.Item label="Gross amount">
                <Input
                  style={{ width: '100%' }}
                  placeholder="gross_amount"
                  type="number"
                  name="gross_amount"
                  // onChange={formik.handleChange}
                  value={formik.values.gross_amount}
                  disabled={true}
                />
                {formik.touched.gross_amount && formik.errors.gross_amount && (
                  <span className="input-error-message">{formik.errors.gross_amount}</span>
                )}
              </Form.Item>
              <Form.Item label="Cost base">
                <Input
                  style={{ width: '100%' }}
                  placeholder="cost_base"
                  type="number"
                  name="cost_base"
                  onChange={formik.handleChange}
                  value={formik.values.cost_base}
                />
                {formik.touched.cost_base && formik.errors.cost_base && (
                  <span className="input-error-message">{formik.errors.cost_base}</span>
                )}
              </Form.Item>
              <Form.Item label="Source">
                <Select
                  showSearch
                  mode="single"
                  name="source"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select source"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.source}
                  onChange={(value) => formik.setFieldValue("source", value)}
                >
                  <Option value="manual">Manual</Option>
                  <Option value="data_feed">Data feed</Option>
                </Select>
                {formik.touched.source && formik.errors.source && (
                  <span className="input-error-message">{formik.errors.source}</span>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
