import {AdminLayout} from "../../Components";
import React from "react";
import AssetOption from "./AssetOption";

export default function NetWealthFilterOption(props) {

    const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Option</span>
      </h1>
      <AssetOption />
    </div>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}