import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Tabs, Checkbox, Row, Col } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import { ProductService } from 'services'
import { get } from 'lodash'
import { AdminLayout } from '../../Components'
import moment from 'moment'
import * as yup from 'yup'
// Adjust the import path as needed

const { TabPane } = Tabs

export default function ProductFeeCharge() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [entry_fee, setEntryFee] = useState([])
  const [exit_fee, setExitFee] = useState([])
  const [brokerage, setBrokerage] = useState([])
  const [management, setManagement] = useState([])
  const [administration, setAdministration] = useState([])
  const [trail_service, setTrailService] = useState([])
  const [icr, setICR] = useState([])
  const [buy_sell_spread, setBuySellSpread] = useState([])
  const [trustee_fee, setTrusteeFee] = useState([])

  let schema = yup.object().shape({
    date: yup.date().required('date Required'),
  })

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const price_parent_id = params.get('price_parent_id')
  const price_type = params.get('price_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Date</h6>
          {moment(text).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      /*
    {
      title: "Entry  fee",
      dataIndex: "entry_fee",
      key: "entry_fee",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.entry_fee ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "Exit  fee",
      dataIndex: "exit_fee",
      key: "exit_fee",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.exit_fee ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "Brokerage  fee",
      dataIndex: "brokerage",
      key: "brokerage",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.brokerage ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "Management  fee",
      dataIndex: "management",
      key: "management",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.management ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "Administration  fee",
      dataIndex: "administration",
      key: "administration",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.administration ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "Trail service  fee",
      dataIndex: "trail_service",
      key: "trail_service",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.trail_service ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "ICR  fee",
      dataIndex: "icr",
      key: "icr",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.icr ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: "Buy sell spread fee",
      dataIndex: "buy_sell_spread",
      key: "buy_sell_spread",
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.buy_sell_spread ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    */
    },
  ]

  if (!business) {
    columns.push({
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          {!business && (
            <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          )}
          {!business && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
    setEntryFee([])
    setExitFee([])
    setBrokerage([])
    setManagement([])
    setAdministration([])
    setTrailService([])
    setICR([])
    setBuySellSpread([])
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    let res = {}
    await setId(id)
    await setIsModalVisible(true)
    if (price_type === 'provider') {
      res = await ProductService.getDetailProviderFeeCharge(id)
    } else {
      res = await ProductService.getDetailProductFeeCharge(id)
    }
    const fee_values = get(res, 'data.data.fee_values', [])
    const transformedFees = fee_values.reduce((acc, current) => {
      const feeType = current.fee
      if (!acc[feeType]) {
        acc[feeType] = []
      }
      acc[feeType].push(current)
      return acc
    }, {})
    setEntryFee(get(transformedFees, 'entry_fee', []))
    setExitFee(get(transformedFees, 'exit_fee', []))
    setBrokerage(get(transformedFees, 'brokerage', []))
    setManagement(get(transformedFees, 'management', []))
    setAdministration(get(transformedFees, 'administration', []))
    setTrailService(get(transformedFees, 'trail_service', []))
    setICR(get(transformedFees, 'icr', []))
    setBuySellSpread(get(transformedFees, 'buy_sell_spread', []))
    setTrusteeFee(get(transformedFees, 'trustee_fee', []))
    formik.setValues(get(res, 'data.data'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this price?')
    if (result) {
      if (price_type === 'provider') {
        await ProductService.deleteProviderFeeCharge(id)
      } else {
        await ProductService.deleteProductFeeCharge(id)
      }
      getData()
    }
  }

  const getData = async () => {
    let res = {}
    if (price_type === 'provider') {
      res = await ProductService.getProviderFeeCharge({
        business: business,
        [price_type]: price_parent_id,
      })
    } else {
      res = await ProductService.getProductFeeCharge({
        business: business,
        [price_type]: price_parent_id,
      })
    }
    setData(get(res, 'data.data', []))
  }

  const initialValues = {
    entry_fee: [],
    exit_fee: [],
    brokerage: [],
    management: [],
    administration: [],
    trail_service: [],
    icr: [],
    buy_sell_spread: [],
    trustee_fee: [],
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values[price_type] = price_parent_id
      values['fee_values'] = {
        entry_fee,
        exit_fee,
        brokerage,
        management,
        administration,
        trail_service,
        icr,
        buy_sell_spread,
        trustee_fee,
      }
      if (id) {
        if (price_type === 'provider') {
          await ProductService.updateProviderFeeCharge(values, id)
        } else {
          await ProductService.updateProductFeeCharge(values, id)
        }
      } else {
        if (price_type === 'provider') {
          await ProductService.saveProviderFeeCharge(values)
        } else {
          await ProductService.saveProductFeeCharge(values)
        }
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  const handleEntreeFeeAdd = () => {
    setEntryFee([
      ...entry_fee,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleEntreeFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = entry_fee.slice()
    new_fees[index][name] = value
    setEntryFee(new_fees)
  }

  const handleRemoveEntryFee = (index) => {
    const new_fees = entry_fee.slice()
    new_fees.splice(index, 1)
    setEntryFee(new_fees)
  }

  const handleExitFeeAdd = () => {
    setExitFee([
      ...exit_fee,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleExitFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = exit_fee.slice()
    new_fees[index][name] = value
    setExitFee(new_fees)
  }

  const handleRemoveExitFee = (index) => {
    const new_fees = exit_fee.slice()
    new_fees.splice(index, 1)
    setExitFee(new_fees)
  }

  // brokerage
  const handleBrokerageAdd = () => {
    setBrokerage([
      ...brokerage,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleBrokerageChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = brokerage.slice()
    new_fees[index][name] = value
    setBrokerage(new_fees)
  }

  const handleRemoveBrokerage = (index) => {
    const new_fees = brokerage.slice()
    new_fees.splice(index, 1)
    setBrokerage(new_fees)
  }

  //management
  const handleManagementFeeAdd = () => {
    setManagement([
      ...management,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleManagementFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = management.slice()
    new_fees[index][name] = value
    setManagement(new_fees)
  }

  const handleRemoveManagementFee = (index) => {
    const new_fees = management.slice()
    new_fees.splice(index, 1)
    setManagement(new_fees)
  }

  //administration
  const handleAdministrationFeeAdd = () => {
    setAdministration([
      ...administration,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleAdministrationFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = administration.slice()
    new_fees[index][name] = value
    setAdministration(new_fees)
  }

  const handleRemoveAdministrationFee = (index) => {
    const new_fees = administration.slice()
    new_fees.splice(index, 1)
    setAdministration(new_fees)
  }

  //trail_service
  const handleTrailServiceFeeAdd = () => {
    setTrailService([
      ...trail_service,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleTrailServiceFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = trail_service.slice()
    new_fees[index][name] = value
    setTrailService(new_fees)
  }

  const handleRemoveTrailServiceFee = (index) => {
    const new_fees = trail_service.slice()
    new_fees.splice(index, 1)
    setTrailService(new_fees)
  }

  //icr
  const handleICRFeeAdd = () => {
    setICR([
      ...icr,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleICRFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = icr.slice()
    new_fees[index][name] = value
    setICR(new_fees)
  }

  const handleRemoveICRFee = (index) => {
    const new_fees = icr.slice()
    new_fees.splice(index, 1)
    setICR(new_fees)
  }

  //buy_sell_spread
  const handleBuySellSpreadFeeAdd = () => {
    setBuySellSpread([
      ...buy_sell_spread,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleBuySellSpreadFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = buy_sell_spread.slice()
    new_fees[index][name] = value
    setBuySellSpread(new_fees)
  }

  const handleRemoveBuySellSpreadFee = (index) => {
    const new_fees = buy_sell_spread.slice()
    new_fees.splice(index, 1)
    setBuySellSpread(new_fees)
  }

  //trustee_fee
  const handleTrusteeFeeAdd = () => {
    setTrusteeFee([
      ...trustee_fee,
      {
        start_balance: 0.0,
        end_balance: 0.0,
        applied_fee: 0.0,
        percentage_fee: 0.0,
      },
    ])
  }

  const handleTrusteeFeeChange = (index, event) => {
    const { name, value } = event.target
    const new_fees = trustee_fee.slice()
    new_fees[index][name] = value
    setTrusteeFee(new_fees)
  }

  const handleRemoveTrusteeFee = (index) => {
    const new_fees = trustee_fee.slice()
    new_fees.splice(index, 1)
    setTrusteeFee(new_fees)
  }

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <div>
          <h1 className="flex_title">
            <span className="title">Fee & Charges</span>
            <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
              Add Charge
            </Button>
          </h1>
          <Table
            className="custom_responsive_table"
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{ defaultPageSize: 50 }}
          />
          <Modal
            title={id ? 'Edit Charge' : 'Add Charge'}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'100%'}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={formik.handleSubmit}>
                Save
              </Button>,
            ]}
          >
            <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
              <TabPane tab="Main" key="1">
                <Form layout="vertical" className={'custom_modal_form'}>
                  <Form.Item label="Date">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="date"
                      name="date"
                      type="date"
                      onChange={formik.handleChange}
                      value={formik.values.date}
                    />
                    {formik.touched.date && formik.errors.date && (
                      <span className="input-error-message">{formik.errors.date}</span>
                    )}
                  </Form.Item>
                  <div>
                    <h3>Entry Fee</h3>
                    {entry_fee.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleEntreeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleEntreeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              value={fee.applied_fee}
                              onChange={(event) => handleEntreeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleEntreeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveEntryFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleEntreeFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add Entry fee
                    </Button>
                  </div>
                  <div>
                    <h3>Exit Fee</h3>
                    {exit_fee.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleExitFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleExitFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleExitFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleExitFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveExitFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleExitFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add Exit fee
                    </Button>
                  </div>
                  <div>
                    <h3>Brokerage Fee</h3>
                    {brokerage.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleBrokerageChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleBrokerageChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleBrokerageChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleBrokerageChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveBrokerage(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleBrokerageAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add Brokerage fee
                    </Button>
                  </div>
                  <div>
                    <h3>Management Fee</h3>
                    {management.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleManagementFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleManagementFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleManagementFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleManagementFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveManagementFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleManagementFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add Management fee
                    </Button>
                  </div>
                  <div>
                    <h3>Administration Fee</h3>
                    {administration.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              type="number"
                              name="start_balance"
                              value={fee.start_balance}
                              onChange={(event) => handleAdministrationFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleAdministrationFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleAdministrationFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleAdministrationFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveAdministrationFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleAdministrationFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add Administration fee
                    </Button>
                  </div>
                  <div>
                    <h3>Trail Service Fee</h3>
                    {trail_service.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleTrailServiceFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleTrailServiceFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleTrailServiceFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleTrailServiceFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveTrailServiceFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleTrailServiceFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add trail service fee
                    </Button>
                  </div>
                  <div>
                    <h3>ICR Fee</h3>
                    {icr.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleICRFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleICRFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleICRFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleICRFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveICRFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleICRFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add ICR fee
                    </Button>
                  </div>
                  <div>
                    <h3>Buy Sell Spread Fee</h3>
                    {buy_sell_spread.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleBuySellSpreadFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleBuySellSpreadFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleBuySellSpreadFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleBuySellSpreadFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveBuySellSpreadFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleBuySellSpreadFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add Buy Sell Spread fee
                    </Button>
                  </div>
                  <div>
                    <h3>Trustee Fee</h3>
                    {trustee_fee.map((fee, index) => (
                      <Row key={index} gutter={16} className={'compact_rows'}>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `Start balance`}>
                            <Input
                              placeholder="Start balance"
                              name="start_balance"
                              type="number"
                              value={fee.start_balance}
                              onChange={(event) => handleTrusteeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <Form.Item label={index === 0 && `End balance`}>
                            <Input
                              placeholder="End balance"
                              name="end_balance"
                              type="number"
                              value={fee.end_balance}
                              onChange={(event) => handleTrusteeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Flat fee`}>
                            <Input
                              placeholder="Flat fee"
                              name="applied_fee"
                              type="number"
                              value={fee.applied_fee}
                              onChange={(event) => handleTrusteeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label={index === 0 && `Percentage fee`}>
                            <Input
                              placeholder="Percentage fee"
                              name="percentage_fee"
                              type="number"
                              value={fee.percentage_fee}
                              onChange={(event) => handleTrusteeFeeChange(index, event)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={'delete_btn_column'}>
                          <Button
                            danger
                            icon={<DeleteOutlined />}
                            size="medium"
                            onClick={() => handleRemoveTrusteeFee(index)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      type="dashed"
                      onClick={handleTrusteeFeeAdd}
                      style={{ width: '100%', marginBottom: '20px' }}
                    >
                      Add trustee fee
                    </Button>
                  </div>
                </Form>
              </TabPane>
            </Tabs>
          </Modal>
        </div>
      </div>
    </>
  )

  return <AdminLayout>{Main}</AdminLayout>
}
