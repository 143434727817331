import React, { useState } from 'react'
import { useTable, useExpanded } from 'react-table'
import { CaretRightOutlined, CaretDownOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'

import makeData from './data'

import { AdminLayout } from 'Components'

import './portfolio.scss'

function Table({ columns: userColumns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { expanded },
  } = useTable(
    {
      columns: userColumns,
      data,
    },
    useExpanded // Use the useExpanded plugin hook
  )

  return (
    <div className="ant-table">
      <table className="portfolio" {...getTableProps()}>
        <thead className="ant-table-thead">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="ant-table-tbody" {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <tr className="ant-table-row" {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td className="ant-table-cell" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function PortfolioListing(props) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
          </span>
        ),
        Cell: ({ row }) =>
          // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
          // to build the toggle for expanding a row
          row.canExpand ? (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />}
            </span>
          ) : null,
      },
      {
        Header: 'First Name',
        accessor: 'firstName',
      },
      {
        Header: 'Last Name',
        accessor: 'lastName',
      },
      {
        Header: 'Age',
        accessor: 'age',
      },
      {
        Header: 'Visits',
        accessor: 'visits',
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Profile Progress',
        accessor: 'progress',
      },
    ],
    []
  )

  const data = React.useMemo(() => makeData(5, 1, 0), [])

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Portfolio</span>
          <div>
            <Button onClick={showModal}>Sell</Button>{' '}
            <Button onClick={showModal} type="primary">
              Buy
            </Button>
          </div>
        </h1>
        <Table columns={columns} data={data} />
      </div>
      <Modal
        title="Buy/Sell"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        <p>Form Will render here</p>
        <p>Form Will render here</p>
        <p>Form Will render here</p>
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default PortfolioListing
