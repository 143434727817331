import React, { useEffect, useState } from 'react'
import { Table, Button, Space } from 'antd'
import produce from 'immer'
import { get } from 'lodash'
import { DatePicker, Select, Cascader } from 'antd'
import { Option } from 'antd/lib/mentions'
import { PlusOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import ReactSelect from 'react-select'
import moment from 'moment'
import { AdminLayout } from 'Components'
import { FormService, PageServiceService } from 'services'
const { RangePicker } = DatePicker

const Rule = ({
  masterIndex,
  i,
  master,
  setMaster,
  groupList,
  formList,
  standaloneFieldList,
  vars,
  valueResolver,
  options,
  htmlResolver,
  rules,
}) => {
  const [fields, setFields] = useState([])
  const [groupOptions, setGroupOptions] = useState([])
  const handleFieldTypChange = (e) => {
    let type =
      master?.[masterIndex]?.rules?.[i]?.fieldType || master?.[masterIndex]?.rules?.[i]?.type
    if (type === 'form') getForm(e.value)
    if (type == 'group') getGroup(e.value)
    const newState = produce(master, (draft) => {
      draft[masterIndex].rules[i].groupId = e.value
      draft[masterIndex].rules[i].varValue = ''
      draft[masterIndex].rules[i].value = ''
      draft[masterIndex].rules[i].expression = ''
    })
    setMaster(newState)
  }
  const getForm = (id, noLoader = false) => {
    PageServiceService.FormDetail(id, { detail: true }, noLoader).then((res) => {
      console.log('res', res)
      setFields(
        res?.data?.data?.items
          ?.filter((item) => item.type === 'field')
          ?.map((item) => ({
            ...item.field,
            label: item.field.name,
            value: item.field.id,
            options: item.field.options,
          })) || []
      )
    })
  }
  const getGroup = (id, noLoader = false) => {
    PageServiceService.GroupDetail(id, { detail: true }, noLoader).then((res) => {
      setFields(
        res?.data?.data?.fields?.map((item) => ({
          ...item.field,
          label: item.field.name,
          value: item.field.id,
          options: item.field.options,
        })) || []
      )
    })
  }
  useEffect(() => {
    let arr = []
    let type =
      master?.[masterIndex]?.rules?.[i]?.fieldType || master?.[masterIndex]?.rules?.[i]?.type
    if (type == 'group') arr = groupList
    if (type == 'form') arr = formList
    if (type == 'standalone') arr = standaloneFieldList
    let val = arr.filter((item) => item.value == master[masterIndex].rules[i].groupId)[0]
    if (val?.value) {
      if (type === 'group') getGroup(val.value, true)
      if (type === 'form') getForm(val.value, true)
    }
  }, [groupList, formList])
  return (
    <div>
      {i > 0 && (
        <div>
          <select
            value={master[masterIndex].rules[i].combinator}
            onChange={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[i].combinator = e.target.value
              })
              setMaster(newState)
            }}
          >
            <option value="and">and</option>
            <option value="or">or</option>
          </select>
        </div>
      )}
      <div className="rule_inner_cont">
        <div className="rule">
          <p>
            Entity Type
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  draft[masterIndex].rules[i].entityType = e.target.value
                })
                setMaster(newState)
              }}
              value={master[masterIndex].rules[i].entityType}
            >
              <option value=""></option>
              <option value="entity">Entity</option>
              <option value="business">Entity Business</option>
              <option value="current_user">Current User</option>
              <option value="current_business">Current Business</option>
            </select>
            <>
              {' '}
              Field Type{' '}
              <select
                onChange={(e) => {
                  const newState = produce(master, (draft) => {
                    draft[masterIndex].rules[i].fieldType = e.target.value
                    draft[masterIndex].rules[i].value = null
                    draft[masterIndex].rules[i].varValue = ''
                    draft[masterIndex].rules[i].expression = ''
                  })
                  setMaster(newState)
                }}
                value={master[masterIndex].rules[i].fieldType}
              >
                <option value=""></option>
                <option value="standalone">Standalone</option>
                <option value="group">Groups</option>
                <option value="form">Forms</option>
              </select>
            </>
            {master[masterIndex].rules[i].fieldType &&
              master[masterIndex].rules[i].fieldType != 'standalone' && (
                <>
                  {(() => {
                    let type = master[masterIndex].rules[i].fieldType
                    if (type == 'group') return 'Groups '
                    if (type == 'form') return 'Forms '
                  })()}
                  <div style={{ display: 'inline-block', width: '150px' }}>
                    <ReactSelect
                      value={(() => {
                        let arr = []
                        let type = master[masterIndex].rules[i].fieldType
                        if (type == 'group') arr = groupList
                        if (type == 'form') arr = formList
                        if (type == 'standalone') arr = standaloneFieldList
                        return arr.filter(
                          (item) => item.value == master[masterIndex].rules[i].groupId
                        )[0]
                      })()}
                      onChange={(e) => {
                        handleFieldTypChange(e)
                      }}
                      options={(() => {
                        let type = master[masterIndex].rules[i].fieldType
                        if (type == 'group') return groupList
                        if (type == 'form') return formList
                        if (type == 'standalone') return standaloneFieldList
                      })()}
                    />
                  </div>
                </>
              )}
            {' If '}
            <Cascader
              showSearch
              options={
                master[masterIndex].rules[i].fieldType == 'standalone'
                  ? standaloneFieldList
                  : fields
              }
              value={[get(master[masterIndex], 'rules[' + i + '].varValue', '')]}
              onChange={(val, doc) => {
                const newState = produce(master, (draft) => {
                  draft[masterIndex].rules[i].name = doc[doc.length - 1].label
                  draft[masterIndex].rules[i].type = doc[doc.length - 1].type
                  draft[masterIndex].rules[i].expression = null
                  draft[masterIndex].rules[i].value = null
                  draft[masterIndex].rules[i].varValue = val
                  draft[masterIndex].rules[i].expression = ''
                })
                setMaster(newState)
              }}
              placeholder="Please select"
            />
            {master[masterIndex].rules[i].type && (
              <>
                {' Expression '}
                <select
                  value={master[masterIndex].rules[i].expression}
                  onChange={(e) => {
                    const newState = produce(master, (draft) => {
                      draft[masterIndex].rules[i].expression = e.target.value
                      draft[masterIndex].rules[i].value =
                        draft[masterIndex].rules[i].type == 'multiple_select'
                          ? []
                          : valueResolver(draft[masterIndex].rules[i].html)
                      draft[masterIndex].rules[i].html =
                        e.target[e.target.selectedIndex].getAttribute('data-html')
                    })
                    setMaster(newState)
                  }}
                >
                  <option></option>
                  {options?.[master?.[masterIndex]?.rules[i]?.type]?.map((option, optionIndex) => (
                    <option data-html={option.type} value={option.expression}>
                      {option.condition_name}
                    </option>
                  ))}
                </select>
              </>
            )}
            {!master[masterIndex].rules[i].expression ||
            master[masterIndex].rules[i].expression == '$ib' ||
            master[masterIndex].rules[i].expression == '$inb' ? null : master[masterIndex].rules[i]
                .type == 'single_select' ||
              master[masterIndex].rules[i].type == 'multi' ||
              master[masterIndex].rules[i].type == 'multiple_select' ? (
              <Select
                mode={
                  master[masterIndex].rules[i].type == 'multi' ||
                  master[masterIndex].rules[i].type == 'multiple_select' ||
                  master[masterIndex].rules[i].expression == '$il' ||
                  master[masterIndex].rules[i].expression == '$nil'
                    ? 'multiple'
                    : undefined
                }
                onChange={(val) => {
                  const newState = produce(master, (draft) => {
                    draft[masterIndex].rules[i].value = val
                  })
                  setMaster(newState)
                }}
                value={master[masterIndex].rules[i].value}
              >
                {(master[masterIndex].rules[i].fieldType == 'standalone'
                  ? standaloneFieldList
                  : fields || []
                )
                  .filter((field) => field.id == master[masterIndex].rules[i].varValue[0])[0]
                  ?.options?.map((item) => (
                    <Option value={item.value} key={item.value}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
            ) : (
              htmlResolver(master[masterIndex].rules[i].html, masterIndex, i)
            )}
          </p>
        </div>
        {i === rules.length - 1 ? (
          <Button
            shape="circle"
            icon={<PlusOutlined />}
            title="Add statement"
            onClick={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules.push({
                  name: '',
                  type: '',
                })
              })
              setMaster(newState)
            }}
          />
        ) : (
          <Button
            onClick={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules.splice(i, 1)
              })
              setMaster(newState)
            }}
            shape="circle"
            icon={<CloseOutlined />}
            title="Remove statement"
          />
        )}
      </div>
    </div>
  )
}

export default Rule
