import Cookies from 'js-cookie'
import axios from 'axios'
import { get } from 'lodash'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { store } from 'Store'
toast.configure()

export const CLIENT_ID = '2HVBLjBuyhAVFK3s7CO3jPr0dl5EKwFqGSF5W129'
export const CLIENT_SECRET =
  'nUENlXc0jStTr3XrmO5Luo0wRAtkmqASQy3LXOspwZG8YWmZ0ar2Ne7DFhBZ2T1QqkwEGd1wDwvoLVfOjRJkKHfn2bbDFDsJuKlDwvANwODRf9zVj6Cwq7ldCeD820mW'
export const baseUrl = 'https://api.metadvice.com.au/api/v1/'
// export const baseUrl = "http://127.0.0.1:8000/api/v1/";

let numberOfAjaxCAllPending = 0

const api = axios.create({
  baseURL: baseUrl,
})

api.interceptors.request.use(async (config) => {
  // Do something before request is sent
  numberOfAjaxCAllPending++
  if (!config.headers.noLoader) {
    store.dispatch({ type: 'app/toggleLoader', payload: true })
  }
  delete config.headers.noLoader
  let { headers } = config
  let token = await Cookies.get('token')
  if (token) {
    headers.Authorization = 'Bearer ' + token
  }
  return config
})

api.interceptors.response.use(
  (res) => {
    numberOfAjaxCAllPending--
    if (numberOfAjaxCAllPending == 0) {
      //hide loader
      store.dispatch({ type: 'app/toggleLoader', payload: false })
    }
    if (res && res.data && res.data.status.message) {
      toast.success(res.data.status.message, { hideProgressBar: true })
    }
    return Promise.resolve(res)
  },
  (err) => {
    numberOfAjaxCAllPending--
    if (numberOfAjaxCAllPending == 0) {
      //hide loader
      store.dispatch({ type: 'app/toggleLoader', payload: false })
    }
    const errors = get(err, 'response.data.status.detail', null)
    if (errors) {
      for (let error in errors) {
        for (let item of errors[error]) {
          toast.error(error + ':' + item, { hideProgressBar: true })
        }
      }
    }
    return Promise.reject(err)
  }
)

export default api
