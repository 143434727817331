import { Input, Form, Tabs, Button, Switch, Upload, Select } from 'antd'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import ThemeManager from 'Pages/Dashboard/themeManager'
const { TabPane } = Tabs
const { Option } = Select
const EditBusiness = (props) => {
  return (
    <AdminLayout preview={props.preview}>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Edit Business</span>
          <Button type="primary">Save</Button>
        </h1>

        <br />

        <Tabs defaultActiveKey="1">
          <TabPane tab="Business Info" key="1">
            <div style={{ maxWidth: 800 }}>
              <Form layout="vertical">
                <div className="align_center">
                  <Upload
                    name="profile_pic"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    showRemoveIcon={true}
                  ></Upload>
                </div>
                <Form.Item label="Business name">
                  <Input name="business_name" />
                </Form.Item>
                <Form.Item label="Type">
                  <Select>
                    <Option value=""></Option>
                    <Option value="parent">Parent</Option>
                    <Option value="child">Child</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Phone">
                  <Input name="phone_number" />
                </Form.Item>
                <Form.Item label="Email">
                  <Input name="email" type="email" />
                </Form.Item>
                <div>
                  <label>
                    Active <Switch style={{ marginLeft: 15 }} name="is_active" />
                  </label>
                </div>
              </Form>
            </div>
          </TabPane>
          <TabPane tab="Theme Settings" key="2">
            <ThemeManager preview={props.preview} reset={true} />
          </TabPane>
        </Tabs>
      </div>
    </AdminLayout>
  )
}

export default EditBusiness
