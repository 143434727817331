import React from 'react'
import { HomeLayout } from 'Components'
import './page.scss'
import { useEffect, useState } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash'
export default function AboutUs() {
  const [about_us, SetAboutUs] = useState('')

  const about_usDetail = async () => {
    const res = await StaticPageService.GetAboutUs()
    await SetAboutUs(get(res, 'data.data.about_us'))
  }

  useEffect(() => {
    about_usDetail()
  }, [])

  return (
    <HomeLayout>
      <div className="public_pages">
        <div className="container">
          <h1>About Us</h1>
          <div className="static_content" dangerouslySetInnerHTML={{ __html: about_us }}></div>
        </div>
      </div>
    </HomeLayout>
  )
}
