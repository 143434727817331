import React, { useEffect, useRef, useState } from 'react'
import { Steps, Modal, Button, Select, message, Table } from 'antd'
import { AdminLayout } from 'Components'
import { PageService, PdfConfigService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import './wizard.scss'
import PreviewModal from 'Pages/Admin/Pages/PreviewModal'
import { useNavigate } from 'react-router-dom'
import TinyEditor from 'Components/TinyEditor'
import { EditOutlined, FilePdfOutlined } from '@ant-design/icons'
import RiskProfileSummary from './riskProfileSummary'

const { Step } = Steps

const { Option } = Select

const QuestionnaireWizard = ({ preview }) => {
  const steps = [
    {
      title: 'First',
      content: 'First-content',
    },
    {
      title: 'Second',
      content: 'Second-content',
    },
  ]
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  const [current, setCurrent] = useState(0)
  const [risks, setRisks] = useState([])
  const [questionnaire, setQuestionnaire] = useState({})
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const [template, setTemplate] = useState([])
  const [editModal, setEditModal] = useState(false)
  const business = preview ? previewUser?.details?.business : user?.details?.business

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const next = () => {
    setCurrent(current + 1)
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const getQuestionnaire = (id) => {
    PdfConfigService.getQuestionnaire(params.wizard, {
      detail: true,
      wizard_history: get(params, 'wizard_history', ''),
    })
      .then((res) => {
        let data = get(res, 'data.data', {})

        let page_data = data.pages.filter((item) => item.show)
        if (data?.show_score) {
          page_data.push({ name: 'Summary', show: true })
        }
        page_data.push({ name: 'Files', show: true })
        data['pages'] = page_data
        setQuestionnaire(data)
      })
      .catch((err) => {})
  }

  const getRiskProfiles = async (form) => {
    const res = await PageService.getFormData({
      form,
      entity: previewUser.details.id || user.details.id,
      page: '2f760c5d-6ef6-437f-bbaa-10e23629ac83',
    })
    const data = get(res, 'data')
    var a = []
    data?.data?.map((item) => {
      Object.keys(item.group_data).map(function (key) {
        a.push(item.group_data[key]['a82446dd-0561-4d1d-a742-97cb832c886e'])
      })
    })
    setRisks(a)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  useEffect(() => {
    getQuestionnaire()
  }, [current])

  // useEffect(() => {
  //   getRiskProfiles("2f760c5d-6ef6-437f-bbaa-10e23629ac83");
  // }, [previewUser, user]);
  const navigate = useNavigate()
  const childRef = useRef(null)

  const containerRef = useRef(null)

  const centerStep = (index) => {
    if (containerRef.current) {
      const stepsContainer = containerRef.current.firstChild // Get the direct child (Steps container)
      const steps = Array.from(stepsContainer.children) // Get the actual steps

      if (steps[index]) {
        const step = steps[index]
        const stepWidth = step.offsetWidth
        const stepLeft = step.offsetLeft
        const newScrollPosition = stepLeft + stepWidth / 2 - containerRef.current.offsetWidth / 2
        containerRef.current.scrollLeft = newScrollPosition
      }
    }
  }

  useEffect(() => {
    centerStep(current)
  }, [current])

  return (
    <AdminLayout preview={preview}>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <div style={{ padding: '15px 20px', backgroundColor: '#fff' }}>
          <h1 className="flex_title">
            <span className="title">{questionnaire.display_name || questionnaire.name}</span>
            {!params.view && (
              <Button
                onClick={() => {
                  PdfConfigService.createWizardHistory({
                    entity: previewUser?.details?.id || user?.details.id,
                    questionnaire: questionnaire.id,
                    data: questionnaire,
                  }).then((res) => {
                    navigate(-1)
                  })
                }}
                type="primary"
              >
                Create History
              </Button>
            )}
          </h1>
        </div>
        <div className="scrollable-steps" ref={containerRef}>
          <Steps current={current}>
            {[...(questionnaire?.pages?.filter((item) => item.show) || [])]?.map((item, index) => {
              return (
                <Step
                  onClick={() => setCurrent(index)}
                  key={item.title}
                  title={item.display_name || item.name}
                  style={{ cursor: 'pointer' }}
                />
              )
            })}
          </Steps>
        </div>

        <div style={{ marginTop: '20px' }}>
          {questionnaire?.pages &&
          ['Files', 'Summary'].includes(questionnaire?.pages[current].name) ? (
            questionnaire?.pages[current].name === 'Summary' ? (
              <RiskProfileSummary
                wizard_history={params.wizard_history}
                entity={previewUser.details.id || user.details.id}
                business={business}
              />
            ) : (
              <Table
                className="custom_responsive_table"
                columns={[
                  {
                    title: 'Name',
                    dataIndex: 'display_name',
                    key: 'display_name',
                    render: (data) => (
                      <div className="table_responsive_cell">
                        <h6>Name</h6>
                        <b>{data}</b>
                      </div>
                    ),
                  },
                  {
                    title: 'Action',
                    dataIndex: 'pages',
                    key: 'pages',
                    width: '150px',
                    render: (data) => (
                      <>
                        <Button
                          disabled={data.length > 0 ? false : true}
                          icon={<EditOutlined />}
                          size={'medium'}
                          onClick={() => {
                            setTemplate(data)
                            setEditModal(true)
                          }}
                        />{' '}
                        <Button
                          disabled={data.length > 0 ? false : true}
                          icon={<FilePdfOutlined />}
                          size={'medium'}
                          onClick={() => {
                            setTemplate(data)
                            setIsModalVisible(true)
                          }}
                        />
                      </>
                    ),
                  },
                ]}
                dataSource={questionnaire.pdfs}
              />
            )
          ) : (
            <PreviewModal
              noModal
              showSave
              name=""
              preview={params.view}
              pageId={questionnaire?.pages?.[current]?.id}
              items={questionnaire?.pages?.[current]?.items}
              wizard_history={params.wizard_history}
              wizard_is_locked={params.is_locked === 'true'}
              ref={childRef}
            />
          )}
        </div>
      </div>
      {/*
      <div style={{ margin: 10, padding: "15px 20px" }}>
        {false ? (
          <div className="steps-content">
            <div className="dummy_content">
              <div className="score_card">
                <table className="bordered">
                  <thead>
                    <tr>
                      <th colSpan={3}>Total Score - 200</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="bordered_cell" style={{ width: 80 }}>
                        Question 1
                      </td>
                      <td
                        className="bordered_cell"
                        style={{ textAlign: "right" }}
                        colSpan={2}
                      >
                        20
                      </td>
                    </tr>
                    <tr>
                      <td className="bordered_cell" style={{ width: 80 }}>
                        Question 2
                      </td>
                      <td
                        className="bordered_cell"
                        style={{ textAlign: "right" }}
                        colSpan={2}
                      >
                        20
                      </td>
                    </tr>
                    <tr>
                      <td className="bordered_cell" style={{ width: 80 }}>
                        Question 3
                      </td>
                      <td
                        className="bordered_cell"
                        style={{ textAlign: "right" }}
                        colSpan={2}
                      >
                        20
                      </td>
                    </tr>
                    <tr>
                      <td className="bordered_cell" style={{ width: 80 }}>
                        Question 4
                      </td>
                      <td
                        className="bordered_cell"
                        style={{ textAlign: "right" }}
                        colSpan={2}
                      >
                        20
                      </td>
                    </tr>
                    <tr>
                      <td className="bordered_cell" style={{ width: 80 }}>
                        <b>Total</b>
                      </td>
                      <td
                        className="bordered_cell"
                        style={{ textAlign: "right" }}
                        colSpan={2}
                      >
                        80<div>(Conservative)</div>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}></td>
                    </tr>
                    <tr>
                      <td style={{ width: 80 }}>Agreed</td>
                      <td colSpan={2} style={{ textAlign: "left" }}>
                        <Select
                          defaultValue={risks[0]}
                          style={{ width: "100%" }}
                        >
                          {risks.map((item) => {
                            return <Option value={item}>{item}</Option>;
                          })}
                        </Select>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}></td>
                    </tr>
                    <tr>
                      <td colSpan={3}></td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <textarea placeholder="Comments"></textarea>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      */}

      <div style={{ margin: 10, padding: '15px 20px' }}>
        <div className="steps-action">
          <Button size="large" className="wizard_buttons" onClick={() => navigate(-1)}>
            Exit
          </Button>
          {current > 0 && (
            <Button size="large" className="wizard_buttons" onClick={() => prev()}>
              Previous
            </Button>
          )}
          {current < questionnaire?.pages?.length && (
            <Button
              size="large"
              className="wizard_buttons"
              type="primary"
              onClick={async () => {
                if (params.is_locked == 'true') {
                  await next()
                } else {
                  // await getQuestionnaire();
                  await childRef.current.savePageData().then((res) => next())
                }
              }}
            >
              Next
            </Button>
          )}
          {current === questionnaire?.pages?.length && (
            <Button
              size="large"
              className="wizard_buttons"
              type="primary"
              onClick={() => message.success('Form Submitted Successfully!')}
            >
              Done
            </Button>
          )}
        </div>
      </div>

      <Modal
        className="pdf_page_view_modal"
        title="Download File"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save">Save as draft</Button>,
          <Button key="submit" type="primary">
            Lock &amp; Create History
          </Button>,
        ]}
      >
        {template?.map((page) => (
          <div className="each_pdf_page pdf_page">
            {page.children.map((child) => (
              <div dangerouslySetInnerHTML={{ __html: child.value }}></div>
            ))}
          </div>
        ))}
      </Modal>

      <Modal
        className="pdf_page_view_modal"
        title="Edit File"
        visible={editModal}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
      >
        {template?.map((page) => (
          <div className="each_pdf_page pdf_page">
            {get(page, 'children', []).map((item) => (
              <TinyEditor isInline={true} value={item.value || '<div></div>'}></TinyEditor>
            ))}
          </div>
        ))}
      </Modal>
    </AdminLayout>
  )
}

export default QuestionnaireWizard
