import {AdminLayout} from "../../Components";
import React from "react";
import AssetTransaction from "./AssetTransaction";

export default function NetWealthFilterTransaction(props) {

    const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Transaction</span>
      </h1>
      <AssetTransaction/>
    </div>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}