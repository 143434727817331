import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, InputNumber, Select, Button, Modal, Table, Checkbox } from 'antd'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import * as yup from 'yup'
import { PageServiceService } from 'services'
import _, { get } from 'lodash'
const { Option } = Select
const { Search } = Input
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, e) => (
      <div className="table_responsive_cell">
        <h6>Name</h6>
        <b>{e.display_name || e.name}</b>
      </div>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (text) => (
      <div className="table_responsive_cell">
        <h6>Type</h6>
        {text}
      </div>
    ),
  },
]

const AddPDFReport = (props) => {
  let { id } = useParams()
  let navigate = useNavigate()

  let schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    category: yup.string().required('Category is required'),
    display_name: yup.string(),
  })
  const pageMockData = [
    {
      name: 'Page 1',
    },
    {
      name: 'Page 2',
    },
  ]
  const [data, SetData] = useState([])

  const [allPages, setAllPages] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [state, setState] = useState(pageMockData)

  const GetFieldList = async () => {
    const res = await PageServiceService.PageList()
    SetData(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    setAllPages(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
  }

  useEffect(async () => {
    await GetFieldList()
  }, [])

  const removeItem = (id) => {
    setState(state.filter((item) => item.id !== id))
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRows);
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const children = []
  for (let i = 1; i < 10; i++) {
    children.push(<Option key={i.toString(36) + i}>Business {i.toString(36) + i}</Option>)
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Add/Edit Report</span>
          <div>
            {' '}
            <Button type="primary">Save Report</Button>
          </div>
        </h1>
        <div>
          <Form layout="vertical">
            <Form.Item label="Report Name">
              <Input style={{ width: '100%' }} placeholder="Name" name="name" />
            </Form.Item>
            <Form.Item label="Display Name">
              <Input style={{ width: '100%' }} placeholder="Display name" name="display_name" />
            </Form.Item>
          </Form>
          <div className="field_preview" style={{ marginLeft: 0 }}>
            <h2 style={{ marginBottom: 35 }}>
              Pages{' '}
              <Button
                style={{ marginLeft: 50 }}
                onClick={showModal}
                icon={<PlusOutlined />}
                size={'small'}
                type="primary"
              >
                Add Pages
              </Button>
            </h2>
            <ReactSortable className="sortable_fields" tag="ul" list={state} setList={setState}>
              {state.map((item, i) => (
                <li className="draggable_list group_field" key={item.id}>
                  <span className="item_name">{item.display_name || item.name}</span>
                  <div>
                    <Checkbox
                      defaultChecked
                      disabled
                      title="Signature setting is controlled by admin for each page"
                    >
                      Signature required?
                    </Checkbox>
                    <Button
                      title="Manage conditions"
                      icon={<QuestionCircleOutlined />}
                      size={'medium'}
                      onClick={() => {
                        navigate('/admin/conditions', { push: true })
                      }}
                    />{' '}
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size={'medium'}
                      onClick={() => {
                        let arr = [...data, item]
                        SetData(_.orderBy(arr, ['name'], ['asc']))
                        removeItem(item.id)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ReactSortable>
          </div>
        </div>
        <Modal
          title="Search and select fields"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary">
              Add Selected Pages
            </Button>,
          ]}
        >
          <Search
            onChange={(e) =>
              SetData(
                allPages.filter((item) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
              )
            }
            placeholder="Search fields"
            style={{ width: '100%' }}
          />
          <Table
            className="custom_responsive_table"
            rowSelection={rowSelection}
            rowKey="id"
            columns={columns}
            dataSource={data}
          />
        </Modal>
      </div>
    </AdminLayout>
  )
}

export default AddPDFReport
