import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { Select, Button, Modal } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import './knowledge.scss'
import { AuthService, StrategyService } from '../../services'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'

const initialItems = {
  droppable1: [{ id: 'item1', content: 'Item 1' }],
  droppable2: [],
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source)
  const destClone = Array.from(destination)
  const [removed] = sourceClone.splice(droppableSource.index, 1)
  destClone.splice(droppableDestination.index, 0, removed)

  const result = {}
  result[droppableSource.droppableId] = sourceClone
  result[droppableDestination.droppableId] = destClone

  return result
}

const KnowledgeArea = (props) => {
  const [state, setState] = useState(initialItems)
  const [data, setData] = useState([])
  const [entity_data, setEntityData] = useState({})
  const [type, setType] = useState('area')

  const [detailData, setDetailData] = useState({})

  const getDetail = async (id) => {
    // await setId(id);
    // await setIsModalVisible(true);
    if (type == 'area') {
      const res = await StrategyService.getDetailStrategyArea(id)
      setDetailData(get(res, 'data.data'))
    } else {
      const res = await StrategyService.getDetailStrategyAttribute(id)
      setDetailData(get(res, 'data.data'))
    }

    // formik.setValues(get(res, "data.data"));
    // setWebLinks(get(res, "data.data.web_links", []));
    // setVideoLinks(get(res, "data.data.video_links", []));
    // setPermissions(get(res, "data.data.permissions", {}));
  }

  let navigate = useNavigate()

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')

  let entity = null
  if (entity_type == 'client') {
    entity = params.get('entity')
  }

  const getData = async () => {
    const entity_data = await getEntityData()
    const res = await StrategyService.getStrategyArea({
      business: entity_data.business,
    })
    setState({ droppable2: get(res, 'data.data', []) })
  }

  const getAtrributeData = async (business, area) => {
    const res = await StrategyService.getStrategyAttribute({
      business: business,
      area: area,
    })
    setState({ droppable2: get(res, 'data.data', []) })
  }

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    setEntityData(new_data)
    return new_data
  }

  useEffect(() => {
    getData()
  }, [])

  const onDragEnd = (result) => {
    const { source, destination } = result

    if (!destination) {
      return
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(state[source.droppableId], source.index, destination.index)
      const newState = { ...state, [source.droppableId]: items }
      setState(newState)
    } else {
      const result = move(
        state[source.droppableId],
        state[destination.droppableId],
        source,
        destination
      )
      setState({
        ...state,
        [source.droppableId]: result[source.droppableId],
        [destination.droppableId]: result[destination.droppableId],
      })
    }
  }

  const [clickedItems, setClickedItems] = useState([])

  const toggleItemClicked = (id) => {
    setClickedItems((prevState) => {
      const isAlreadyClicked = prevState.includes(id)
      if (isAlreadyClicked) {
        return prevState.filter((item) => item !== id)
      } else {
        return [...prevState, id]
      }
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = (e, id) => {
    e.stopPropagation()
    setIsModalOpen(true)
    getDetail(id)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const onClickAttribute = (e, business, area) => {
    e.stopPropagation()
    // navigate(`/attribute-area?entity=${entity}&entity_type=client&area=${area}`)
    getAtrributeData(business, area)
    setType('attribute')
  }

  const onClickAreaButton = (e) => {
    e.stopPropagation()
    getData()
    setType('area')
  }

  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Fact sheets</span>
        {type == 'attribute' && (
          <Button type="primary" onClick={(e) => onClickAreaButton(e)}>
            Go to Area
          </Button>
        )}
      </h1>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <div className="knowledge_area">
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <div
                  className={
                    snapshot.isDraggingOver
                      ? 'cards_knowledge_container'
                      : 'cards_knowledge_container'
                  }
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {state.droppable2.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id} index={index}>
                      {(provided, snapshot) => (
                        <div
                          onClick={() => toggleItemClicked(item.id)}
                          className={`${snapshot.isDragging ? 'knowledge_box cards_knowledge_box flip-card is_dragging' : 'knowledge_box cards_knowledge_box flip-card'} ${clickedItems.includes(item.id) ? 'clicked' : ''}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            background: `linear-gradient(to right, ${type === 'area' ? get(item, 'color', '#808080') : get(item, 'area.color', '#808080')},
                                                                ${type === 'area' ? get(item, 'color2', '#808080') : get(item, 'area.color2', '#808080')})`,
                          }}
                        >
                          <div className="flip-card-inner">
                            <div className="flip-card-front">
                              {type === 'area' && get(item, 'icon', '') && (
                                <span
                                  className="material-icons-outlined"
                                  style={{
                                    width: 24,
                                    fontSize: '24px',
                                    marginRight: '5px',
                                  }}
                                >
                                  {get(item, 'icon', '')}
                                </span>
                              )}
                              {type === 'attribute' && get(item, 'area.icon', '') && (
                                <span
                                  className="material-icons-outlined"
                                  style={{
                                    width: 24,
                                    fontSize: '24px',
                                    marginRight: '5px',
                                  }}
                                >
                                  {get(item, 'area.icon', '')}
                                </span>
                              )}
                              <h3 style={{ margin: '0' }}>{get(item, 'subject')}</h3>
                              <div>
                                <p></p>
                              </div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: get(item, 'intro', ''),
                                }}
                              />
                            </div>
                            <div className="flip-card-back">
                              <div>
                                <p>{item.subject}</p>
                                <div>
                                  <p></p>
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: get(item, 'body_text'),
                                  }}
                                />
                                <div>
                                  <p></p>
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: get(item, 'summary'),
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Button
                                  type="primary"
                                  size="small"
                                  onClick={(e) => showModal(e, item.id)}
                                >
                                  Open Modal
                                </Button>
                                {
                                  type === 'area' && (
                                    <ArrowRightOutlined
                                      style={{ fontSize: 20, marginLeft: 5 }}
                                      title="Go to attribute"
                                      onClick={(e) =>
                                        onClickAttribute(e, entity_data.business, item.id)
                                      }
                                    />
                                  )
                                  // <Button type="primary" onClick={(e) => onClickAttribute(e, entity_data.business, item.id)}>
                                  // Go to attribute
                                  // </Button>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        </DragDropContext>
      </div>
      <Modal
        title={detailData.subject}
        visible={isModalOpen}
        width={'100%'}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary">
            Print
          </Button>,
        ]}
      >
        <div dangerouslySetInnerHTML={{ __html: get(detailData, 'intro') }} />
        <br />
        <div dangerouslySetInnerHTML={{ __html: get(detailData, 'body_text') }} />
        <br />
        <div dangerouslySetInnerHTML={{ __html: get(detailData, 'summary') }} />
        <div>
          {get(detailData, 'web_links', []).map((item) => (
            <a href={item.web_link} target="_blank" rel="noopener noreferrer">
              {item.web_name}
            </a>
          ))}
        </div>
        <br />
        <div>
          {get(detailData, 'video_links', []).map((item) => (
            <a href={item.video_link} target="_blank" rel="noopener noreferrer">
              {item.video_icon}
            </a>
          ))}
        </div>
      </Modal>
    </div>
  )

  return Main
}

export default KnowledgeArea
