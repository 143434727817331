import api from './api'

const endpoints = {
  element_chart: '/data-saving/element-chart/',
  risk_profile_chart: '/data-saving/risk-profile-chart/',
}

const ElementChartDataList = async (params) => {
  return api.get(endpoints.element_chart, { params })
}

const RiskProfileDataList = async (params) => {
  return api.get(endpoints.risk_profile_chart, { params })
}

export default {
  ElementChartDataList,
  RiskProfileDataList,
}
