import React, { useEffect, useState } from 'react'
import { Checkbox } from 'antd'
import { Link, useNavigate } from 'react-router-dom'
import { Radio } from 'antd'
import { HomeLayout } from 'Components'
import { SubscriptionService, AuthService } from 'services'
import { get } from 'lodash'
import { useFormik } from 'formik'
import * as yup from 'yup'
import Cookies from 'js-cookie'
import { AuthUtils } from 'utils'

export default function Signup() {
  const navigate = useNavigate()
  const [plan_data, SetPlan] = useState([])
  const [plan_detail, SetPlanDetail] = useState({})
  // const [errors, SetError] = useState({user_type: 'Please select advisor or client', phone_number:'',
  //     confirm_password: '', password: '', accept: 'Please accept term and conditions'})
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

  const queryString = require('query-string')
  const query_obj = queryString.parse(window.location.search)

  let schema = yup.object().shape({
    user_type: yup.string().required('This is required fields'),
    plan_type: yup.string().required('This is required fields'),
    first_name: yup.string().required('First name is required fields'),
    business_name: yup.string().when('user_type', {
      is: 'user',
      then: yup.string().required('business name is required fields'),
    }),
    last_name: yup.string().required('Last name is required fields'),
    phone_number: yup
      .string()
      .required('Phone number is required fields')
      .matches(AuthUtils.phone_regex(), 'Phone number is not valid'),
    password: yup
      .string()
      .required('password is required fields')
      .matches(
        AuthUtils.password_regex(),
        'Minimum 8 characters, at least 1 letter, 1 number and 1 special character'
      ),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    accept: yup.boolean().oneOf([true], 'Please accept term & conditions and privacy and policy'),
    email: yup.string().email('Invalid email').required('Required'),
  })

  const formik = useFormik({
    initialValues: {
      user_type: query_obj.user_type ? query_obj.user_type : '',
      plan_type: query_obj.plan_type ? query_obj.plan_type : '',
      first_name: '',
      last_name: '',
      phone_number: '',
      email: '',
      password: '',
      confirm_password: '',
      accept: false,
    },
    // validationSchema: schema,
    onSubmit: async (values) => {
      let response = await AuthService.signup(values)

      const expire_in = (await get(response, 'data.token.expires_in')) / (3600 * 24)
      Cookies.set('user_id', get(response, 'data.data.id'), {
        expires: expire_in,
      })
      Cookies.set('user_type', get(response, 'data.data.user_type'), {
        expires: expire_in,
      })
      Cookies.set('token', get(response, 'data.token.access_token'), {
        expires: expire_in,
      })
      Cookies.set('refresh_token', get(response, 'data.token.refresh_token'), {
        expires: expire_in,
      })
      navigate('/user/dashboard')
      //alert(JSON.stringify(values, null, 2));
    },
    validationSchema: schema,
  })

  const PricingDetail = async (id) => {
    if (id) {
      const res = await SubscriptionService.GetPricingPlanDetail(id, {
        public: true,
      })
      SetPlanDetail(get(res, 'data.data'))
    } else {
      SetPlanDetail({})
    }
  }

  const PricingPlanList = async (user_type) => {
    let plan = ''
    if (user_type === 'user') {
      plan = 'business'
    } else if (user_type === 'client') {
      plan = 'individual'
    }
    if (plan) {
      const res = await SubscriptionService.GetPricingPlanList({
        plan_type: plan,
      })
      await SetPlan(get(res, 'data.data'))
    }
  }

  useEffect(() => {
    if (query_obj.user_type && query_obj.plan_type) {
      PricingPlanList(query_obj.user_type)
      PricingDetail(query_obj.plan_type)
    }
  }, [])

  const PlanTypeOptions = () => {
    return plan_data.map((item) => (
      <option value={item.id} key={item.id}>
        {item.plan_name} ({item.plan_type}) - ${item.price}/month
      </option>
    ))
  }

  return (
    <HomeLayout>
      <div className="auth">
        <div className="container">
          <div className="signup">
            <div className="signup_features">
              <h1>Sign Up</h1>
              {get(plan_detail, 'plan_name') ? (
                <h2>
                  Your selected {get(plan_detail, 'plan_type')} {get(plan_detail, 'plan_name')} has
                  following benefits
                </h2>
              ) : (
                <h2>You have not selected any plan.</h2>
              )}

              <ul>
                {get(plan_detail, 'capabilities', []).map((item) => (
                  <li key={item.id}>
                    <span className="material-icons">check_circle</span>
                    {item.capability.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="base_form">
              <form onSubmit={formik.handleSubmit}>
                <div className="form_group " style={{ alignItems: 'center' }}>
                  <label className="form_label" style={{ marginRight: 20 }}>
                    I'm a/an
                  </label>
                  <div className="spread_input_half">
                    <Radio.Group
                      buttonStyle="solid"
                      name="user_type"
                      onChange={(e) => {
                        PricingPlanList(e.target.value)
                        formik.handleChange(e)
                      }}
                      value={formik.values.user_type}
                    >
                      <Radio.Button value={'user'}>Advisor</Radio.Button>
                      <Radio.Button value={'client'}>Client</Radio.Button>
                    </Radio.Group>
                    {formik.touched.user_type && formik.errors.user_type && (
                      <span className="input-error-message">{formik.errors.user_type}</span>
                    )}
                  </div>
                </div>
                <div className="form_group">
                  <label className="form_label">Select plan type</label>
                  <select
                    name="plan_type"
                    value={formik.values.plan_type}
                    onChange={(e) => {
                      PricingDetail(e.target.value)
                      formik.handleChange(e)
                    }}
                  >
                    <option value=""></option>
                    {PlanTypeOptions()}
                  </select>
                  {formik.touched.plan_type && formik.errors.plan_type && (
                    <span className="input-error-message">{formik.errors.plan_type}</span>
                  )}
                </div>
                {formik.values.user_type === 'user' ? (
                  <div className="form_group">
                    <label className="form_label">Business Name</label>
                    <input
                      type="text"
                      name="business_name"
                      value={formik.values.business_name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.business_name && formik.errors.business_name && (
                      <span className="input-error-message">{formik.errors.business_name}</span>
                    )}
                  </div>
                ) : null}
                <div className="form_group half">
                  <div>
                    <label className="form_label">First Name</label>
                    <input
                      type="text"
                      name="first_name"
                      value={formik.values.first_name}
                      onChange={(e) => formik.handleChange(e)}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <span className="input-error-message">{formik.errors.first_name}</span>
                    )}
                  </div>
                  <div>
                    <label className="form_label">Last Name</label>
                    <input
                      type="text"
                      name="last_name"
                      value={formik.values.last_name}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <span className="input-error-message">{formik.errors.last_name}</span>
                    )}
                  </div>
                </div>
                <div className="form_group">
                  <label className="form_label">Phone Number</label>
                  <input
                    type="text"
                    name="phone_number"
                    value={formik.values.phone_number}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone_number && formik.errors.phone_number && (
                    <span className="input-error-message">{formik.errors.phone_number}</span>
                  )}
                </div>
                <div className="form_group">
                  <label className="form_label">Email Address</label>
                  <input
                    type="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.email && formik.errors.email && (
                    <span className="input-error-message">{formik.errors.email}</span>
                  )}
                </div>

                <div className="form_group">
                  <label className="form_label">Password</label>
                  <div className="input_mask">
                    <input
                      type="password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                    />
                    <button>
                      {/*<span className="material-icons-outlined">visibility</span>*/}
                      {/* <span className="material-icons-outlined">visibility_off</span> */}
                    </button>
                    {formik.touched.password && formik.errors.password && (
                      <span className="input-error-message">{formik.errors.password}</span>
                    )}
                  </div>
                </div>
                <div className="form_group">
                  <label className="form_label">Confirm Password</label>
                  <div className="input_mask">
                    <input
                      type="password"
                      name="confirm_password"
                      value={formik.values.confirm_password}
                      onChange={formik.handleChange}
                    />
                    <button>
                      <span className="material-icons-outlined">visibility</span>
                      {/* <span className="material-icons-outlined">visibility_off</span> */}
                    </button>
                    {formik.touched.confirm_password && formik.errors.confirm_password && (
                      <span className="input-error-message">{formik.errors.confirm_password}</span>
                    )}
                  </div>
                </div>
                <div>
                  <Checkbox
                    name="accept"
                    checked={formik.values.accept}
                    onChange={formik.handleChange}
                  >
                    I accept all <Link to="/">Terms &amp; Conditions</Link> and{' '}
                    <Link to="/">Privacy Policy</Link>
                  </Checkbox>
                  {formik.touched.accept && formik.errors.accept && (
                    <span className="input-error-message">{formik.errors.accept}</span>
                  )}
                </div>
                <button type="submit" className="primary_form">
                  SIGN UP
                </button>
              </form>
              {/* <div className="success_signup">
                                    <div>
                                        <span className="material-icons-outlined">check_circle</span>
                                        <h3>Registration Successful</h3>
                                        <p>Please check your email inbox as well as spam folder to verify your email address</p>
                                    </div>
                                    <Link to="/login" className="primary_form">Go to Login</Link>
                                </div> */}
            </div>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}
