import { Dropdown, Layout, Menu, Avatar } from 'antd'
import React, { useEffect } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { AuthService } from 'services'
import { useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { deleteUser, setUser } from 'Store/reducers/userSlice'
import { setTheme } from 'Store/reducers/themeReducer'
import { PageService } from 'services'
import { getFirstPage } from 'utils/helpers'

const { Header } = Layout

export default function HomeHead() {
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)
  useEffect(() => {
    // getUser();
  }, [])

  // const getUser = async () => {
  //   let res = await AuthService.getCurrentUser();

  //   dispatch(setUser(get(res, "data", {})));
  //   // navigate(firstPage ? "/user/page/" + firstPage : "/user/dashboard");
  // };

  const previewUser = useSelector((state) => state.previewUser)
  useEffect(() => {
    getTheme()
  }, [user, previewUser])

  const getTheme = async () => {
    try {
      let userType = document.URL.includes('preview')
        ? previewUser?.details?.user_type
        : user?.details?.user_type
      let previewUserId =
        userType == 'business' ? previewUser?.details?.id : previewUser?.details?.business
      let userId = userType == 'business' ? previewUser?.details?.id : user?.details?.business

      let entity =
        userType === 'admin'
          ? ''
          : document.URL.includes('preview')
            ? previewUserId || userId
            : userId
      if (!entity) throw 400
      const res = await PageService.getPageData({
        entity,
        page: '44fe4067-ef89-46e5-920f-16690bc0f735',
      })
      const themeData = get(res, 'data.data.data', {}) || {}
      setThemeData(themeData)
    } catch {
      setThemeData({})
    }
  }

  const setThemeData = (themeData) => {
    const themeChartData = {
      data: {
        data: {
          logo: themeData['f845705a-add1-4839-a2a7-3f8ed6e1f3be']
            ? themeData['f845705a-add1-4839-a2a7-3f8ed6e1f3be']
            : 'https://metadvice.com.au/horizontal_logo.svg',
          theme_color: {
            primaryColor: themeData['7a4c2378-8af8-4140-b5c8-050ec021ab57']
              ? themeData['7a4c2378-8af8-4140-b5c8-050ec021ab57']
              : '#1890ff',
            errorColor: themeData['882e715c-b5d9-485c-8e76-c06650a8a57f']
              ? themeData['882e715c-b5d9-485c-8e76-c06650a8a57f']
              : '#d51e20',
            warningColor: themeData['022cd4db-49d4-4507-bfff-58eb6264e58e']
              ? themeData['022cd4db-49d4-4507-bfff-58eb6264e58e']
              : '#1919cc',
            successColor: themeData['92620700-c4ef-4592-b9fa-97c334a83d10']
              ? themeData['92620700-c4ef-4592-b9fa-97c334a83d10']
              : '#a149ee',
            infoColor: themeData['edd5e5b3-8047-40be-9263-d142c541adb3']
              ? themeData['edd5e5b3-8047-40be-9263-d142c541adb3']
              : '#c98809',
            topColor: themeData['950bc107-142e-4eca-8931-6e9da9875bf8']
              ? themeData['950bc107-142e-4eca-8931-6e9da9875bf8']
              : '#001529',
          },
          chart_color: {
            chart1: themeData['c1e7cd2d-967c-4c68-b5e7-98a89d50db4a']
              ? themeData['c1e7cd2d-967c-4c68-b5e7-98a89d50db4a']
              : '#1890ff',
            chart2: themeData['463e2e45-08bf-49d6-ae2f-95ad2128afc5']
              ? themeData['463e2e45-08bf-49d6-ae2f-95ad2128afc5']
              : '#417505',
            chart3: themeData['f93a6bc3-99e9-4ef4-bfde-0d2ddda2fe26']
              ? themeData['f93a6bc3-99e9-4ef4-bfde-0d2ddda2fe26']
              : '#1466b5',
            chart4: themeData['be7706f2-6b52-453e-8f0e-9e2e25d88173']
              ? themeData['be7706f2-6b52-453e-8f0e-9e2e25d88173']
              : '#52c41a',
            chart5: themeData['73daf6b6-005b-4728-8ff1-8ef35bc35dac']
              ? themeData['73daf6b6-005b-4728-8ff1-8ef35bc35dac']
              : '#9c27b0',
            chart6: themeData['0ce7b6a8-d3a8-451d-a690-c7d39aa0d6ba']
              ? themeData['0ce7b6a8-d3a8-451d-a690-c7d39aa0d6ba']
              : '#dcbf15',
            chart7: themeData['c40dd17b-c1b7-4c30-bee0-6a40161173f9']
              ? themeData['c40dd17b-c1b7-4c30-bee0-6a40161173f9']
              : '#0E8E89',
            chart8: themeData['bf286f39-143e-4efd-9465-4bd1618cdc46']
              ? themeData['bf286f39-143e-4efd-9465-4bd1618cdc46']
              : '#E19348',
            chart9: themeData['6d03d4b4-e453-400f-9179-0bb895f0bd09']
              ? themeData['6d03d4b4-e453-400f-9179-0bb895f0bd09']
              : '#F383A2',
            chart10: themeData['ce3641d5-3812-40de-8933-155a5ce00cc7']
              ? themeData['ce3641d5-3812-40de-8933-155a5ce00cc7']
              : '#247FEA',
          },
        },
      },
    }
    dispatch(setTheme(get(themeChartData, 'data', {})))
    document.documentElement.style.setProperty(
      '--topbar',
      themeChartData.data.data.theme_color.topColor
    )
  }

  const dispatch = useDispatch()
  const LogoutClick = async () => {
    navigate('/')

    await AuthService.logout()
    dispatch(deleteUser())
    Cookies.remove('user_id')
    Cookies.remove('user_type')
    Cookies.remove('token')
    Cookies.remove('refresh_token')
  }

  const userMenu = (
    <Menu>
      <Menu.Item key="1">
        <a href="/user/profile">My Profile</a>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => navigate('/change-password')}>
        Change Password
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3" style={{ cursor: 'pointer' }} onClick={LogoutClick}>
        Logout
      </Menu.Item>
    </Menu>
  )
  return (
    <Header
      className="header"
      style={{
        backgroundColor: useSelector((state) => state.theme.current_theme.theme_color.topColor),
      }}
    >
      <div className="logo">
        <img alt="MetAdvise logo" src={useSelector((state) => state.theme.current_theme.logo)} />
      </div>
      <div className="user_profile_dropdown">
        <Dropdown overlay={userMenu} placement="bottom" arrow>
          <div>
            <Avatar
              style={{ backgroundColor: '#f56a00', verticalAlign: 'middle' }}
              size="large"
              src={get(user, 'details.profile_pic', '')}
            >
              {get(user, 'details.first_name', '').charAt(0)}
            </Avatar>{' '}
            <span className="user_menu_name">
              {get(user, 'details.first_name', '') + ' ' + get(user, 'details.last_name', '')}{' '}
            </span>
            <DownOutlined />
          </div>
        </Dropdown>
      </div>
    </Header>
  )
}
