import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { BusinessService } from 'services'
import { get } from 'lodash'
import AdviceDocConfig from './AdviceDocConfig'

const { TabPane } = Tabs

export default function AdviceDocConfigSetting({ preview }) {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type === 'business') {
    business = params.get('entity')
  }

  const [business_list, setBusinessList] = useState([])

  const get_business_list = async (params = { is_active: true, business: business }) => {
    const res = await BusinessService.business(params)
    const businesses = get(res, 'data.data', [])
    const transformedData = businesses.map((business) => ({
      name: business.business_name,
      id: business.id,
      users: [
        {
          name: 'User 1',
          id: uuidv4(),
        },
        {
          name: 'User 2',
          id: uuidv4(),
        },
      ],
    }))

    setBusinessList(transformedData)
  }

  useEffect(() => {
    get_business_list()
  }, [])

  return (
    <div>
      <Tabs type="card">
        <TabPane tab="Advice Doc" key="1">
          <AdviceDocConfig preview={preview} business_list={business_list} />
        </TabPane>
        <TabPane tab="Section" key="2">
          <div></div>
        </TabPane>
        <TabPane tab="Content" key="3">
          <div></div>
        </TabPane>
        <TabPane tab="Additional info" key="4">
          <div></div>
        </TabPane>
      </Tabs>
    </div>
  )
}
