const password_regex = () => {
  //Minimum eight characters, at least one letter, one number and one special character:
  return '^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$'
}

const phone_regex = () => {
  //phone validation
  return '^\\+?\\d*$'
}

export default {
  password_regex,
  phone_regex,
}
