import { Tooltip, Form } from 'antd'
const Heading = (props) => {
  return (
    <Form.Item>
      <div className="form_field">
        <div>
          {props.level == 'h1' && <h1>{props.displayName ? props.displayName : props.name}</h1>}
          {props.level == 'h2' && <h2>{props.displayName ? props.displayName : props.name}</h2>}
          {props.level == 'h3' && <h3>{props.displayName ? props.displayName : props.name}</h3>}
          {props.level == 'h4' && <h4>{props.displayName ? props.displayName : props.name}</h4>}
          {props.level == 'h5' && <h5>{props.displayName ? props.displayName : props.name}</h5>}
          {props.level == 'h6' && <h6>{props.displayName ? props.displayName : props.name}</h6>}
        </div>
      </div>
    </Form.Item>
  )
}

export default Heading
