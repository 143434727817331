import api from './api'

const endpoints = {
  questionnaire: 'questionnaire/',
}

const getQuestionnaires = async (params) => {
  return api.get(endpoints.questionnaire, { params })
}

const getQuestionnaire = async (id) => {
  return api.get(endpoints.questionnaire + id + '/')
}

const saveQuestionnaire = async (data) => {
  return api.post(endpoints.questionnaire, data)
}
const updateQuestionnaire = async (data, id) => {
  return api.put(endpoints.questionnaire + id + '/', data)
}

const deleteQuestionnaire = async (id) => {
  return api.delete(endpoints.questionnaire + id + '/')
}

export default {
  getQuestionnaires,
  getQuestionnaire,
  saveQuestionnaire,
  updateQuestionnaire,
  deleteQuestionnaire,
}
