import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Row, Col, Tabs } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { StrategyService } from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'
import CustomTree from 'Components/CustomTree/CustomTree'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

const { TabPane } = Tabs

export default function AdviceDocConfig({ preview, business_list }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [permissions, setPermissions] = useState({})
  const [activeKey, setActiveKey] = useState('1')
  const navigate = useNavigate()

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Subject</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Short name',
      dataIndex: 'short_name',
      key: 'short_name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Short name</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Expiry date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Expiry date</h6>
          {text && moment(text).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setPermissions({})
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    const res = await StrategyService.getDetailAdviceDocConfig(id)
    formik.setValues(get(res, 'data.data'))
    setPermissions(get(res, 'data.data.permissions', {}))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this config?')
    if (result) {
      await StrategyService.deleteAdviceDocConfig(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await StrategyService.getAdviceDocConfig({
      business: business,
    })
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    subject: yup.string().required('Subject Required'),
    short_name: yup.string(),
    expiry_date: yup.string(),
    description: yup.string(),
  })

  const initialValues = {
    subject: '',
    expiry_date: '',
    short_name: '',
    description: '',
    permissions: {},
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      values['permissions'] = permissions
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this config?')
          if (result) {
            await StrategyService.updateAdviceDocConfig(values, id)
          }
        } else {
          await StrategyService.updateAdviceDocConfig(values, id)
        }
      } else {
        await StrategyService.saveAdviceDocConfig(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this doc config to global?')
    if (result) {
      const res = await StrategyService.getResetAdviceDocConfig(id)
      formik.setValues(get(res, 'data.data'))
    }
  }
  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  const onCheckPerMission = (checkedKeys) => {
    setPermissions(checkedKeys)
    // console.log(checkedKeys);
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add doc config
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit doc config' : 'Add doc config'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Subject">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Subject"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>
              <Form.Item label="Short name">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Short name"
                  name="short_name"
                  onChange={formik.handleChange}
                  value={formik.values.short_name}
                />
                {formik.touched.short_name && formik.errors.short_name && (
                  <span className="input-error-message">{formik.errors.short_name}</span>
                )}
              </Form.Item>
              <Form.Item label="Expiry date">
                <Input
                  style={{ width: '100%' }}
                  type="date"
                  placeholder="Expiry date"
                  name="expiry_date"
                  onChange={formik.handleChange}
                  value={formik.values.expiry_date}
                />
                {formik.touched.expiry_date && formik.errors.expiry_date && (
                  <span className="input-error-message">{formik.errors.expiry_date}</span>
                )}
              </Form.Item>
              <Form.Item label="Description">
                <QuillEditor
                  theme="snow"
                  name="description"
                  value={formik.values.description}
                  onChange={(value) => formik.setFieldValue('description', value)}
                />
              </Form.Item>
            </Form>
          </TabPane>
          <TabPane tab="Permissions" key="2">
            <CustomTree
              preview={preview}
              onCheckPerMission={onCheckPerMission}
              business_list={business_list}
              permissions={permissions}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
