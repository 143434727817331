import { Radio, Select, Form, Tooltip, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
const { Option } = Select

const Boolean = (props) => {
  useEffect(() => {
    if (first) {
      setValue(props.value)
      setFirst(false)
    }
  }, [props.value])

  const [value, setValue] = useState('')
  const [first, setFirst] = useState(true)

  useEffect(() => {
    if (!first && props.dependantValue && props.dependsOn) {
      setValue(props.filtering.values[props.dependantValue].defaultValue)
    }
  }, [props.dependantValue])

  useEffect(() => {
    if (props.onChange) props.onChange(value)
  }, [value])

  return (
    <Form.Item>
      {props.display === 'dropdown' ? (
        props.layout === 'horizontal' ? (
          <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
            {!props.exclude_label ? (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Select
                style={{ width: 120 }}
                disabled={props.isReadOnly}
                defaultValue={props.defaultBool}
                value={value || props.defaultBool}
                onChange={setValue}
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </div>
          </div>
        ) : (
          <div className="form_field">
            {!props.exclude_label ? (
              <label>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Select
                style={{ width: 120 }}
                disabled={props.isReadOnly}
                defaultValue={props.defaultBool}
                value={value}
                onChange={setValue}
              >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </div>
          </div>
        )
      ) : props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <Radio.Group
              disabled={props.isReadOnly}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <Space direction="horizontal">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ marginBottom: 10, display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <Radio.Group
              disabled={props.isReadOnly}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            >
              <Space direction="vertical">
                <Radio value="yes">Yes</Radio>
                <Radio value="no">No</Radio>
              </Space>
            </Radio.Group>
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default Boolean
