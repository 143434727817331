import { Link, useNavigate } from 'react-router-dom'
import { HomeLayout } from 'Components'
import { Radio } from 'antd'
import { useFormik } from 'formik'
import * as yup from 'yup'
import React from 'react'
import { AuthService } from 'services'

export default function ForgotPassword() {
  const navigate = useNavigate()
  let schema = yup.object().shape({
    user_type: yup.string().required('Please select admin, advisor or client'),
    email: yup.string().required('Email is required fields'),
  })

  const formik = useFormik({
    initialValues: {
      user_type: '',
      email: '',
    },
    onSubmit: async (values) => {
      await AuthService.send_forgot_link(values)
      navigate('/login')
    },
    validationSchema: schema,
  })

  return (
    <HomeLayout>
      <div className="auth">
        <div className="container">
          <div className="base_form login">
            <h1>Forgot password</h1>
            <form onSubmit={formik.handleSubmit}>
              <div style={{ textAlign: 'center' }}>
                <Radio.Group
                  buttonStyle="solid"
                  name="user_type"
                  onChange={formik.handleChange}
                  value={formik.values.user_type}
                >
                  <Radio.Button value={'admin'}>Admin</Radio.Button>
                  <Radio.Button value={'user'}>Advisor</Radio.Button>
                  <Radio.Button value={'client'}>Client</Radio.Button>
                </Radio.Group>
              </div>
              {formik.touched.user_type && formik.errors.user_type && (
                <span style={{ textAlign: 'center' }} className="input-error-message">
                  {formik.errors.user_type}
                </span>
              )}
              <div className="form_group">
                <label className="form_label">Email Address or Username</label>
                <input
                  type="text"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="input-error-message">{formik.errors.email}</span>
                )}
              </div>
              <div className="help_text left" style={{ marginTop: -10 }}>
                <Link to="/login"> {'< '} Go back to Login</Link>
              </div>
              <button type="submit" className="primary_form">
                Send reset instructions
              </button>
            </form>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}
