import { Table, Input, Select, Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { useEffect, useState } from 'react'
import { PageServiceService } from '../../../services'
import { get } from 'lodash'
const { Option } = Select
const Groups = (props) => {
  let navigate = useNavigate()
  const [data, SetData] = useState([])
  const [allData, setAllData] = useState([])
  const [filter, setFilter] = useState('all')

  const DeleteFields = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} group ?`)
    if (result) {
      const res = await PageServiceService.GroupDelete(id)
      await GetGroupList()
    }
  }

  const columns = [
    {
      title: 'Group Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Group Name</h6>
          <b>{e.name}</b>
        </div>
      ),
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Display Name</h6>
          <b>{e.display_name || '-'}</b>
        </div>
      ),
    },
    {
      title: 'Number of fields',
      dataIndex: 'field_count',
      key: 'field_count',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Number of fields</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Category</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/groups/edit/' + record.id)}
          />
          <Button icon={<EyeOutlined />} size={'medium'} />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={() => DeleteFields(record.id, record.name)}
          />
        </Space>
      ),
    },
  ]

  const GetGroupList = async () => {
    const res = await PageServiceService.GroupList()
    SetData(get(res, 'data.data'))
    setAllData(get(res, 'data.data', []))
  }

  useEffect(async () => {
    await GetGroupList()
  }, [])

  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Manage Groups</span>{' '}
        <Button
          onClick={() => {
            navigate('/admin/groups/add', { push: true })
          }}
          icon={<PlusOutlined />}
          type="primary"
        >
          Create Group
        </Button>
      </h1>

      <Input.Group compact style={{ width: '100%' }}>
        <Select
          defaultValue="all"
          style={{ width: '140px' }}
          onChange={(val) => setFilter(val)}
          value={filter}
        >
          <Option value="all">All categories</Option>
          <Option value="name">Name</Option>
          <Option value="category">Category</Option>
        </Select>
        <Input.Search
          onChange={(e) => {
            let arr = []
            if (filter === 'all') {
              arr = allData.filter(
                (item) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                  item.category.toLowerCase().includes(e.target.value.toLowerCase())
              )
            }
            if (filter === 'name') {
              arr = allData.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            }
            if (filter === 'category') {
              arr = allData.filter((item) =>
                item.category.toLowerCase().includes(e.target.value.toLowerCase())
              )
            }
            SetData(arr)
          }}
          allowClear
          style={{ width: 'calc(100% - 140px)' }}
        />
      </Input.Group>
      <br />
      <Table className="custom_responsive_table" columns={columns} dataSource={data} />
    </div>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default Groups
