import api from './api'

const endpoints = {
  theme: 'theme-manager/',
  table_config_manager: 'table-config-manager/',
  restore: 'theme-manager/restore/',
}

const getTheme = async (params) => {
  return api.get(endpoints.theme, { params })
}

const saveTheme = async (data) => {
  return api.put(endpoints.theme, data)
}

const getTableConfig = async (params) => {
  return api.get(endpoints.table_config_manager, { params })
}

const saveTableConfig = async (data) => {
  return api.put(endpoints.table_config_manager, data)
}

const resetTheme = async (data) => api.post(endpoints.restore, data)

export default {
  getTheme,
  saveTheme,
  resetTheme,
  getTableConfig,
  saveTableConfig,
}
