import { Input, Table, Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  CheckCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { PageService, PdfConfigService } from 'services'
import { useEffect, useState } from 'react'
import { get } from 'lodash'

// const data = [
//   {
//     key: '1',
//     name: 'Cover',
//     signature : 'No',
//     type: 'Today',
//   },
//   {
//     key: '2',
//     name: 'Back Cover',
//     signature : 'Yes',
//     type: 'Today',
//   },
//   {
//     key: '3',
//     name: 'Assets',
//     signature : 'Yes',
//     type: 'Today',
//   },
// ];
const PDFConfigList = (props) => {
  let navigate = useNavigate()
  const [data, setData] = useState([])

  const get_data = async () => {
    const res = await PdfConfigService.getPageConfigs()
    setData(get(res, 'data.data', []))
  }

  useEffect(() => {
    get_data()
  }, [])

  const DeletePageLayout = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} page config ?`)
    if (result) {
      await PdfConfigService.deletePageConfig(id)
      await get_data()
    }
  }

  const columns = [
    {
      title: 'Page Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Page Name</h6>
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Display Name</h6>
          {text}
        </div>
      ),
    },

    {
      title: 'Signature required?',
      dataIndex: 'signature',
      key: 'signature',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Signature required?</h6>
          {get(record, 'default_page.require_signature', false) ? (
            <Button type="primary" icon={<CheckCircleOutlined />} size={'medium'} />
          ) : (
            <Button danger icon={<CloseOutlined />} size={'medium'} />
          )}
        </div>
      ),
    },
    // {
    //   title: 'Added On',
    //   dataIndex: 'type',
    //   key: 'type',
    // },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => {
              navigate('/admin/pdf-config/edit-page/' + record.id)
            }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={(e) => DeletePageLayout(record.id, record.name)}
          />
        </Space>
      ),
    },
  ]

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Manage PDF Config Pages</span>{' '}
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              navigate('/admin/pdf-config/add-page')
            }}
          >
            Add Page
          </Button>
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table className="custom_responsive_table" columns={columns} dataSource={data} />
      </div>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default PDFConfigList
