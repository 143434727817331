import api from './api'

const endpoints = {
  area: 'area/',
  reset_area: 'reset-area/',
  goal: 'goal/',
  reset_goal: 'reset-goal/',
}

const getArea = async (params) => {
  return api.get(endpoints.area, { params })
}

const getDetailArea = async (id) => {
  return api.get(endpoints.area + id + '/')
}

const getResetArea = async (id) => {
  return api.get(endpoints.reset_area + id + '/')
}

const saveArea = async (data) => {
  return api.post(endpoints.area, data)
}
const updateArea = async (data, id) => {
  return api.put(endpoints.area + id + '/', data)
}

const deleteArea = async (id) => {
  return api.delete(endpoints.area + id + '/')
}

const getGoal = async (params) => {
  return api.get(endpoints.goal, { params })
}

const getDetailGoal = async (id) => {
  return api.get(endpoints.goal + id + '/')
}

const getResetGoal = async (id) => {
  return api.get(endpoints.reset_goal + id + '/')
}

const saveGoal = async (data) => {
  return api.post(endpoints.goal, data)
}
const updateGoal = async (data, id) => {
  return api.put(endpoints.goal + id + '/', data)
}

const deleteGoal = async (id) => {
  return api.delete(endpoints.goal + id + '/')
}

export default {
  getArea,
  getResetArea,
  getDetailArea,
  saveArea,
  updateArea,
  deleteArea,
  getGoal,
  getDetailGoal,
  getResetGoal,
  saveGoal,
  updateGoal,
  deleteGoal,
}
