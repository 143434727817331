function formatNumberWithCommasAndDecimals(number) {
  const formattedNumber = parseFloat(number).toFixed(2) // Round to 2 decimal places
  const [integerPart, decimalPart] = formattedNumber.split('.')
  const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  if (decimalPart === '00') {
    return integerWithCommas
  }

  return `${integerWithCommas}.${decimalPart}`
}

export default {
  formatNumberWithCommasAndDecimals,
}
