import React, { useEffect, useState } from 'react'
import { Tabs, InputNumber, Radio, Space, Table, Modal, Button, Input } from 'antd'
import { PageServiceService } from 'services'
import get from 'lodash/get'

const GroupModal = ({ visible, setGroupModal, addGroup, index, tabIndex, addNestedGroup }) => {
  const [groups, setGroups] = useState([])
  const [allGroups, setAllGroups] = useState([])
  const [count, setCount] = useState(0)
  useEffect(() => {
    getGroups()
  }, [visible])
  const getGroups = async (page) => {
    if (visible && !groups.length) {
      try {
        let res = await PageServiceService.GroupList()
        setCount(get(res, 'data.count', 0))
        setAllGroups(get(res, 'data.data', []))
        setGroups(get(res, 'data.data', []))
      } catch (err) {}
    }
  }
  return (
    <Modal
      onOk={() => setGroupModal(false)}
      onCancel={() => setGroupModal(false)}
      visible={visible}
    >
      <Input.Group compact style={{ width: '100%' }}>
        <Input.Search
          onChange={(e) => {
            let arr = allGroups.filter((item) =>
              item.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
            setGroups(arr)
          }}
          allowClear
          style={{ width: '100%' }}
        />
      </Input.Group>
      <Table
        className="custom_responsive_table"
        dataSource={groups}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (index != undefined && tabIndex != undefined) {
                addNestedGroup(index, tabIndex, record)
              } else {
                addGroup(record)
              }
              setGroupModal(false)
            },
          }
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'id',
            render: (text, e) => (
              <div className="table_responsive_cell">
                <h6>Name</h6>
                <b>{e.display_name || e.name}</b>
              </div>
            ),
          },
          {
            title: 'Category',
            dataIndex: 'category',
            key: 'id',
            render: (text) => (
              <div className="table_responsive_cell">
                <h6>Category</h6>
                {text}
              </div>
            ),
          },
        ]}
      />
    </Modal>
  )
}

export default GroupModal
