import React, { useState, useEffect } from 'react'
import { Line, Pie } from '@ant-design/charts'
import { useSelector } from 'react-redux'
import { ElementService } from '../../services'
import { get } from 'lodash'
import { Button, Checkbox, Select } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
const { Option } = Select

const CustomLine = ({ element, preview, wizard_history, wizard_is_locked, show_title }) => {
  /*
    const [data, setData] = useState([]);
    useEffect(() => {
        asyncFetch();
    }, []);
    const asyncFetch = () => {
        fetch('https://gw.alipayobjects.com/os/bmw-prod/1d565782-dde4-4bb6-8946-ea6a38ccf184.json')
            .then((response) => response.json())
            .then((json) => setData(json))
            .catch((error) => {
            console.log('fetch data failed', error);
        });
    };
    */
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const entity = preview ? params.get('entity') : user?.details?.id

  const [data, setData] = useState([])
  const [primary_filter_list, setPrimaryFilterList] = useState([])
  const [secondary_filter_obj, setSecondaryFilterObj] = useState({})

  const [primary_filter, setPrimaryFilter] = useState({})
  const [secondary_filter, setSecondaryFilter] = useState({})
  const [selected_filter, setSelected] = useState({})
  const [selected_checkbox, setSelectedCheckBox] = useState([])

  const ElementChartData = async () => {
    try {
      const res = await ElementService.ElementChartDataList({
        element: get(element, 'element.id'),
        entity: entity,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
      const primary_res_data = get(res, 'data.data.primary_filter', {})
      const secondary_res_data = get(res, 'data.data.secondary_filter', {})

      setPrimaryFilter(primary_res_data)
      setSecondaryFilter(secondary_res_data)

      setPrimaryFilterList(get(res, 'data.data.primary_filter_list', []))
      setSecondaryFilterObj(get(res, 'data.data.secondary_filter_obj', {}))
      setData(get(res, 'data.data.all_data', []))

      const first_primary_key = get(res, 'data.data.primary_filter_list', [])[0]

      if (first_primary_key) {
        let first_filtered_data = []
        if (primary_res_data[first_primary_key.id]) {
          for (const [key, value] of Object.entries(
            get(primary_res_data[first_primary_key.id], 'values', [])
          )) {
            first_filtered_data.push({ type: key, value: value })
          }
        }
        await setConfig((prevState) => ({
          ...prevState,
          data: first_filtered_data,
        }))
        setSelected({ type: 'primary', value: first_primary_key.id })
      }
    } catch (err) {}

    // for (const [key, value] of Object.entries(primary_res_data)) {
    //   console.log(`${key}: ${value}`);
    // }
  }

  useEffect(() => {
    ElementChartData()
  }, [])

  var theme = useSelector((state) => state.theme.current_theme)
  var default_config = {
    data: data,
    padding: 'auto',
    xField: 'value',
    yField: 'type',
    theme: {
      colors10: [theme.chart_color.chart1],
    },
    annotations: [
      {
        type: 'regionFilter',
        start: ['min', 'median'],
        end: ['max', '0'],
        color: theme.chart_color.chart1,
      },
      {
        type: 'line',
        start: ['min', 'median'],
        end: ['max', 'median'],
        style: {
          stroke: theme.chart_color.chart2,
          lineDash: [2, 2],
        },
      },
    ],
  }
  const [config, setConfig] = useState(default_config)

  const onClickPrimary = (id) => {
    let filtered_data = []
    if (primary_filter[id]) {
      for (const [key, value] of Object.entries(get(primary_filter[id], 'values', []))) {
        filtered_data.push({ type: key, value: value })
      }
    }

    setConfig((prevState) => ({
      ...prevState,
      data: filtered_data,
    }))
  }

  const onClickSecondary = (id) => {
    let filtered_data = []
    if (secondary_filter[id]) {
      for (const [key, value] of Object.entries(get(secondary_filter[id], 'values', []))) {
        filtered_data.push({ type: key, value: value })
      }
    }

    setConfig((prevState) => ({
      ...prevState,
      data: filtered_data,
    }))
  }

  const onChangeFilter = (value, e) => {
    setSelectedCheckBox([]) //set reset with empty array
    setSelected({ type: e.filter_type, value: value })
    if (e.filter_type == 'primary') {
      onClickPrimary(value)
    } else {
      onClickSecondary(value)
    }
  }

  const hasAnyCommonItem = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item))
  }

  const handleCheckboxChange = (value) => {
    let filtered_data = []
    setSelectedCheckBox(value)
    if (selected_filter.type == 'primary') {
      filtered_data = data.filter((item) => hasAnyCommonItem(value, item.primary))
    } else {
      filtered_data = data.filter((item) => value.includes(item.secondary))
    }

    setConfig((prevState) => ({
      ...prevState,
      data: filtered_data,
    }))
  }

  const resetCheckboxClick = () => {
    setSelectedCheckBox([])
    if (selected_filter.type == 'primary') {
      onClickPrimary(selected_filter.value)
    } else {
      onClickSecondary(selected_filter.value)
    }
  }
  return (
    <div>
      {show_title !== false && (
        <h1 style={{ textAlign: 'center' }}>{get(element, 'element.title')}</h1>
      )}
      <div style={{ maxWidth: 700, marginLeft: 'auto', marginRight: 'auto' }}>
        <Line {...config} />
      </div>
      <div style={{ textAlign: 'center' }}>
        <div>
          <Select value={selected_filter.value} onChange={onChangeFilter}>
            {primary_filter_list.map((item) => (
              <Option key={item.id} value={item.id} filter_type="primary">
                {item.name}
              </Option>
            ))}
            {secondary_filter_obj.id && (
              <Option
                key={secondary_filter_obj.id}
                value={secondary_filter_obj.id}
                filter_type="secondary"
              >
                {secondary_filter_obj.name}
              </Option>
            )}
          </Select>
          <Button
            style={{ marginLeft: '5px' }}
            icon={<ReloadOutlined />}
            type="link"
            onClick={resetCheckboxClick}
          >
            Reset
          </Button>
        </div>
        {selected_filter.type == 'primary' && primary_filter[selected_filter.value] && (
          <div>
            <Checkbox.Group
              options={Object.keys(primary_filter[selected_filter.value].options).map((key) => ({
                label: primary_filter[selected_filter.value].options[key],
                value: key,
              }))}
              onChange={handleCheckboxChange}
              value={selected_checkbox}
            />
          </div>
        )}

        {selected_filter.type == 'secondary' && secondary_filter[selected_filter.value] && (
          <div>
            <Checkbox.Group
              options={Object.keys(secondary_filter[selected_filter.value].options).map((key) => ({
                label: secondary_filter[selected_filter.value].options[key],
                value: key,
              }))}
              onChange={handleCheckboxChange}
              value={selected_checkbox}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export default CustomLine
