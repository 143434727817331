import React from 'react'
import { Bar, G2 } from '@ant-design/plots'
import { useSelector } from 'react-redux'
const BarStackedPercentChart = () => {
  const data = [
    { year: '2013', type: 'Kernenergie', value: 92.1 },
    { year: '2013', type: 'Braunkohle', value: 145.1 },
    { year: '2013', type: 'Steinkohle', value: 110.6 },
    { year: '2013', type: 'Gas', value: 39.4 },
    { year: '2013', type: 'Wasserkraft', value: 21.0 },
    { year: '2013', type: 'Biomasse', value: 48.3 },
    { year: '2013', type: 'Wind', value: 47.2 },
    { year: '2013', type: 'Solar', value: 29.7 },
    { year: '2014', type: 'Kernenergie', value: 91.8 },
    { year: '2014', type: 'Braunkohle', value: 140.9 },
    { year: '2014', type: 'Steinkohle', value: 99.0 },
    { year: '2014', type: 'Gas', value: 33.2 },
    { year: '2014', type: 'Wasserkraft', value: 18.5 },
    { year: '2014', type: 'Biomasse', value: 53.9 },
    { year: '2014', type: 'Wind', value: 51.4 },
    { year: '2014', type: 'Solar', value: 32.8 },
    { year: '2015', type: 'Kernenergie', value: 87.1 },
    { year: '2015', type: 'Braunkohle', value: 139.4 },
    { year: '2015', type: 'Steinkohle', value: 103.9 },
    { year: '2015', type: 'Gas', value: 30.0 },
    { year: '2015', type: 'Wasserkraft', value: 20.2 },
    { year: '2015', type: 'Biomasse', value: 56.5 },
    { year: '2015', type: 'Wind', value: 84.6 },
    { year: '2015', type: 'Solar', value: 36.8 },
    { year: '2016', type: 'Kernenergie', value: 80.0 },
    { year: '2016', type: 'Braunkohle', value: 134.8 },
    { year: '2016', type: 'Steinkohle', value: 100.0 },
    { year: '2016', type: 'Gas', value: 45.2 },
    { year: '2016', type: 'Wasserkraft', value: 20.8 },
    { year: '2016', type: 'Biomasse', value: 49.3 },
    { year: '2016', type: 'Wind', value: 77.8 },
    { year: '2016', type: 'Solar', value: 37.5 },
  ]
  G2.registerInteraction('element-link', {
    start: [
      {
        trigger: 'interval:mouseenter',
        action: 'element-link-by-color:link',
      },
    ],
    end: [
      {
        trigger: 'interval:mouseleave',
        action: 'element-link-by-color:unlink',
      },
    ],
  })
  var theme = useSelector((state) => state.theme.current_theme)
  const config = {
    data,
    color: [
      theme.chart_color.chart1,
      theme.chart_color.chart8,
      theme.chart_color.chart2,
      theme.chart_color.chart3,
      theme.chart_color.chart4,
      theme.chart_color.chart5,
      theme.chart_color.chart6,
      theme.chart_color.chart7,
      theme.chart_color.chart9,
      theme.chart_color.chart10,
    ],
    colorField: 'type',
    xField: 'value',
    yField: 'year',
    seriesField: 'type',
    isPercent: true,
    isStack: true,
    meta: {
      value: {
        min: 0,
        max: 1,
      },
    },
    label: {
      position: 'middle',
      content: (item) => {
        return `${(item.value * 100).toFixed(2)}%`
      },
      style: {
        fill: '#fff',
      },
    },
    tooltip: false,
    interactions: [
      {
        type: 'element-highlight-by-color',
      },
      {
        type: 'element-link',
      },
    ],
  }
  return <Bar {...config} />
}
export default BarStackedPercentChart
