import React, { Component } from 'react'
import Nestable from 'react-nestable'
import { Select, Tabs, InputNumber, Radio, Space, Input, Modal, Cascader, Button } from 'antd'
import { AdminLayout } from 'Components'
import '../../form.scss'

function Toolbar({ addElement, setFieldModal, addTabGroup, setGroupModal, addForm, setFormModal }) {
  return (
    <div className="form_tools">
      <button
        className="button_tool"
        title="Element"
        onClick={() => {
          addElement()
        }}
      >
        <span className="icon material-icons-outlined">donut_small</span>
        <span className="text">Element</span>
      </button>
      <button
        className="button_tool"
        title="Group"
        onClick={() => {
          setGroupModal(true)
        }}
      >
        <span className="icon material-icons-outlined">vertical_split</span>
        <span className="text">Group</span>
      </button>
      <button
        className="button_tool"
        title="Group"
        onClick={() => {
          setFieldModal(true)
        }}
      >
        <span className="icon material-icons-outlined">toggle_on</span>
        <span className="text">Field</span>
      </button>
      {setFormModal && (
        <button
          className="button_tool"
          title="Form"
          onClick={() => {
            setFormModal(true)
          }}
        >
          <span className="icon material-icons-outlined">checklist</span>
          <span className="text">Form</span>
        </button>
      )}
      <button
        className="button_tool"
        title="Tab"
        onClick={() => {
          addTabGroup('tab')
        }}
      >
        <span className="icon material-icons-outlined">calendar_view_week</span>
        <span className="text">Tab</span>
      </button>
    </div>
  )
}

export default Toolbar
