import { Input, Table, Button, Space } from 'antd'
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  HistoryOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { useNavigate } from 'react-router-dom'
import { PdfConfigService } from '../../services'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import React from 'react'

const data = [
  {
    key: '1',
    version: 1,
    pages: 2,
    createdOn: 'Thursday, 22nd March, 2022',
    entries: 2,
  },
  {
    key: '2',
    version: 2,
    pages: 3,
    createdOn: 'Thursday, 22nd March, 2022',
    entries: 0,
  },
  {
    key: '3',
    version: 3,
    pages: 5,
    createdOn: 'Thursday, 22nd March, 2022',
    entries: 2,
  },
]
const WizardListing = ({ data }) => {
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          <a
            onClick={() => {
              navigate(
                `wizard-history?wizard=${record.id}&entity_id=${entity_id}&entity_type=${entity_type}`,
                { push: true }
              )
            }}
          >
            {record.display_name || record.name}
          </a>
        </div>
      ),
    },

    {
      title: 'Number of entries',
      dataIndex: 'history_count',
      key: 'history_count',
      width: '150px',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Number of entries</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '150px',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EyeOutlined />}
            size={'medium'}
            //onClick={() => navigate("/admin/questionnaire/wizard")}
            onClick={() =>
              navigate(
                '/preview/user/wizard?view=true&wizard=' +
                  record.id`&entity_id=${entity_id}&entity_type=${entity_type}`,
                {
                  push: true,
                }
              )
            }
          />
        </Space>
      ),
    },
  ]

  let navigate = useNavigate()

  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const entity_id = previewUser?.details?.id || user?.details?.id
  const entity_type = previewUser?.details?.user_type || user?.details?.user_type

  const get_latest_history = (id) => {
    PdfConfigService.getLatestWizardHistory({
      questionnaire: id,
      entity: previewUser?.details?.id || user?.details?.id,
    }).then((res) => {
      const res_data = get(res, 'data.data', null)
      if (res_data) {
        navigate(
          '/preview/user/wizard?view=true&wizard=' +
            res_data +
            `&entity_id=${entity_id}&entity_type=${entity_type}`,
          { push: true }
        )
      }
    })
  }

  return (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title"></span>
          {/* <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate("/admin/questionnaire/add")}
          >
            Add Questionnaire
          </Button> */}
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table className="custom_responsive_table" columns={columns} dataSource={data} />
      </div>
    </>
  )
}

export default WizardListing
