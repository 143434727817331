import React, { useEffect, useState } from 'react'
import { Input, Table, Button, Space, Modal, Select } from 'antd'
import { EyeOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { useNavigate } from 'react-router-dom'
import { PageService } from 'services'
import { get } from 'lodash'
const { Option } = Select

const RiskProfileSummary = ({ wizard_history, entity, business }) => {
  const [risks, setRisks] = useState([])
  const [summaryData, setSummaryData] = useState([])
  const [result, setResult] = useState('')
  const [agreed_result, setAgreedResult] = useState('')
  const [comments, setComments] = useState('')
  const [total, setTotal] = useState('')

  const GetRiskProfileSummary = async () => {
    const res = await PageService.getRiskProfileSummary({
      wizard_history,
      entity,
    })
    setSummaryData(get(res, 'data.data.summary'))
    setTotal(get(res, 'data.data.wizard.score'))
    setResult(get(res, 'data.data.wizard.result'))
    setComments(get(res, 'data.data.wizard.comments'))
    setAgreedResult(get(res, 'data.data.wizard.agreed_result'))
  }
  const GetRiskProfileOptions = async () => {
    const res = await PageService.getRiskProfileOptions({ business })
    setRisks(get(res, 'data.data'))
  }

  const updateRiskProfileSummary = async (data) => {
    const res = await PageService.updateRiskProfileSummary(data)
  }

  useEffect(() => {
    GetRiskProfileOptions()
    GetRiskProfileSummary()
  }, [])

  const OnClickSummary = (e) => {
    e.preventDefault()
    const result = window.confirm(
      `Are you sure to confirm? (if Ok, this will lock the risk profiler)`
    )
    if (result) {
      let data = { agreed_result, comments }
      data['wizard_history'] = wizard_history
      data['entity'] = entity
      updateRiskProfileSummary(data)
    }
  }

  return (
    <div style={{ margin: 10, padding: '15px 20px' }}>
      <div className="steps-content">
        <div className="dummy_content">
          <div className="score_card">
            <table className="bordered" width={'100%'}>
              <thead>
                <tr>
                  <th colSpan={3}>Total Score - {total}</th>
                </tr>
              </thead>
              <tbody key="item__scored_body">
                {summaryData.map((item) => (
                  <tr key={item.id}>
                    <td className="bordered_cell">{item.question}</td>
                    <td
                      className="bordered_cell"
                      style={{ width: 80, textAlign: 'right' }}
                      colSpan={2}
                    >
                      {item.score}
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="bordered_cell">
                    <b>Total</b>
                  </td>
                  <td
                    className="bordered_cell"
                    style={{ width: 80, textAlign: 'right' }}
                    colSpan={2}
                  >
                    {total}
                    <div>({result})</div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      Agreed
                      <Select
                        defaultValue={result}
                        style={{ width: '200px' }}
                        onChange={(value) => setAgreedResult(value)}
                        value={agreed_result}
                      >
                        {risks.map((item) => {
                          return (
                            <Option key={item.id} value={item.value}>
                              {item.value}
                            </Option>
                          )
                        })}
                      </Select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                </tr>
                <tr>
                  <td colSpan={3}></td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ padding: '0px' }}>
                    <textarea
                      placeholder="Comments"
                      value={comments}
                      onChange={(e) => setComments(e.target.value)}
                    ></textarea>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3} style={{ padding: '0px' }}>
                    <Button type="primary" onClick={(e) => OnClickSummary(e)} block>
                      Confirm
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RiskProfileSummary
