import { Input, Table, Button, Modal, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  ThunderboltOutlined,
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  HistoryOutlined,
  LockOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import TinyEditor from 'Components/TinyEditor'
import React, { useEffect, useState } from 'react'
import { PageService, PdfConfigService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'

const ReportsList = ({ data }) => {
  let navigate = useNavigate()
  const historyListColumns = [
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Created On</h6>
          {moment(text).format('MM-DD-YYYY hh:mm A')}
        </div>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (data) => (
        <div className="table_responsive_cell">
          <h6>Created By</h6>
          {data.name}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          {!record.is_locked ? (
            <Button
              icon={<EditOutlined />}
              size={'medium'}
              onClick={() => {
                PdfConfigService.getHistory(record.id).then((res) => {
                  setHistoryDetail(get(res, 'data.data', {}))
                  setEditModal(true)
                })
              }}
            />
          ) : (
            <Button icon={<LockOutlined />} size={'medium'} />
          )}
        </Space>
      ),
    },
  ]
  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Report Name</h6>
          <a
            onClick={() => {
              navigate(`report-history?report=${record.id}`)
            }}
          >
            {record.display_name || record.name}
          </a>
        </div>
      ),
    },
    {
      title: 'No of Files',
      dataIndex: 'history_count',
      key: 'history_count',
      width: '150px',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>No of Files</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '150px',
      render: (text, record) => (
        <Space size="middle">
          {/* <Button icon={<EditOutlined />} size={"medium"} /> */}

          {/* <Button
            icon={<ThunderboltOutlined />}
            size={"medium"}
            onClick={() => getPdfTemplate(record.id)}
            title="Quick Edit"
          ></Button>
          <Button
            icon={<HistoryOutlined />}
            size={"medium"}
            onClick={() => {
              navigate(`report-history?report=${record.id}`);
              // getHistoryList(record.id);
            }}
            title="Quick Edit"
          ></Button>
          */}

          <Button
            icon={<EyeOutlined />}
            size={'medium'}
            onClick={() => get_latest_history(record.id)}
          />
          {/* <Button danger icon={<DeleteOutlined />} size={"medium"} /> */}
        </Space>
      ),
    },
  ]
  const get_latest_history = (id) => {
    PdfConfigService.getLatestReportHistory({
      pdf: id,
      entity: previewUser?.details?.id || user?.details?.id,
    }).then((res) => {
      const res_data = get(res, 'data.data', null)
      console.log(res_data)
      setLatestHistory(res_data)
      setViewHistory(true)
    })
  }
  const getHistoryList = (id) => {
    PdfConfigService.getHistoryList({
      pdf: id,
      entity: previewUser?.details?.id || user?.details?.id,
    }).then((res) => {
      setHistoryList(get(res, 'data.data', []))
      setReportListVisible(true)
    })
  }

  const getPdfTemplate = (id) => {
    PdfConfigService.getPageTemplate(id, { detail: true })
      .then((res) => {
        setTemplate(get(res, 'data.data', {}))
        showModal()
      })
      .catch((err) => {})
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const [formats, setFormats] = useState([
    { title: 'Paragraph', block: 'p', attributes: { class: 'p' } },
    { title: 'Heading 1', block: 'h1', attributes: { class: 'h1' } },
    { title: 'Heading 2', block: 'h2', attributes: { class: 'h2' } },
    { title: 'Heading 3', block: 'h3', attributes: { class: 'h3' } },
    { title: 'Heading 4', block: 'h4', attributes: { class: 'h4' } },
    { title: 'Heading 5', block: 'h5', attributes: { class: 'h5' } },
    { title: 'Heading 6', block: 'h6', attributes: { class: 'h6' } },
  ])

  const [template, setTemplate] = useState({})
  const [historyList, setHistoryList] = useState([])
  const [reportListVisible, setReportListVisible] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [historyDetail, setHistoryDetail] = useState({})

  const [style, setStyle] = useState('')
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)

  const getTemplateThemeRecords = async (form) => {
    const res = await PageService.getFormData({
      form,
      entity: previewUser.details?.business.id || user.details?.business.id,
      page: 'd1c1b901-870b-4c87-9f88-54e3bb1e6a9b',
      field: '68353128-076a-49b6-bd2c-93e21794daf8',
    })
    const data = get(res, 'data')
    let a = []
    data?.options_data?.map((item, index) => {
      var formats = {
        name: item.name,
        title: item.name,
        block: item.value[0] === 'h' ? item.value : 'p',
        attributes: { class: item.value },
      }
      a.push(formats)
    })
    var styles = ''
    data?.data?.map((item) => {
      Object.keys(item.group_data).map(function (key) {
        var styleItem = `
            .${item.group_data[key]['68353128-076a-49b6-bd2c-93e21794daf8']}{
              color: ${
                item.group_data[key]['6f17d93e-1ad6-42bd-be8e-889d9dcc685b']
                  ? item.group_data[key]['6f17d93e-1ad6-42bd-be8e-889d9dcc685b']
                  : '#000000'
              };
              font-family: ${
                item.group_data[key]['7987bb64-50ee-4436-a7e4-3dd90022d1a9']
                  ? item.group_data[key]['7987bb64-50ee-4436-a7e4-3dd90022d1a9']
                  : 'Roboto'
              };
              padding-top: ${
                item.group_data[key]['38711d58-4f6b-48c6-b644-492455d35b7a']?.single
                  ? item.group_data[key]['38711d58-4f6b-48c6-b644-492455d35b7a'].single + 'px'
                  : '14px'
              };
              font-style: ${
                item.group_data[key]['4e6c6bc9-e8d5-4251-b141-abf03e1ed699']
                  ? item.group_data[key]['4e6c6bc9-e8d5-4251-b141-abf03e1ed699']
                  : 'normal'
              };
              font-weight: ${
                item.group_data[key]['aa8d082b-95cc-4d21-a878-3e3a7cbe71fc']
                  ? item.group_data[key]['aa8d082b-95cc-4d21-a878-3e3a7cbe71fc']
                  : '400'
              };
              text-decoration: ${
                item.group_data[key]['982971c9-8106-4ee0-a506-799c41f7eb79']
                  ? item.group_data[key]['982971c9-8106-4ee0-a506-799c41f7eb79']
                  : 'none'
              };
              font-size: ${
                item.group_data[key]['b0d779f7-6332-42e8-9c6a-bcd1c8ce0bbf']?.single
                  ? item.group_data[key]['b0d779f7-6332-42e8-9c6a-bcd1c8ce0bbf'].single + 'px'
                  : '0px'
              };
              padding-bottom: ${
                item.group_data[key]['cc536f9f-8b40-4d49-a3c5-1109bc796135']?.single
                  ? item.group_data[key]['cc536f9f-8b40-4d49-a3c5-1109bc796135'].single + 'px'
                  : '0px'
              };
          }
          `
        styles = styles + styleItem
      })
    })
    setFormats(a)
    setStyle(styles)
  }

  useEffect(() => {
    getTemplateThemeRecords('aac9f32f-08e4-4c01-8509-2bf079fa4ad6')
  }, [previewUser, user])

  const renderTemplateHtml = () => {
    let arr = []
    get(template, 'pages', []).map((page) => page.children.map((child) => arr.push(child.value)))
    return arr
  }
  const [viewHistory, setViewHistory] = useState(false)
  const [latestHistory, setLatestHistory] = useState({})

  return (
    <div>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          {/* <span className="title">Manage Reports</span>{" "} */}
          {/* <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate("/reports/add")}
          >
            Add Report
          </Button> */}
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table className="custom_responsive_table" columns={columns} dataSource={data} />
      </div>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title="Template Quick Edit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              PdfConfigService.createHistory({
                entity: previewUser?.details?.id || user?.details.id,
                pdf: template.id,
                data: template,
              })
            }}
          >
            Create History
          </Button>,
        ]}
      >
        {get(template, 'pages', []).map((page) => (
          <div
            style={{
              boxShadow:
                'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
              padding: '10px',
              margin: '10px',
              height: '1000px',
              overflow: 'auto',
            }}
          >
            {page.children.map((child) => (
              <div dangerouslySetInnerHTML={{ __html: child.value }}></div>
            ))}
          </div>
        ))}
        {/* {renderTemplateHtml().map((item, i) => (
          <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
        ))} */}
      </Modal>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title={`History`}
        visible={reportListVisible}
        onOk={() => setReportListVisible()}
        onCancel={() => setReportListVisible()}
      >
        <Table
          className="custom_responsive_table"
          columns={historyListColumns}
          dataSource={historyList}
        />
      </Modal>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title="Edit History"
        visible={editModal}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
      >
        <div className="page_fake_cont">
          <div className="fake_page">
            {get(historyDetail, 'data.pages', []).map((page) => (
              <div>
                {get(page, 'children', []).map((item) => (
                  <TinyEditor
                    customStyle={style}
                    formats={formats}
                    value={item.value || '<div></div>'}
                  ></TinyEditor>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Modal>
      <Modal
        className="pdf_page_view_modal"
        title="Template Quick Edit"
        visible={viewHistory}
        onOk={() => setViewHistory(false)}
        onCancel={() => setViewHistory(false)}
      >
        {get(latestHistory, 'data.pages', []).map((page) => (
          <div className="each_pdf_page pdf_page">
            {page.children.map((child) => (
              <div dangerouslySetInnerHTML={{ __html: child.value }}></div>
            ))}
          </div>
        ))}
      </Modal>
    </div>
  )
}

export default ReportsList
