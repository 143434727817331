import React, { useState } from 'react'
import { SketchPicker } from 'react-color'
import { Button, ConfigProvider, Modal, Radio, Checkbox, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import { CustomLine, CustomDonutChart } from 'Components'
import { ThemeService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'

function ThemeManager(themeProps) {
  // These are default theme colors
  const { theme } = useSelector((state) => state)
  console.log(theme, 'manager')
  // #TODO: Update state data according to theme redux state
  const themeColors = useSelector((state) => get(state, 'theme.current_theme.theme_color', ''))

  const chartColors = useSelector((state) => get(state, 'theme.current_theme.chart_color', ''))

  const paramsId = useParams().id
  React.useEffect(() => {
    if (themeColors) {
      setThemeColor(themeColors)
    }
    if (chartColors) {
      setChartColor(chartColors)
    }
  }, [themeColors, chartColors])
  const [themeColor, setThemeColor] = useState({
    primaryColor: '#1890ff',
    errorColor: '#ff4d4f',
    warningColor: '#faad14',
    successColor: '#52c41a',
    infoColor: '#1890ff',
  })

  React.useEffect(() => {
    handlePreviewEntity()
  }, [paramsId])

  const handlePreviewEntity = async () => {
    console.log('%%%%%%%%%%', themeProps, paramsId)
    if (themeProps.preview && paramsId) {
      console.log('******************')
      let res = await ThemeService.getTheme({ business: paramsId })
      setThemeColor(res.data.data.theme_color)
      setChartColor(res.data.data.chart_color)
    }
  }
  const props = {
    action: '//jsonplaceholder.typicode.com/posts/',
    listType: 'picture',
    previewFile(file) {
      console.log('Your upload file:', file)
      // Your process logic. Here we just mock to the same file
      return fetch('https://next.json-generator.com/api/json/get/4ytyBoLK8', {
        method: 'POST',
        body: file,
      })
        .then((res) => res.json())
        .then(({ thumbnail }) => thumbnail)
    },
  }

  const [chartColor, setChartColor] = useState({
    chart1: '#1890ff',
    chart2: '#ff4d4f',
    chart3: '#1466b5',
    chart4: '#52c41a',
    chart5: '#9c27b0',
    chart6: '#dcbf15',
    chart7: '#0E8E89',
    chart8: '#E19348',
    chart9: '#F383A2',
    chart10: '#247FEA',
  })

  function onThemeColorChange(nextColor) {
    const mergedNextColor = {
      ...themeColor,
      ...nextColor,
    }
    setThemeColor(mergedNextColor)
    ConfigProvider.config({
      theme: mergedNextColor,
    })
  }

  function onChartColorChange(nextColor) {
    const mergedNextColor = {
      ...chartColor,
      ...nextColor,
    }
    setChartColor(mergedNextColor)
  }

  const handleSubmit = (e, reset = false) => {
    e.preventDefault()
    const data = {
      chart_color: chartColor,
      theme_color: themeColor,
    }
    if (themeProps.preview && paramsId) {
      data.business = paramsId
    }
    if (reset) {
      ThemeService.resetTheme({ business: paramsId })
    } else {
      ThemeService.saveTheme(data)
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div className="theme_manager">
      {console.log('data', themeColor)}
      <br />
      <h3>Logo</h3>
      <div style={{ maxWidth: 400 }}>
        <Upload {...props}>
          <Button icon={<UploadOutlined />}>Upload</Button>
        </Upload>
      </div>
      <br />
      <h3>Theme Colors</h3>
      <div className="pallet_line">
        <div className="inline_color_edit">
          <button style={{ backgroundColor: themeColor.primaryColor }}></button>
          <SketchPicker
            className="float_sketch"
            color={themeColor.primaryColor}
            onChange={({ hex }) => {
              onThemeColorChange({
                primaryColor: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: themeColor.errorColor }}></button>
          <SketchPicker
            className="float_sketch"
            color={themeColor.errorColor}
            onChange={({ hex }) => {
              onThemeColorChange({
                errorColor: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: themeColor.warningColor }}></button>
          <SketchPicker
            className="float_sketch"
            color={themeColor.warningColor}
            onChange={({ hex }) => {
              onThemeColorChange({
                warningColor: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: themeColor.successColor }}></button>
          <SketchPicker
            className="float_sketch"
            color={themeColor.successColor}
            onChange={({ hex }) => {
              onThemeColorChange({
                successColor: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: themeColor.infoColor }}></button>
          <SketchPicker
            className="float_sketch"
            color={themeColor.infoColor}
            onChange={({ hex }) => {
              onThemeColorChange({
                infoColor: hex,
              })
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <h3>Chart Colors</h3>
      <div className="pallet_line">
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart1 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart1}
            onChange={({ hex }) => {
              onChartColorChange({
                chart1: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart2 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart2}
            onChange={({ hex }) => {
              onChartColorChange({
                chart2: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart3 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart3}
            onChange={({ hex }) => {
              onChartColorChange({
                chart3: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart4 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart4}
            onChange={({ hex }) => {
              onChartColorChange({
                chart4: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart5 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart5}
            onChange={({ hex }) => {
              onChartColorChange({
                chart5: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart6 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart6}
            onChange={({ hex }) => {
              onChartColorChange({
                chart6: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart7 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart7}
            onChange={({ hex }) => {
              onChartColorChange({
                chart7: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart8 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart8}
            onChange={({ hex }) => {
              onChartColorChange({
                chart8: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart9 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart9}
            onChange={({ hex }) => {
              onChartColorChange({
                chart9: hex,
              })
            }}
          />
        </div>
        <div className="inline_color_edit">
          <button style={{ backgroundColor: chartColor.chart10 }}></button>
          <SketchPicker
            className="float_sketch"
            color={chartColor.chart10}
            onChange={({ hex }) => {
              onChartColorChange({
                chart10: hex,
              })
            }}
          />
        </div>
      </div>
      <br />
      <br />
      <br />
      <Button size="large" onClick={showModal}>
        Preview theme
      </Button>{' '}
      &nbsp;&nbsp;
      {themeProps.reset && (
        <Button onClick={(e) => handleSubmit(e, true)} size="large">
          Reset to default
        </Button>
      )}
      &nbsp;&nbsp;
      <Button onClick={handleSubmit} size="large" type="primary">
        Save theme
      </Button>
      <Modal
        title="Theme Preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[]}
        width={1000}
      >
        <h2>Buttons</h2>
        <Button type="primary">Primary Button</Button>
        <Button>Default Button</Button>
        <Button type="dashed">Dashed Button</Button>
        <Button type="text">Text Button</Button>
        <Button type="link">Link Button</Button>
        <br />
        <br />
        <br />
        <h2>Form Elements</h2>
        <Checkbox>Checkbox 1</Checkbox>
        <Radio>Radio 1</Radio>

        <br />
        <br />
        <br />
        <h2>Charts</h2>
        <CustomDonutChart />
        <CustomLine />
      </Modal>
    </div>
  )
}

export default ThemeManager
