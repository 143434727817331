import React, { useEffect, useState } from 'react'
import { Form, Tooltip, Input } from 'antd'
import { SketchPicker } from 'react-color'
import { InfoCircleOutlined } from '@ant-design/icons'

const ColorSelector = (props) => {
  const [color, setColor] = useState('#000000')
  return (
    <Form.Item>
      {props.layout === 'horizontal' ? (
        <div className={'form_field center_flex inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div className="color_field">
            <div className="pallet_line inline_color_edit">
              <button
                style={{
                  backgroundColor: props.value || props.defaultTextValue || '#000000',
                }}
              ></button>
              <SketchPicker
                className="float_sketch"
                color={props.value || props.defaultTextValue || '#000000'}
                onChange={({ hex }) => {
                  props.onChange(hex)
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div className="color_field">
            <div className="pallet_line inline_color_edit">
              <button
                style={{
                  backgroundColor: props.value || props.defaultTextValue || '#000000',
                }}
              ></button>
              <SketchPicker
                className="float_sketch"
                color={props.value || props.defaultTextValue || '#000000'}
                onChange={({ hex }) => {
                  props.onChange(hex)
                }}
              />
            </div>
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default ColorSelector
