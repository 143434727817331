import api from './api'

const endpoints = {
  field: 'field/',
  group: 'group/',
  form: 'form/',

  page: 'page/',
  groupCondition: 'group-condition/',
  pdfCondition: 'pdf-condition/',
  mainPageCondition: 'main-page-condition/',
  pageCondition: 'page-condition/',
  formCondition: 'form-condition/',
  adviceStrategy: 'condition-advice-strategy/',
  areaStrategy: 'condition-strategy-area/',
  attributeStrategy: 'condition-strategy-attribute/',
  variable_list: 'variable-list/',
}

const FieldAdd = async (data) => {
  return api.post(endpoints.field, data)
}

const FieldList = async (query) => {
  let url = query ? `${endpoints.field}${query}` : endpoints.field
  return api.get(url)
}

const FieldUpdate = async (id, data) => {
  return api.put(endpoints.field + id + '/', data)
}

const FieldDetail = async (id) => {
  return api.get(endpoints.field + id + '/')
}

const FieldDelete = async (id) => {
  return api.delete(endpoints.field + id + '/')
}

const GroupAdd = async (data) => {
  return api.post(endpoints.group, data)
}

const GroupList = async (params) => {
  return api.get(endpoints.group, { params })
}

const PageList = async (params) => {
  return api.get(endpoints.page, { params })
}

const GroupUpdate = async (id, data) => {
  return api.put(endpoints.group + id + '/', data)
}

const GroupDetail = async (id, params, noLoader) => {
  return api.get(endpoints.group + id + '/', { params, headers: { noLoader } })
}

const FormDetail = async (id, params, noLoader) => {
  return api.get(endpoints.form + id + '/', { params, headers: { noLoader } })
}

const GroupDelete = async (id) => {
  return api.delete(endpoints.group + id + '/')
}

const saveGroupCondition = async (id, data) => {
  return api.put(endpoints.groupCondition + id + '/', data)
}
const saveMainPageCondition = async (id, data) => {
  return api.put(endpoints.mainPageCondition + id + '/', data)
}
const savePdfCondition = async (id, data) => {
  return api.put(endpoints.pdfCondition + id + '/', data)
}
const savePageCondition = async (id, data) => {
  return api.put(endpoints.pageCondition + id + '/', data)
}

const saveAdviceStrategyCondition = async (id, data) => {
  return api.put(endpoints.adviceStrategy + id + '/', data)
}

const saveAreaStrategyCondition = async (id, data) => {
  return api.put(endpoints.areaStrategy + id + '/', data)
}

const saveAttributeStrategyCondition = async (id, data) => {
  return api.put(endpoints.attributeStrategy + id + '/', data)
}

const saveFormCondition = async (id, data) => {
  return api.put(endpoints.formCondition + id + '/', data)
}
const getPageCondition = async (id) => {
  return api.get(endpoints.pageCondition + id + '/')
}
const getAdviceStrategyCondition = async (id) => {
  return api.get(endpoints.adviceStrategy + id + '/')
}

const getAttributeStrategyCondition = async (id) => {
  return api.get(endpoints.attributeStrategy + id + '/')
}

const getAreaStrategyCondition = async (id) => {
  return api.get(endpoints.areaStrategy + id + '/')
}

const getGroupCondition = async (id) => {
  return api.get(endpoints.groupCondition + id + '/')
}

const getPdfCondition = async (id) => {
  return api.get(endpoints.pdfCondition + id + '/')
}

const getFormCondition = async (id) => {
  return api.get(endpoints.formCondition + id + '/')
}

const getMainPageCondition = async (id) => {
  return api.get(endpoints.mainPageCondition + id + '/')
}

const getVariableList = async () => {
  return api.get(endpoints.variable_list)
}

export default {
  FieldAdd,
  FieldList,
  FieldUpdate,
  FieldDetail,
  FieldDelete,
  GroupAdd,
  GroupList,
  GroupUpdate,
  GroupDetail,
  GroupDelete,
  PageList,
  saveGroupCondition,
  getGroupCondition,
  getVariableList,
  getFormCondition,
  saveFormCondition,
  FormDetail,
  getPageCondition,
  savePageCondition,
  getPdfCondition,
  savePdfCondition,
  saveMainPageCondition,
  getMainPageCondition,
  getAdviceStrategyCondition,
  saveAdviceStrategyCondition,
  saveAttributeStrategyCondition,
  getAttributeStrategyCondition,
  saveAreaStrategyCondition,
  getAreaStrategyCondition,
}
