import React, { useEffect, useState } from 'react'
import { Table, Button, Space } from 'antd'
import produce from 'immer'
import { get } from 'lodash'
import { DatePicker, Select, Cascader } from 'antd'
import { Option } from 'antd/lib/mentions'
import { PlusOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons'
import ReactSelect from 'react-select'
import moment from 'moment'
import { AdminLayout } from 'Components'
import { FormService, PageServiceService } from 'services'
import CustomSelect from './Select'
import Rule from './Rule'
import { toast } from 'react-toastify'
const { RangePicker } = DatePicker
const getHundredYears = () => {
  let year = moment().year()
  let years = []
  for (let i = 0; i < 100; i++) {
    years.push(year - i)
  }
  return years
}
const variables = {
  'patient name': 'string',
  'has car': 'boolean',
  number: 'number',
  attachment: 'attachment',
  date: 'date',
}
const vars = [
  {
    type: 'string',
    label: 'Email',
    value: 'email',
    children: [{ type: 'string', label: 'String 2', value: 'string 2' }],
  },
  { type: 'boolean', label: 'Has Car', value: true },
  { type: 'number', label: 'Number', value: 'number' },
  { type: 'attachment', label: 'Attachment', value: 'doc' },
  { type: 'date', label: 'Date', value: 'date' },
  { type: 'multi', label: 'Multi', value: 'multi' },
  { type: 'multi', label: 'Multi 2', value: 'multi 2' },
]
const options = {
  date: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
      type: 'date',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
      type: 'date',
    },
    {
      condition_name: 'Is blank',
      expression: '$ib',
      type: 'date',
    },
    {
      condition_name: 'Is not blank',
      expression: '$inb',
      type: 'date',
    },
    {
      condition_name: 'Greater than (date)',
      expression: '$gt',
      type: 'date',
    },
    {
      condition_name: 'Greater than or equal (date)',
      expression: '$gte',
      type: 'date',
    },
    {
      condition_name: 'Less than (date)',
      expression: '$lt',
      type: 'date',
    },
    {
      condition_name: 'Less than or equal (date)',
      expression: '$lte',
      type: 'date',
    },
    {
      condition_name: 'Between (date)',
      expression: '$bt',
      type: 'date range',
    },
    {
      condition_name: 'Greater than (day)',
      expression: '$dgt',
      type: 'day',
    },
    {
      condition_name: 'Greater than or equal (day)',
      expression: '$dgte',
      type: 'day',
    },
    {
      condition_name: 'Less than (day)',
      expression: '$dlt',
      type: 'day',
    },
    {
      condition_name: 'Less than or equal (day)',
      expression: '$dlte',
      type: 'day',
    },
    {
      condition_name: 'Between (day)',
      expression: '$dbt',
      type: 'day range',
    },
    {
      condition_name: 'Greater than (month)',
      expression: '$mgt',
      type: 'month',
    },
    {
      condition_name: 'Greater than or equal (month)',
      expression: '$mgte',
      type: 'month',
    },
    {
      condition_name: 'Less than (month)',
      expression: '$mlt',
      type: 'month',
    },
    {
      condition_name: 'Less than or equal (month)',
      expression: '$mlte',
      type: 'month',
    },
    {
      condition_name: 'Between (month)',
      expression: '$mbt',
      type: 'month range',
    },
    {
      condition_name: 'Greater than (year)',
      expression: '$ygt',
      type: 'year',
    },
    {
      condition_name: 'Greater than or equal (year)',
      expression: '$ygte',
      type: 'year',
    },
    {
      condition_name: 'Less than (year)',
      expression: '$ylt',
      type: 'year',
    },
    {
      condition_name: 'Less than or equal (year)',
      expression: '$ylte',
      type: 'year',
    },
    {
      condition_name: 'Between (year)',
      expression: '$ybt',
      type: 'year range',
    },
  ],
  multiple_select: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
      type: 'select',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
      type: 'select',
    },
    {
      condition_name: 'In list',
      expression: '$il',
      type: 'multi',
    },
    {
      condition_name: 'Not in list',
      expression: '$nil',
      type: 'multi',
    },
    {
      condition_name: 'Is blank',
      expression: '$ib',
      type: 'none',
    },
    {
      condition_name: 'Is not blank',
      expression: '$inb',
      type: 'none',
    },
  ],
  multi: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
      type: 'select',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
      type: 'select',
    },
    {
      condition_name: 'In list',
      expression: '$il',
      type: 'multi',
    },
    {
      condition_name: 'Not in list',
      expression: '$nil',
      type: 'multi',
    },
    {
      condition_name: 'Is blank',
      expression: '$ib',
      type: 'none',
    },
    {
      condition_name: 'Is not blank',
      expression: '$inb',
      type: 'none',
    },
    // {
    //   condition_name: "Match to other field",
    //   expression: "$mf",
    //   type: "other multi",
    // },
    // {
    //   condition_name: "Not match to other field",
    //   expression: "$nmf",
    //   type: "other multi",
    // },
  ],
  single_select: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
      type: 'select',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
      type: 'select',
    },
    {
      condition_name: 'In list',
      expression: '$il',
      type: 'multi',
    },
    {
      condition_name: 'Not in list',
      expression: '$nil',
      type: 'multi',
    },
    {
      condition_name: 'Is blank',
      expression: '$ib',
      type: 'none',
    },
    {
      condition_name: 'Is not blank',
      expression: '$inb',
      type: 'none',
    },
    // {
    //   condition_name: "Match to other field",
    //   expression: "$mf",
    //   type: "other multi",
    // },
    // {
    //   condition_name: "Not match to other field",
    //   expression: "$nmf",
    //   type: "other multi",
    // },
  ],

  attachment: [
    {
      condition_name: 'Is blank',
      expression: '$ib',
      type: 'none',
    },
    {
      condition_name: 'Is not blank',
      expression: '$inb',
      type: 'none',
    },
  ],
  text: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
      type: 'text',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
      type: 'text',
    },
    {
      condition_name: 'Contains',
      expression: '$c',
      type: 'text',
    },
    {
      condition_name: 'Not Contain',
      expression: '$nc',
      type: 'text',
    },
    {
      condition_name: 'Is blank',
      expression: '$ib',
      type: 'none',
    },
    {
      condition_name: 'Is not blank',
      expression: '$inb',
      type: 'none',
    },
    {
      condition_name: 'Starts with',
      expression: '$sw',
      type: 'text',
    },
    {
      condition_name: 'Ends With',
      expression: '$ew',
      type: 'text',
    },
  ],
  number: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
      type: 'number',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
      type: 'number',
    },
    {
      condition_name: 'Greater than',
      expression: '$gt',
      type: 'number',
    },
    {
      condition_name: 'Greater than or Equal',
      expression: '$gte',
      type: 'number',
    },
    {
      condition_name: 'Less than',
      expression: '$lt',
      type: 'number',
    },
    {
      condition_name: 'Less than or Equal',
      expression: '$lte',
      type: 'number',
    },
    {
      condition_name: 'Between',
      expression: '$bt',
      type: 'number range',
    },
  ],
  boolean: [
    {
      condition_name: 'Equal',
      expression: '$eq',
      type: 'boolean',
    },
    {
      condition_name: 'Not Equal',
      expression: '$neq',
      type: 'boolean',
    },
    {
      condition_name: 'Is Blank',
      expression: '$ib',
      type: 'none',
    },
    {
      condition_name: 'Is Not Blank',
      expression: '$inb',
      type: 'none',
    },
  ],
}

const GroupConditions = () => {
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  const getFieldType = () => {
    if (params.group) return 'group'
    if (params.form) return 'form'
    if (params.pdf) return 'pdf'
    return 'standalone'
  }
  const [master, setMaster] = useState([
    {
      combinator: 'and',
      rules: [
        {
          name: '',
          type: '',
          fieldType: getFieldType(),
          groupId: params.group,
          formId: params.form,
          pdf: params.pdf,
        },
      ],
      result: {
        action: 'Show',
        target: 'Email',
      },
    },
  ])

  const [groupList, setGroupList] = useState([])
  const [formList, setFormList] = useState([])
  const [standaloneFieldList, setStandaloneFieldList] = useState([])
  const [entity, setEntity] = useState({ type: '', id: '' })
  const [fields, setFields] = useState([])
  const [loader, setLoader] = useState(true)

  useEffect(() => {
    getGroupList()
    getFormList()
    getStandaloneFieldList()
    getConditions()
  }, [])

  const validateConditions = () => {
    console.log(master)
    for (let i = 0; i < (master || []).length; i++) {
      let rules = master[i]?.rules || []
      for (let j = 0; j < rules.length; j++) {
        let rule = rules[j]
        const { entityType, fieldType, groupId, expression, value } = rule || {}
        if (!entityType) {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Entity Type required`)
          return false
        }
        if (!fieldType) {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Field Type required`)
          return false
        }
        if (!groupId && fieldType === 'group') {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Group is required`)
          return false
        }
        if (!groupId && fieldType === 'form') {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Form is required`)
          return false
        }
        if (!groupId && fieldType === 'standalone') {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Field is required`)
          return false
        }
        if (!expression) {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Expression is required`)
          return false
        }
        if (expression !== '$ib' && expression !== '$inb' && !value) {
          toast.error(`Condition ${i + 1} Rule ${j + 1} Value is required`)
          return false
        }
      }
    }

    return true
  }

  const saveConditions = () => {
    if (!validateConditions()) {
      return
    }
    if (params.main_page) {
      PageServiceService.saveMainPageCondition(params.fieldId, {
        condition: master,
      })
    }
    if (params.form) {
      PageServiceService.saveFormCondition(params.fieldId, {
        condition: master,
      })
    }
    if (params.group) {
      PageServiceService.saveGroupCondition(params.fieldId, {
        condition: master,
      })
    }

    if (params.page) {
      PageServiceService.savePageCondition(params.fieldId, {
        condition: master,
      })
    }
    if (params.advice_strategy) {
      PageServiceService.saveAdviceStrategyCondition(params.fieldId, {
        condition: master,
      })
    }

    if (params.attribute_strategy) {
      PageServiceService.saveAttributeStrategyCondition(params.fieldId, {
        condition: master,
      })
    }
    if (params.area_strategy) {
      PageServiceService.saveAreaStrategyCondition(params.fieldId, {
        condition: master,
      })
    }

    if (params.pdf) {
      PageServiceService.savePdfCondition(params.fieldId, {
        condition: master,
      })
    }
  }

  const getConditions = () => {
    setLoader(true)
    let apiCaller = PageServiceService.getGroupCondition
    if (params.main_page) {
      apiCaller = PageServiceService.getMainPageCondition
    }
    if (params.form) {
      apiCaller = PageServiceService.getFormCondition
    }
    if (params.page) {
      apiCaller = PageServiceService.getPageCondition
    }
    if (params.advice_strategy) {
      apiCaller = PageServiceService.getAdviceStrategyCondition
    }
    if (params.area_strategy) {
      apiCaller = PageServiceService.getAreaStrategyCondition
    }
    if (params.attribute_strategy) {
      apiCaller = PageServiceService.getAttributeStrategyCondition
    }
    if (params.pdf) {
      apiCaller = PageServiceService.getPdfCondition
    }
    apiCaller(params.fieldId)
      .then((res) => {
        setMaster(
          res?.data?.data?.condition?.length
            ? res?.data?.data?.condition
            : [
                {
                  combinator: 'and',
                  rules: [
                    {
                      name: '',
                      type: '',
                      fieldType: getFieldType(),
                      groupId: params.group,
                      formId: params.form,
                      pdf: params.pdf,
                    },
                  ],
                  result: {
                    action: 'Show',
                    target: 'Email',
                  },
                },
              ]
        )
      })
      .finally(() => setLoader(false))
  }

  const getGroupList = () => {
    PageServiceService.GroupList().then((res) => {
      setGroupList(
        res?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      )
    })
  }

  const getFormList = () => {
    FormService.getForms().then((res) => {
      setFormList(
        res?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      )
    })
  }

  const getStandaloneFieldList = () => {
    PageServiceService.FieldList('?detail=true').then((res) => {
      setStandaloneFieldList(
        res?.data?.data?.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        })) || []
      )
    })
  }
  const valueResolver = (type) => {
    switch (type) {
      case 'multi':
        return []
      case 'multiple_select':
        return []
      case 'day':
        return []
      default:
        return ''
    }
  }
  const renderRules = (rules, masterIndex) => {
    return rules.map((item, i) => {
      return (
        <Rule
          i={i}
          key={item.id}
          masterIndex={masterIndex}
          setMaster={setMaster}
          master={master}
          standaloneFieldList={standaloneFieldList}
          formList={formList}
          groupList={groupList}
          vars={vars}
          valueResolver={valueResolver}
          options={options}
          htmlResolver={htmlResolver}
          rules={rules}
        />
      )
    })
  }
  const htmlResolver = (html, masterIndex, rulesIndex) => {
    let rule = master[masterIndex].rules[rulesIndex]
    switch (html) {
      case 'text':
        return (
          <input
            onChange={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = e.target.value
              })
              setMaster(newState)
            }}
            value={master[masterIndex].rules[rulesIndex].value}
          />
        )
      case 'number':
        return (
          <input
            type={'number'}
            onChange={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = e.target.value
              })
              setMaster(newState)
            }}
            value={master[masterIndex].rules[rulesIndex].value}
          />
        )
      case 'number range':
        return (
          <span>
            <input
              placeholder="from"
              type={'number'}
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', null) || []
                  arr[0] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[0]')}
            />
            <input
              placeholder="to"
              type={'number'}
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', null) || []
                  arr[1] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[1]')}
            />
          </span>
        )
      case 'boolean':
        return (
          <select
            onChange={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = e.target.value
              })
              setMaster(newState)
            }}
            value={get(master[masterIndex], 'rules[' + rulesIndex + '].value')}
          >
            <option value={true}>true</option>
            <option value={false}>false</option>
          </select>
        )
      case 'date':
        return (
          <DatePicker
            format={'DD/MM/YYYY'}
            onChange={(date) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = moment(date).format('YYYY-MM-DD')
              })
              setMaster(newState)
            }}
            value={
              get(master[masterIndex], 'rules[' + rulesIndex + '].value', null)
                ? moment(master[masterIndex].rules[rulesIndex].value)
                : null
            }
          />
        )
      case 'date range':
        return (
          <span>
            <RangePicker
              onChange={(date) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', []) || []
                  arr = [...arr]
                  arr[0] = moment(date[0]).format('YYYY-MM-DD')
                  arr[1] = moment(date[1]).format('YYYY-MM-DD')
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={[
                get(master[masterIndex], 'rules[' + rulesIndex + '].value', null)
                  ? moment(master[masterIndex].rules[rulesIndex].value[0])
                  : null,
                get(master[masterIndex], 'rules[' + rulesIndex + '].value', null)
                  ? moment(master[masterIndex].rules[rulesIndex].value[1])
                  : null,
              ]}
            />
            {/* <DatePicker
              onChange={(date) => {
                const newState = produce(master, (draft) => {
                  let arr = [
                    ...get(
                      draft[masterIndex],
                      'rules[' + rulesIndex + '].value',
                      []
                    ),
                  ];
                  arr[1] = moment(date).format('YYYY-MM-DD');
                  draft[masterIndex].rules[rulesIndex].value = arr;
                });
                setMaster(newState);
              }}
              value={
                get(
                  master[masterIndex],
                  'rules[' + rulesIndex + '].value',
                  null
                )
                  ? moment(master[masterIndex].rules[rulesIndex].value[1])
                  : null
              }
            /> */}
          </span>
        )
      case 'day':
        return (
          <input
            type="number"
            value={get(master[masterIndex], 'rules[' + rulesIndex + '].value', '')}
            onChange={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = e.target.value
              })
              setMaster(newState)
            }}
          />
        )
      case 'day range':
        return (
          <span>
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = [...get(draft[masterIndex], 'rules[' + rulesIndex + '].value', [])]
                  arr[0] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[0]', null)}
            >
              <option></option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = [...get(draft[masterIndex], 'rules[' + rulesIndex + '].value', [])]
                  arr[1] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[1]', null)}
            >
              <option></option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </span>
        )
      case 'month range':
        return (
          <span>
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', null) || []

                  arr[0] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[0]', null)}
            >
              <option></option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((item) => (
                <option value={item}>
                  {moment()
                    .month(item - 1)
                    .format('MMM')}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', null) || []
                  arr[1] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[1]', null)}
            >
              <option></option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((item) => (
                <option value={item}>
                  {moment()
                    .month(item - 1)
                    .format('MMM')}
                </option>
              ))}
            </select>
          </span>
        )
      case 'month':
        return (
          <span>
            <input
              type="number"
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value', '')}
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  draft[masterIndex].rules[rulesIndex].value = e.target.value
                })
                setMaster(newState)
              }}
            />
          </span>
        )

      case 'year':
        return (
          <span>
            <input
              type="number"
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value', '')}
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  draft[masterIndex].rules[rulesIndex].value = e.target.value
                })
                setMaster(newState)
              }}
            />
          </span>
        )
      case 'year range':
        return (
          <span>
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', null) || []
                  arr[0] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[0]', null)}
            >
              <option></option>
              {getHundredYears().map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  let arr = get(draft[masterIndex], 'rules[' + rulesIndex + '].value', null) || []

                  arr[1] = e.target.value
                  draft[masterIndex].rules[rulesIndex].value = arr
                })
                setMaster(newState)
              }}
              value={get(master[masterIndex], 'rules[' + rulesIndex + '].value[1]', null)}
            >
              <option></option>
              {getHundredYears().map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </span>
        )
      case 'multi':
        return (
          <Select
            mode="multiple"
            allowClear
            defaultValue={[]}
            onChange={(val) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = val
              })
              setMaster(newState)
            }}
            value={get(master[masterIndex], 'rules[' + rulesIndex + '].value', []) || []}
          >
            {vars.map((item) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        )
      case 'select':
        return (
          <Select
            allowClear
            defaultValue={[]}
            value={get(master[masterIndex], 'rules[' + rulesIndex + '].value', null)}
            onChange={(val) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = val
              })
              setMaster(newState)
            }}
          >
            {vars.map((item) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
          </Select>
        )
      case 'other multi':
        return (
          <Select
            allowClear
            defaultValue={[]}
            onChange={(val) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[rulesIndex].value = val
              })
              setMaster(newState)
            }}
            value={get(master[masterIndex], 'rules[' + rulesIndex + '].value', null)}
          >
            {vars
              .filter(
                (item) =>
                  item.type == 'multi' &&
                  item.value != master[masterIndex].rules[rulesIndex].varValue[0]
              )
              .map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        )
      case 'none':
        return null
      default:
        return null
    }
  }

  console.log('master', master)
  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Manage Conditions</span>{' '}
          <Button type="primary" onClick={saveConditions}>
            Save Conditions
          </Button>
        </h1>
        <p style={{ marginTop: -20 }}>
          Conditions with number are independent conditions and does their rules does not affect any
          other condition
        </p>
        <br />
        <br />
        {loader
          ? null
          : master.map((item, masterIndex) => (
              <div>
                {item.rules.length > 0 && masterIndex > 0 ? (
                  <span>
                    <span>Apply </span>
                    <select
                      value={master[masterIndex].combinator}
                      onChange={(e) => {
                        const newState = produce(master, (draft) => {
                          draft[masterIndex].combinator = e.target.value
                        })
                        setMaster(newState)
                      }}
                    >
                      <option value="and">and</option>
                      <option value="or">or</option>
                    </select>
                    <span> condition between below statements</span>
                  </span>
                ) : null}
                <div className="master_rule" data-index={masterIndex + 1}>
                  <div className="rules">
                    <div className="rules_container">
                      <div className="statements">{renderRules(item.rules, masterIndex)}</div>
                    </div>
                    {/* <Button
                shape="circle" icon={<PlusOutlined />}
                title='Add statement'
                onClick={(e) => {
                  const newState = produce(master, (draft) => {
                    draft[masterIndex].rules.push({
                      name: '',
                      type: '',
                    });
                  });
                  setMaster(newState);
                }}
              /> */}
                  </div>
                  {/* <div className="result">
              <h4>Result</h4>
              <div style={{ marginLeft: -5 }}>
                <select
                  value={get(master[masterIndex], "result.action", "Show")}
                  onChange={(e) => {
                    const newState = produce(master, (draft) => {
                      draft[masterIndex].result.action = e.target.value;
                    });
                    setMaster(newState);
                  }}
                >
                  <option value="Show">Show</option>
                  <option value="Hide">Hide</option>
                </select>
                <select
                  value={get(master[masterIndex], "result.target", "Email")}
                  onChange={(e) => {
                    const newState = produce(master, (draft) => {
                      draft[masterIndex].result.target = e.target.value;
                    });
                    setMaster(newState);
                  }}
                >
                  <option value="Email">Email</option>
                  <option value="File Upload">File Upload</option>
                  <option value="First Name">First Name</option>
                  <option value="Last Name">Last Name</option>
                </select>
              </div>
            </div> */}
                  <Button
                    onClick={() => {
                      const newState = produce(master, (draft) => {
                        draft.splice(masterIndex, 1)
                      })
                      setMaster(newState)
                    }}
                    className="remove_master_rule"
                    shape="circle"
                    icon={<DeleteOutlined />}
                    title="Remove statement"
                  />
                </div>
              </div>
            ))}
        <span>
          <Button
            type="primary"
            onClick={(e) => {
              const newState = produce(master, (draft) => {
                draft.push({
                  combinator: 'and',
                  rules: [{ name: '', type: '' }],
                  result: {
                    action: 'Show',
                    target: 'Email',
                  },
                })
              })
              setMaster(newState)
            }}
          >
            Add New Rule
          </Button>
        </span>
      </div>
    </AdminLayout>
  )
}

export default GroupConditions
