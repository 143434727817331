import React from 'react'

const action_by_option = {
  advice_for: 'Advice for',
  adviser: 'Financial Adviser',
  accountant: 'Tax adviser',
  lawyer: 'Legal adviser',
  third_party: 'Third party',
}

const implemented_option = {
  immediately: 'Immediately',
  end_of_calendar_year: 'This calendar year',
  next_of_calendar_year: 'Next calendar year',
  end_of_financial_year: 'This financial year',
  next_of_financial_year: 'Next financial year',
  before_retirement: 'Before retirement',
  after_retirement: 'After retirement',
  before_change_job: 'Before change job',
  after_change_job: 'After change job',
  other_strategy: 'After the implementation of other strategy',
  date_specific: 'Date specific',
}

const timing_option = {
  immediately: 'Immediately',
  end_of_calendar_year: 'This calendar year',
  next_of_calendar_year: 'Next calendar year',
  end_of_financial_year: 'This financial year',
  next_of_financial_year: 'Next financial year',
  before_retirement: 'Before retirement',
  after_retirement: 'After retirement',
  before_change_job: 'Before change job',
  after_change_job: 'After change job',
  date_specific: 'Date specific',
}

const advise_for_options_all = {
  client1: 'Client1',
  client2: 'Client2',
  company1: 'Company1',
  company2: 'Company2',
  partnership1: 'Partnership1',
  partnership2: 'Partnership2',
  smsf1: 'smsf1',
  smsf2: 'smsf2',
  trust1: 'trust1',
  trust2: 'trust2',
}

export default {
  action_by_option,
  timing_option,
  implemented_option,
  advise_for_options_all,
}
