import Form from 'Pages/Admin/Pages/AddPage/Form'
import React from 'react'
import { ReactSortable } from 'react-sortablejs'
import Element from '../Element'
import Field from '../Field'
import Group from '../Group'
import { useNavigate } from 'react-router-dom'

function Tab({
  data,
  addGroup,
  addElement,
  index,
  handleTabName,
  handleTabDescription,
  tabGroupIndex,
  setGroupModal,
  groups,
  forms,
  handleChartTypes,
  deleteElement,
  setFormModal,
  showFormButton,
  setFieldModal,
  handleViewOnly,
  shuffleTabElements,
  handleShowHeading,
  handleSortingEnable,
  pdfTemplates,
  formId,
  pageId,
  questionnaires,
}) {
  let { name, description, children } = data
  let navigate = useNavigate()
  const renderForm = (item, i) => {
    switch (item.type) {
      case 'element':
        return (
          <Element
            pdfTemplates={pdfTemplates}
            questionnaires={questionnaires}
            itemIndex={tabGroupIndex}
            tabIndex={index}
            index={i}
            handleChartTypes={handleChartTypes}
            id={item.id}
            type={item.element.type}
            sub_type={item.element.sub_type}
            data={item.element}
            groups={groups}
            forms={forms}
          />
        )
      case 'group':
        return (
          <Group
            handleViewOnly={handleViewOnly}
            handleShowHeading={handleShowHeading}
            handleSortingEnable={handleSortingEnable}
            itemIndex={tabGroupIndex}
            index={i}
            tabIndex={index}
            data={item}
            group={item.group}
            id={item.group.id || item.group}
          />
        )
      case 'field': {
        if (!item.field) return null
        return <Field field={item.field} id={item.field.id || item.field} />
      }
      case 'form':
        return (
          <Form
            handleViewOnly={handleViewOnly}
            handleShowHeading={handleShowHeading}
            handleSortingEnable={handleSortingEnable}
            itemIndex={tabGroupIndex}
            index={i}
            tabIndex={index}
            data={item}
            form={item.form}
            id={item.form.id || item.form}
          />
        )
    }
  }
  return (
    <div>
      <span className="element_type">{'Tab ' + (index + 1)}</span>
      <div className={'group_item'} style={{ backgroundColor: '#79a1c7' }}>
        <form className="">
          <input
            value={name}
            onChange={(e) => handleTabName(tabGroupIndex, index, e)}
            className="tab_inputs"
            placeholder="Tab name"
          />
          <textarea
            value={description}
            onChange={(e) => handleTabDescription(tabGroupIndex, index, e)}
            rows={4}
            style={{ marginTop: 10, height: 'calc(100% - 44px)' }}
            className="tab_inputs"
            placeholder="Tab description"
          />
        </form>
        <br />
        <br />
        <div className="floating_tab_buttons">
          <button title="Add Field" onClick={setFieldModal}>
            <span className="material-icons-outlined">vertical_split</span>
          </button>

          <button title="Add Group" onClick={setGroupModal}>
            <span className="material-icons-outlined">vertical_split</span>
          </button>
          <button title="Add Element" onClick={addElement}>
            <span className="material-icons-outlined">donut_small</span>
          </button>
          {showFormButton && (
            <button title="Add form" onClick={setFormModal}>
              <span className="material-icons-outlined">checklist</span>
            </button>
          )}
        </div>
        <div className="child_tab_elements">
          <ReactSortable
            list={children}
            setList={(list) => {
              shuffleTabElements(tabGroupIndex, index, list)
            }}
          >
            {children.map((item, i) => (
              <div key={item.id} style={{ position: 'relative', marginBottom: 10 }}>
                <button
                  className="delete_tab"
                  onClick={() => deleteElement(tabGroupIndex, index, i)}
                >
                  <span className="material-icons-outlined">delete</span>
                </button>
                <button
                  style={{ display: item.id ? 'inline-block' : 'none' }}
                  className="condition_tab"
                  onClick={() => {
                    if (pageId)
                      navigate(`/admin/conditions?page=${pageId}&fieldId=${item.id}`, {
                        push: true,
                      })
                    if (formId)
                      navigate(`/admin/conditions?form=${formId}&fieldId=${item.id}`, {
                        push: true,
                      })
                  }}
                >
                  <span className="material-icons-outlined" style={{ fontSize: '20px' }}>
                    help
                  </span>
                </button>
                {renderForm(item, i)}
              </div>
            ))}
          </ReactSortable>
          {children.length === 0 ? <p className="empty_elements">Please add elements</p> : null}
        </div>
      </div>
    </div>
  )
}

export default Tab
