import React, { useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import 'antd/dist/antd.variable.min.css'
import 'quill/dist/quill.snow.css'
import './global.scss'
import { store } from 'Store'
import { Provider } from 'react-redux'
import { ConfigProvider, Spin } from 'antd'
import { get } from 'lodash'
import routes from './router'
import { useSelector } from 'react-redux'
import theme from 'services/theme'

const customHistory = createBrowserHistory()

function App() {
  return (
    <div className="app">
      <Provider store={store}>
        <Main />
      </Provider>
    </div>
  )
}

const Main = () => {
  let themeColors = useSelector((state) => get(state, 'theme.current_theme.theme_color', null))
  React.useEffect(() => {
    if (themeColors) {
      ConfigProvider.config({
        theme: themeColors,
      })
    }
  }, [themeColors])
  const { showLoader } = useSelector((state) => state.app)
  return (
    <ConfigProvider>
      <Router history={customHistory}>{routes()}</Router>
      {showLoader && (
        <div className="loader-overlay">
          <div className="api-loader">
            <Spin size="large" />
          </div>
        </div>
      )}
    </ConfigProvider>
  )
}

export default App
