import React, { useEffect, useState } from 'react'
import { Form, Input, Table, Modal, Button, Space, Select, Checkbox, Upload, Switch } from 'antd'
import { EditOutlined, DeleteOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { AuthService } from 'services'
import { get } from 'lodash'
import * as yup from 'yup'
import { AuthUtils } from 'utils'
import { useFormik, Formik } from 'formik'
import qs from 'query-string'
import { useDispatch, useSelector } from 'react-redux'
import { setUser } from 'Store/reducers/userSlice'
const userSchema = yup.object().shape({
  first_name: yup.string().required('Required'),
  last_name: yup.string().required('Required'),
  email: yup.string().email('Invalid email').required('Required'),
  phone_number: yup.string().required('Required'),
})

const MyProfile = (props) => {
  const { user } = useSelector((state) => state)
  const [image_loading, SetImageLoading] = useState(false)
  const [imageUrl, SetImageUrl] = useState(false)
  const [imageError, SetImageError] = useState('')
  const [profile_pic_blob, SetPicBlob] = useState(null)
  const dispatch = useDispatch()
  const imageHandleAction = async (option) => {
    SetImageLoading(true)
    await getBase64(option.file, (imageUrl) => {
      SetImageUrl(imageUrl)
      SetImageLoading(false)
      SetPicBlob(option.file)
    })
  }
  useEffect(() => {
    SetImageUrl(get(user, 'details.profile_pic', ''))
  }, [user])

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    SetImageError(null)
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      SetImageError('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      SetImageError('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const uploadButton = (
    <div>
      {image_loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Avatar</div>
    </div>
  )
  return (
    <AdminLayout preview={props.preview}>
      <Formik
        initialValues={{
          email: get(user, 'details.email', ''),
          phone_number: get(user, 'details.phone_number', ''),
          first_name: get(user, 'details.first_name', ''),
          last_name: get(user, 'details.last_name', ''),
        }}
        enableReinitialize
        validationSchema={userSchema}
        onSubmit={(values) => {
          let form = new FormData()
          form.append('email', values.email)
          form.append('phone_number', values.phone_number)
          form.append('first_name', values.first_name)
          form.append('last_name', values.last_name)
          if (profile_pic_blob) {
            form.append('profile_pic', profile_pic_blob)
            form.append('has_profile_pic', true)
          }
          form.append('user_type', user.details.user_type)
          AuthService.updateUser(form, user.details.id).then((res) => {
            console.log('res', res)
            dispatch(setUser(res.data))
          })
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onFinish={handleSubmit} layout="vertical">
            <Upload
              name="profile_pic"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              customRequest={imageHandleAction}
              beforeUpload={beforeUpload}
              showRemoveIcon={true}
              // onChange={formik.handleChange}
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              {imageUrl ? (
                <div className="contained_image" style={{ backgroundImage: `url(${imageUrl})` }}>
                  <DeleteOutlined onClick={() => console.log('delete pic')} />
                </div>
              ) : (
                uploadButton
              )}
            </Upload>
            {/* <div className="align_center">
          <Upload
            name="profile_pic"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            customRequest={imageHandleAction}
            beforeUpload={beforeUpload}
            showRemoveIcon={true}
            // onChange={formik.handleChange}
            style={{ backgroundImage: `url(${imageUrl})` }}
          >
            {imageUrl ? (
              <div
                className="contained_image"
                style={{ backgroundImage: `url(${imageUrl})` }}
              >
                <DeleteOutlined onClick={() => DeletePic()} />
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        {imageError && (
          <span className="input-error-message" style={{ width: '50%' }}>
            {imageError}
          </span>
        )} */}

            <Form.Item label="Name">
              <div className="inline_inputs">
                <div className="half_input">
                  <Input
                    placeholder="First name"
                    style={{ width: '50%' }}
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.first_name && (
                    <span className="input-error-message" style={{ width: '50%' }}>
                      {errors.first_name}
                    </span>
                  )}
                </div>
                <div className="half_input">
                  <Input
                    placeholder="Last name"
                    style={{ width: '50%' }}
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.last_name && (
                    <span className="input-error-message" style={{ width: '50%' }}>
                      {errors.last_name}
                    </span>
                  )}
                </div>
              </div>
            </Form.Item>
            <Form.Item label="Phone">
              <Input
                placeholder="Enter phone"
                name="phone_number"
                value={values.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone_number && (
                <span className="input-error-message" style={{ width: '50%' }}>
                  {errors.phone_number}
                </span>
              )}
            </Form.Item>

            <Form.Item label="Email">
              <Input
                placeholder="Enter Email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && (
                <span className="input-error-message" style={{ width: '50%' }}>
                  {errors.email}
                </span>
              )}
            </Form.Item>
            <Button htmlType="submit">Save</Button>
          </Form>
        )}
      </Formik>
    </AdminLayout>
  )
}

export default MyProfile
