import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
  routes: null,
}

export const routeSlice = createSlice({
  name: 'routes',
  initialState,
  reducers: {
    setRoutes: (state, { payload }) => {
      state.routes = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setRoutes } = routeSlice.actions

export default routeSlice.reducer
