import React, { useEffect, useState } from 'react'
import { Switch, Select } from 'antd'
import { PageServiceService } from 'services'
import { get } from 'lodash'
const { Option } = Select
function Group({
  group,
  handleViewOnly,
  itemIndex,
  data,
  tabIndex,
  index,
  handleShowHeading,
  handleSortingEnable,
}) {
  const [allGroups, setAllGroups] = useState([])
  useEffect(() => {
    getGroups()
  }, [])

  const getGroups = async (page) => {
    let res = await PageServiceService.GroupList()
    setAllGroups(get(res, 'data.data', []))
  }

  const [droppable, setDroppable] = useState(false)

  return (
    <div>
      <span className="element_type">{'Group'}</span>
      <div
        className={'group_item'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span> {get(group, 'display_name', '') || get(group, 'name', '')}</span>
        {handleShowHeading && (
          <div>
            <b>Show Heading?</b> &nbsp;
            <Switch
              onChange={(val) => handleShowHeading(val, itemIndex, tabIndex, index)}
              checked={get(data, 'display_heading', false)}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </div>
        )}
        {handleSortingEnable && (
          <div>
            <b>Is Sortable?</b> &nbsp;
            <Switch
              onChange={(val) => handleSortingEnable(val, itemIndex, tabIndex, index)}
              checked={get(data, 'reorder', false)}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </div>
        )}
        <div>
          <b>Is droppable?</b> &nbsp;
          <Switch
            onChange={(val) => setDroppable(val)}
            checked={droppable}
            checkedChildren="Yes"
            unCheckedChildren="No"
          />
        </div>
        {droppable && (
          <Select
            style={{ width: 200 }}
            showSearch
            placeholder="Select droppable group"
            optionFilterProp="children"
            // onChange={onChange}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().includes(input.toLowerCase())
            }
          >
            {allGroups.map((group) => {
              return <Option value={group.id}>{group.name}</Option>
            })}
          </Select>
        )}
        {handleViewOnly && !droppable && (
          <div>
            <b>View Only?</b> &nbsp;
            <Switch
              onChange={(val) => handleViewOnly(val, itemIndex, tabIndex, index)}
              checked={get(data, 'view_only', false)}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Group
