import React, { Component, useState } from 'react'
import Nestable from 'react-nestable'
import {
  Select,
  Tabs,
  InputNumber,
  Radio,
  Space,
  Input,
  Modal,
  Cascader,
  Button,
  TreeSelect,
  Switch,
} from 'antd'
import '../../form.scss'
import ReactSelect from 'react-select'
import './element.scss'
import { get, flatten } from 'lodash'
const { Option } = Select

const values = [
  {
    value: 'assets',
    label: 'Assets',
  },
  {
    value: 'net_worth',
    label: 'Net Worth',
  },
  {
    value: 'liability',
    label: 'Liabilities',
  },
  {
    value: 'personal_info',
    label: 'Personal Info',
  },
]

const subForm = [
  {
    value: 'Form',
    label: 'Form',
    children: values,
  },
  {
    value: 'Group',
    label: 'Group',
    children: values,
  },
]

const options = [
  {
    value: 'pie',
    label: 'Pie',
    children: subForm,
  },
  {
    value: 'donut',
    label: 'Donut',
    children: subForm,
  },
  {
    value: 'line',
    label: 'Line',
    children: subForm,
  },
  {
    value: 'bar',
    label: 'Bar',
    children: subForm,
  },
  {
    value: 'text',
    label: 'Text',
    children: [
      {
        value: 'summary1',
        label: 'Summary1',
      },
      {
        value: 'summary2',
        label: 'Summary2',
      },
    ],
  },
]

function Element({
  handleChartTypes,
  itemIndex,
  tabIndex,
  type,
  index,
  sub_type,
  title,
  data,
  groups,
  forms,
  pdfTemplates,
  questionnaires,
}) {
  const handleCascader = (value, type) => {
    handleChartTypes(value, itemIndex, tabIndex, index, type)
  }
  const [elementType, setElementType] = React.useState('pie')
  function handleChange(value) {
    setElementType(value)
  }

  const [columns, setColumns] = React.useState(['Backlog', 'Reviewed', 'In Progress', 'Done'])
  function handleColumnChange(value) {
    setColumns(value)
  }

  let sourceData = get(data, 'data_source_type', 'group') == 'group' ? groups : forms || []
  sourceData = sourceData.map((item) => {
    let obj = {
      ...item,
      value: item.id,
      label: item.name,
      source: item.id,
    }
    delete obj.id
    return obj
  })

  const [selectedValues, setSelectedValues] = useState([])

  const x = Array.from({ length: 50 }, (_, i) => ({
    title: `Block ${i + 1}`,
    value: `${i + 1}`,
  }))

  const allIds = x.map(({ value }) => value)
  return (
    <div>
      <span className="element_type">{'Element'}</span>
      <div className="element">
        <div className="element_container">
          <div className="element_row">
            <div className="element_config_item">
              <label>Element Title</label>
              <Input
                value={data.title}
                onChange={(e) =>
                  handleChartTypes(e.target.value, itemIndex, tabIndex, index, 'title')
                }
                style={{ width: 250 }}
              />
            </div>
            <div className="element_config_item">
              <label>Element Type</label>
              <Select
                style={{ width: 250 }}
                value={data.type}
                onChange={(value) => {
                  handleChartTypes(value, itemIndex, tabIndex, index, 'type')
                }}
              >
                <Option value="pie">Pie</Option>
                <Option value="donut">Donut</Option>
                <Option value="line">Line</Option>
                <Option value="bar">Bar</Option>
                <Option value="progress_bar">Progress Bar</Option>
                <Option value="workbook">Workbook</Option>
                <Option value="workbook_setting">Workbook Setting</Option>
                <Option value="report">Report</Option>
                <Option value="wizard">Wizard</Option>
                <Option value="riskProfile">Risk profile</Option>
                <Option value="strategy_setting">Strategy Setting</Option>
                <Option value="strategy_recommendation">Strategy-Recommendation</Option>
                <Option value="advice_doc_config">Advice-Doc-config</Option>
                <Option value="strategy_history">Strategy-History</Option>
                <Option value="knowledge_area">Knowledge-Area</Option>
                <Option value="product_config">Product Config</Option>
                <Option value="objective_config">Objective Config</Option>
                <Option value="investment">Investment</Option>
                <Option value="widget">Widget</Option>
                <Option value="table_config">Table config</Option>
                <Option value="login_history">Login History</Option>
                <Option value="element_fields">Fields</Option>
                <Option value="element_groups">Groups</Option>
                <Option value="element_forms">Forms</Option>
                <Option value="element_pages">Pages</Option>
                <Option value="element_questionnaire">Questionnaire</Option>
                <Option value="element_portfolio">Portfolio</Option>
                <Option value="element_portfolio_recommendation">Portfolio - Recommendation</Option>
                <Option value="element_page_config_admin">Page Config - Admin</Option>
                <Option value="element_page_config_business">Page Config - Business</Option>
                <Option value="element_page_config_user">Page Config - User</Option>
                <Option value="element_page_config_client">Page Config - Client</Option>
                <Option value="element_pdf_config_template">PDF Config - Template</Option>
                <Option value="element_pdf_config_config_pages">PDF Config - Config Pages</Option>
                <Option value="element_pdf_config_default_pages">PDF Config - Default Pages</Option>
                <Option value="portfolio_option">Portfolio option</Option>
                <Option value="asset_portfolio_option">Asset Portfolio option</Option>
                <Option value="liability_portfolio_option">Liability Portfolio option</Option>
                <Option value="portfolio_transaction">Portfolio transaction</Option>
                <Option value="element_entities_admin">Entities - Admin</Option>
                <Option value="element_entities_user">Entities - User</Option>
                <Option value="element_entities_client">Entities - Client</Option>
                <Option value="element_entities_business">Entities - Business</Option>
                <Option value="element_settings_contact_us">Settings - Contact Us</Option>
                <Option value="element_settings_about_us">Settings - About Us</Option>
                <Option value="element_settings_privacy_policy">Settings - Privacy Policy</Option>
                <Option value="element_settings_tnc">Settings - T&C</Option>
                <Option value="element_settings_capabilities">Settings - Capabilities</Option>
                <Option value="element_settings_price_plan">Settings - Price Plan</Option>
                <Option value="access_control">Access Control</Option>
              </Select>
            </div>

            {(data.type == 'workbook' || data.type == 'workbook_setting') && (
              <div className="element_config_item">
                <label>Form</label>
                <Select
                  onChange={(val) => {
                    handleChartTypes(val, itemIndex, tabIndex, index, 'form')
                  }}
                  style={{ width: 250 }}
                  value={data.form}
                >
                  {forms.map((form) => (
                    <Option key={form.id} value={form.id}>
                      {form.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {(data.type == 'workbook' || data.type == 'workbook_setting') && (
              <div className="element_config_item">
                <label>Group</label>
                <Select
                  onChange={(val) => {
                    handleChartTypes(val, itemIndex, tabIndex, index, 'group')
                  }}
                  style={{ width: 250 }}
                  value={data.group}
                >
                  {groups.map((group) => (
                    <Option key={group.id} value={group.id}>
                      {group.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}
            {(data.type == 'report' || data.type == 'wizard') && (
              <div className="element_config_item">
                <label>Visibility</label>
                <Select
                  onChange={(val) => {
                    handleChartTypes(val, itemIndex, tabIndex, index, 'visibility')
                  }}
                  mode="multiple"
                  style={{ width: 250 }}
                  value={data.visibility}
                  placeholder={data.visibility && data.visibility.length ? '' : 'All'}
                >
                  {(data.type === 'report' ? pdfTemplates : questionnaires).map((template) => (
                    <Option key={template.id} value={template.id}>
                      {template.name}
                    </Option>
                  ))}
                </Select>
              </div>
            )}

            {data.type === 'riskProfile' && (
              <div className="element_config_item">
                <label>Questionnaire</label>
                <Select style={{ width: 250 }} onChange={handleChange}>
                  <Option value="1">Questionnaire 1</Option>
                  <Option value="2">Questionnaire 2</Option>
                  <Option value="3">Questionnaire 3</Option>
                  <Option value="4">Questionnaire 4</Option>
                </Select>
              </div>
            )}

            {data.type !== 'workbook' &&
              data.type !== 'workbook_setting' &&
              data.type !== 'riskProfile' &&
              data.type !== 'report' &&
              data.type !== 'widget' &&
              data.type !== 'wizard' && (
                <div className="element_config_item">
                  <label>Legend Position</label>
                  <Select
                    value={data.position}
                    onChange={(value) => {
                      handleChartTypes(value, itemIndex, tabIndex, index, 'position')
                    }}
                    style={{ width: 250 }}
                  >
                    <Option value="top">Top</Option>
                    <Option value="bottom">Bottom</Option>
                    <Option value="left">Left</Option>
                    <Option value="right">Right</Option>
                  </Select>
                </div>
              )}
          </div>
          <div className="element_row">
            {data.type !== 'workbook' &&
              data.type !== 'workbook_setting' &&
              data.type !== 'widget' &&
              data.type !== 'riskProfile' && (
                <div className="element_config_item">
                  <label>Entity</label>
                  <Select
                    value={data.entity_data_source}
                    onChange={(value) => {
                      handleChartTypes(value, itemIndex, tabIndex, index, 'entity_data_source')
                    }}
                    style={{ width: 250 }}
                  >
                    <Option value="user">User</Option>
                    <Option value="client">Client</Option>
                    <Option value="business">Business</Option>
                  </Select>
                </div>
              )}
            {data.type !== 'workbook' &&
              data.type !== 'workbook_setting' &&
              data.type !== 'riskProfile' &&
              data.type !== 'report' &&
              data.type !== 'widget' &&
              data.type !== 'wizard' && (
                <div className="element_config_item">
                  <label>Data Source Type</label>
                  <Select
                    value={data.data_source_type}
                    onChange={(value) => {
                      handleChartTypes(value, itemIndex, tabIndex, index, 'data_source_type')
                    }}
                    style={{ width: 250 }}
                  >
                    <Option value="form">Form</Option>
                    <Option value="group">Group</Option>
                  </Select>
                </div>
              )}
            {data.type !== 'workbook' &&
            data.type !== 'workbook_setting' &&
            data.type !== 'riskProfile' &&
            data.type !== 'report' &&
            data.type !== 'wizard' &&
            data.data_source_type ? (
              <div className="element_config_item">
                <label>Data Source</label>

                <ReactSelect
                  value={
                    data.type == 'bar' || data.type == 'progress_bar'
                      ? data?.data_source.map((dt) => ({
                          ...dt,
                          value: dt.source,
                          label: dt.label || dt.source_name,
                        }))
                      : {
                          ...data.data_source[0],
                          value: data.data_source[0]?.source,
                          label: data.data_source[0]?.label || data.data_source[0]?.source_name,
                        }
                  }
                  onChange={(val) => {
                    if (Array.isArray(val)) {
                      val.map((v) => ({ ...v, id: null }))
                      handleChartTypes(val, itemIndex, tabIndex, index, 'data_source')
                    } else {
                      handleChartTypes([val], itemIndex, tabIndex, index, 'data_source')
                    }
                  }}
                  isMulti={data.type == 'bar' || data.type == 'progress_bar'}
                  options={sourceData}
                />
              </div>
            ) : null}
          </div>
          {data.type !== 'workbook' &&
          data.type !== 'workbook_setting' &&
          data.data_source &&
          data.data_source.length
            ? data.data_source.map((item, dataIndex) => (
                <div key={item.id}>
                  <h3 style={{ textTransform: 'capitalize' }}>{item.name}</h3>
                  <div className="element_row">
                    <div className="element_config_item">
                      <label>Data Field</label>
                      <Select
                        value={item.data_field}
                        onChange={(val) =>
                          handleChartTypes(val, itemIndex, tabIndex, index, 'data_field', dataIndex)
                        }
                        style={{ width: 250 }}
                      >
                        {sourceData
                          .filter(
                            (source) =>
                              source.source == item.source ||
                              source.source == item.form ||
                              source.source == item.group
                          )[0]
                          ?.fields.filter((f) => f.type == 'number')
                          .map((field, i) => (
                            <Option key={field.id} value={field.id}>
                              {field.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div className="element_config_item">
                      <label>Name Field</label>
                      <Select
                        value={item.name_field}
                        onChange={(val) =>
                          handleChartTypes(val, itemIndex, tabIndex, index, 'name_field', dataIndex)
                        }
                        style={{ width: 250 }}
                      >
                        {sourceData
                          .filter(
                            (source) =>
                              source.source == item.source ||
                              source.source == item.form ||
                              source.source == item.group
                          )[0]
                          ?.fields.filter((f) => f.type == 'text')
                          .map((field, i) => (
                            <Option key={field.id} value={field.id}>
                              {field.name}
                            </Option>
                          ))}
                      </Select>
                    </div>

                    <div className="element_config_item">
                      <label>Primary Filter</label>
                      <Select
                        onChange={(val) =>
                          handleChartTypes(
                            val,
                            itemIndex,
                            tabIndex,
                            index,
                            'primary_filter',
                            dataIndex
                          )
                        }
                        mode="multiple"
                        style={{ width: 250 }}
                        value={item.primary_filter}
                      >
                        {sourceData
                          .filter(
                            (source) =>
                              source.source == item.source ||
                              source.source == item.form ||
                              source.source == item.group
                          )[0]
                          ?.fields.filter((f) => f.type.includes('select'))
                          .map((field) => (
                            <Option key={field.id} value={field.id}>
                              {field.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    <div className="element_config_item" key={index}>
                      <label>Secondary Filter</label>
                      <Select
                        onChange={(val) => {
                          handleChartTypes(
                            val,
                            itemIndex,
                            tabIndex,
                            index,
                            'secondary_filter',
                            dataIndex
                          )
                        }}
                        style={{ width: 250 }}
                        value={item.secondary_filter}
                      >
                        <Option key={1} value={''}></Option>
                        {sourceData
                          .filter(
                            (source) =>
                              source.source == item.source ||
                              source.source == item.form ||
                              source.source == item.group
                          )[0]
                          ?.fields.filter((f) => f.type.includes('select'))
                          .map((field) => (
                            <Option key={field.id} value={field.id}>
                              {field.name}
                            </Option>
                          ))}
                      </Select>
                    </div>
                    {data.type === 'progress_bar' && (
                      <div className="element_config_item">
                        <label>Show Options</label>
                        <Select
                          onChange={(val) =>
                            handleChartTypes(
                              val,
                              itemIndex,
                              tabIndex,
                              index,
                              'show_options',
                              dataIndex
                            )
                          }
                          mode="multiple"
                          style={{ width: 250 }}
                          value={item.show_options}
                        >
                          {flatten(
                            sourceData
                              .filter(
                                (source) =>
                                  source.source == item.source ||
                                  source.source == item.form ||
                                  source.source == item.group
                              )[0]
                              ?.fields?.filter((f) => f?.type?.includes('select'))
                              .filter((field) => item?.secondary_filter?.includes(field.id))
                              .map((item) => item.options)
                          ).map((field) => (
                            <Option key={field.id} value={field.value}>
                              {field.name}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    )}
                    {data.type === 'progress_bar' && (
                      <div className="element_config_item">
                        <label>Description Field</label>
                        <Select
                          value={item.description_field}
                          onChange={(val) =>
                            handleChartTypes(
                              val,
                              itemIndex,
                              tabIndex,
                              index,
                              'description_field',
                              dataIndex
                            )
                          }
                          style={{ width: 250 }}
                        >
                          {sourceData
                            .filter(
                              (source) =>
                                source.source == item.source ||
                                source.source == item.form ||
                                source.source == item.group
                            )[0]
                            ?.fields.filter((f) => f.type == 'text')
                            .map((field, i) => (
                              <Option key={field.id} value={field.id}>
                                {field.name}
                              </Option>
                            ))}
                        </Select>
                      </div>
                    )}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    </div>
  )
}

export default Element
