import React, { useEffect, useState } from 'react'
import { AuthService } from 'services'
import { get } from 'lodash'
import { Table } from 'antd'
import moment from 'moment'
import { useSelector } from 'react-redux'

const LoginHistory = ({ preview }) => {
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const entity = preview ? previewUser?.details?.id : user?.details?.id

  const [data, setData] = useState([])
  const [total, setCount] = useState(0)

  const columns = [
    {
      title: 'Login datetime',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Login datetime</h6>
          {moment(text).format('MM-DD-YYYY hh:mm A')}
        </div>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      key: 'ip',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Open</h6>
          {text}
        </div>
      ),
    },
  ]

  const load_data = async (page = 1) => {
    const res = await AuthService.getLoginHistory({ page, entity })
    setData(get(res, 'data.data', []))
    setCount(get(res, 'data.count', 0))
  }

  useEffect(() => {
    load_data()
  }, [])

  return (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          showSizeChanger: false,
          total: total,
          defaultPageSize: 10,
          PageSize: 10,
          onChange: (current_page) => load_data(current_page),
        }}
      />
    </div>
  )
}

export default LoginHistory
