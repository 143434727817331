import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
  user: [],
  business: [],
  client: [],
}

export const entitySlice = createSlice({
  name: 'entity',
  initialState,
  reducers: {
    setUserList: (state, { payload }) => {
      state.user = get(payload, 'data', [])
    },
    setBusinessList: (state, { payload }) => {
      state.business = get(payload, 'data', [])
    },
    setClientList: (state, { payload }) => {
      state.client = get(payload, 'data', [])
    },
  },
})

// Action creators are generated for each case reducer function
export const { setUserList, setBusinessList, setClientList } = entitySlice.actions

export default entitySlice.reducer
