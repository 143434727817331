import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { HomeLayout } from 'Components'

import { Radio } from 'antd'
import './auth.scss'
import Cookies from 'js-cookie'
import { AuthService } from 'services'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { setUser } from 'Store/reducers/userSlice'
import { getFirstPage } from 'utils/helpers'

export default function Login() {
  const [loginData, SetData] = useState({
    email: '',
    password: '',
    user_type: 'admin',
  })
  const navigate = useNavigate()

  const onChangeInput = (e) => {
    const { name, value } = e.target
    SetData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const [showPassword, SetShowPassword] = useState(false)
  const clickPassword = () => {
    SetShowPassword(!showPassword)
  }
  const dispatch = useDispatch()
  const loginSubmit = (e) => {
    e.preventDefault()
    //const res = axios_post(LOGIN_API_URL,loginData, false);

    let res = AuthService.login(loginData)
    res.then((response) => {
      if (response) {
        dispatch(setUser(response.data))
        const expire_in = response.data.token.expires_in / (3600 * 24)
        Cookies.set('user_id', get(response, 'data.data.id'), {
          expires: expire_in,
        })
        Cookies.set('user_type', get(response, 'data.data.user_type'), {
          expires: expire_in,
        })
        Cookies.set('token', get(response, 'data.token.access_token'), {
          expires: expire_in,
        })
        Cookies.set('refresh_token', get(response, 'data.token.refresh_token'), {
          expires: expire_in,
        })
        let firstPage = getFirstPage(response?.data?.data?.current_page_config?.page_data)?.id

        navigate(firstPage ? '/user/page/' + firstPage : '/user/dashboard')
      }
    })
  }
  return (
    <HomeLayout>
      <div className="auth">
        <div className="container">
          <div className="base_form login">
            <h1>Login</h1>
            <form onSubmit={loginSubmit}>
              <div style={{ textAlign: 'center' }}>
                <Radio.Group
                  buttonStyle="solid"
                  name="user_type"
                  onChange={onChangeInput}
                  value={loginData.user_type}
                >
                  <Radio.Button value={'admin'}>Admin</Radio.Button>
                  <Radio.Button value={'user'}>Advisor</Radio.Button>
                  <Radio.Button value={'client'}>Client</Radio.Button>
                </Radio.Group>
              </div>
              <div className="form_group" style={{ marginTop: 25 }}>
                <label className="form_label">Email Address or username</label>
                <input
                  name="email"
                  type="text"
                  required
                  value={loginData.email}
                  onChange={onChangeInput}
                />
              </div>
              <div className="form_group">
                <label className="form_label">Password</label>
                <div className="input_mask">
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    required
                    value={loginData.password}
                    onChange={onChangeInput}
                  />
                  <button onClick={(e) => (e.preventDefault(), clickPassword())}>
                    <span className="material-icons-outlined">visibility</span>
                    {/* <span className="material-icons-outlined">visibility_off</span> */}
                  </button>
                </div>
                <div className="help_text right">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </div>
              <button type="submit" className="primary_form">
                LOGIN
              </button>
            </form>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}
