import api from './api'

const endpoints = {
  page: 'page/',
  pageConfig: 'page-config/',
  pageData: 'data-saving/page-data/',
  file: 'data-saving/attachment/',
  groupData: '/data-saving/group-data/',
  formData: '/data-saving/form-data/',
  groupDropdown: '/data-saving/group-data-dropdown/',
  formDropdown: '/data-saving/form-data-dropdown/',
  groupReorder: '/data-saving/group-reorder/',
  formReorder: '/data-saving/form-reorder/',
  groupConditions: '/data-saving/valid-group-condition/',
  formConditons: '/data-saving/valid-form-condition/',
  pageConditions: '/data-saving/valid-page-condition/',
  riskProfileSummary: '/data-saving/risk-profile-summary/',
  riskProfileOption: '/data-saving/risk-profile-options/',
  adviseForOption: '/data-saving/advise-for-options/',
  dependants_list: '/data-saving/dependants/',
  estate_planning: '/data-saving/estate-planning/',
  goals_list: '/data-saving/goals/',
}

const getPages = async (params) => {
  return api.get(endpoints.page, { params })
}

const getPage = async (id) => {
  return api.get(endpoints.page + id + '/')
}

const savePage = async (data) => {
  return api.post(endpoints.page, data)
}
const updatePage = async (data) => {
  return api.put(endpoints.page + data.id + '/', data)
}

const deletePage = async (id) => {
  return api.delete(endpoints.page + id + '/')
}

const savePageConfig = (data) => {
  return api.put(endpoints.pageConfig, data)
}

const getPageConfig = (params) => {
  return api.get(endpoints.pageConfig, { params })
}

const savePageData = (data) => {
  return api.post(endpoints.pageData, data)
}

const updatePageData = (data, params) => {
  return api.put(endpoints.pageData, data, { params })
}

const uploadFile = (data) => {
  return api.post(endpoints.file, data)
}

const getPageData = (params) => api.get(endpoints.pageData, { params })

const saveGroupData = (data) => api.post(endpoints.groupData, data)

const getGroupData = (params) => api.get(endpoints.groupData, { params })

const updateGroupData = (data, id) => api.put(endpoints.groupData + id + '/', data)

const deleteGroupRecord = (id) => api.delete(endpoints.groupData + id + '/')

const saveFormData = (data) => api.post(endpoints.formData, data)

const getFormData = (params) => api.get(endpoints.formData, { params })

const deleteFormData = (id) => api.delete(endpoints.formData + id + '/')

const updateFormData = (data, id) => api.put(endpoints.formData + id + '/', data)

const getGroupDropdown = (params) => api.get(endpoints.groupDropdown, { params })

const getFormDropdown = (params) => api.get(endpoints.formDropdown, { params })

const groupReorder = (data) =>
  api.post(endpoints.groupReorder, data, { headers: { noLoader: true } })
const formReorder = (data) => api.post(endpoints.formReorder, data, { headers: { noLoader: true } })

const getGroupConditions = (data) => api.post(endpoints.groupConditions, data)
const getFormConditions = (data) => api.post(endpoints.formConditons, data)
const getPageCondition = (data, entity) =>
  api.post(endpoints.pageConditions + `?entity=${entity}`, data)

const getRiskProfileSummary = (params) => api.get(endpoints.riskProfileSummary, { params })

const updateRiskProfileSummary = (data) => api.put(endpoints.riskProfileSummary, data)

const getRiskProfileOptions = (params) => api.get(endpoints.riskProfileOption, { params })

const getAdviseForOption = async (entity, params = {}) => {
  return api.get(endpoints.adviseForOption + entity + '/', { params })
}

const getDependantList = async (entity, params = {}) => {
  return api.get(endpoints.dependants_list + entity + '/', { params })
}

const getEstatePlanning = async (entity, params = {}) => {
  return api.get(endpoints.estate_planning + entity + '/', { params })
}

const getGoalList = async (entity, params = {}) => {
  return api.get(endpoints.goals_list + entity + '/', { params })
}

export default {
  getPages,
  getPage,
  savePage,
  updatePage,
  deletePage,
  savePageConfig,
  getPageConfig,
  savePageData,
  getPageData,
  updatePageData,
  uploadFile,
  saveGroupData,
  getGroupData,
  updateGroupData,
  deleteGroupRecord,
  saveFormData,
  getFormData,
  deleteFormData,
  updateFormData,
  getGroupDropdown,
  getFormDropdown,
  groupReorder,
  formReorder,
  getGroupConditions,
  getFormConditions,
  getPageCondition,
  getRiskProfileSummary,
  updateRiskProfileSummary,
  getRiskProfileOptions,
  getAdviseForOption,
  getDependantList,
  getEstatePlanning,
  getGoalList,
}
