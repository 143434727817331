import React, { useEffect, useState } from 'react'
import { Input, Form, Button, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
const { TextArea } = Input
const MultiLineText = (props) => {
  const [fields, setFields] = useState(props.value || [''])

  const addField = () => {
    const fieldsArray = [...fields]
    fieldsArray.push('')
    setFields(fieldsArray)
  }
  const updateFieldValue = (e, index) => {
    const fieldsArray = [...fields]
    fieldsArray[index] = e.target.value
    setFields(fieldsArray)
  }

  const removeField = (index) => {
    const fieldsArray = [...fields]
    fieldsArray.splice(index, 1)
    setFields(fieldsArray)
  }

  useEffect(() => {
    if (first) {
      setValue(props.value)
      setFirst(false)
    }
  }, [props.value])

  const [value, setValue] = useState('')
  const [first, setFirst] = useState(true)

  useEffect(() => {
    if (!first && props.dependantValue && props.dependsOn) {
      setValue(props.filtering.values[props.dependantValue].defaultValue)
    }
  }, [props.dependantValue])

  useEffect(() => {
    if (props.onChange) props.onChange(value)
  }, [value])

  useEffect(() => {
    if (props.onChange) props.onChange(fields)
  }, [fields])

  return (
    <Form.Item>
      {props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div style={{ display: 'flex', flex: 1 }}>
            <TextArea
              value={value}
              style={{ width: `${props.width}%` }}
              rows={props.rows}
              required={props.isRequired}
              readOnly={props.isReadOnly}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            {props.dynamicFields ? (
              fields?.map((item, index) => {
                return (
                  <div style={{ display: 'flex', marginBottom: 5 }}>
                    <TextArea
                      defaultValue={props.defaultTextValue}
                      style={{ width: `${props.width}%` }}
                      rows={props.rows}
                      required={props.isRequired}
                      value={item}
                      onChange={(e) => {
                        updateFieldValue(e, index)
                      }}
                      readOnly={props.isReadOnly}
                    />
                    {props.dynamicFields ? (
                      <Button
                        danger={fields.length - 1 == index ? false : true}
                        type="link"
                        icon={
                          <span
                            className="material-icons-outlined"
                            style={{
                              fontSize: 25,
                              display: 'inline-block',
                              marginLeft: 5,
                            }}
                            onClick={() => {
                              fields.length - 1 == index ? addField() : removeField(index)
                            }}
                          >
                            {fields.length - 1 == index ? 'add_box' : 'disabled_by_default'}
                          </span>
                        }
                      />
                    ) : null}
                  </div>
                )
              })
            ) : (
              <div style={{ display: 'flex' }}>
                <TextArea
                  style={{ width: `${props.width}%` }}
                  rows={props.rows}
                  required={props.isRequired}
                  readOnly={props.isReadOnly}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default MultiLineText
