import api from './api'

const endpoints = {
  aboutus: 'about-us/',
  termcondition: 'term-condition/',
  privacypolicy: 'privacy-policy/',
  companycontact: 'company-contact/',
  contactus: 'contact-us/',
}

const GetAboutUs = async () => {
  return api.get(endpoints.aboutus)
}

const UpdateAboutUs = async (data) => {
  return api.put(endpoints.aboutus, data)
}

const TermConditionGet = async () => {
  return api.get(endpoints.termcondition)
}

const TermConditionUpdate = async (data) => {
  return api.put(endpoints.termcondition, data)
}

const PrivacyPolicyGet = async () => {
  return api.get(endpoints.privacypolicy)
}

const PrivacyPolicyUpdate = async (data) => {
  return api.put(endpoints.privacypolicy, data)
}

const CompanyContactGet = async () => {
  return api.get(endpoints.companycontact)
}

const CompanyContactUpdate = async (id, data) => {
  return api.put(endpoints.companycontact + id + '/', data)
}

const ContactUsPost = async (data) => {
  return api.post(endpoints.contactus, data)
}

export default {
  GetAboutUs,
  UpdateAboutUs,
  TermConditionGet,
  TermConditionUpdate,
  PrivacyPolicyGet,
  PrivacyPolicyUpdate,
  CompanyContactGet,
  CompanyContactUpdate,
  ContactUsPost,
}
