import React from 'react'
import { MailOutlined } from '@ant-design/icons'
import { useDrag } from 'react-dnd'
import { get } from 'lodash'

const Item = ({ id, from, moveItem, obj }) => {
  const [{ isDragging }, drag] = useDrag({
    type: from,
    item: { id, from },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  })

  const color1 = get(obj, 'area.color', '#808080')
  const color2 = get(obj, 'area.color2', '#808080')
  const icon = get(obj, 'area.icon', '')

  const style = {
    opacity: isDragging ? 0 : 1, // Or use display: isDragging ? 'none' : 'block' for complete disappearance
    background: `linear-gradient(to right, ${color1}, ${color2})`,
  }

  return (
    <div className="stratergy_box_container" ref={drag} style={style}>
      <div className="stratergy_card">
        {/* <span className='stratergy_card_number'>{id}</span> */}
        <div className="stratergy_card_summary">
          <div>
            {from !== 'center' && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {icon && (
                  <span
                    className="material-icons-outlined"
                    style={{ width: 25, fontSize: '24px', marginRight: '5px' }}
                  >
                    {icon}
                  </span>
                )}
                <h3 style={{ margin: '0' }}>{get(obj, 'subject')}</h3>
              </div>
            )}
            {from === 'center' && (
              <div style={{ textAlign: 'center' }}>
                {icon && (
                  <span
                    className="material-icons-outlined"
                    style={{ width: 24, fontSize: '24px', marginRight: '5px' }}
                  >
                    {icon}
                  </span>
                )}
                <h3 style={{ margin: '0' }}>{get(obj, 'subject')}</h3>
              </div>
            )}
          </div>
          <div>
            <p></p>
          </div>
          {from == 'center' && (
            <div dangerouslySetInnerHTML={{ __html: get(obj, 'description') }} />
          )}
        </div>
        <div className="stratergy_card_actions">
          <button className="choose">
            <MailOutlined style={{ fontSize: 18, paddingRight: 4 }} /> Contact Advisor
          </button>
          {/* {from !== 'left' && <button className='later' onClick={() => moveItem(id, from, 'left')}>Later</button>}
        {from !== 'right' && <button className='choose' onClick={() => moveItem(id, from, 'right')}><CheckOutlined/> Choose Stratergy</button>} */}
        </div>
      </div>
    </div>
  )
}

export default Item
