import React, { useEffect, useState } from 'react'
import { Tabs } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import StrategyArea from './StrategyArea'
import StrategyAtrribute from './StrategyAtrribute'
import StrategyAction from './StrategyAction'
import AdviceStrategy from './AdviceStrategy'
import { BusinessService } from 'services'
import { get } from 'lodash'
import BenefitAndRisk from './BenefitAndRisk'
import ImplementationStep from './ImplementationStep'

const { TabPane } = Tabs

export default function StrategySetting({ preview }) {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type === 'business') {
    business = params.get('entity')
  }

  const [business_list, setBusinessList] = useState([])

  const get_business_list = async (params = { is_active: true, business: business }) => {
    const res = await BusinessService.business(params)
    const businesses = get(res, 'data.data', [])
    const transformedData = businesses.map((business) => ({
      name: business.business_name,
      id: business.id,
      users: [
        {
          name: 'User 1',
          id: uuidv4(),
        },
        {
          name: 'User 2',
          id: uuidv4(),
        },
      ],
    }))

    setBusinessList(transformedData)
  }

  useEffect(() => {
    get_business_list()
  }, [])

  return (
    <div>
      <Tabs type="card">
        <TabPane tab="Strategy" key="1">
          <AdviceStrategy preview={preview} business_list={business_list} />
        </TabPane>
        {/*<TabPane tab="Action" key="2">*/}
        {/*    <StrategyAction preview={preview} business_list={business_list}/>*/}
        {/*</TabPane>*/}
        <TabPane tab="Attributes" key="3">
          <StrategyAtrribute preview={preview} business_list={business_list} />
        </TabPane>
        <TabPane tab="Area" key="4">
          <StrategyArea preview={preview} business_list={business_list} />
        </TabPane>
        <TabPane tab="Benefits and risks" key="5">
          <BenefitAndRisk preview={preview} business_list={business_list} />
        </TabPane>
        <TabPane tab="Implementation steps" key="6">
          <ImplementationStep preview={preview} business_list={business_list} />
        </TabPane>
      </Tabs>
    </div>
  )
}
