import React from 'react'
import AdminLayout from '../../../Components/Layout/AdminLayout'
import './page.scss'
import { useState, useEffect } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash'
import { Button } from 'antd'
import { useQuill } from 'react-quilljs'

export default function AdminAboutUs(props) {
  const [about_us, SetAboutUs] = useState('')

  const about_usDetail = async () => {
    const res = await StaticPageService.GetAboutUs()
    await SetAboutUs(get(res, 'data.data.about_us'))
  }

  useEffect(() => {
    about_usDetail()
  }, [])

  const onClickSave = () => {
    StaticPageService.UpdateAboutUs({ about_us })
  }

  const { quill, quillRef } = useQuill()
  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(about_us)
      quill.on('text-change', (delta, oldDelta, source) => {
        SetAboutUs(quill.root.innerHTML)
      })
    }
  }, [quill, about_us])

  const Main = (
    <>
      <div className="static_admin_setting">
        <h1>About Us</h1>
        <div ref={quillRef} />
        <Button onClick={onClickSave} type="primary">
          Save
        </Button>
      </div>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}
