import { Tooltip, Form, DatePicker } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useEffect, useState } from 'react'
const { RangePicker } = DatePicker
const Date = (props) => {
  useEffect(() => {
    if (first) {
      setValue(props.value)
      setFirst(false)
    }
  }, [props.value])

  const [value, setValue] = useState('')
  const [first, setFirst] = useState(true)

  useEffect(() => {
    if (!first && props.dependantValue && props.dependsOn) {
      if (props.dateVariant.toLowerCase().includes('range')) {
        setValue(
          props.filtering.values[props.dependantValue].fromValue &&
            props.filtering.values[props.dependantValue].toValue
            ? [
                props.filtering.values[props.dependantValue].fromValue,
                props.filtering.values[props.dependantValue].toValue,
              ]
            : null
        )
      } else {
        setValue(props.filtering.values[props.dependantValue].defaultValue)
      }
    }
  }, [props.dependantValue])

  useEffect(() => {
    if (props.onChange) props.onChange(value)
  }, [value])

  return (
    <Form.Item>
      {props.dateVariant === 'date' ? (
        props.layout === 'horizontal' ? (
          <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
            {!props.exclude_label ? (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <DatePicker
                value={value && moment(value).isValid() ? moment(value) : null}
                onChange={(val) => setValue(moment(val).format('YYYY-MM-DD'))}
                format={'DD/MM/YYYY'}
                disabled={props.isReadOnly}
              />
            </div>
          </div>
        ) : (
          <div className="form_field">
            {!props.exclude_label ? (
              <label style={{ display: 'block' }}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <DatePicker
                value={value && moment(value).isValid() ? moment(value) : null}
                onChange={(val) => setValue(moment(val).format('YYYY-MM-DD'))}
                format={'DD/MM/YYYY'}
                disabled={props.isReadOnly}
              />
            </div>
          </div>
        )
      ) : props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <RangePicker
              value={value && value.length ? [moment(value[0]), moment(value[1])] : null}
              onChange={(val) => {
                let date = [
                  moment(val[0]).format('YYYY-MM-DD'),
                  moment(val[1]).format('YYYY-MM-DD'),
                ]
                setValue(date)
              }}
              format={'DD/MM/YYYY'}
              disabled={props.isReadOnly}
            />
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ marginBottom: 10, display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <RangePicker
              value={value && value.length ? [moment(value[0]), moment(value[1])] : null}
              onChange={(val) => {
                let date = [
                  moment(val[0]).format('YYYY-MM-DD'),
                  moment(val[1]).format('YYYY-MM-DD'),
                ]
                setValue(date)
              }}
              format={'DD/MM/YYYY'}
              disabled={props.isReadOnly}
            />
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default Date
