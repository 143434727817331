import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, Space, Table, Tabs } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { StrategyService } from 'services'
import { get } from 'lodash'
import { OptionValue } from 'utils'

const { TabPane } = Tabs
const { Option } = Select
const timing_option = OptionValue.timing_option
const action_by_option = OptionValue.action_by_option

export default function ImplementationStep({ preview, business_list }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Subject</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          <Button
            danger
            icon={<DeleteOutlined />}
            size="medium"
            onClick={() => deleteData(record.id)}
          />
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    const res = await StrategyService.getDetailImplementationStep(id)
    formik.setValues(get(res, 'data.data'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this implementation steps?')
    if (result) {
      await StrategyService.deleteImplementationStep(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await StrategyService.getImplementationStep()
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    subject: yup.string().required('Subject Required'),
    action_by: yup.string(),
    timing: yup.string(),
  })

  const initialValues = {
    subject: '',
    action_by: '',
    timing: '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (id) {
        await StrategyService.updateImplementationStep(values, id)
      } else {
        await StrategyService.saveImplementationStep(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })
  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add Implementation steps
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit Implementation steps' : 'Add Implementation steps'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Subject">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Subject"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>

              <Form.Item label="Action by">
                <Select
                  mode="single"
                  name="action_by"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="select action by"
                  value={formik.values.action_by}
                  onChange={(value) => formik.setFieldValue('action_by', value)}
                >
                  <Option value=""></Option>
                  {Object.entries(action_by_option).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
                {formik.touched.action_by && formik.errors.action_by && (
                  <span className="input-error-message">{formik.errors.action_by}</span>
                )}
              </Form.Item>

              <Form.Item label="Timing">
                <Select
                  mode="single"
                  name="timing"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="select timing"
                  value={formik.values.timing}
                  onChange={(value) => formik.setFieldValue('timing', value)}
                >
                  <Option value=""></Option>
                  {Object.entries(timing_option).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
                {formik.touched.timing && formik.errors.timing && (
                  <span className="input-error-message">{formik.errors.timing}</span>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
