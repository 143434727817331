import { Form, Tooltip, Input, Button, Upload } from 'antd'
import {
  InfoCircleOutlined,
  UploadOutlined,
  FileTextOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from '@ant-design/icons'
import { PageService } from 'services'
import { get } from 'lodash'
import { useEffect, useState } from 'react'

const Attachment = (props) => {
  const [image, setImage] = useState(
    props.multiAttachments
      ? props.value && Array.isArray(props.value)
        ? [...props.value]
        : []
      : props.value
  )

  return (
    <Form.Item>
      {props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <div className="single_attachment">
              <div>
                {Array.isArray(image) ? (
                  image.map((img) => (
                    <div>
                      <Button
                        style={{ marginLeft: 5 }}
                        danger
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          let arr = image.filter((im) => img != im)
                          setImage(arr)
                          props.onChange(arr)
                          props.setDeleteAttachments(img)
                        }}
                      ></Button>
                      <a href={img} target="_blank" rel="noopener noreferrer" download>
                        {img.split('/')[img.split('/').length - 1]}
                      </a>
                      {/* <span>{img.split("/")[img.split("/").length - 1]}</span>
                      <Button
                        download={img}
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          window.open(img);
                        }}
                      >
                        Download
                      </Button> */}
                    </div>
                  ))
                ) : image ? (
                  <div>
                    <Button
                      style={{ marginLeft: 5 }}
                      danger
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setImage('')
                        props.onChange('')
                        props.setDeleteAttachments(image)
                      }}
                    ></Button>
                    <a href={image} target="_blank" rel="noopener noreferrer" download>
                      {image.split('/')[image.split('/').length - 1]}
                    </a>
                    {/* <span>{image.split("/")[image.split("/").length - 1]}</span>
                    <Button
                      download={image}
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        window.open(image);
                      }}
                    >
                      Download
                    </Button> */}
                  </div>
                ) : null}
              </div>
              {props.multiAttachments || (!props.multiAttachments && !image) ? (
                <input
                  disabled={props.isReadOnly}
                  type={'file'}
                  value=""
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      let formData = new FormData()
                      formData.append('file', e.target.files[0])
                      formData.append('field', props.id)
                      PageService.uploadFile(formData).then((res) => {
                        if (props.multiAttachments) {
                          let arr = [...image]
                          arr.push(get(res, 'data.data.file', ''))
                          setImage(arr)
                          props.onChange(arr)
                        } else {
                          setImage(get(res, 'data.data.file', ''))
                          props.onChange(get(res, 'data.data.file', ''))
                        }
                        props.setAttachments(get(res, 'data.data.id', ''))
                      })
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}
          <div>
            <div className="single_attachment">
              <div>
                {Array.isArray(image) ? (
                  image.map((img) => (
                    <div>
                      <Button
                        style={{ marginLeft: 5 }}
                        danger
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                          let arr = image.filter((im) => img != im)
                          setImage(arr)
                          props.onChange(arr)
                          props.setDeleteAttachments(img)
                        }}
                      ></Button>
                      <a href={img} target="_blank" rel="noopener noreferrer" download>
                        {img.split('/')[img.split('/').length - 1]}
                      </a>
                      {/* <span>{img.split("/")[img.split("/").length - 1]}</span>
                      <Button
                        download={img}
                        icon={<DownloadOutlined />}
                        onClick={() => {
                          window.open(img);
                        }}
                      >
                        Download
                      </Button> */}
                    </div>
                  ))
                ) : image ? (
                  <div>
                    <Button
                      style={{ marginLeft: 5 }}
                      danger
                      type="text"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setImage('')
                        props.onChange('')
                        props.setDeleteAttachments(image)
                      }}
                    ></Button>
                    <a href={image} target="_blank" rel="noopener noreferrer" download>
                      {image.split('/')[image.split('/').length - 1]}
                    </a>
                    {/* <span>{image.split("/")[image.split("/").length - 1]}</span>
                    <Button
                      download={image}
                      icon={<DownloadOutlined />}
                      onClick={() => {
                        window.open(image);
                      }}
                    >
                      Download
                    </Button> */}
                  </div>
                ) : null}
              </div>
              {props.multiAttachments || (!props.multiAttachments && !image) ? (
                <input
                  disabled={props.isReadOnly}
                  type={'file'}
                  value=""
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      let formData = new FormData()
                      formData.append('file', e.target.files[0])
                      formData.append('field', props.id)
                      PageService.uploadFile(formData).then((res) => {
                        if (props.multiAttachments) {
                          let arr = [...image]
                          arr.push(get(res, 'data.data.file', ''))
                          setImage(arr)
                          props.onChange(arr)
                        } else {
                          setImage(get(res, 'data.data.file', ''))
                          props.onChange(get(res, 'data.data.file', ''))
                        }
                        props.setAttachments(get(res, 'data.data.id', ''))
                      })
                    }
                  }}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default Attachment
