import React, { useEffect, useRef, useState } from 'react'
import { Tabs, Button } from 'antd'
import { DownOutlined, SmileOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Space } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { DeleteOutlined } from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import { PageService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
const workbookGroupId = 'acdd415f-8543-4885-bb7c-485e7547a5a4'
const workbookGroupFieldId = '2e1fa45c-27b1-40b7-99e5-01f952eaa58e'
const progressGroupFieldId = 'ffeede29-6f12-47d1-b016-3915e9658432'
const progressFormFieldId = '08d641da-2b26-4861-9472-39603bb06279'
const progressGroupId = '25627427-7290-448e-b0d1-5db5d8ef8aad'
const formProgressFieldId = '82da920d-f193-4636-8cb4-3883ef9ec2b0'
const workbookFormId = 'c39bb863-e943-48ae-afaa-5279d1713dbb'
const statusFieldId = 'c247549c-d63b-4c0c-8f57-efc20dfa1757'

const WorkbookSetting = ({
  openForm,
  progress,
  addWorkbookSettingStatus,
  addWorkbookSettingProgress,
  handleDragChange,
  statuses,
  deleteRecord,
  getFormRecords,
  getFormDetails,
  formRecords,
  handleWorkbookCardClick,
}) => {
  const { pageId } = useParams()

  const timer = useRef(null)
  const [backlog, setBacklog] = useState([])
  const [statusOptions, setStatusOptions] = useState([])
  const [inProgress, setInProgress] = useState([])
  const [done, setDone] = useState([])
  const [data, setData] = useState([])
  const [status, setStatus] = useState([
    { id: 1, name: 'Analyse' },
    { id: 2, name: 'New' },
    { id: 3, name: 'Blocked' },
  ])
  const [options, setOptions] = useState({})
  const [statusList, setStatusList] = useState([])
  const user_option_data = useSelector((state) =>
    state.entityReducer.user.map((item) => ({
      value: item.id,
      name: item.name,
    }))
  )
  useEffect(() => {
    setStatusList(
      (statuses || [])
        .filter((item) => !item.is_selected)
        .map((item) => ({
          id: item.record_id,
          name: item.data[workbookGroupFieldId],
        }))
    )
  }, [statuses])

  const entity = useSelector((state) => state?.previewUser?.details?.id || state?.user?.details?.id)
  const business = useSelector(
    (state) => state?.previewUser?.details?.business || state?.user?.details?.business
  )
  // const menu = (
  //   <Menu
  //     items={(statusOptions || []).map((item) => ({
  //       key: item.value,
  //       label: (
  //         <div
  //           onClick={(e) => {
  //             e.stopPropagation();

  //             let pd = {
  //               form: workbookFormId,
  //               page: pageId,
  //               ...form.body,
  //               field_data: {
  //                 ...form.body.field_data,
  //                 [statusFieldId]: progress.id,
  //               },
  //               entity: {
  //                 id: entity,
  //               },
  //             };
  //             PageService.updateFormData(pd, form.id).then((res) => {
  //               getFormRecords(workbookFormId);
  //             });
  //           }}
  //         >
  //           {item.name}
  //         </div>
  //       ),
  //     }))}
  //   />
  // );

  const getGroupOptions = (id, cb) => {
    PageService.getGroupDropdown({
      entity: business,
      field: progressGroupFieldId,
    }).then((res) => {
      let obj = {}
      cb(res?.data?.data || [])
    })
  }
  const getFormOptions = (id, cb) => {
    PageService.getFormDropdown({ entity, field: id }).then((res) => {})
  }
  useEffect(() => {
    getGroupOptions(progressGroupFieldId, setStatusOptions)
  }, [])
  useEffect(() => {
    if (formRecords && formRecords.length) {
      prepareFormData()
    }
  }, [formRecords])
  const prepareFormData = (options) => {
    let data = progress.map((p) => ({
      ...p,
      id: p.record_id,
      formRecords: formRecords
        .filter((item) => item.data[formProgressFieldId] == p.record_id)
        .map((item) => ({ ...item, id: item.record_id })),
    }))
    setData(data)
  }
  return (
    <>
      <div style={{ textAlign: 'right', paddingBottom: 15, marginTop: -10 }}>
        <Button type="primary" onClick={() => getFormDetails()}>
          <PlusOutlined />
          Add Task
        </Button>
      </div>
      <div className="droppable" style={{ marginBottom: 50 }}>
        <ReactSortable
          style={{ display: 'flex', flex: 1 }}
          list={data}
          setList={(val) => setData(val)}
          onEnd={() => {
            // setTimeout(() => {
            //   PageService.groupReorder(data.map((item) => item.record_id));
            // }, 1500);
          }}
        >
          {data && data.length
            ? data?.map((item, index) => (
                <div
                  key={item.record_id}
                  className="each_droppable_box"
                  style={{
                    position: 'relative',
                    background: '#eeeeee',
                    marginRight: '5px',
                    borderRadius: '5px',
                  }}
                >
                  {/* <DeleteOutlined
                    onClick={(e) =>
                      deleteRecord(item.record_id, progressGroupId)
                    }
                    color="red"
                    size={5}
                    style={{
                      padding: 5,
                      background: "tomato",
                      position: "absolute",
                      top: -10,
                      right: 5,
                      borderRadius: "50%",
                    }}
                  /> */}
                  <b
                    onClick={() =>
                      addWorkbookSettingProgress(
                        item.record_id,
                        item?.data?.[workbookGroupFieldId],
                        item?.data?.[progressFormFieldId]
                      )
                    }
                  >
                    {item?.data?.[workbookGroupFieldId]}
                  </b>
                  <ReactSortable
                    list={item?.formRecords}
                    onEnd={(e) => {
                      setTimeout(() => {
                        data.map((progress) => {
                          ;(progress?.formRecords || []).map((form) => {
                            if (form?.data?.[formProgressFieldId] != progress.id) {
                              let pd = {
                                form: workbookFormId,
                                page: pageId,
                                ...form.body,
                                field_data: {
                                  ...form.body.field_data,
                                  [formProgressFieldId]: progress.id,
                                },
                                entity: {
                                  id: entity,
                                },
                              }
                              PageService.updateFormData(pd, form.id).then((res) => {
                                getFormRecords(workbookFormId)
                              })
                            }
                          })
                        })
                        PageService.formReorder(item.formRecords.map((item) => item.id))
                      }, 1500)
                    }}
                    setList={(val) => {
                      // clearTimeout(timer.current);
                      let tempdata = JSON.parse(JSON.stringify(data))
                      tempdata[index].formRecords = val
                      setData(tempdata)
                    }}
                    group="status"
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                  >
                    {item?.formRecords.map((s, index) => (
                      <div
                        className="droppable_element"
                        key={s.id}
                        style={{
                          textAlign: 'left',
                          background: 'white',
                          color: '#575757',
                          border: 'none',
                          padding: '10px',
                        }}
                        // onClick={() => addWorkbookSettingStatus(s.id, s.name)}
                        onClick={() => handleWorkbookCardClick(s)}
                      >
                        <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>#1000</div>
                        <div>{s?.data?.['a82446dd-0561-4d1d-a742-97cb832c886e']}</div>
                        {/* <div>
                          Record Date:{" "}
                          {s?.data?.["88c5a642-2a68-49fe-9524-6e29cfa9950c"]}
                        </div> */}

                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '12px',
                            marginTop: '10px',
                          }}
                        >
                          <div>
                            {user_option_data.filter(
                              (item) =>
                                item.value == s?.data?.['2700c17d-5f38-45e4-b83b-f5aeb4e9ccd7']
                            )?.[0]?.name || '---'}
                          </div>
                          <div>
                            <select
                              style={{ border: 'none' }}
                              onClick={(e) => e.stopPropagation()}
                              value={
                                statusOptions.filter(
                                  (item) =>
                                    item.value == s?.data?.['c247549c-d63b-4c0c-8f57-efc20dfa1757']
                                )?.[0]?.value
                              }
                              onChange={(e) => {
                                let pd = {
                                  form: workbookFormId,
                                  page: pageId,
                                  ...s.body,
                                  field_data: {
                                    ...s.body.field_data,
                                    [statusFieldId]: e.target.value,
                                  },
                                  entity: {
                                    id: entity,
                                  },
                                }
                                PageService.updateFormData(pd, s.id).then((res) => {
                                  getFormRecords(workbookFormId)
                                })
                              }}
                            >
                              {(statusOptions || [])
                                .filter((option) => {
                                  if (
                                    (item?.data?.[progressGroupFieldId] || []).find(
                                      (val) => val == option.value
                                    )
                                  ) {
                                    return true
                                  } else {
                                    return false
                                  }
                                })
                                .map((item) => (
                                  <option value={item.value}>{item.name}</option>
                                ))}
                            </select>
                            {/* <Dropdown
                              overlay={
                                <Menu
                                  items={(statusOptions || [])
                                    .filter((option) => {
                                      if (
                                        (
                                          item?.data?.[progressGroupFieldId] ||
                                          []
                                        ).find((val) => val == option.value)
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    })
                                    .map((item) => ({
                                      key: item.value,
                                      label: (
                                        <div
                                          onClick={(e) => {
                                            e.stopPropagation();

                                            let pd = {
                                              form: workbookFormId,
                                              page: pageId,
                                              ...s.body,
                                              field_data: {
                                                ...s.body.field_data,
                                                [statusFieldId]: item.value,
                                              },
                                              entity: {
                                                id: entity,
                                              },
                                            };
                                            PageService.updateFormData(
                                              pd,
                                              s.id
                                            ).then((res) => {
                                              getFormRecords(workbookFormId);
                                            });
                                          }}
                                        >
                                          {console.log("item", item.name)}
                                          {item.name}
                                        </div>
                                      ),
                                    }))}
                                />
                              }
                            >
                              <div onClick={(e) => e.preventDefault()}>
                                <Space>
                                  {statusOptions.filter(
                                    (item) =>
                                      item.value ==
                                      s?.data?.[
                                        "c247549c-d63b-4c0c-8f57-efc20dfa1757"
                                      ]
                                  )?.[0]?.name || "---"}
                                  <DownOutlined />
                                </Space>
                              </div>
                            </Dropdown> */}
                          </div>
                        </div>

                        <DeleteOutlined
                          onClick={(e) => {
                            e.stopPropagation()
                            // let temp = JSON.parse(JSON.stringify(item));
                            // temp.data[progressFormFieldId].splice(index, 1);
                            // handleDragChange(temp);
                            PageService.deleteFormData(s.record_id).then(() => {
                              getFormRecords(workbookFormId)
                            })
                          }}
                          color="red"
                          size={5}
                          style={{
                            padding: 5,
                            background: 'tomato',
                            position: 'absolute',
                            top: -5,
                            right: -5,
                            borderRadius: '50%',
                            zIndex: 100,
                          }}
                        />
                      </div>
                    ))}
                  </ReactSortable>
                </div>
              ))
            : null}
        </ReactSortable>
      </div>
    </>
  )
}

export default WorkbookSetting
