import api from './api'

const endpoints = {
  form: 'form/',
}

const getForms = async (params) => {
  return api.get(endpoints.form, { params })
}

const getForm = async (id) => {
  return api.get(endpoints.form + id + '/')
}

const saveForm = async (data) => {
  return api.post(endpoints.form, data)
}
const updateForm = async (data) => {
  return api.put(endpoints.form + data.id + '/', data)
}

const deleteForm = async (id) => {
  return api.delete(endpoints.form + id + '/')
}

export default {
  getForms,
  getForm,
  saveForm,
  updateForm,
  deleteForm,
}
