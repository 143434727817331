import { Input, Modal, Button, Select, Form } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import ReactQuill from 'react-quill'
import 'quill-mention'
import 'quill-mention/dist/quill.mention.css'
import './pdf.scss'
import { PageServiceService, PdfConfigService } from '../../../../services'
import { get } from 'lodash'
import { Editor } from '@tinymce/tinymce-react'
import { ReactSortable } from 'react-sortablejs'
import TinyEditor from 'Components/TinyEditor'
const atValues = [
  { id: 1, value: 'Fredrik Sundqvist' },
  { id: 2, value: 'Patrik Sjölin' },
]
const { Option } = Select
const CreateCustomPDFPage = (props) => {
  let navigate = useNavigate()
  const params = useParams()
  const [name, setName] = useState('')
  const [display_name, setDisplayName] = useState('')
  const [fields, SetFields] = useState([])
  const [attachment_bg_field, SetAttachmentBgField] = useState('')
  const [image, SetImage] = useState(null)
  const [default_page, SetDefaultPage] = useState(null)
  const [has_image, SetHasImage] = useState(false)
  const [list, setList] = useState([])
  const [default_page_list, setDefaultPageList] = useState([])
  const [selected_layouts, selectedLayouts] = useState([])
  const [page_layout, SetPageLayout] = useState('')
  const [specialCharData, SetSpecialCharData] = useState({
    user: [],
    client: [],
    business: [],
  })

  const myRefs = useRef([])
  const mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@', '#'],
    source: function (searchTerm, renderList, mentionChar) {
      let values

      if (mentionChar === '@') {
        values = atValues
      }

      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i])
        renderList(matches, searchTerm)
      }
    },
  }

  const modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      source: useCallback((searchTerm, renderItem, mentionChar) => {
        let values

        if (mentionChar === '@') {
          values = atValues
        }

        if (searchTerm.length === 0) {
          renderItem(values, searchTerm)
        } else if (values) {
          const matches = []
          for (let i = 0; i < values.length; i += 1)
            if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
              matches.push(values[i])
          renderItem(matches, searchTerm)
        }
      }, []),
    },

    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'link'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['image'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ],
  }

  const [bgImage, setBackgroundImage] = useState('')

  const onChangePicture = (e) => {
    SetImage(e.target.files[0])
    setBackgroundImage(URL.createObjectURL(e.target.files[0]))
    SetHasImage(true)
  }

  const removeBgImage = (e) => {
    setBackgroundImage('')
    SetImage(null)
    SetHasImage(true)
  }

  const handleRemoveItem = (index) => {
    const items = [...list]
    items.splice(index, 1)
    setList(items)
  }

  const addItem = (id) => {
    var item = {
      value: '',
    }
    const items = [...list, item]
    setList(items)
  }

  const handleChange = (index, value) => {
    let items = [...list]
    items[index].value = value
    setList(items)
    console.warn('Inside onchange', items, index, value)
  }

  // const layouts = [
  //   [
  //     {
  //       id:0,
  //       value:"<p>Hallo world<p>"
  //     },
  //     {
  //       id:1,
  //       value:"<p>Layout 1<p>"
  //     }
  //   ],
  //   [
  //     {
  //       id:0,
  //       value:"<p>Hallo world<p>"
  //     },
  //     {
  //       id:1,
  //       value:"<p>Layout 2<p>"
  //     }
  //   ],
  // ]

  const handleLayout = (value) => {
    // let items = layouts[index]
    SetPageLayout(value)
    const selected_layout_obj = selected_layouts.find((o) => o.id === value)
    setList(
      get(selected_layout_obj, 'children', []).map((item) => ({
        value: item.value,
      }))
    )
    SetAttachmentBgField(get(selected_layout_obj, 'attachment_bg_field', ''))
    setBackgroundImage(get(selected_layout_obj, 'image', ''))
    // setList(items)
  }

  const onChangeDefaultPage = (value) => {
    SetDefaultPage(value)
    selectedLayouts(
      get(
        default_page_list.find((o) => o.id === value),
        'layouts',
        []
      )
    )
  }

  const GetDefaultPageList = async (default_page = null) => {
    const res = await PdfConfigService.getDefaultPages({ detail: true })
    const res_data_list = get(res, 'data.data', [])
    setDefaultPageList(res_data_list)
    if (default_page) {
      selectedLayouts(
        get(
          res_data_list.find((o) => o.id === default_page),
          'layouts',
          []
        )
      )
    }
  }

  const GetFieldList = async () => {
    const res = await PageServiceService.FieldList()
    SetFields(get(res, 'data.data', []))
  }

  const GetVariableList = async () => {
    const res = await PageServiceService.getVariableList()
    SetSpecialCharData(get(res, 'data.data'))
  }

  const get_detail = async () => {
    const res = await PdfConfigService.getPageConfig(params.config_page_id)
    SetAttachmentBgField(get(res, 'data.data.attachment_bg_field', '') || '')
    setName(get(res, 'data.data.name', ''))
    setDisplayName(get(res, 'data.data.display_name', ''))
    setList(get(res, 'data.data.children', []))
    setBackgroundImage(get(res, 'data.data.image', ''))
    GetDefaultPageList(get(res, 'data.data.default_page', ''))
    SetDefaultPage(get(res, 'data.data.default_page', ''))
    SetPageLayout(get(res, 'data.data.page_layout', ''))
  }

  const load_data = async () => {
    await GetVariableList()
    await GetFieldList()
    if (params.config_page_id) {
      await get_detail()
    } else {
      await GetDefaultPageList()
    }
  }

  useEffect(() => {
    load_data()
  }, [])

  const SaveClick = async () => {
    let formData = new FormData()
    if (image) {
      formData.append('image', image)
    }
    if (page_layout) {
      formData.append('page_layout', page_layout)
    }
    formData.append('attachment_bg_field', attachment_bg_field)
    formData.append('children', JSON.stringify(list))
    formData.append('has_image', has_image)
    formData.append('name', name)
    formData.append('display_name', display_name)
    if (default_page) {
      formData.append('default_page', default_page)
    }

    if (params.config_page_id) {
      await PdfConfigService.updatePageConfig(formData, params.config_page_id)
    } else {
      await PdfConfigService.savePageConfig(formData)
    }
    navigate(`/admin/pdf-config/pages`)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const resetPageConfig = async (id) => {
    const result = await window.confirm(`Are you sure to reset to selected layout ?`)
    if (result) {
      await PdfConfigService.resetPageConfig(id)
      window.location.reload()
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const editorRef = useRef(null)

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Add Page</span>{' '}
          <div>
            {params.config_page_id && (
              <Button
                style={{ marginRight: 5 }}
                onClick={() => resetPageConfig(params.config_page_id)}
              >
                Reset config
              </Button>
            )}
            <Button style={{ marginRight: 5 }} onClick={showModal}>
              Preview Page
            </Button>
            <Button type="primary" onClick={SaveClick}>
              Save Page
            </Button>
          </div>
        </h1>
        <div className="form_builder_layout">
          <Form layout="vertical">
            <Form.Item label="Heading section">
              <Input placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item label="Display Name">
              <Input
                placeholder="Display name"
                value={display_name}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Form.Item>
          </Form>
          <div className="layout_options">
            <Select
              placeholder={'Select default page'}
              style={{ width: 450 }}
              value={default_page}
              onChange={onChangeDefaultPage}
            >
              <Option value=""></Option>
              {default_page_list.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
            <Select
              placeholder={'Select layout'}
              style={{ width: 450 }}
              value={page_layout}
              onChange={(value) => handleLayout(value)}
            >
              <Option value=""></Option>
              {selected_layouts.map((item, index) => (
                <Option key={item.id} value={item.id}>
                  {' '}
                  {`Layout ${index + 1}`}
                </Option>
              ))}
            </Select>

            <div className="cover_bg_controls">
              <label className="bd_set_btn" htmlFor="bgImage">
                Select bg image
              </label>
              <input id="bgImage" type="file" onChange={onChangePicture} hidden />
              <Button
                danger
                type="link"
                icon={<DeleteOutlined />}
                size={'medium'}
                onClick={removeBgImage}
              />
            </div>
          </div>
          <Form.Item label="Background image" labelAlign="vertical">
            <Select
              defaultValue=""
              style={{ width: '300px' }}
              value={attachment_bg_field}
              onChange={(value) => SetAttachmentBgField(value)}
            >
              <Option value=""></Option>
              {fields
                .filter((item) => item.type === 'attachment')
                .map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <br />
          <br />
          <br />

          <div
            className="form_builder_layout pdf_page"
            style={bgImage ? { backgroundImage: `url(${bgImage})` } : null}
          >
            <div
              className="form_builder"
              style={bgImage ? { backgroundColor: 'transparent' } : { backgroundColor: '#F8F8F8' }}
            >
              <ReactSortable
                list={list}
                setList={(list) => {
                  console.log('list', list)
                  setList(list)
                }}
              >
                {list.map((item, index) => {
                  return (
                    <div key={index} className="pdf_element">
                      {/* <ReactQuill modules={{...modules}}  value={item.value} onChange={(val)=>handleChange(index,val)}/> */}
                      <TinyEditor
                        width={100}
                        height={250}
                        value={item.value}
                        onChange={(val) => handleChange(index, val)}
                        special_char_data={specialCharData}
                      />
                      <div className="elements_control">
                        <Button
                          className="conditions"
                          type="link"
                          icon={<QuestionCircleOutlined />}
                          size={'medium'}
                          onClick={() => {
                            navigate(
                              `/admin/conditions?pdf=${params.config_page_id}&fieldId=${item.id}`,
                              { push: true }
                            )
                          }}
                        />
                        <Button
                          danger
                          type="link"
                          icon={<DeleteOutlined />}
                          size={'medium'}
                          onClick={() => {
                            handleRemoveItem(index)
                          }}
                        />
                      </div>
                      Use @ to access variables
                    </div>
                  )
                })}
              </ReactSortable>
            </div>
          </div>
          <div className="form_tools">
            <button className="button_tool" title="Element" onClick={addItem}>
              <span className="icon material-icons-outlined">add</span>
              <span className="text">Add</span>
            </button>
          </div>
        </div>
        <br />
      </div>
      <Modal
        title="Preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
      >
        <div
          style={
            bgImage
              ? {
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: 'contain',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  padding: '30px',
                }
              : { padding: '30px' }
          }
        >
          {list.map((item, index) => {
            return <div key={index} dangerouslySetInnerHTML={{ __html: item.value }} />
          })}
        </div>
      </Modal>
    </AdminLayout>
  )
}

export default CreateCustomPDFPage
