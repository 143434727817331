import { Input, Table, Button, Space, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
  CopyOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { PageService } from 'services'
import { useEffect, useState } from 'react'
import { get } from 'lodash'
import produce from 'immer'
const { Option } = Select

const PageList = (props) => {
  let navigate = useNavigate()
  const [filter, setFilter] = useState('all')
  const [pages, setPages] = useState([])
  const [allPages, setAllPages] = useState([])
  useEffect(() => {
    getPages()
  }, [])
  const prepareData = (items) => {
    const nextState = produce(items, (draft) => {
      recursive(draft)
    })
    return nextState
  }
  const recursive = (data) => {
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      if (item.type == 'group') {
        let temp = { ...item.group }
        item.group = temp.id
      }
      if (item.type == 'form') {
        let temp = { ...item.form }
        item.form = temp.id
      }
      if (item.type == 'field') {
        let temp = { ...item.field }
        item.field = temp.id
      } else if (item.tabs) {
        recursive(item.tabs)
      } else if (item.children) {
        recursive(item.children)
      }
    }
  }
  const getPages = async () => {
    try {
      let res = await PageService.getPages()
      setPages(get(res, 'data.data', []).map((item) => ({ ...item, key: item.id })))
      setAllPages(get(res, 'data.data', []).map((item) => ({ ...item, key: item.id })))
    } catch (err) {
      console.log('err', err)
    }
  }
  const columns = [
    {
      title: 'Page Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Page Name</h6>
          <b>{e.name}</b>
        </div>
      ),
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Display Name</h6>
          <b>{e.display_name || '-'}</b>
        </div>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Type</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/pages/edit/' + record.id)}
          />
          <Button
            icon={<QuestionCircleOutlined />}
            size={'medium'}
            onClick={() => {
              navigate(`/admin/conditions?main_page=${record.id}&fieldId=${record.id}`, {
                push: true,
              })
            }}
          />
          <Button
            icon={<CopyOutlined />}
            size={'medium'}
            onClick={async () => {
              if (window.confirm('Create a Copy of ' + record.name + ' ?') == true) {
                try {
                  let res = await PageService.getPage(record.id)
                  let data = JSON.parse(JSON.stringify(res?.data?.data)) || {}
                  delete data.id
                  data.name = 'Copy of ' + data.name
                  data.items = prepareData(data.items)
                  let saveObj = await PageService.savePage(data)
                  getPages()
                } catch (err) {
                  console.log('err', err.response)
                }
              } else {
              }
            }}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={() => {
              if (window.confirm(`Are you sure, you want to delete "${record.name}"`) == true) {
                PageService.deletePage(record.id)
                getPages()
              }
            }}
          />
        </Space>
      ),
    },
  ]
  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Manage Pages</span>{' '}
        <Button
          onClick={() => {
            navigate('/admin/pages/add', { push: true })
          }}
          icon={<PlusOutlined />}
          type="primary"
        >
          Add Page
        </Button>
      </h1>
      <Input.Group compact style={{ width: '100%' }}>
        <Select
          defaultValue="all"
          style={{ width: '140px' }}
          onChange={(val) => setFilter(val)}
          value={filter}
        >
          <Option value="all">All categories</Option>
          <Option value="name">Name</Option>
          <Option value="type">Type</Option>
        </Select>
        <Input.Search
          onChange={(e) => {
            let arr = []
            if (filter === 'all') {
              arr = allPages.filter(
                (item) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                  item.type.toLowerCase().includes(e.target.value.toLowerCase())
              )
            }
            if (filter === 'name') {
              arr = allPages.filter((item) =>
                item.name.toLowerCase().includes(e.target.value.toLowerCase())
              )
            }

            if (filter === 'type') {
              arr = allPages.filter((item) =>
                item.type.toLowerCase().includes(e.target.value.toLowerCase())
              )
            }
            setPages(arr)
          }}
          allowClear
          style={{ width: 'calc(100% - 140px)' }}
        />
      </Input.Group>
      <br />
      <Table className="custom_responsive_table" columns={columns} dataSource={pages} />
    </div>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default PageList
