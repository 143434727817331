import React, { useEffect, useRef, useState } from 'react'
import { Tabs, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { DeleteOutlined } from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import { PageService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
const workbookGroupId = 'acdd415f-8543-4885-bb7c-485e7547a5a4'
const workbookGroupFieldId = '2e1fa45c-27b1-40b7-99e5-01f952eaa58e'
const progressGroupFieldId = 'ffeede29-6f12-47d1-b016-3915e9658432'
const progressGroupId = '25627427-7290-448e-b0d1-5db5d8ef8aad'
const WorkbookSetting = ({
  openForm,
  progress,
  addWorkbookSettingStatus,
  addWorkbookSettingProgress,
  handleDragChange,
  statuses,
  deleteRecord,
}) => {
  const timer = useRef(null)
  const [backlog, setBacklog] = useState([])
  const [inProgress, setInProgress] = useState([])
  const [done, setDone] = useState([])
  const [data, setData] = useState([])
  const [status, setStatus] = useState([
    { id: 1, name: 'Analyse' },
    { id: 2, name: 'New' },
    { id: 3, name: 'Blocked' },
  ])
  const [options, setOptions] = useState({})
  const [statusList, setStatusList] = useState([])
  useEffect(() => {
    setStatusList(
      (statuses || [])
        .filter((item) => !item.is_selected)
        .map((item) => ({
          id: item.record_id,
          name: item.data[workbookGroupFieldId],
        }))
    )
  }, [statuses])

  const entity = useSelector((state) => state?.previewUser?.details?.id || state?.user?.details?.id)

  const getGroupOptions = () => {
    PageService.getGroupDropdown({ entity, field: progressGroupFieldId }).then((res) => {
      let obj = {}
      get(res, 'data.data', []).map((item) => (obj[item.value] = item.name))
      setOptions(obj)
      prepareFormData(obj)
    })
  }
  useEffect(() => {
    getGroupOptions()
  }, [])
  const prepareFormData = (options) => {
    let data = progress.map((p) => ({
      ...p,
      data: {
        ...p.data,
        [progressGroupFieldId]: (p.data[progressGroupFieldId] || []).map((item) => ({
          id: item,
          name: options[item],
        })),
      },
    }))
    setData(data)
  }
  return (
    <>
      <div style={{ textAlign: 'right', paddingBottom: 15, marginTop: -10 }}>
        <Button type="primary" onClick={() => addWorkbookSettingProgress()}>
          <PlusOutlined />
          Add Progress
        </Button>
      </div>
      <div className="droppable" style={{ marginBottom: 50 }}>
        <div className="droppable_sidebar">
          <h1>Status</h1>
          <ReactSortable
            list={statusList}
            setList={(val) => {
              setStatusList(val)
            }}
            onEnd={(e) => {
              setTimeout(() => {
                data.forEach((item) => handleDragChange(item))
              }, 1500)
            }}
            group="status"
            animation={200}
            delayOnTouchStart={true}
            delay={2}
          >
            {(statusList || []).map((item) => (
              <div
                key={item.id}
                className="droppable_element"
                onClick={() => addWorkbookSettingStatus(item.id, item.name)}
              >
                {item?.name}
                <DeleteOutlined
                  onClick={(e) => deleteRecord(item.id, workbookGroupId)}
                  color="red"
                  size={5}
                  style={{
                    padding: 5,
                    background: 'tomato',
                    position: 'absolute',
                    top: -5,
                    right: -5,
                    borderRadius: '50%',
                  }}
                />
              </div>
            ))}
          </ReactSortable>

          <Button type="primary" onClick={() => addWorkbookSettingStatus()}>
            <PlusOutlined />
            Add Status
          </Button>
        </div>
        <ReactSortable
          style={{ display: 'flex', flex: 1 }}
          list={data}
          setList={(val) => setData(val)}
          onEnd={() => {
            setTimeout(() => {
              PageService.groupReorder(data.map((item) => item.record_id))
            }, 1500)
          }}
        >
          {data && data.length
            ? data?.map((item, index) => (
                <div
                  key={item.record_id}
                  className="each_droppable_box"
                  style={{ position: 'relative' }}
                >
                  <DeleteOutlined
                    onClick={(e) => deleteRecord(item.record_id, progressGroupId)}
                    color="red"
                    size={5}
                    style={{
                      padding: 5,
                      background: 'tomato',
                      position: 'absolute',
                      top: -10,
                      right: 5,
                      borderRadius: '50%',
                    }}
                  />
                  <b
                    onClick={() =>
                      addWorkbookSettingProgress(
                        item.record_id,
                        item?.data?.[workbookGroupFieldId],
                        item?.data?.[progressGroupFieldId]
                      )
                    }
                  >
                    {item?.data?.[workbookGroupFieldId]}
                  </b>
                  <ReactSortable
                    list={item?.data?.[progressGroupFieldId]}
                    onEnd={(e) => {
                      setTimeout(() => {
                        data.forEach((item) => handleDragChange(item))
                      }, 1500)
                    }}
                    setList={(val) => {
                      clearTimeout(timer.current)
                      let tempdata = JSON.parse(JSON.stringify(data))
                      tempdata[index].data[progressGroupFieldId] = val
                      setData(tempdata)
                    }}
                    group="status"
                    animation={200}
                    delayOnTouchStart={true}
                    delay={2}
                  >
                    {item?.data?.[progressGroupFieldId].map((s, index) => (
                      <div
                        className="droppable_element"
                        key={s.id}
                        onClick={() => addWorkbookSettingStatus(s.id, s.name)}
                      >
                        {s.name}

                        <DeleteOutlined
                          onClick={() => {
                            let temp = JSON.parse(JSON.stringify(item))

                            temp.data[progressGroupFieldId].splice(index, 1)
                            handleDragChange(temp)
                          }}
                          color="red"
                          size={5}
                          style={{
                            padding: 5,
                            background: 'tomato',
                            position: 'absolute',
                            top: -5,
                            right: -5,
                            borderRadius: '50%',
                          }}
                        />
                      </div>
                    ))}
                  </ReactSortable>
                </div>
              ))
            : null}
        </ReactSortable>
      </div>
    </>
  )
}

export default WorkbookSetting
