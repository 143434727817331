import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Row, Col, Tabs } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { GoalService } from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'
import CustomTree from 'Components/CustomTree/CustomTree'

const { TabPane } = Tabs

export default function ObjectiveArea({ preview, business_list }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [webLinks, setWebLinks] = useState([])
  const [videoLinks, setVideoLinks] = useState([])
  const [permissions, setPermissions] = useState({})
  const [activeKey, setActiveKey] = useState('1')

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Area',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Area</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setWebLinks([])
    setVideoLinks([])
    setPermissions({})
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    const res = await GoalService.getDetailArea(id)
    formik.setValues(get(res, 'data.data'))
    setWebLinks(get(res, 'data.data.web_links', []))
    setVideoLinks(get(res, 'data.data.video_links', []))
    setPermissions(get(res, 'data.data.permissions', {}))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this area?')
    if (result) {
      await GoalService.deleteArea(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await GoalService.getArea({ business: business })
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    subject: yup.string().required('Area Required'),
    icon: yup.string(),
    description: yup.string(),
    web_links: yup.array().of(
      yup.object().shape({
        web_link: yup.string().url('Invalid URL').required('Web link required'),
        web_name: yup.string().required('Web name required'),
      })
    ),
    video_links: yup.array().of(
      yup.object().shape({
        video_icon: yup.string().required('Video icon required'),
        video_link: yup.string().url('Invalid URL').required('Video link required'),
      })
    ),
  })

  const initialValues = {
    subject: '',
    description: '',
    icon: '',
    web_links: [],
    video_links: [],
    permissions: {},
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      values['permissions'] = permissions
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this area?')
          if (result) {
            await GoalService.updateArea(values, id)
          }
        } else {
          await GoalService.updateArea(values, id)
        }
      } else {
        await GoalService.saveArea(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this area to global?')
    if (result) {
      const res = await GoalService.getResetArea(id)
      formik.setValues(get(res, 'data.data'))
      setWebLinks(get(res, 'data.data.web_links', []))
      setVideoLinks(get(res, 'data.data.video_links', []))
    }
  }

  const handleAddWebLink = () => {
    setWebLinks([...webLinks, { web_link: '', web_name: '' }])
  }

  const handleAddVideoLink = () => {
    setVideoLinks([...videoLinks, { video_icon: '', video_link: '' }])
  }

  const handleWebLinkChange = (index, event) => {
    const { name, value } = event.target
    const newWebLinks = webLinks.slice()
    newWebLinks[index][name] = value
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleVideoLinkChange = (index, event) => {
    const { name, value } = event.target
    const newVideoLinks = videoLinks.slice()
    newVideoLinks[index][name] = value
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const handleRemoveWebLink = (index) => {
    const newWebLinks = webLinks.slice()
    newWebLinks.splice(index, 1)
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleRemoveVideoLink = (index) => {
    const newVideoLinks = videoLinks.slice()
    newVideoLinks.splice(index, 1)
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }
  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  const onCheckPerMission = (checkedKeys) => {
    setPermissions(checkedKeys)
    // console.log(checkedKeys);
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add Area
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit area' : 'Add area'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Area">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Area"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>
              <Form.Item label="Icon">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Icon"
                  name="icon"
                  onChange={formik.handleChange}
                  value={formik.values.icon}
                />
                {formik.touched.icon && formik.errors.icon && (
                  <span className="input-error-message">{formik.errors.icon}</span>
                )}
              </Form.Item>
              <Form.Item label="Description">
                <QuillEditor
                  theme="snow"
                  name="description"
                  value={formik.values.description}
                  onChange={(value) => formik.setFieldValue('description', value)}
                />
              </Form.Item>
              {webLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Web Name ${index + 1}`}>
                      <Input
                        placeholder="Web Name"
                        name="web_name"
                        value={link.web_name}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Web Link ${index + 1}`}>
                      <Input
                        placeholder="Web Link"
                        name="web_link"
                        value={link.web_link}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_link}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveWebLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddWebLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Web Link
              </Button>

              {videoLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Video Icon ${index + 1}`}>
                      <Input
                        placeholder="Video Icon"
                        name="video_icon"
                        value={link.video_icon}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_icon}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Video Link ${index + 1}`}>
                      <Input
                        placeholder="Video Link"
                        name="video_link"
                        value={link.video_link}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_link}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveVideoLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddVideoLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Video Link
              </Button>
            </Form>
          </TabPane>
          <TabPane tab="Permissions" key="2">
            <CustomTree
              preview={preview}
              onCheckPerMission={onCheckPerMission}
              business_list={business_list}
              permissions={permissions}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
