import React, { useEffect, useState } from 'react'
import { Form, Tooltip, InputNumber, Button, Row, Col, Slider } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

const Number = (props) => {
  const [fields, setFields] = useState(props.value || [{}])
  const [value, setValue] = useState('')
  const [first, setFirst] = useState(true)
  const [mount, setMount] = useState(true)
  const addField = () => {
    const fieldsArray = [...fields]
    fieldsArray.push({})
    setFields(fieldsArray)
  }
  const updateFieldValue = (val, index) => {
    const fieldsArray = [...fields]
    fieldsArray[index] = { ...fieldsArray[index], ...val }
    console.log('lll', fieldsArray)
    setFields(fieldsArray)
  }

  const removeField = (index) => {
    const fieldsArray = [...fields]
    fieldsArray.splice(index, 1)
    setFields(fieldsArray)
  }

  useEffect(() => {
    if (props.onChange && !mount) props?.onChange(fields)
    if (mount) setMount(false)
  }, [fields])
  console.log('value', value, props.value)
  useEffect(() => {
    if (!mount && props.dependantValue && props.dependsOn) {
      console.log('dpe valuej', props.filtering.values[props.dependantValue].defaultValue)
      let digit = props.filtering.values[props.dependantValue].defaultValue
      setValue({
        single: digit,
      })
      props.onChange({ single: digit })
    }
  }, [props.dependantValue])
  useEffect(() => {
    console.log('first', first)
    if (first && props?.value?.single) {
      setFirst(false)

      setValue({
        single: typeof props.value.single === 'number' ? props.value.single : '',
      })
    }
  }, [props.value])
  return (
    <Form.Item>
      {props.layout === 'horizontal' ? (
        <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
          {!props.exclude_label ? (
            <label style={!props.isGroup ? { width: '20%' } : null}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}

          {props.numberType === 'single' &&
            (props.numberDisplay === 'slider' ? (
              <div style={{ display: 'flex', flex: 1 }}>
                <Slider
                  min={props.numberMin}
                  max={props.numberMax}
                  style={{ flex: 1 }}
                  // value={props?.value?.single}
                  // onChange={(val) => {
                  //   props.onChange({ single: val });
                  // }}
                />
                <InputNumber
                  min={1}
                  max={props.numberMax}
                  style={{ margin: '0 16px' }}
                  // value={props?.value?.single}
                  // onChange={(val) => {
                  //   props.onChange({ single: val });
                  // }}
                />
              </div>
            ) : (
              <div style={{ display: 'flex', flex: 1 }}>
                <InputNumber
                  style={{ width: `${props.width}%` }}
                  defaultValue={props.defaultNumber}
                  readOnly={props.isReadOnly}
                  addonAfter={props.numberFormat == 'percentage' ? '%' : undefined}
                  addonBefore={props.numberFormat == 'currency' ? props.currency : undefined}
                  step={props.step}
                  value={value?.single}
                  onChange={(val) => {
                    setValue({ single: val })
                    props.onChange({ single: val })
                  }}
                />
              </div>
            ))}

          {props.numberType === 'range' &&
            (props.numberDisplay === 'slider' ? (
              <div style={{ display: 'flex', flex: 1 }}>
                <InputNumber
                  min={1}
                  max={props.numberMin}
                  style={{ margin: '0 16px' }}
                  // value={props?.value?.single}
                  // onChange={(val) => {
                  //   props.onChange({ single: val });
                  // }}
                />
                <Slider
                  min={props.numberMin}
                  max={props.numberMax}
                  style={{ flex: 1 }}
                  range
                  // value={props?.value?.single}
                  // onChange={(val) => {
                  //   props.onChange({ single: val });
                  // }}
                />
                <InputNumber
                  min={1}
                  max={props.numberMax}
                  style={{ margin: '0 16px' }}
                  // value={props?.value?.single}
                  // onChange={(val) => {
                  //   props.onChange({ single: val });
                  // }}
                />
              </div>
            ) : (
              <div>
                <div>
                  <InputNumber
                    addonBefore={
                      props.numberFormat == 'currency'
                        ? props.currency
                        : props.numberFormat == 'percentage'
                          ? '%'
                          : null
                    }
                    step={props.step}
                    style={{ width: '203px' }}
                    readOnly={props.isReadOnly}
                    value={props?.value?.start}
                    onChange={(val) => {
                      let value = props.value || {}
                      props.onChange({ ...value, start: val })
                    }}
                  />
                  <span className="input_sep"> - </span>
                  <InputNumber
                    readOnly={props.isReadOnly}
                    addonBefore={
                      props.numberFormat == 'currency'
                        ? props.currency
                        : props.numberFormat == 'percentage'
                          ? '%'
                          : null
                    }
                    step={props.step}
                    style={{ marginLeft: -1, width: '233px' }}
                    value={props?.value?.end}
                    onChange={(val) => {
                      let value = props.value || {}
                      props.onChange({ ...value, end: val })
                    }}
                  />
                </div>
              </div>
            ))}

          {props.numberType === 'flat_var' && (
            <div>
              <InputNumber
                step={props.step}
                addonBefore={props.currency}
                readOnly={props.isReadOnly}
                value={props?.value?.flat}
                onChange={(val) => {
                  let value = props.value || {}
                  props.onChange({ ...value, flat: val })
                }}
              />
              <span className="input_sep">+</span>
              <InputNumber
                step={props.step}
                readOnly={props.isReadOnly}
                style={{ marginLeft: -1 }}
                addonAfter="%"
                value={props?.value?.variable}
                onChange={(val) => {
                  let value = props.value || {}
                  props.onChange({ ...value, variable: val })
                }}
              />
            </div>
          )}

          {props.numberType === 'flat_var_range' && (
            <div>
              <div>
                <span>
                  <InputNumber
                    addonBefore={
                      props.numberFormat == 'currency'
                        ? props.currency
                        : props.numberFormat == 'percentage'
                          ? '%'
                          : null
                    }
                    step={props.step}
                    readOnly={props.isReadOnly}
                    value={props?.value?.start}
                    onChange={(val) => {
                      let value = props.value || {}
                      props.onChange({ ...value, start: val })
                    }}
                  />
                  <span className="input_sep"> - </span>
                  <InputNumber
                    readOnly={props.isReadOnly}
                    addonBefore={
                      props.numberFormat == 'currency'
                        ? props.currency
                        : props.numberFormat == 'percentage'
                          ? '%'
                          : null
                    }
                    step={props.step}
                    style={{ marginLeft: -1 }}
                    value={props?.value?.end}
                    onChange={(val) => {
                      let value = props.value || {}
                      props.onChange({ ...value, end: val })
                    }}
                  />
                </span>
                <span className="input_sep"></span>
                <span className="input_sep"></span>
                <span className="input_sep"></span>
                <InputNumber
                  step={props.step}
                  addonBefore={props.currency}
                  readOnly={props.isReadOnly}
                  value={props?.value?.flat}
                  onChange={(val) => {
                    let value = props.value || {}
                    props.onChange({ ...value, flat: val })
                  }}
                />
                <span className="input_sep">+</span>
                <InputNumber
                  step={props.step}
                  style={{ marginLeft: -1 }}
                  readOnly={props.isReadOnly}
                  addonAfter="%"
                  value={props?.value?.variable}
                  onChange={(val) => {
                    let value = props.value || {}
                    props.onChange({ ...value, variable: val })
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="form_field">
          {!props.exclude_label ? (
            <label style={{ display: 'block' }}>
              {props.displayName ? props.displayName : props.name}{' '}
              {props.tooltip ? (
                <Tooltip title={props.tooltip}>
                  <span>
                    <InfoCircleOutlined />
                  </span>
                </Tooltip>
              ) : null}{' '}
              {props.isRequired ? <span className="red">*</span> : null}
            </label>
          ) : null}

          {props.numberType === 'single' && (
            <div>
              {props.dynamicFields && fields.length ? (
                fields?.map((item, index) => (
                  <div style={{ display: 'flex', marginBottom: 13 }}>
                    <div style={{ display: 'flex', flex: 1 }}>
                      <InputNumber
                        style={{ width: `${props.width}%` }}
                        defaultValue={props.defaultNumber}
                        step={props.step}
                        readOnly={props.isReadOnly}
                        addonAfter={props.numberFormat == 'percentage' ? '%' : undefined}
                        addonBefore={props.numberFormat == 'currency' ? props.currency : undefined}
                        value={item.single}
                        onChange={(val) => {
                          updateFieldValue({ ...item, single: val }, index)
                        }}
                      />
                    </div>
                    <Button
                      danger
                      type="link"
                      icon={
                        <span
                          className="material-icons-outlined"
                          style={{
                            fontSize: 25,
                            display: 'inline-block',
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            fields.length - 1 == index ? addField() : removeField(index)
                          }}
                        >
                          {fields.length - 1 == index ? 'add_box' : 'disabled_by_default'}
                        </span>
                      }
                    />
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex' }}>
                  {props.numberDisplay === 'slider' ? (
                    <div style={{ display: 'flex', flex: 1 }}>
                      <Slider
                        min={props.numberMin}
                        max={props.numberMax}
                        style={{ flex: 1 }}
                        // value={props?.value?.single}
                        // onChange={(val) => {
                        //   props.onChange({ single: val });
                        // }}
                      />
                      <InputNumber
                        min={1}
                        max={props.numberMax}
                        style={{ margin: '0 16px' }}
                        // value={props?.value?.single}
                        // onChange={(val) => {
                        //   props.onChange({ single: val });
                        // }}
                      />
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flex: 1 }}>
                      <InputNumber
                        style={{ width: `${props.width}%` }}
                        defaultValue={props.defaultNumber}
                        step={props.step}
                        readOnly={props.isReadOnly}
                        addonAfter={props.numberFormat == 'percentage' ? '%' : undefined}
                        addonBefore={props.numberFormat == 'currency' ? props.currency : undefined}
                        value={props?.value?.single}
                        onChange={(val) => {
                          props.onChange({ single: val })
                        }}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {props.numberType === 'range' && (
            <div>
              {props.dynamicFields && fields.length ? (
                fields?.map((item, index) => (
                  <div style={{ display: 'flex', marginBottom: 13 }}>
                    <div>
                      <div>
                        <InputNumber
                          addonBefore={
                            props.numberFormat == 'currency'
                              ? props.currency
                              : props.numberFormat == 'percentage'
                                ? '%'
                                : null
                          }
                          step={props.step}
                          style={{ width: '203px' }}
                          readOnly={props.isReadOnly}
                          value={item.start}
                          onChange={(val) => {
                            updateFieldValue({ ...item, start: val }, index)
                          }}
                        />
                        <span className="input_sep"> - </span>
                        <InputNumber
                          addonBefore={
                            props.numberFormat == 'currency'
                              ? props.currency
                              : props.numberFormat == 'percentage'
                                ? '%'
                                : null
                          }
                          step={props.step}
                          style={{ marginLeft: -1, width: '233px' }}
                          readOnly={props.isReadOnly}
                          value={item.end}
                          onChange={(val) => {
                            updateFieldValue({ ...item, end: val }, index)
                          }}
                        />
                      </div>
                    </div>
                    <Button
                      danger
                      type="link"
                      icon={
                        <span
                          className="material-icons-outlined"
                          style={{
                            fontSize: 25,
                            display: 'inline-block',
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            fields.length - 1 == index ? addField() : removeField(index)
                          }}
                        >
                          {fields.length - 1 == index ? 'add_box' : 'disabled_by_default'}
                        </span>
                      }
                    />
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex' }}>
                  {props.numberDisplay === 'slider' ? (
                    <div style={{ display: 'flex', flex: 1 }}>
                      <InputNumber
                        min={1}
                        max={props.numberMin}
                        style={{ margin: '0 16px' }}
                        // value={props?.value?.single}
                        // onChange={(val) => {
                        //   props.onChange({ single: val });
                        // }}
                      />
                      <Slider
                        min={props.numberMin}
                        max={props.numberMax}
                        style={{ flex: 1 }}
                        range
                        // value={props?.value?.single}
                        // onChange={(val) => {
                        //   props.onChange({ single: val });
                        // }}
                      />
                      <InputNumber
                        min={1}
                        max={props.numberMax}
                        style={{ margin: '0 16px' }}
                        // value={props?.value?.single}
                        // onChange={(val) => {
                        //   props.onChange({ single: val });
                        // }}
                      />
                    </div>
                  ) : (
                    <div>
                      {props.numberFormat === 'integer' && (
                        <div>
                          <InputNumber
                            addonBefore={
                              props.numberFormat == 'currency'
                                ? props.currency
                                : props.numberFormat == 'percentage'
                                  ? '%'
                                  : null
                            }
                            step={props.step}
                            style={{ width: '203px' }}
                            readOnly={props.isReadOnly}
                            value={props?.value?.start}
                            onChange={(val) => {
                              let value = props.value || {}
                              props.onChange({ ...value, start: val })
                            }}
                          />
                          <span className="input_sep"> - </span>
                          <InputNumber
                            addonBefore={
                              props.numberFormat == 'currency'
                                ? props.currency
                                : props.numberFormat == 'percentage'
                                  ? '%'
                                  : null
                            }
                            step={props.step}
                            style={{ marginLeft: -1, width: '233px' }}
                            readOnly={props.isReadOnly}
                            value={props?.value?.end}
                            onChange={(val) => {
                              let value = props.value || {}
                              props.onChange({ ...value, end: val })
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {props.numberType === 'flat_var' && (
            <div>
              {props.dynamicFields && fields.length ? (
                fields?.map((item, index) => (
                  <div style={{ display: 'flex', marginBottom: 13 }}>
                    <div>
                      <InputNumber
                        step={props.step}
                        addonBefore={props.currency}
                        readOnly={props.isReadOnly}
                        value={item.flat}
                        onChange={(val) => {
                          updateFieldValue({ ...item, flat: val }, index)
                        }}
                      />
                      <span className="input_sep">+</span>
                      <InputNumber
                        step={props.step}
                        style={{ marginLeft: -1 }}
                        addonAfter="%"
                        value={item.variable}
                        readOnly={props.isReadOnly}
                        onChange={(val) => {
                          updateFieldValue({ ...item, variable: val }, index)
                        }}
                      />
                    </div>
                    <Button
                      danger
                      type="link"
                      icon={
                        <span
                          className="material-icons-outlined"
                          style={{
                            fontSize: 25,
                            display: 'inline-block',
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            fields.length - 1 == index ? addField() : removeField(index)
                          }}
                        >
                          {fields.length - 1 == index ? 'add_box' : 'disabled_by_default'}
                        </span>
                      }
                    />
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex' }}>
                  <div>
                    <InputNumber
                      step={props.step}
                      addonBefore={props.currency}
                      readOnly={props.isReadOnly}
                      value={props?.value?.flat}
                      onChange={(val) => {
                        let value = props.value || {}
                        props.onChange({ ...value, flat: val })
                      }}
                    />
                    <span className="input_sep">+</span>
                    <InputNumber
                      step={props.step}
                      style={{ marginLeft: -1 }}
                      readOnly={props.isReadOnly}
                      addonAfter="%"
                      value={props?.value?.variable}
                      onChange={(val) => {
                        let value = props.value || {}
                        props.onChange({ ...value, variable: val })
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {props.numberType === 'flat_var_range' && (
            <div>
              {props.dynamicFields && fields.length ? (
                fields?.map((item, index) => (
                  <div style={{ display: 'flex', marginBottom: 13 }}>
                    <div>
                      <span>
                        <InputNumber
                          addonBefore={
                            props.numberFormat == 'currency'
                              ? props.currency
                              : props.numberFormat == 'percentage'
                                ? '%'
                                : null
                          }
                          step={props.step}
                          readOnly={props.isReadOnly}
                          value={item.start}
                          onChange={(val) => {
                            updateFieldValue({ ...item, start: val }, index)
                          }}
                        />
                        <span className="input_sep"> - </span>
                        <InputNumber
                          addonBefore={
                            props.numberFormat == 'currency'
                              ? props.currency
                              : props.numberFormat == 'percentage'
                                ? '%'
                                : null
                          }
                          step={props.step}
                          style={{ marginLeft: -1 }}
                          readOnly={props.isReadOnly}
                          value={item.end}
                          onChange={(val) => {
                            updateFieldValue({ ...item, end: val }, index)
                          }}
                        />
                      </span>
                      <span className="input_sep"></span>
                      <span className="input_sep"></span>
                      <span className="input_sep"></span>
                      <InputNumber
                        step={props.step}
                        addonBefore={props.currency}
                        readOnly={props.isReadOnly}
                        value={item.flat}
                        onChange={(val) => {
                          updateFieldValue({ ...item, flat: val }, index)
                        }}
                      />
                      <span className="input_sep">+</span>
                      <InputNumber
                        step={props.step}
                        style={{ marginLeft: -1 }}
                        addonAfter="%"
                        value={item.variable}
                        readOnly={props.isReadOnly}
                        onChange={(val) => {
                          updateFieldValue({ ...item, variable: val }, index)
                        }}
                      />
                    </div>
                    <Button
                      danger
                      type="link"
                      icon={
                        <span
                          className="material-icons-outlined"
                          style={{
                            fontSize: 25,
                            display: 'inline-block',
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            fields.length - 1 == index ? addField() : removeField(index)
                          }}
                        >
                          {fields.length - 1 == index ? 'add_box' : 'disabled_by_default'}
                        </span>
                      }
                    />
                  </div>
                ))
              ) : (
                <div style={{ display: 'flex' }}>
                  <div>
                    <span>
                      <InputNumber
                        addonBefore={
                          props.numberFormat == 'currency'
                            ? props.currency
                            : props.numberFormat == 'percentage'
                              ? '%'
                              : null
                        }
                        step={props.step}
                        readOnly={props.isReadOnly}
                        value={props?.value?.start}
                        onChange={(val) => {
                          let value = props.value || {}
                          props.onChange({ ...value, start: val })
                        }}
                      />
                      <span className="input_sep"> - </span>
                      <InputNumber
                        addonBefore={
                          props.numberFormat == 'currency'
                            ? props.currency
                            : props.numberFormat == 'percentage'
                              ? '%'
                              : null
                        }
                        step={props.step}
                        style={{ marginLeft: -1 }}
                        readOnly={props.isReadOnly}
                        value={props?.value?.end}
                        onChange={(val) => {
                          let value = props.value || {}
                          props.onChange({ ...value, end: val })
                        }}
                      />
                    </span>
                    <span className="input_sep"></span>
                    <span className="input_sep"></span>
                    <span className="input_sep"></span>
                    <InputNumber
                      step={props.step}
                      addonBefore={props.currency}
                      readOnly={props.isReadOnly}
                      value={props?.value?.flat}
                      onChange={(val) => {
                        let value = props.value || {}
                        props.onChange({ ...value, flat: val })
                      }}
                    />
                    <span className="input_sep">+</span>
                    <InputNumber
                      step={props.step}
                      style={{ marginLeft: -1 }}
                      addonAfter="%"
                      value={props?.value?.variable}
                      readOnly={props.isReadOnly}
                      onChange={(val) => {
                        let value = props.value || {}
                        props.onChange({ ...value, variable: val })
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </Form.Item>
  )
}

export default Number
