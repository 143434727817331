import React from 'react'
import { HomeLayout } from 'Components'
import { useEffect, useState } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash'
export default function TermsAndConditions() {
  const [term_and_condition, SetTermCondition] = useState('')

  const termConditionDetail = async () => {
    const res = await StaticPageService.TermConditionGet()
    await SetTermCondition(get(res, 'data.data.term_and_condition'))
  }

  useEffect(() => {
    termConditionDetail()
  }, [])

  return (
    <HomeLayout>
      <div className="public_pages">
        <div className="container">
          <h1>Terms and Conditions</h1>
          <div
            className="static_content"
            dangerouslySetInnerHTML={{ __html: term_and_condition }}
          ></div>
        </div>
      </div>
    </HomeLayout>
  )
}
