import { Input, Table, Button, Space, Modal, Select } from 'antd'
import { useNavigate } from 'react-router-dom'
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  CloseOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { PdfConfigService } from 'services'
import { get } from 'lodash'
const { Option } = Select

// const data = [
//   {
//     key: '1',
//     name: 'Asset report',
//     signature : 'No',
//     type: '3',
//   },
//   {
//     key: '2',
//     name: 'Net worth report',
//     signature : 'Yes',
//     type: '5',
//   },
// ];
const PDFPageTypes = (props) => {
  let navigate = useNavigate()
  const [name, SetName] = useState('')
  const [require_signature, SetRequireSignature] = useState(false)
  const [data, SetData] = useState([])
  const [toc, setToc] = useState(false)
  const [heading, setHeading] = useState('h1')
  const [page_config_id, PageConfigId] = useState(null)

  const columns = [
    {
      title: 'Page Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Page Name</h6>
          <a
            onClick={() => {
              navigate(`/admin/pdf-config/default-page/list/${record.id}/${record.name}`, {
                push: true,
              })
            }}
          >
            {text}
          </a>
        </div>
      ),
    },
    {
      title: 'Signature required?',
      dataIndex: 'require_signature',
      key: 'require_signature',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Signature required?</h6>
          {record.require_signature ? (
            <Button type="primary" icon={<CheckCircleOutlined />} size={'medium'} />
          ) : (
            <Button danger icon={<CloseOutlined />} size={'medium'} />
          )}
        </div>
      ),
    },
    {
      title: 'Heading',
      dataIndex: 'heading',
      key: 'heading',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Heading</h6>
          {record.toc && record.heading ? record.heading : '-'}
        </div>
      ),
    },
    {
      title: 'Number of layouts',
      dataIndex: 'layout_count',
      key: 'layout_count',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Number of layouts</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size={'medium'} onClick={() => get_detail(record)} />
          <Button
            onClick={(e) => DeleteDefaultPage(record.id, record.name)}
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
          />
        </Space>
      ),
    },
  ]

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const setDefault = () => {
    SetName('')
    SetRequireSignature(false)
    PageConfigId(null)
    setIsModalVisible(false)
    setToc(false)
    setHeading('h1')
  }

  const handleCancel = () => {
    setDefault()
  }

  const get_detail = async (record) => {
    SetName(record.name)
    SetRequireSignature(record.require_signature)
    PageConfigId(record.id)
    setToc(record.toc)
    setHeading(record.heading)
    showModal()
  }

  const DeleteDefaultPage = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} default page ?`)
    if (result) {
      await PdfConfigService.deleteDefaultPage(id)
      await get_data()
    }
  }

  const get_data = async () => {
    const res = await PdfConfigService.getDefaultPages()
    SetData(get(res, 'data.data', []))
  }

  const OnClickSave = async () => {
    if (page_config_id) {
      const res = await PdfConfigService.updateDefaultPage({
        name,
        require_signature,
        toc,
        heading,
        id: page_config_id,
      })
    } else {
      const res = await PdfConfigService.saveDefaultPage({
        name,
        require_signature,
        toc,
        heading,
      })
    }
    get_data()
    setDefault()
  }

  useEffect(() => {
    get_data()
  }, [])

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Default Pages</span>{' '}
          <Button icon={<PlusOutlined />} type="primary" onClick={showModal}>
            Add Page Type
          </Button>
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table
          className="custom_responsive_table"
          columns={columns}
          dataSource={data}
          rowKey="id"
        />
      </div>
      <Modal
        title="Add page type"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={OnClickSave}>
            Save
          </Button>,
        ]}
      >
        <Input placeholder="name" value={name} onChange={(e) => SetName(e.target.value)} />
        <br />
        <br />
        <div>
          <Checkbox
            checked={require_signature}
            onChange={(e) => SetRequireSignature(e.target.checked)}
          >
            Requires Signature?
          </Checkbox>
        </div>
        <br />
        <div>
          <Checkbox checked={toc} onChange={(e) => setToc(e.target.checked)}>
            Table of content?
          </Checkbox>
        </div>
        <br />
        {toc && (
          <div>
            <Select
              defaultValue="h1"
              style={{ width: 120 }}
              onChange={(value) => setHeading(value)}
              value={heading}
            >
              <Option value="h1">H1</Option>
              <Option value="h2">H2</Option>
              <Option value="h3">H3</Option>
              <Option value="h4">H4</Option>
              <Option value="h5">H5</Option>
              <Option value="h6">H6</Option>
            </Select>
          </div>
        )}
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default PDFPageTypes
