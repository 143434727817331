import React, { useEffect, useState } from 'react'
import { Input, Form, Button, Tooltip, Checkbox, DatePicker, Select } from 'antd'
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import { get } from 'lodash'
import moment from 'moment'

const { Option } = Select
const { TextArea } = Input
const CheckList = (props) => {
  const [fields, setFields] = useState(
    props.value || [
      {
        id: Math.random() * Math.random(),
        created_on: moment().format('YYYY-MM-DD'),
      },
    ]
  )

  const addField = () => {
    const fieldsArray = [...fields]
    fieldsArray.push({
      id: Math.random() * Math.random(),
      created_on: moment().format('YYYY-MM-DD'),
    })
    setFields(fieldsArray)
  }

  const removeField = (index) => {
    const fieldsArray = [...fields]
    fieldsArray.splice(index, 1)
    setFields(fieldsArray)
  }

  useEffect(() => {
    if (first) {
      setFirst(false)
      setFields(
        props.value || [
          {
            id: Math.random() * Math.random(),
            created_on: moment().format('YYYY-MM-DD'),
          },
        ]
      )
    }
  }, [props.value])

  const [first, setFirst] = useState(true)

  useEffect(() => {
    if (props.onChange && !first) props.onChange(fields)
  }, [fields])

  const handleValueChange = (index, type, value) => {
    let _fields = JSON.parse(JSON.stringify(fields))
    let obj = _fields[index]
    obj[type] = value
    _fields[index] = obj
    setFields(_fields)
  }

  return (
    <Form.Item>
      <div className="form_field">
        {!props.exclude_label ? (
          <label style={{ display: 'block' }}>
            {props.displayName ? props.displayName : props.name}{' '}
            {props.tooltip ? (
              <Tooltip title={props.tooltip}>
                <span>
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            ) : null}{' '}
            {props.isRequired ? <span className="red">*</span> : null}
          </label>
        ) : null}
        <div>
          <ReactSortable className="sortable_fields" tag="ul" list={fields} setList={setFields}>
            {fields?.map((item, index) => {
              return (
                <li key={item.id} style={{ display: 'flex', marginBottom: 5 }}>
                  <div>
                    <div style={{ display: 'flex' }}>
                      <input
                        defaultChecked={item.checked}
                        id={item.id.toString()}
                        className="checklist_checkbox"
                        type="checkbox"
                        value={item.checked}
                        onChange={(e) => handleValueChange(index, 'checked', e.target.checked)}
                      />

                      {!item.isEditable ? (
                        <div>
                          <TextArea
                            style={{
                              width: 'calc(100% - 20px)',
                              verticalAlign: 'text-top',
                              marginBottom: 5,
                            }}
                            value={item.description || ''}
                            onChange={(e) => {
                              handleValueChange(index, 'description', e.target.value)
                            }}
                          />{' '}
                          <DatePicker
                            format={'DD-MM-YYYY'}
                            style={{ width: 110, marginBottom: 5 }}
                            onChange={(date, dateString) => {
                              handleValueChange(index, 'created_on', dateString)
                            }}
                            disabled
                            value={item.created_on ? moment(item.created_on) : null}
                            placeholder="created on"
                            size="small"
                          />{' '}
                          <span
                            style={{
                              display: 'inline-block',
                              fontWeight: 'bold',
                            }}
                          >
                            by{' '}
                            {get(props, 'user.details.first_name') +
                              ' ' +
                              get(props, 'user.details.last_name')}
                          </span>{' '}
                          {props.assignedTo && (
                            <span style={{ display: 'inline-block' }}>
                              , Assigned to{' '}
                              <Select
                                size="small"
                                style={{ width: 200, marginBottom: 5 }}
                                showSearch
                                placeholder=""
                                optionFilterProp="children"
                                value={item.assigned_to}
                                onChange={(value) => {
                                  handleValueChange(index, 'assigned_to', value)
                                }}
                                // onChange={onChange}
                                // onSearch={onSearch}
                                filterOption={(input, option) =>
                                  (option?.children).toLowerCase().includes(input.toLowerCase())
                                }
                              >
                                {get(props, 'clients', []).map((item) => (
                                  <Option value={item.id}>{`${item.name}`}</Option>
                                ))}
                              </Select>{' '}
                            </span>
                          )}{' '}
                          {props.dueDate && (
                            <span style={{ display: 'inline-block' }}>
                              to be completed on{' '}
                              <DatePicker
                                format={'DD-MM-YYYY'}
                                onChange={(date, dateString) => {
                                  handleValueChange(index, 'due_date', date.format('YYYY-MM-DD'))
                                }}
                                value={item.due_date ? moment(item.due_date) : null}
                                style={{ width: 110, marginBottom: 5 }}
                                placeholder=""
                                size="small"
                              />
                            </span>
                          )}{' '}
                          {props.isMandatory && ' (Required)'}{' '}
                          {props.showOverdue &&
                            !item.checked &&
                            moment(item.due_date || undefined).isBefore(moment().startOf('day')) &&
                            ' - Overdue'}
                        </div>
                      ) : (
                        <div>
                          <div>
                            <b style={{ fontSize: 14, paddingLeft: 5 }}>
                              {item.value ? item.value : ''}
                            </b>
                          </div>{' '}
                          - {item.createdOn}{' '}
                          <span style={{ display: 'inline-block' }}>by {item.assignedBy}</span>{' '}
                          {props.assignedTo && (
                            <span style={{ display: 'inline-block' }}>
                              Assigned to {item.assignedTo}{' '}
                            </span>
                          )}{' '}
                          {props.dueDate && (
                            <span style={{ display: 'inline-block' }}>
                              to be completed on {item.completedOn}
                            </span>
                          )}{' '}
                          {props.isMandatory && ' (Required)'} {props.showOverdue && ' - Overdue'}
                        </div>
                      )}
                    </div>
                  </div>
                  <div>
                    <Button
                      danger
                      type="link"
                      icon={
                        <span
                          className="material-icons-outlined"
                          style={{
                            fontSize: 25,
                            display: 'inline-block',
                            marginLeft: 5,
                          }}
                          onClick={() => {
                            removeField(index)
                          }}
                        >
                          disabled_by_default
                        </span>
                      }
                    />
                  </div>
                </li>
              )
            })}
          </ReactSortable>
        </div>
        <Button
          className="add_task_btn"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            addField()
          }}
        >
          Add Task Item
        </Button>
      </div>
    </Form.Item>
  )
}

export default CheckList
