import api from './api'

const endpoints = {
  investment_option: 'investment-option/',
  investment_option_history: 'investment-option-history/',
}

const getInvestmentOption = async (params) => {
  return api.get(endpoints.investment_option, { params })
}

const getInvestmentOptionHistory = async (params) => {
  return api.get(endpoints.investment_option_history, { params })
}

export default {
  getInvestmentOption,
  getInvestmentOptionHistory,
}
