import { get } from 'lodash'
import React from 'react'
import { Switch } from 'antd'

function Form({
  form,
  handleViewOnly,
  itemIndex,
  data,
  tabIndex,
  index,
  handleShowHeading,
  handleSortingEnable,
}) {
  return (
    <div>
      <span className="element_type">{'Form'}</span>
      <div
        className={'group_item'}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <span> {get(form, 'display_name', '') || get(form, 'name', '')}</span>
        {handleShowHeading && (
          <div>
            <b>Show Heading?</b> &nbsp;
            <Switch
              onChange={(val) => handleShowHeading(val, itemIndex, tabIndex, index)}
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={data.display_heading}
            />
          </div>
        )}
        {handleSortingEnable && (
          <div>
            <b>Is Sortable?</b> &nbsp;
            <Switch
              onChange={(val) => handleSortingEnable(val, itemIndex, tabIndex, index)}
              checked={get(data, 'reorder', false)}
              checkedChildren="Yes"
              unCheckedChildren="No"
            />
          </div>
        )}
        {handleViewOnly && (
          <div>
            <b>View Only?</b> &nbsp;
            <Switch
              onChange={(val) => handleViewOnly(val, itemIndex, tabIndex, index)}
              checkedChildren="Yes"
              unCheckedChildren="No"
              checked={data.view_only}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Form
