import React, { useState } from 'react'
import { Input, Table, Button, Space, Modal, Select } from 'antd'
import { EyeOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { useNavigate } from 'react-router-dom'
const { Option } = Select
const data = [
  {
    businessName: 'Business 1',
    key: '1',
    name: 'Ravi Bhushan',
    receivedOn: 'Thursday, 22nd March, 2022',
    lockDate: 'Thursday, 22nd March, 2022',
    status: 'In progress',
  },
  {
    businessName: 'Business 2',
    key: '2',
    name: 'Han Sia',
    receivedOn: 'Thursday, 22nd March, 2022',
    lockDate: 'Thursday, 22nd March, 2022',
    status: 'Submitted',
  },
  {
    businessName: 'Business 3',
    key: '3',
    name: 'Prashant Sharma',
    receivedOn: 'Thursday, 22nd March, 2022',
    lockDate: 'Thursday, 22nd March, 2022',
    status: 'Submitted',
  },
]
const QuestionnaireData = (props) => {
  const columns = [
    {
      title: 'Business Name',
      dataIndex: 'businessName',
      key: 'businessName',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Business Name</h6>
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: 'Submitted By',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Submitted By</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Received On',
      dataIndex: 'receivedOn',
      key: 'receivedOn',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Received On</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Lock Date',
      dataIndex: 'lockDate',
      key: 'lockDate',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Lock Date</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Status</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button type="primary" icon={<FilePdfOutlined />} size={'medium'} onClick={showModal}>
            Merge
          </Button>
          <Button
            icon={<EyeOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/questionnaire/wizard')}
          />
          <Button danger icon={<DeleteOutlined />} size={'medium'} />
        </Space>
      ),
    },
  ]

  let navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">User Personal Data</span>
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table className="custom_responsive_table" columns={columns} dataSource={data} />
      </div>
      <Modal
        title="Select Merge Template"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Close
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Merge
          </Button>,
        ]}
      >
        <label>Select Merge template</label>
        <Select defaultValue="lucy" style={{ width: '100%' }}>
          <Option value="jack">Jack</Option>
          <Option value="lucy">Lucy</Option>
          <Option value="disabled" disabled>
            Disabled
          </Option>
          <Option value="Yiminghe">yiminghe</Option>
        </Select>
      </Modal>
    </AdminLayout>
  )
}

export default QuestionnaireData
