const { default: api } = require('./api')

const endpoints = {
  advise_for_options: 'data-saving/advise-for-options/',
}

export const getAdviseForOptions = async (clientId = '') => {
  return api.get(endpoints.advise_for_options + clientId || '')
}

export default { getAdviseForOptions }
