import React, { useEffect, useState } from 'react'
import { Form, Modal, Button, Select } from 'antd'
import { DeleteOutlined, SettingOutlined, PlusOutlined } from '@ant-design/icons'
import { PageService } from 'services'
import { AdminLayout } from 'Components'
import Nestable from 'react-nestable'
import { get } from 'lodash'
import MultiSelect from 'react-select'

const { Option } = Select

const children = []

const UserPageConfig = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [pages, setPages] = useState([])
  const [selectedPages, setSelectedPages] = useState([])
  const [inputPages, setInputPages] = useState([])
  const exists = React.useRef(false)

  useEffect(() => {
    getPages()
    getPageConfig()
  }, [])

  const getPages = (str) => {
    PageService.getPages({ search: str }).then((res) => {
      setPages(get(res, 'data.data', []))
    })
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInputPages([])
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInputPages([])
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const renderItem = (obj) => {
    let item = obj.item
    return (
      <div className="page_list_item">
        {item.page.name}
        <div>
          <Button title="Settings" icon={<SettingOutlined />} />{' '}
          <Button
            onClick={() => {
              deletePage(item.id, JSON.parse(JSON.stringify(selectedPages)))
            }}
            danger
            icon={<DeleteOutlined />}
          />
        </div>
      </div>
    )
  }

  const deletePage = (id, items, path = []) => {
    let deleted
    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      if (item.id == id) {
        path.push(`[${i}]`)
        deleted = items.splice(i, 1)
        break
      } else if ((!item.children || !item.children.length) && items.length - 1 == i) {
        path.pop()
      } else if (item.children && item.children.length) {
        path.push(`[${i}].children`)
        deletePage(id, item.children, path)
      }
    }
    if (
      selectedPages[selectedPages.length - 1].id == get(items, `[${items.length - 1}].id`, 0) ||
      get(deleted, '[0].id') == selectedPages[selectedPages.length - 1].id
    ) {
      setSelectedPages(items)
    }
  }

  const checkIfExists = (id, items = selectedPages) => {
    for (let i = 0; i < items.length; i++) {
      let item = items[i]
      if (item.id == id) {
        exists.current = true
      } else if (item.children && item.children.length) {
        checkIfExists(id, item.children)
      }
    }
  }

  const savePageConfig = () => {
    let data = {
      type: 'user',
      page_data: selectedPages,
    }
    PageService.savePageConfig(data)
  }

  const getPageConfig = () => {
    PageService.getPageConfig({ type: 'user' }).then((res) => {
      setSelectedPages(get(res, 'data.data.page_data', []))
    })
  }

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Manage User Pages</span>{' '}
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            Add Page
          </Button>
          <Button onClick={savePageConfig} type="primary">
            Save
          </Button>
        </h1>
        <div className="manage_page">
          <Nestable
            items={selectedPages}
            renderItem={renderItem}
            maxDepth={5}
            onChange={(e) => setSelectedPages(e.items)}
            confirmChange={({ destinationParent }) => {
              if (get(destinationParent, 'page.type', '') == 'page') {
                return false
              }
              return true
            }}
          />
        </div>
      </div>
      <Modal
        title="Add Page"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Enter page name">
            <MultiSelect
              isMulti
              options={pages.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              value={inputPages}
              onChange={(val, f) => {
                console.log(f)

                if (f.action === 'select-option') {
                  exists.current = false
                  checkIfExists(f.option.value)
                  if (exists.current) {
                    window.alert('Page already exists')
                    return
                  }
                  setInputPages(val)
                  let index = pages.findIndex((item) => item.id == f.option.value)
                  let page = pages[index]
                  let selected = [...selectedPages]
                  selected.push({ page, id: page.id })
                  setSelectedPages(selected)
                }
                if (f.action === 'remove-value') {
                  setInputPages(val)
                  let index = selectedPages.findIndex(
                    (item) => item.page.id == f.removedValue.value
                  )
                  let selected = [...selectedPages]
                  if (index >= 0) {
                    selected.splice(index, 1)
                    setSelectedPages(selected)
                  }
                }
              }}
            />
            {/* <Select
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
              // onSearch={getPages}
              onSelect={(id) => {
                exists.current = false;
                checkIfExists(id);
                if (exists.current) {
                  window.alert("Page already exists");
                  return;
                }
                let index = pages.findIndex((item) => item.id == id);
                let page = pages[index];
                let selected = [...selectedPages];
                selected.push({ page, id: page.id });
                setSelectedPages(selected);
              }}
              onDeselect={(id) => {
                let index = selectedPages.findIndex(
                  (item) => item.page.id == id
                );
                let selected = [...selectedPages];
                if (index >= 0) {
                  selected.splice(index, 1);
                  setSelectedPages(selected);
                }
              }}
            >
              {pages.map((item) => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select> */}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default UserPageConfig
