import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Tabs, Checkbox } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ProductService } from 'services'
import { get } from 'lodash'
import { AdminLayout } from '../../Components'
import moment from 'moment'
// Adjust the import path as needed

const { TabPane } = Tabs

export default function ProductAssetAllocation() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [assetClassList, setAssetClassList] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [price_data, setPriceData] = useState({})
  const [columns, setColumns] = useState([
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Date</h6>
          {moment(text).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Source</h6>
          {text}
        </div>
      ),
    },
  ])

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const price_parent_id = params.get('price_parent_id')
  const price_type = params.get('price_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getAssetClassList()
    getData()
  }, [])

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
    setPriceData({})
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const transformedPriceData = (price_data_list) => {
    return price_data_list.reduce((acc, item) => {
      acc[item.asset_class] = item.price
      return acc
    }, {})
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    let res = {}
    if (price_type === 'provider') {
      res = await ProductService.getDetailProviderAssetAllocation(id)
    } else {
      res = await ProductService.getDetailProductAssetAllocation(id)
    }
    formik.setValues(get(res, 'data.data'))
    const price_data_list = get(res, 'data.data.price_data', [])
    const price_data_obj = transformedPriceData(price_data_list)
    formik.setFieldValue('price_data', price_data_obj)
    setPriceData(price_data_obj)
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this asset allocation?')
    if (result) {
      if (price_type === 'provider') {
        await ProductService.deleteProviderAssetAllocation(id)
      } else {
        await ProductService.deleteProductAssetAllocation(id)
      }
      getData()
    }
  }

  const getData = async () => {
    let res = {}
    if (price_type === 'provider') {
      res = await ProductService.getProviderAssetAllocation({
        business: business,
        [price_type]: price_parent_id,
      })
    } else {
      res = await ProductService.getProductAssetAllocation({
        business: business,
        [price_type]: price_parent_id,
      })
    }
    setData(get(res, 'data.data', []))
  }

  const getAssetClassList = async () => {
    const res = await ProductService.getAssetClass()
    const asset_class_list_data = get(res, 'data.data', [])
    setAssetClassList(asset_class_list_data)
    const transformedColumns = asset_class_list_data.map((col) => ({
      title: col.name,
      dataIndex: col.id,
      key: col.id,
      render: (text, e) => (
        <div className="table_responsive_cell">
          {get(transformedPriceData(e.price_data), col.id, 0)}
        </div>
      ),
    }))

    if (!business) {
      transformedColumns.push({
        title: 'Action',
        key: 'action',
        width: '90px',
        render: (text, record) => (
          <Space size="middle">
            {!business && (
              <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
            )}
            {!business && (
              <Button
                danger
                icon={<DeleteOutlined />}
                size="medium"
                onClick={() => deleteData(record.id)}
              />
            )}
          </Space>
        ),
      })
    }

    setColumns([...columns, ...transformedColumns])
  }

  let schema = yup.object().shape({
    date: yup.date().required('date Required'),
    source: yup.string(),
  })

  const initialValues = {
    date: '',
    price_data: {},
    price_override: 0.0,
    source: '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values[price_type] = price_parent_id
      values['price_data'] = price_data
      if (id) {
        if (price_type === 'provider') {
          await ProductService.updateProviderAssetAllocation(values, id)
        } else {
          await ProductService.updateProductAssetAllocation(values, id)
        }
      } else {
        if (price_type === 'provider') {
          await ProductService.saveProviderAssetAllocation(values)
        } else {
          await ProductService.saveProductAssetAllocation(values)
        }
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  const handleChangePriceData = (key, value) => {
    setPriceData((prevState) => ({
      ...prevState,
      [key]: value,
    }))
  }

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <div>
          <h1 className="flex_title">
            <span className="title">Asset Allocation</span>
            <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
              Add asset allocation
            </Button>
          </h1>
          <Table
            className="custom_responsive_table"
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{ defaultPageSize: 50 }}
          />
          <Modal
            title={id ? 'Edit asset allocation' : 'Add asset allocation'}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'100%'}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={formik.handleSubmit}>
                Save
              </Button>,
            ]}
          >
            <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
              <TabPane tab="Main" key="1">
                <Form layout="vertical" className={'custom_modal_form'}>
                  <Form.Item label="Date">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="date"
                      name="date"
                      type="date"
                      onChange={formik.handleChange}
                      value={formik.values.date}
                    />
                    {formik.touched.date && formik.errors.date && (
                      <span className="input-error-message">{formik.errors.date}</span>
                    )}
                  </Form.Item>
                  <Form.Item label="Source">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="source"
                      name="source"
                      onChange={formik.handleChange}
                      value={formik.values.source}
                    />
                    {formik.touched.source && formik.errors.source && (
                      <span className="input-error-message">{formik.errors.source}</span>
                    )}
                  </Form.Item>
                  {assetClassList.map((item) => (
                    <Form.Item label={item.name} id={item.id}>
                      <Input
                        style={{ width: '100%' }}
                        placeholder="price"
                        name="price"
                        type="number"
                        onChange={(e) => handleChangePriceData(item.id, e.target.value)}
                        value={get(price_data, item.id, 0)}
                      />
                      {formik.touched.price && formik.errors.price && (
                        <span className="input-error-message">{formik.errors.price}</span>
                      )}
                    </Form.Item>
                  ))}
                </Form>
              </TabPane>
            </Tabs>
          </Modal>
        </div>
      </div>
    </>
  )

  return <AdminLayout>{Main}</AdminLayout>
}
