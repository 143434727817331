import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Table,
  Modal,
  Button,
  Space,
  Select,
  Upload,
  Switch,
  Col,
  Row,
  Tabs,
  Tag,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { AuthService } from 'services'
import { get } from 'lodash'
import * as yup from 'yup'
import { AuthUtils } from 'utils'
import { useFormik } from 'formik'
import qs from 'query-string'
import { useDispatch } from 'react-redux'
import { setUser } from 'Store/reducers/previewUserSlice'
import { setRoutes } from 'Store/reducers/routeSlice'
import { useNavigate } from 'react-router-dom'
import { getFirstPage } from 'utils/helpers'
const { Option } = Select
const { TabPane } = Tabs

const AdminList = (props) => {
  const queryString = require('query-string')
  const query_obj = queryString.parse(window.location.search)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image_loading, SetImageLoading] = useState(false)
  const [imageUrl, SetImageUrl] = useState(false)
  const [id, SetId] = useState(null)
  const [imageError, SetImageError] = useState('')
  const [profile_pic_blob, SetPicBlob] = useState(null)
  const [total, setTotal] = useState(0)
  const [data, SetData] = useState([])
  const [is_edit, setIsEdit] = useState(false)
  const [active_params, SetActiveParams] = useState(
    query_obj.ac === undefined ? 'all' : query_obj.is_active
  )
  const [search_params, setSearchParam] = useState(query_obj.search ? query_obj.search : '')
  const [search_text, SetSearchText] = useState(query_obj.search ? query_obj.search : '')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let schema = yup.object().shape({
    first_name: yup.string().required('First name Required'),
    last_name: yup.string().required('Last name Required'),
    phone_number: yup
      .string()
      .required('This is required fields')
      .matches(AuthUtils.phone_regex(), 'Phone number is not valid'),
    is_active: yup.boolean(),
    email: yup.string().email('Invalid email').required('Required'),
  })

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    is_active: true,
    user_type: 'admin',
    has_profile_pic: false,
  }

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const uploadButton = (
    <div>
      {image_loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Avatar</div>
    </div>
  )

  const imageHandleAction = async (option) => {
    SetImageLoading(true)
    await getBase64(option.file, (imageUrl) => {
      SetImageUrl(imageUrl)
      SetImageLoading(false)
      SetPicBlob(option.file)
    })
  }

  const DeletePic = () => {
    SetImageUrl(null)
    SetPicBlob(null)
    SetImageError(null)
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    SetImageError(null)
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      SetImageError('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      SetImageError('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: schema,
    onSubmit: async (values) => {
      let form_data = new FormData()
      for (let key in values) {
        if (key != 'profile_pic' && key != 'id' && key != 'business') {
          form_data.append(key, values[key])
        }
      }
      if (profile_pic_blob) {
        form_data.append('profile_pic', profile_pic_blob)
      }
      if (imageUrl) {
        form_data.append('has_profile_pic', true)
      }
      if (id) {
        await AuthService.updateEntity(id, form_data)
      } else {
        const res = await AuthService.createEntity(form_data)
      }
      setIsModalVisible(false)
      SetSearchText('')
      setSearchParam('')
      SetActiveParams('all')
      get_data()
    },
    validationSchema: schema,
  })

  const get_data = async (params = {}, page) => {
    //admins
    const res = await AuthService.users({ user_type: 'admin', ...params })
    SetData(get(res, 'data.data', []))
    setTotal(get(res, 'data.count', 0))
  }

  const get_detail = async (id) => {
    setIsEdit(true)
    SetImageLoading(true)
    await SetId(id)
    await setIsModalVisible(true)
    const res = await AuthService.detailEntity(id)
    formik.setValues(get(res, 'data.data'))
    SetImageUrl(get(res, 'data.data.profile_pic'))
    SetImageLoading(false)
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          <a onClick={() => onNameClick(e)}>{text}</a>
        </div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Phone</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Email</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size={'medium'} onClick={() => get_detail(record.id)} />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    const params = make_params(search_params)
    get_data(params)
    const searchString = qs.stringify(params)
    // if (searchString) {
    //   window.history.replaceState({}, "", "?" + searchString);
    // } else {
    //   var newURL = window.location.href.split("?")[0];
    //   window.history.replaceState({}, "", newURL);
    // }
  }, [active_params, search_params])

  const SetInitialValue = () => {
    setIsEdit(false)
    formik.setValues(initialValues)
    SetId(null)
    SetImageUrl(null)
    SetPicBlob(null)
    SetImageError(null)
  }

  const showModal = () => {
    setIsModalVisible(true)
    SetInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    SetInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    SetInitialValue()
  }

  const make_params = (search_text) => {
    let query = {}

    if (active_params || active_params === false) {
      if (active_params !== 'all') {
        query['is_active'] = active_params
      }
    }
    if (search_text) {
      query['search'] = search_text
    }
    return query
  }

  const [filter, setFilter] = useState(false)

  const toggleFilter = () => {
    setFilter(!filter)
  }

  const [isSearchModalVisible, setSearchIsModalVisible] = useState(false)

  const showSearchModal = () => {
    setSearchIsModalVisible(true)
  }

  const handleSearchOk = () => {
    setSearchIsModalVisible(false)
  }

  const handleSearchCancel = () => {
    setSearchIsModalVisible(false)
  }

  const onNameClick = async (data) => {
    const res = await AuthService.detailEntity(data.id, { detail: true })
    let firstPage = getFirstPage(res?.data?.data?.current_page_config?.page_data)?.id
    dispatch(setUser({ ...res.data.data, user_type: 'business' }))
    dispatch(setRoutes(res.data.data.current_page_config))

    navigate(
      firstPage
        ? '/preview/user/page/' + firstPage + `?entity_type=admin&entity=${data.id}`
        : '/preview/user/dashboard'
    )
  }

  const Main = (
    <>
      <div className="white_content_area">
        <h1 className="flex_title">
          <span className="title"></span>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            Add Admin
          </Button>
        </h1>
        <Tabs type="card">
          <TabPane tab="List" key="1">
            <Tag closable color={'blue'}>
              All active/inactive users
            </Tag>
            <br />
            <Input.Group compact style={{ width: '100%' }}>
              <Input.Search
                allowClear
                style={{ width: 'calc(100% - 31px)' }}
                value={search_text}
                onChange={(e) => SetSearchText(e.target.value)}
                onSearch={(value) => setSearchParam(search_text)}
              />
              <Button onClick={toggleFilter} icon={<FilterOutlined />}></Button>
            </Input.Group>
            <div className={filter ? 'custom_panel show' : 'custom_panel'}>
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="First name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Last name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Phone">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Email">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Status">
                      <Select
                        defaultValue="all"
                        style={{ width: '100%' }}
                        value={active_params}
                        onChange={(value) => SetActiveParams(value)}
                      >
                        <Option value="all">All</Option>
                        <Option value={true}>Active</Option>
                        <Option value={false}>Inactive</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}></Col>
                  <Col className="gutter-row" span={6}></Col>
                  <Col className="gutter-row end_column" span={6}>
                    <Button onClick={showSearchModal} style={{ marginRight: 5 }}>
                      Save Filter
                    </Button>
                    <Button type="primary">Apply Filters</Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <br />
            <Table
              className="custom_responsive_table"
              pagination={{
                total,
                onChange: (val) => get_data({ page: val }),
                defaultPageSize: 50,
                PageSize: 50,
              }}
              columns={columns}
              dataSource={data}
              rowKey="id"
            />
          </TabPane>
          <TabPane tab="Saved Searches" key="2">
            <div className="ant-table-content">
              <table style={{ width: '100%' }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell">Name</th>
                    <th style={{ width: '180px' }} className="ant-table-cell">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">All Members</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">Only Active Members</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">Members with ram in their name</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">All Inactive members</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        title="Add admin"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
          !is_edit && ( // Check if 'is_edit' is true to render the Save and Edit button
            <Button
              key="submit-edit"
              type="primary"
              onClick={() => {
                formik.values['edit'] = true
                formik.handleSubmit(formik.values)
              }}
            >
              Save and Edit
            </Button>
          ),
        ]}
      >
        <Form layout="vertical">
          <div className="align_center">
            <Upload
              name="profile_pic"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              customRequest={imageHandleAction}
              beforeUpload={beforeUpload}
              showRemoveIcon={true}
              // onChange={formik.handleChange}
              style={{ backgroundImage: `url(${imageUrl})` }}
            >
              {imageUrl ? (
                <div className="contained_image" style={{ backgroundImage: `url(${imageUrl})` }}>
                  <DeleteOutlined onClick={() => DeletePic()} />
                </div>
              ) : (
                uploadButton
              )}
            </Upload>
          </div>
          {imageError && (
            <span className="input-error-message" style={{ width: '50%' }}>
              {imageError}
            </span>
          )}
          <Form.Item label="Name">
            <div className="inline_inputs">
              <div className="half_input">
                <Input
                  placeholder="First name"
                  style={{ width: '50%' }}
                  name="first_name"
                  onChange={formik.handleChange}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name && (
                  <span className="input-error-message" style={{ width: '50%' }}>
                    {formik.errors.first_name}
                  </span>
                )}
              </div>
              <div className="half_input">
                <Input
                  placeholder="Last name"
                  style={{ width: '50%' }}
                  name="last_name"
                  onChange={formik.handleChange}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name && (
                  <span className="input-error-message" style={{ width: '50%' }}>
                    {formik.errors.last_name}
                  </span>
                )}
              </div>
            </div>
          </Form.Item>
          <Form.Item label="Phone">
            <Input
              placeholder="Enter phone"
              name="phone_number"
              onChange={formik.handleChange}
              value={formik.values.phone_number}
            />
            {formik.touched.phone_number && formik.errors.phone_number && (
              <span className="input-error-message">{formik.errors.phone_number}</span>
            )}
          </Form.Item>
          <Form.Item label="Email">
            <Input
              placeholder="Enter Email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email && (
              <span className="input-error-message">{formik.errors.email}</span>
            )}
          </Form.Item>
          <div>
            <label>
              Active{' '}
              <Switch
                style={{ marginLeft: 15 }}
                name="is_active"
                onChange={(value) => formik.setFieldValue('is_active', value)}
                checked={formik.values.is_active}
              />
            </label>
          </div>
        </Form>
      </Modal>
      <Modal
        title="Save Search Criteria"
        visible={isSearchModalVisible}
        onOk={handleSearchOk}
        onCancel={handleSearchCancel}
      >
        <Input placeholder="Enter search criteria name" />
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default AdminList
