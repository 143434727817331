import React, { useEffect, useState } from 'react'
import { Button, Space, Table } from 'antd'
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons'
import { get } from 'lodash'
import { AuthService, StrategyService } from 'services'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'

export default function AdviceStrategyHistory({ preview }) {
  const [data, setData] = useState([])
  let navigate = useNavigate()

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let entity = null
  if (entity_type == 'client') {
    entity = params.get('entity')
  }

  const columns = [
    {
      title: 'Created on',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Created on</h6>
          {moment(text).format('ddd, D MMMM, YYYY, h:mm:ss A')}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          {/*<Button*/}
          {/*  icon={<EditOutlined />}*/}
          {/*  size="medium"*/}
          {/*/>*/}
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() =>
              navigate(
                `/preview/user/advice-strategy-scenario?entity=${entity}&entity_type=client&history=${record.id}`
              )
            }
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            size="medium"
            onClick={() => deleteData(record.id)}
          />
        </Space>
      ),
    },
  ]

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    return new_data
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this scenario?')
    if (result) {
      await StrategyService.deleteAdviceStrategyHistory(id)
      getData()
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const entity_data = await getEntityData()
    const res = await StrategyService.getAdviceStrategyHistory({
      entity: entity,
      business: entity_data.business,
    })
    setData(get(res, 'data.data', []))
  }

  return (
    <div>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
    </div>
  )
}
