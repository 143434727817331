import React, { Component } from 'react'
import Nestable from 'react-nestable'
import { Select, Tabs, InputNumber, Radio, Space, Input, Modal, Cascader, Button } from 'antd'
import { AdminLayout } from 'Components'
import '../form.scss'
import Toolbar from './Toolbar'
import FormPreview from './FormPreview'
import Element from './Element'
import TabsGroup from './TabsGroup'
import { ReactSortable } from 'react-sortablejs'

import Tab from './Tab'
import Group from './Group'
import produce from 'immer'
import GroupModal from '../GroupModal'
import { FormService, PageServiceService, PdfConfigService } from 'services'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'lodash'
import FieldModal from '../FieldModal'
import Field from './Field'

class AddForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      tabGroupIndex: '',
      tabIndex: '',
      groupModal: false,
      fieldModal: false,
      visible: false,
      items: [],
      forms: [],
      groups: [],
      pdfTemplates: [],
      questionnaires: [],
    }
  }

  getGroups = async (page) => {
    try {
      let res = await PageServiceService.GroupList({
        data_source: true,
        all_field: true,
      })
      this.setState({ groups: get(res, 'data.data', []) })
    } catch (err) {}
  }
  getPdfConfigs = async () => {
    try {
      let res = await PdfConfigService.getPageTemplates()
      this.setState({ pdfTemplates: get(res, 'data.data', []) })
    } catch (err) {}
  }
  getQuestionnaires = async () => {
    try {
      let res = await PdfConfigService.getQuestionnaires()
      this.setState({ questionnaires: get(res, 'data.data', []) })
    } catch (err) {}
  }
  getForms = async (page) => {
    try {
      let res = await FormService.getForms({
        data_source: true,
        all_field: true,
      })
      this.setState({ forms: get(res, 'data.data', []) })
    } catch (err) {}
  }

  showModal = () => {
    this.setState({
      visible: true,
    })
  }

  handleOk = () => {
    this.setState({ visible: false })
  }

  handleCancel = () => {
    this.setState({ visible: false })
  }
  addTab = (index) => {
    const nextState = produce(this.state.items, (draft) => {
      draft[index].tabs.push({
        name: '',
        description: '',
        children: [],
      })
    })
    let clone = JSON.parse(JSON.stringify(nextState))
    this.setState({ items: clone })
  }
  addNestedGroup = async (index, tabIndex, group) => {
    let res = await PageServiceService.GroupDetail(group.id, { detail: true })
    const nextState = produce(this.state.items, (draft) => {
      draft[index].tabs[tabIndex].children.push({
        group: get(res, 'data.data', null) || group,
        type: 'group',
      })
    })
    let clone = JSON.parse(JSON.stringify(nextState))
    this.setState({ items: clone })
  }
  addNestedField = async (index, tabIndex, field) => {
    let res = await PageServiceService.FieldDetail(field.id)
    const nextState = produce(this.state.items, (draft) => {
      draft[index].tabs[tabIndex].children.push({
        field: get(res, 'data.data', null) || field,
        type: 'field',
      })
    })
    let clone = JSON.parse(JSON.stringify(nextState))
    this.setState({ items: clone })
  }
  addNestedElement = (index, tabIndex) => {
    const nextState = produce(this.state.items, (draft) => {
      draft[index].tabs[tabIndex].children.push({
        type: 'element',
        element: {
          type: 'bar',
          title: 'bar title',
          position: 'top',
        },
      })
    })
    let clone = JSON.parse(JSON.stringify(nextState))
    this.setState({ items: clone })
  }

  renderForm = (item, index) => {
    switch (item.type) {
      case 'tab_group':
        return (
          <TabsGroup
            groups={this.state.groups}
            forms={this.state.forms}
            pdfTemplates={this.state.pdfTemplates}
            questionnaires={this.state.questionnaires}
            addTab={() => this.addTab(index)}
            data={item}
            id={item.id}
            addGroup={this.addNestedGroup}
            addElement={this.addNestedElement}
            index={index}
            handleTabName={this.handleTabName}
            handleTabDescription={this.handleTabDescription}
            setGroupModal={this.setGroupModal}
            setFieldModal={this.setFieldModal}
            handleChartTypes={this.handleChartTypes}
            deleteElement={this.deleteElement}
            shuffle={this.shuffle}
            shuffleTabElements={this.shuffleTabElements}
            formId={this.props.formId}
          />
        )
      case 'element':
        return (
          <Element
            handleChartTypes={this.handleChartTypes}
            id={item.id}
            itemIndex={index}
            type={item.element.type}
            sub_type={item.element.sub_type}
            data={item.element}
            forms={this.state.forms}
            pdfTemplates={this.state.pdfTemplates}
            questionnaires={this.state.questionnaires}
            groups={this.state.groups}
            handleEditCondition={() => {
              this.props.navigate(
                `/admin/conditions?form=${this.props.formId}&fieldId=${item.id}`,
                { push: true }
              )
            }}
          />
        )
      case 'group':
        return (
          <Group
            group={item.group}
            id={item.group.id || item.group}
            handleEditCondition={() => {
              this.props.navigate(
                `/admin/conditions?form=${this.props.formId}&fieldId=${item.id}`,
                { push: true }
              )
            }}
          />
        )
      case 'field':
        return (
          <Field
            field={item.field}
            id={item.field.id || item.field}
            handleEditCondition={() => {
              this.props.navigate(
                `/admin/conditions?form=${this.props.formId}&fieldId=${item.id}`,
                { push: true }
              )
            }}
          />
        )
      default:
        return null
    }
  }

  addElement = () => {
    let arr = [...this.state.items]
    arr.push({
      type: 'element',
      element: {
        type: 'bar',
        title: 'bar title',
        position: 'top',
      },
    })
    let clone = JSON.parse(JSON.stringify(arr))
    this.setState({ items: clone })
  }

  addGroup = async (group) => {
    let arr = [...this.state.items]
    let res = await PageServiceService.GroupDetail(group.id, { detail: true })
    arr.push({
      group: get(res, 'data.data', null) || group,
      type: 'group',
    })
    let clone = JSON.parse(JSON.stringify(arr))

    this.setState({ items: clone })
  }
  addField = async (field) => {
    let res = await PageServiceService.FieldDetail(field.id)
    let nextstate = produce(this.state.items, (draft) => {
      draft.push({
        field: get(res, 'data.data', null) || field,
        type: 'field',
      })
    })
    let clone = JSON.parse(JSON.stringify(nextstate))
    this.setState({ items: clone })
  }
  addTabGroup = () => {
    let arr = [...this.state.items]
    arr.push({
      type: 'tab_group',
      tabs: [],
    })
    let clone = JSON.parse(JSON.stringify(arr))
    this.setState({ items: clone })
  }
  handleTabName = (index, tabIndex, e) => {
    const nextState = produce(this.state.items, (draft) => {
      draft[index].tabs[tabIndex].name = e.target.value
    })
    let clone = JSON.parse(JSON.stringify(nextState))

    this.setState({ items: clone })
  }
  handleTabDescription = (index, tabIndex, e) => {
    const nextState = produce(this.state.items, (draft) => {
      draft[index].tabs[tabIndex].description = e.target.value
    })
    let clone = JSON.parse(JSON.stringify(nextState))

    this.setState({ items: clone })
  }
  setGroupModal = (bool, index, tabIndex) => {
    this.setState({ groupModal: bool, tabIndex, tabGroupIndex: index })
  }
  setFieldModal = (bool, index, tabIndex) => {
    this.setState({ fieldModal: bool, tabIndex, tabGroupIndex: index })
  }

  handleChartTypes = (val, itemIndex, tabIndex, elementIndex, type, dataSourceIndex) => {
    if (tabIndex == undefined) {
      const nextState = produce(this.state.items, (draft) => {
        if (type == 'type') {
          draft[itemIndex].element.type = val
          draft[itemIndex].element.data_source = []
        }
        if (type == 'title') {
          draft[itemIndex].element.title = val
        }

        if (type == 'position') {
          draft[itemIndex].element.position = val
        }
        if (type == 'visibility') {
          draft[itemIndex].element.visibility = val
        }
        if (type == 'data_source_type') {
          draft[itemIndex].element[type] = val
          draft[itemIndex].element.data_source = []
        }
        if (type == 'entity_data_source') {
          draft[itemIndex].element[type] = val
        }
        if (type == 'data_source') {
          draft[itemIndex].element[type] = val
        }
        if (type == 'data_field') {
          draft[itemIndex].element.data_source[dataSourceIndex].data_field = val
        }
        if (type == 'name_field') {
          draft[itemIndex].element.data_source[dataSourceIndex].name_field = val
        }
        if (type == 'primary_filter') {
          draft[itemIndex].element.data_source[dataSourceIndex].primary_filter = val
        }
        if (type == 'show_options') {
          draft[itemIndex].element.data_source[dataSourceIndex].show_options = val
        }
        if (type == 'secondary_filter') {
          draft[itemIndex].element.data_source[dataSourceIndex].secondary_filter = val
        }
        if (type == 'description_field') {
          draft[itemIndex].element.data_source[dataSourceIndex].description_field = val
        }
        if (type == 'show_slide') {
          draft[itemIndex].element.data_source[dataSourceIndex].show_slide = val
        }
        if (type == 'source') {
          draft[itemIndex].element.source = val
        }
        if (type == 'form') {
          draft[itemIndex].element.form = val
        }
        if (type == 'group') {
          draft[itemIndex].element.group = val
        }
      })
      let clone = JSON.parse(JSON.stringify(nextState))
      this.setState({ items: clone })
    } else {
      const nextState = produce(this.state.items, (draft) => {
        if (type == 'type') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.type = val
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source = []
        }
        if (type == 'title') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.title = val
        }

        if (type == 'position') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.position = val
        }
        if (type == 'data_source_type') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element[type] = val
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source = []
        }
        if (type == 'entity_data_source') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element[type] = val
        }
        if (type == 'visibility') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element[type] = val
        }
        if (type == 'data_source') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element[type] = val
        }
        if (type == 'data_field') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].data_field = val
        }
        if (type == 'name_field') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].name_field = val
        }
        if (type == 'primary_filter') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].primary_filter = val
        }
        if (type == 'show_options') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].show_options = val
        }
        if (type == 'secondary_filter') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].secondary_filter = val
        }
        if (type == 'description_field') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].description_field = val
        }
        if (type == 'show_slide') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.data_source[
            dataSourceIndex
          ].show_slide = val
        }
        if (type == 'source') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.source = val
        }
        if (type == 'group') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.group = val
        }

        if (type == 'form') {
          draft[itemIndex].tabs[tabIndex].children[elementIndex].element.form = val
        }
      })
      let clone = JSON.parse(JSON.stringify(nextState))

      this.setState({ items: clone })
    }
  }
  prepareData = () => {
    const nextState = produce(this.state.items, (draft) => {
      this.recursive(draft)
    })
    console.log('nextstate', nextState)
    return nextState
  }
  recursive = (data) => {
    for (let i = 0; i < data.length; i++) {
      let item = data[i]
      if (item.type == 'group') {
        let temp = { ...item.group }
        item.group = temp.id
      }
      if (item.type == 'field') {
        let temp = { ...item.field }
        item.field = temp.id
      } else if (item.tabs) {
        this.recursive(item.tabs)
      } else if (item.children) {
        this.recursive(item.children)
      }
    }
  }
  saveForm = async () => {
    let data = {
      name: this.state.name,
      items: this.prepareData(),
      display_name: this.state.display_name,
    }
    if (this.props.formId) {
      data.id = this.props.formId
      await FormService.updateForm(data)
    } else {
      await FormService.saveForm(data)
    }
    this.props.navigate('/admin/forms')
  }
  getForm = async () => {
    if (!this.props.formId) return
    try {
      let res = await FormService.getForm(this.props.formId)
      console.log('res', res)
      this.setState({
        name: get(res, 'data.data.name', ''),
        display_name: get(res, 'data.data.display_name', ''),
      })
      if (get(res, 'data.data.items', '')) {
        this.setState({ items: res.data.data.items })
      }
    } catch (err) {}
  }
  componentDidMount() {
    this.getForms()
    this.getGroups()
    this.getForm()
    this.getPdfConfigs()
    this.getQuestionnaires()
  }
  deleteElement = (elementIndex, tabIndex, tabChildIndex) => {
    let nextState = produce(this.state.items, (draft) => {
      if (tabChildIndex != undefined) {
        draft[elementIndex].tabs[tabIndex].children.splice(tabChildIndex, 1)
      } else if (tabIndex != undefined) {
        draft[elementIndex].tabs.splice(tabIndex, 1)
      } else {
        draft.splice(elementIndex, 1)
      }
    })
    let clone = JSON.parse(JSON.stringify(nextState))
    this.setState({ items: clone })
  }
  shuffle = (list, elementIndex) => {
    let newState = produce(this.state.items, (draft) => {
      draft[elementIndex].tabs = JSON.parse(JSON.stringify(list))
    })
    let clone = JSON.parse(JSON.stringify(newState))
    this.setState({ items: clone })
  }
  shuffleTabElements = (elementIndex, tabIndex, children) => {
    let newState = produce(this.state.items, (draft) => {
      draft[elementIndex].tabs[tabIndex].children = JSON.parse(JSON.stringify(children))
    })
    let clone = JSON.parse(JSON.stringify(newState))
    this.setState({ items: clone })
  }
  render() {
    console.log('state', this.state.items)
    const { visible, loading } = this.state
    return (
      <AdminLayout>
        <div style={{ margin: 10, padding: '35px 40px', backgroundColor: '#fff' }}>
          <h1 className="flex_title">
            <span className="title">Add/Edit Form</span>
            <div>
              <Button style={{ marginRight: 5 }} onClick={this.showModal}>
                Preview Form
              </Button>
              <Button onClick={this.saveForm} type="primary">
                Save Form
              </Button>
            </div>
          </h1>
          <div className="form_field" style={{ maxWidth: 1100 }}>
            <label style={{ marginBottom: 5, display: 'block' }}>Form name</label>
            <div>
              <Input
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                required={true}
              />
            </div>
          </div>
          <div className="form_field" style={{ maxWidth: 1100 }}>
            <label style={{ marginBottom: 5, display: 'block' }}>Display name</label>
            <div>
              <Input
                value={this.state.display_name}
                onChange={(e) => this.setState({ display_name: e.target.value })}
                required={true}
              />
            </div>
          </div>
          <div className="form_builder_layout">
            <div className="form_builder" style={{ paddingTop: 65 }}>
              <ReactSortable
                list={this.state.items}
                setList={(list) => this.setState({ items: list })}
              >
                {this.state.items.map((item, i) => (
                  <div className="drag_item" key={i}>
                    <button className="delete_tab" onClick={() => this.deleteElement(i)}>
                      <span className="material-icons-outlined">delete</span>
                    </button>
                    <button
                      style={{ display: item.id ? 'inline-block' : 'none' }}
                      className="condition_tab"
                      onClick={() =>
                        item.id &&
                        this.props.navigate(
                          `/admin/conditions?form=${this.props.formId}&fieldId=${item.id}`,
                          { push: true }
                        )
                      }
                    >
                      <span className="material-icons-outlined" style={{ fontSize: '20px' }}>
                        help
                      </span>
                    </button>

                    {this.renderForm(item, i)}
                  </div>
                ))}
              </ReactSortable>
            </div>
            <Toolbar
              addTabGroup={this.addTabGroup}
              addElement={this.addElement}
              setGroupModal={this.setGroupModal}
              setFieldModal={this.setFieldModal}
            />
          </div>
          <div></div>
        </div>
        <FormPreview
          handleOk={this.handleOk}
          visible={visible}
          handleCancel={this.handleCancel}
          items={this.state.items}
          name={this.state.name}
          displayName={this.state.display_name}
        />
        <GroupModal
          setGroupModal={this.setGroupModal}
          visible={this.state.groupModal}
          addGroup={this.addGroup}
          addNestedGroup={this.addNestedGroup}
          index={this.state.tabGroupIndex}
          tabIndex={this.state.tabIndex}
        />
        <FieldModal
          setFieldModal={this.setFieldModal}
          visible={this.state.fieldModal}
          addField={this.addField}
          addNestedField={this.addNestedField}
          index={this.state.tabGroupIndex}
          tabIndex={this.state.tabIndex}
        />
      </AdminLayout>
    )
  }
}

const Container = () => {
  const navigate = useNavigate()
  const { formId } = useParams()
  return <AddForm formId={formId} navigate={navigate} />
}

export default Container
