import { AdminLayout } from 'Components'
import { get } from 'lodash'
import PreviewModal from 'Pages/Admin/Pages/PreviewModal'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthService, BusinessService, PageService } from 'services'
import { useDispatch } from 'react-redux'
import { setBusinessList, setClientList, setUserList } from 'Store/reducers/entityReducer'
import Dashboard from 'Pages/Dashboard/dashboard'
import RiskProfile from 'Pages/RiskProfile/risk'
import { getFirstPage } from 'utils/helpers'
import { setUser } from 'Store/reducers/previewUserSlice'
import { setRoutes } from 'Store/reducers/routeSlice'

const PagePreview = ({ preview }) => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity = params.get('entity')
  const entity_type = params.get('entity_type')

  const [pageName, setPageName] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [is_dashboard, setDashboard] = useState(false)
  const [is_risk_profile, setRiskProfile] = useState(false)
  const [hasSidebarCondition, setSidebarCondition] = useState(false)

  const [items, setItems] = useState([])
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { pageId } = useParams()
  useEffect(() => {
    getPreviewEntity()
    getPage()
    get_user_data()
    get_client_data()
    get_business_data()
  }, [pageId])
  const getPage = async () => {
    if (!pageId) return
    try {
      let res = await PageService.getPage(pageId)
      setPageName(get(res, 'data.data.name', ''))
      setDisplayName(get(res, 'data.data.display_name', ''))
      setDashboard(get(res, 'data.data.is_dashboard', false))
      setRiskProfile(get(res, 'data.data.is_risk_profile', false))
      setSidebarCondition(get(res, 'data.data.has_sidebar_condition', false))

      console.log('page fields', res.data)

      if (get(res, 'data.data.items', '')) {
        setItems(res.data.data.items)
      }
    } catch (err) {}
  }
  const getPreviewEntity = async () => {
    if (!preview) return
    if (entity_type === 'business') {
      const res = await BusinessService.detailBusiness(entity, {
        detail: true,
      })
      dispatch(setUser({ ...res.data.data, user_type: 'business' }))
      dispatch(setRoutes(res.data.data.current_page_config))
    }
    if (entity_type === 'client') {
      const res = await AuthService.detailEntity(entity, { detail: true })
      dispatch(setUser(res.data.data))
      dispatch(setRoutes(res.data.data.current_page_config))
    }
  }

  const get_user_data = async () => {
    const res = await AuthService.users({ user_type: 'user', is_active: true })
    dispatch(setUserList(get(res, 'data', {})))
  }

  const get_client_data = async () => {
    const res = await AuthService.users({
      user_type: 'client',
      is_active: true,
    })
    dispatch(setClientList(get(res, 'data', {})))
  }

  const get_business_data = async () => {
    const res = await BusinessService.business({ is_active: true })
    dispatch(setBusinessList(get(res, 'data', {})))
  }
  return is_dashboard ? (
    <Dashboard preview={preview} items={items} />
  ) : is_risk_profile ? (
    <RiskProfile preview={preview} />
  ) : (
    <AdminLayout preview={preview}>
      <PreviewModal
        showSave
        items={items}
        name={displayName || pageName}
        noModal
        preview={preview}
        pageId={pageId}
        hasSidebarCondition={hasSidebarCondition}
      />
    </AdminLayout>
  )
}

export default PagePreview
