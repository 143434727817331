import React, { useEffect, useState } from 'react'
import { Button, Card, Tabs, Modal, Input, Select, Space, Table } from 'antd'
import { PlusOutlined, EyeOutlined, DeleteOutlined, FilePdfOutlined } from '@ant-design/icons'
import { AdminLayout, CustomBarChart, CustomPieChart } from 'Components'
import { useNavigate } from 'react-router-dom'
import './risk.scss'
import NestedPieChartComponent from 'Components/Charts/groupedpie'
import StockLine from 'Components/Charts/stockLine'
import Portfolio from 'Components/Portfolio/portfolio'
import { ElementService, PdfConfigService } from '../../services'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import moment from 'moment'

const { Option } = Select
const { TabPane } = Tabs
const RiskProfile = ({ preview }) => {
  let navigate = useNavigate()
  const [chart_data, setChartData] = useState([])
  const [asset_data, setAssetData] = useState([])
  const [percent_outer_chart, setPercentOuterChart] = useState({})
  const [portfolioData, setPortfolioData] = useState([])
  const [current_risk_profile, setCurrentRiskProfile] = useState('')

  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const [historyList, setHistoryList] = useState([])

  const entity = params.get('entity')
    ? params.get('entity')
    : preview && previewUser?.details
      ? previewUser?.details?.id
      : user?.details?.id
  const business = preview ? previewUser?.details?.business : user?.details?.business

  const riskHistoryData = [
    {
      key: '1',
      name: 'Ravi Bhushan',
      owner: 'Ravi Bhushan',
      receivedOn: 'Thursday, 22nd March, 2022',
      lockDate: 'Thursday, 22nd March, 2022',
      result: 'Conservative',
      status: 'In progress',
    },
    {
      key: '2',
      name: 'Han Sia',
      owner: 'Ravi Bhushan',
      receivedOn: 'Thursday, 22nd March, 2022',
      lockDate: 'Thursday, 22nd March, 2022',
      result: 'Conservative',
      status: 'Submitted',
    },
    {
      key: '3',
      name: 'Prashant Sharma',
      owner: 'Ravi Bhushan',
      receivedOn: 'Thursday, 22nd March, 2022',
      lockDate: 'Thursday, 22nd March, 2022',
      result: 'Conservative',
      status: 'Submitted',
    },
  ]

  const riskHistoryColumns = [
    {
      title: 'Submitted By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (data, record) => (
        <div className="table_responsive_cell">
          <h6>Submitted By</h6>
          <b>{data.name}</b>
        </div>
      ),
    },
    {
      title: 'Owner',
      dataIndex: 'entity',
      key: 'entity',
      render: (data, record) => (
        <div className="table_responsive_cell">
          <h6>Owner</h6>
          <b>{data.name}</b>
        </div>
      ),
    },
    {
      title: 'Received On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Received On</h6>
          <span className="sep_time">{moment(text).format('ddd , D MMMM , YYYY')}</span>
          <span className="sep_time">{moment(text).format('h:mm:ss A')}</span>
        </div>
      ),
    },
    {
      title: 'Lock Date',
      dataIndex: 'lock_date',
      key: 'lock_date',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Lock Date</h6>
          {text ? (
            <>
              <span className="sep_time">{moment(text).format('ddd , D MMMM , YYYY')}</span>
              <span className="sep_time">{moment(text).format('h:mm:ss A')}</span>
            </>
          ) : (
            <span></span>
          )}
        </div>
      ),
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Result</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Result / Agreed Profile',
      dataIndex: 'result',
      key: 'result',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Result</h6>
          {record.result} / {record.agreed_result}
        </div>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'is_locked',
      key: 'is_locked',
      render: (is_locked) => (
        <div className="table_responsive_cell">
          <h6>Status</h6>
          {is_locked ? <span>Submitted</span> : <span>In progress</span>}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<FilePdfOutlined />} onClick={showModal} size={'medium'} />
          <Button
            icon={<EyeOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/questionnaire/wizard')}
          />
          <Button danger icon={<DeleteOutlined />} size={'medium'} />
        </Space>
      ),
    },
  ]

  const columns = [
    {
      title: 'Asset Class',
      dataIndex: 'asset',
      key: 'asset',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Asset Class</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Target',
      dataIndex: 'target',
      key: 'target',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Target</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Current',
      dataIndex: 'current',
      key: 'current',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Current</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Variance',
      dataIndex: 'variance',
      key: 'variance',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Variance</h6>
          {text}
        </div>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      asset: 'Fixed',
      target: 400,
      current: 800,
      variance: 400,
    },
    {
      key: '2',
      asset: 'Equity',
      target: 400,
      current: 800,
      variance: 400,
    },
    {
      key: '3',
      asset: 'Cash',
      target: 400,
      current: 800,
      variance: 400,
    },
  ]
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const get_risk_profile_chart_data = async () => {
    const res = await ElementService.RiskProfileDataList({ business, entity })
    await setPortfolioData(get(res, 'data.data.portfolios'))
    await setChartData(get(res, 'data.data.chart_data'))
    await setAssetData(get(res, 'data.data.asset'))
    await setPercentOuterChart(get(res, 'data.data.percent_outer_chart'))
    await setCurrentRiskProfile(get(res, 'data.data.current_risk_profile'))
  }

  useEffect(() => {
    get_risk_profile_chart_data()
    getHistoryList()
  }, [])

  const inner_chart =
    asset_data?.map((item) => ({
      name: item.asset,
      parentName: item.parentName,
      data: item.target,
    })) || []
  const outer_chart = calculatePercentages(asset_data)
  function calculatePercentages(assetData) {
    const grouped = assetData.reduce((acc, { parentName, target }) => {
      acc[parentName] = (acc[parentName] || 0) + target
      return acc
    }, {})

    // Calculate the total sum of all groups
    const total = Object.values(grouped).reduce((sum, value) => sum + value, 0)

    // Calculate the percentage for each group
    const result = Object.entries(grouped).map(([name, value]) => ({
      name,
      data: (value / total) * 100,
    }))

    return result
  }
  // const firstParentName = asset_data && asset_data.length > 0 ? [{name: asset_data[0].parentName, data: 100}] : [];

  //   function get_outer_chart_data() {
  //     return Object.entries(percent_outer_chart).map(([name, value]) => ({
  //         name,
  //         data: Math.round(value) // Optionally round values to nearest integer
  //     }));
  // }

  const getHistoryList = () => {
    PdfConfigService.getWizardHistoryList({
      // questionnaire: params.wizard,
      questionnaire: '05561b55-1606-4d1b-8b82-d1e1bc0ff8e9',
      entity: previewUser?.details?.id || user?.details?.id,
    })
      .then((res) => {
        setHistoryList(get(res, 'data.data', []))
      })
      .catch((err) => console.log('err'))
  }

  return (
    <AdminLayout preview={preview}>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Risk Profile</span>
        </h1>

        <Tabs type="card">
          <TabPane tab="Current" key="1">
            <div style={{ padding: 20 }}>
              Who :{' '}
              <Select defaultValue="lucy" style={{ width: 120 }}>
                <Option value="jack">Jack</Option>
                <Option value="lucy">Lucy</Option>
              </Select>
              <br />
              <br />
              {historyList.filter((item) => item.is_locked).length > 0 && (
                <div>
                  <StockLine chart_data={chart_data} />
                </div>
              )}
              <br />
              <br />
              <Portfolio
                portfolioData={portfolioData}
                current_risk_profile={current_risk_profile}
              />
              {/* <div className="row risk_chart align-items-stretch">
                <div className="col-md-12 col-lg-6 col-xl-6">
                  <Card bordered={false}>
                    <StockLine />
                  </Card>
                </div>
                <div className="col-md-12 col-lg-6 col-xl-6">
                  <Card bordered={false}>
                  </Card>
                  </div>
                </div> */}
              {historyList.filter((item) => item.is_locked).length > 0 && (
                <NestedPieChartComponent inner_chart={inner_chart} outer_chart={outer_chart} />
              )}
              <br />
              <br />
              {historyList.filter((item) => item.is_locked).length > 0 && (
                <Table
                  className="custom_responsive_table"
                  columns={columns}
                  dataSource={asset_data}
                  rowKey="asset"
                />
              )}
            </div>
          </TabPane>
          <TabPane tab="History" key="2">
            <Button type="primary">Create risk profile</Button>
            <div style={{ padding: 20 }}>
              <Input.Group compact style={{ width: '100%' }}>
                <Select defaultValue="all" style={{ width: '140px' }} disabled>
                  <Option value="all">Owner</Option>
                </Select>
                <Input.Search allowClear style={{ width: 'calc(100% - 140px)' }} />
              </Input.Group>
              <br />
              <Table
                className="custom_responsive_table"
                columns={riskHistoryColumns}
                dataSource={historyList}
                rowKey="id"
              />
            </div>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        title="Risk Profile History"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut viverra metus ac leo
          consectetur ullamcorper. Phasellus condimentum eros ac dignissim fringilla. Interdum et
          malesuada fames ac ante ipsum primis in faucibus. Aliquam cursus dictum odio in mollis.
          Vestibulum quis sem ipsum. Mauris sagittis, lacus eu molestie suscipit, neque urna
          fermentum leo, ac dignissim turpis lectus in felis. Sed ligula mi, congue eu mollis vitae,
          malesuada ac lacus. Quisque et nisl et elit tempor tristique. Mauris in suscipit nulla.
          Proin aliquam metus augue, in condimentum eros faucibus non. Nam a convallis enim, nec
          bibendum purus. Vestibulum consectetur enim ut massa bibendum varius. Nam non imperdiet
          leo. Integer vitae luctus ligula. Nulla porta erat at posuere commodo. Praesent ornare
          luctus sodales.
        </p>
        <p>
          Nam dui elit, rutrum eu massa nec, finibus egestas tortor. Duis imperdiet rutrum diam, et
          elementum quam bibendum at. Curabitur odio erat, facilisis a lectus vel, tincidunt finibus
          eros. Sed et metus aliquet, convallis lorem ut, tristique ex. Morbi varius eget ipsum sit
          amet mollis. Proin et erat sapien. Sed dui velit, vestibulum vitae bibendum id, commodo in
          lectus.
        </p>
        <p>
          Donec sed velit ante. Ut vestibulum aliquam sapien, non aliquet dolor euismod non. Aenean
          porttitor porttitor neque. Integer velit odio, feugiat ut ex eget, posuere ultrices sem.
          Vestibulum dolor arcu, auctor non mi vitae, placerat cursus lacus. Nunc elementum est non
          quam congue, sit amet scelerisque turpis mattis. Sed elementum, metus vitae vestibulum
          commodo, nunc metus congue sapien, in accumsan ligula tortor at tellus. Praesent
          consectetur augue eu aliquet fringilla. Sed condimentum quis dolor id vulputate. Vivamus
          sed facilisis magna, sed bibendum tellus. Proin ut purus nec enim volutpat faucibus sit
          amet et ante. Sed eget arcu sodales, imperdiet ante at, elementum nunc. Aliquam convallis
          velit turpis, non laoreet tortor auctor sit amet. Sed ullamcorper malesuada sapien, vel
          gravida ipsum efficitur a.
        </p>
      </Modal>
    </AdminLayout>
  )
}

export default RiskProfile
