import React, { Component, Fragment } from 'react'
import { HomeFoot, HomeNav } from 'Components'

export default class HomeLayout extends Component {
  render() {
    return (
      <Fragment>
        <HomeNav />
        {this.props.children}
        <HomeFoot />
      </Fragment>
    )
  }
}
