import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import './barSelect.scss'

const BarMultiSelectChart = (props) => {
  var theme = useSelector((state) => state.theme.current_theme)
  var colors = [
    theme.chart_color.chart1,
    theme.chart_color.chart2,
    theme.chart_color.chart3,
    theme.chart_color.chart4,
    theme.chart_color.chart5,
    theme.chart_color.chart6,
    theme.chart_color.chart7,
    theme.chart_color.chart8,
    theme.chart_color.chart9,
    theme.chart_color.chart10,
  ]

  const getColor = (index) => {
    if (index > 9) {
      return colors[Math.floor(Math.random() * 10)]
    } else {
      return colors[index]
    }
  }

  const [data, setData] = useState([])
  const [score, setScore] = useState(0)

  useEffect(() => {
    console.log('props updated in multi-select', props)
    var activeData = []
    props.dt.map((d, index) => {
      activeData.push({
        value: parseInt(d.value),
        y: 4,
        indexLabel: d.name,
        color: getColor(index),
      })
    })
    setData(activeData)
    console.log('Constructed data', activeData)
  }, [props])

  const updateCheckItem = (e, index) => {
    console.log(index, e.target.checked)
    var activeData = [...data]
    activeData[index].checked = e.target.checked
    setData(activeData)

    console.log('Updated data', activeData)

    calculateScore()
  }

  const calculateScore = () => {
    var sum = 0
    for (var i = 0; i < data.length; i++) {
      if (data[i].checked) {
        sum += data[i].value
      }
    }
    setScore(sum)
  }

  return (
    <div
      style={{
        maxWidth: 650,
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        alignItems: 'center',
        minHeight: '150px',
      }}
    >
      <div style={{ flex: 1 }}>
        <div className="custom_bar_chart_cont">
          <div className="custom_bar_chart">
            {data.map((item, index) => (
              <>
                <input id={index} onChange={(e) => updateCheckItem(e, index)} type="checkbox" />
                <Tooltip title={'Value: ' + item.value}>
                  <label
                    htmlFor={index}
                    style={
                      item.checked
                        ? { backgroundColor: '#000', color: '#fff' }
                        : { backgroundColor: item.color, color: '#fff' }
                    }
                  >
                    {item.indexLabel}
                  </label>
                </Tooltip>
              </>
            ))}
          </div>
        </div>
        <div className="score_count">{props.showScore ? 'Score:' + score : ''}</div>
      </div>
    </div>
  )
}

export default BarMultiSelectChart
