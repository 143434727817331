import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, InputNumber, Select, Radio, Button, Modal, Table, Checkbox } from 'antd'
import {
  EditOutlined,
  EyeOutlined,
  DeleteOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import './group.scss'
import * as yup from 'yup'
import { useFormik } from 'formik'
import { PageServiceService } from 'services'
import _, { get } from 'lodash'
import { click } from '@testing-library/user-event/dist/click'
import page_management from '../../../services/page_management'
const { Option } = Select
const { Search } = Input
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, e) => (
      <div className="table_responsive_cell">
        <h6>Name</h6>
        <b>{e.display_name || e.name}</b>
      </div>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    render: (text) => (
      <div className="table_responsive_cell">
        <h6>Type</h6>
        {text}
      </div>
    ),
  },
  {
    title: 'isRequired',
    dataIndex: 'isRequired',
    render: (text, record) => (
      <div className="table_responsive_cell">
        {record.isRequired === 1 ? <span>Yes</span> : <span>No</span>}
      </div>
    ),
  },
]

// const data = [];
//   for (let i = 0; i < 5; i++) {
//   data.push({
//       key: i,
//       name: `Field ${i}`,
//       type: "text",
//       category: `Core`,
//   });
//   }

const AddGroup = (props) => {
  let { id } = useParams()
  let navigate = useNavigate()

  let schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    category: yup.string().required('Category is required'),
    display_name: yup.string(),
  })

  const formik = useFormik({
    initialValues: {
      name: '',
      category: '',
      display_name: '',
    },
    onSubmit: async (values) => {
      const payload = {
        ...values,
        selected_fields: state.map((item) => ({
          ...item,
          include: item.include ? true : false,
        })),
      }
      if (id) {
        await PageServiceService.GroupUpdate(id, payload)
        navigate('/admin/groups')
      } else {
        await PageServiceService.GroupAdd(payload)
        navigate('/admin/groups')
      }
    },
    validationSchema: schema,
  })

  const [data, SetData] = useState([])
  const [allFields, setAllFields] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [state, setState] = useState([])

  const GetFieldList = async () => {
    const res = await PageServiceService.FieldList()
    SetData(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    setAllFields(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    getGroupDetail(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    //setState(get(res, 'data.data'));
  }

  const getGroupDetail = async (data) => {
    if (id) {
      const res = await PageServiceService.GroupDetail(id)
      formik.setFieldValue('name', get(res, 'data.data.name'))
      formik.setFieldValue('display_name', get(res, 'data.data.display_name'))

      formik.setFieldValue('category', get(res, 'data.data.category'))
      const selected_rows = get(res, 'data.data.fields')
      const selected_keys = get(res, 'data.data.fields').map((item) => item.id)
      await setState(get(res, 'data.data.fields'))
      // await setSelectedRows(selected_rows);
      // await setSelectedRowKeys(selected_keys);

      const selectedObj = {}
      selected_rows.map((item) => (selectedObj[item.id] = item))

      let arr = data.filter((item) => (selectedObj[item.id] ? false : true))
      setSelectedRowKeys(selected_keys.filter((item) => (selectedObj[item] ? false : true)))
      setSelectedRows(selected_rows.filter((item) => (selectedObj[item] ? false : true)))
      SetData(_.orderBy(arr, ['name'], ['asc']))
      setSelectedRowKeys([])
      setSelectedRows([])
    }
  }

  useEffect(async () => {
    await GetFieldList()
  }, [])

  const removeItem = (id) => {
    setState(state.filter((item) => item.id !== id))
  }
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRows);
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const hasSelected = selectedRowKeys.length > 0

  const clickSelectedField = () => {
    const selectedObj = {}
    selectedRows.map((item) => (selectedObj[item.id] = item))

    let arr = data.filter((item) => (selectedObj[item.id] ? false : true))
    setSelectedRowKeys(selectedRowKeys.filter((item) => (selectedObj[item] ? false : true)))
    setSelectedRows(selectedRows.filter((item) => (selectedObj[item] ? false : true)))

    SetData(_.orderBy(arr, ['name'], ['asc']))
    setState((prev) => [...prev, ...selectedRows])
    setSelectedRowKeys([])
    setSelectedRows([])
    handleOk()
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Add/Edit Group</span>
          <div>
            {' '}
            <Button
              onClick={() => {
                navigate('/admin/conditions', { push: true })
              }}
            >
              Edit Conditions
            </Button>{' '}
            <Button type="primary" onClick={formik.handleSubmit}>
              Save Group
            </Button>
          </div>
        </h1>
        <div className="preview_layout">
          <Form layout="vertical">
            <Form.Item label="Group Name">
              <Input
                style={{ width: 400 }}
                placeholder="Group name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
            </Form.Item>
            <Form.Item label="Display Name">
              <Input
                style={{ width: 400 }}
                placeholder="Display name"
                name="display_name"
                onChange={formik.handleChange}
                value={formik.values.display_name}
              />
            </Form.Item>
            {/* <Form.Item label="Percent Width">
              <Input
                type={'number'}
                style={{ width: 400 }}
                placeholder="Percent Width (%)"
                name="percent"
                onChange={formik.handleChange}
                value={formik.values.percent}
              />
            </Form.Item> */}
            {formik.touched.name && formik.errors.name && (
              <span style={{ textAlign: 'center' }} className="input-error-message">
                {formik.errors.name}
              </span>
            )}
            <Form.Item label="Category">
              <Select
                style={{ width: 400 }}
                name="category"
                onChange={(value) => (
                  formik.handleChange(value), formik.setFieldValue('category', value)
                )}
                value={formik.values.category}
              >
                <Option value=""></Option>
                <Option value="core">Core</Option>
                <Option value="custom">Custom</Option>
              </Select>
            </Form.Item>
            {formik.touched.category && formik.errors.category && (
              <span style={{ textAlign: 'center' }} className="input-error-message">
                {formik.errors.category}
              </span>
            )}
          </Form>
          <div className="field_preview">
            <h2 style={{ marginBottom: 35 }}>
              Fields{' '}
              <Button
                style={{ marginLeft: 50 }}
                onClick={showModal}
                icon={<PlusOutlined />}
                size={'small'}
                type="primary"
              >
                Add Fields
              </Button>
            </h2>
            <ReactSortable className="sortable_fields" tag="ul" list={state} setList={setState}>
              {state.map((item, i) => (
                <li className="draggable_list group_field" key={item.id}>
                  <span className="item_name">{item.display_name || item.name}</span>
                  <div>
                    {item.include && (
                      <InputNumber
                        style={{ marginRight: 4, width: 60 }}
                        min={1}
                        max={100}
                        defaultValue={100}
                        value={state[i].percent || 0}
                        onChange={(value) => {
                          let arr = [...state]
                          arr[i].percent = value
                          setState(arr)
                        }}
                      />
                    )}
                    <span className="checkbox_group">
                      <Checkbox
                        checked={item.include}
                        onChange={(e) => {
                          let i = state.findIndex((doc) => doc.id == item.id)
                          let arr = [...state]
                          arr[i].include = !arr[i].include
                          setState(arr)
                        }}
                      />
                    </span>
                    <Button
                      title="Manage conditions"
                      icon={<QuestionCircleOutlined />}
                      size={'medium'}
                      onClick={() => {
                        navigate(`/admin/conditions?group=${id}&fieldId=${item.selected_field}`, {
                          push: true,
                        })
                      }}
                    />{' '}
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size={'medium'}
                      onClick={() => {
                        let arr = [...data, item]
                        SetData(_.orderBy(arr, ['name'], ['asc']))
                        removeItem(item.id)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ReactSortable>
          </div>
        </div>
        <Modal
          title="Search and select fields"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" onClick={clickSelectedField}>
              Add Selected Fields
            </Button>,
          ]}
        >
          <Search
            onChange={(e) =>
              SetData(
                allFields.filter((item) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
              )
            }
            placeholder="Search fields"
            style={{ width: '100%' }}
          />
          <div style={{ marginBottom: 5, marginTop: 15 }}>
            <span style={{ marginLeft: 8 }}>
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
            </span>
          </div>
          <Table
            className="custom_responsive_table"
            rowSelection={rowSelection}
            rowKey="id"
            columns={columns}
            dataSource={data}
          />
        </Modal>
      </div>
    </AdminLayout>
  )
}

export default AddGroup
