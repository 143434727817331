import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Select, Space, Table, Tabs } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ProductService } from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'

const { Option } = Select
const { TabPane } = Tabs

export default function AssetClassConfig({ preview }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Short name',
      dataIndex: 'short_name',
      key: 'short_name',
      sorter: (a, b) => a.short_name.localeCompare(b.short_name),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Short name</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      sorter: (a, b) => a.category.localeCompare(b.category),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Category</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          <Button
            danger
            icon={<DeleteOutlined />}
            size="medium"
            onClick={() => deleteData(record.id)}
          />
        </Space>
      ),
    },
  ]

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    const res = await ProductService.getDetailAssetClass(id)
    formik.setValues(get(res, 'data.data'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this asset class?')
    if (result) {
      await ProductService.delete(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await ProductService.getAssetClass()
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    name: yup.string().required('Name Required'),
    short_name: yup.string(),
    category: yup.string().required('Category Required'),
    notes: yup.string(),
  })

  const initialValues = {
    name: '',
    short_name: '',
    category: '',
    notes: '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      if (id) {
        await ProductService.updateAssetClass(values, id)
      } else {
        await ProductService.saveAssetClass(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add asset class
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit asset class' : 'Add asset class'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Name">
                <Input
                  style={{ width: '100%' }}
                  placeholder="name"
                  name="name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <span className="input-error-message">{formik.errors.name}</span>
                )}
              </Form.Item>
              <Form.Item label="Short name">
                <Input
                  style={{ width: '100%' }}
                  placeholder="short_name"
                  name="short_name"
                  onChange={formik.handleChange}
                  value={formik.values.short_name}
                />
                {formik.touched.short_name && formik.errors.short_name && (
                  <span className="input-error-message">{formik.errors.short_name}</span>
                )}
              </Form.Item>
              <Form.Item label="Category">
                <Select
                  mode="single"
                  name="category"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="category"
                  value={formik.values.category}
                  onChange={(value) => formik.setFieldValue('category', value)}
                >
                  <Option value=""></Option>
                  <Option value="defensive">Defensive</Option>
                  <Option value="growth">Growth</Option>
                  <Option value="neutral">Neutral</Option>
                </Select>
                {formik.touched.category && formik.errors.category && (
                  <span className="input-error-message">{formik.errors.category}</span>
                )}
              </Form.Item>
              <Form.Item label="Notes">
                <QuillEditor
                  theme="snow"
                  name="notes"
                  value={formik.values.notes}
                  onChange={(value) => formik.setFieldValue('notes', value)}
                />
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
