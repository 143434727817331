import React from 'react'
import { AdminLayout } from 'Components'
import { useEffect, useState } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash/object'

export default function AdminContactUS(props) {
  const [Id, SetId] = useState('')
  const [email, SetEmail] = useState('')
  const [phone_number, SetPhone] = useState('')
  const [facebook, SetFacebook] = useState('')
  const [instagram, SetInstagram] = useState('')
  const [twitter, SetTwitter] = useState('')
  const [linkedin, SetLinkedin] = useState('')
  const [whatsapp, SetWhatapp] = useState('')
  const [youtube, SetYoutube] = useState('')
  const [address, SetAddress] = useState('')

  useEffect(async () => {
    const res = await StaticPageService.CompanyContactGet()
    SetId(get(res, 'data.data.id'))
    SetEmail(get(res, 'data.data.email'))
    SetPhone(get(res, 'data.data.phone_number'))
    SetFacebook(get(res, 'data.data.facebook'))
    SetInstagram(get(res, 'data.data.instagram'))
    SetLinkedin(get(res, 'data.data.linkedin'))
    SetTwitter(get(res, 'data.data.twitter'))
    SetWhatapp(get(res, 'data.data.whatsapp'))
    SetYoutube(get(res, 'data.data.youtube'))
    SetAddress(get(res, 'data.data.address'))
  }, [])

  const OnSubmit = async (e) => {
    e.preventDefault()
    const res = await StaticPageService.CompanyContactUpdate(Id, {
      email,
      phone_number,
      facebook,
      instagram,
      linkedin,
      twitter,
      whatsapp,
      youtube,
      address,
    })
  }

  const Main = (
    <>
      <div className="static_admin_setting">
        <h1>Contact Us</h1>
        <form className="base_form contact_details_setting" onSubmit={OnSubmit}>
          <div className="form_group half">
            <div>
              <label className="form_label">Email Address</label>
              <input
                name="email"
                value={email}
                type="email"
                required
                onChange={(e) => SetEmail(e.target.value)}
              />
            </div>
            <div>
              <label className="form_label">Contact Number</label>
              <input
                name="phone_number"
                value={phone_number}
                type="text"
                required
                onChange={(e) => SetPhone(e.target.value)}
              />
            </div>
          </div>
          <div className="form_group half">
            <div>
              <label className="form_label">Facebook</label>
              <input
                name="facebook"
                value={facebook}
                type="text"
                onChange={(e) => SetFacebook(e.target.value)}
              />
            </div>
            <div>
              <label className="form_label">Instagram</label>
              <input
                name="instagram"
                value={instagram}
                type="text"
                onChange={(e) => SetInstagram(e.target.value)}
              />
            </div>
          </div>
          <div className="form_group half">
            <div>
              <label className="form_label">Twitter</label>
              <input
                name="twitter"
                value={twitter}
                type="text"
                onChange={(e) => SetTwitter(e.target.value)}
              />
            </div>
            <div>
              <label className="form_label">Linkedin</label>
              <input
                name="linkedin"
                value={linkedin}
                type="text"
                onChange={(e) => SetLinkedin(e.target.value)}
              />
            </div>
          </div>
          <div className="form_group half">
            <div>
              <label className="form_label">Whatsapp</label>
              <input
                name="whatsapp"
                value={whatsapp}
                type="text"
                onChange={(e) => SetWhatapp(e.target.value)}
              />
            </div>
            <div>
              <label className="form_label">Youtube</label>
              <input
                name="youtube"
                value={youtube}
                type="text"
                onChange={(e) => SetYoutube(e.target.value)}
              />
            </div>
          </div>
          <div className="form_group">
            <label className="form_label">Address</label>
            <textarea
              name="address"
              value={address}
              cols="30"
              rows="5"
              onChange={(e) => SetAddress(e.target.value)}
            ></textarea>
          </div>
          <button style={{ marginTop: '10px' }} type="submit" className="primary_form">
            SUBMIT
          </button>
        </form>
      </div>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}
