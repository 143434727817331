import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { editorKey } from 'constants'

const TinyEditor = ({
  customStyle,
  formats,
  width,
  height,
  onChange,
  value,
  readOnly,
  id,
  isInline,
  special_char_data,
}) => {
  // const specialChars = [
  //   { text: 'name', value: '<span class="mark mceNonEditable">Client Name</span>' },
  //   { text: 'date', value: '<span class="mark mceNonEditable">Date of Birth</span>' },
  //   { text: 'last name', value: '<span class="mark mceNonEditable">Last Name</span>' },
  // ];

  const specialChars = []
  if (special_char_data) {
    for (const item of special_char_data['client']) {
      const name = item.name
      specialChars.push({
        text: 'client.' + name,
        value: `<span data-item=${'field-client-' + item.id} class="mark mceNonEditable">${'client.' + name}</span>`,
      })
    }
    for (const item of special_char_data['business']) {
      const name = item.name
      specialChars.push({
        text: 'business.' + name,
        value: `<span data-item=${'field-business-' + item.id} class="mark mceNonEditable">${'business.' + name}</span>`,
      })
    }
    for (const item of special_char_data['user']) {
      const name = item.name
      specialChars.push({
        text: 'user.' + name,
        value: `<span data-item=${'field-user-' + item.id} class="mark mceNonEditable">${'user.' + name}</span>`,
      })
    }
  }

  if (!formats || formats?.length < 1) {
    formats = [
      { title: 'Heading 1', block: 'h1', attributes: { class: 'h1' } },
      { title: 'Heading 2', block: 'h2', attributes: { class: 'h2' } },
      { title: 'Heading 3', block: 'h3', attributes: { class: 'h3' } },
      { title: 'Heading 4', block: 'h4', attributes: { class: 'h4' } },
      { title: 'Heading 5', block: 'h5', attributes: { class: 'h5' } },
      { title: 'Heading 6', block: 'h6', attributes: { class: 'h6' } },
      { title: 'Paragraph', block: 'p', attributes: { class: 'p' } },
      { title: 'Intro text', block: 'p', attributes: { class: 'p1' } },
      { title: 'Summary text', block: 'p', attributes: { class: 'p2' } },
      { title: 'Warning text', block: 'p', attributes: { class: 'p3' } },
      { title: 'Instructional', block: 'p', attributes: { class: 'p4' } },
    ]
  }
  return (
    <div
      style={{
        width: `${width}%`,
        backgroundColor: '#fff',
      }}
    >
      <Editor
        onEditorChange={onChange}
        disabled={readOnly}
        value={value}
        apiKey={editorKey}
        key={id}
        inline={isInline}
        init={{
          content_css: '/editor.css',
          content_style: customStyle,
          height: height,
          menubar: false,
          table_cell_class_list: [
            { title: 'None', value: '' },
            { title: 'Header', value: 'table_header_custom' },
            { title: 'Sub Header', value: 'table_sub_header_custom' },
          ],
          noneditable_noneditable_class: 'mceNonEditable',
          plugins: [
            'autolink',
            'lists',
            'link',
            'image',
            'charmap',
            'preview',
            'anchor',
            'textcolor',
            'searchreplace',
            'visualblocks',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
          ],
          setup: function (editor) {
            var onAction = function (autocompleteApi, rng, value) {
              editor.selection.setRng(rng)
              editor.insertContent(value)
              autocompleteApi.hide()
            }

            var getMatchedChars = function (pattern) {
              return specialChars.filter(function (char) {
                return char.text.indexOf(pattern) !== -1
              })
            }

            /* An autocompleter that allows you to insert special characters */
            editor.ui.registry.addAutocompleter('specialchars_cardmenuitems', {
              ch: '@',
              minChars: 1,
              columns: 1,
              onAction: onAction,
              fetch: (pattern) => {
                return new Promise((resolve) => {
                  const results = getMatchedChars(pattern).map((char) => ({
                    type: 'cardmenuitem',
                    value: char.value,
                    label: char.text,
                    items: [
                      {
                        type: 'cardcontainer',
                        direction: 'vertical',
                        items: [
                          {
                            type: 'cardtext',
                            text: char.text,
                            name: 'char_name',
                          },
                        ],
                      },
                    ],
                  }))
                  resolve(results)
                })
              },
              // fetch: function (pattern) {
              //   return new tinymce.util.Promise(function (resolve) {
              //     var results = getMatchedChars(pattern).map(function (char) {
              //       return {
              //         type: 'autocompleteitem',

              //         value: char.value,
              //         text: char.text,
              //         icon: char.value
              //       }
              //     });
              //     resolve(results);
              //   });
              // }
            })
          },
          style_formats: formats,
          toolbar:
            'undo redo | image | table | styles | ' +
            'bold italic forecolor backcolor | alignleft aligncenter ' +
            'alignright | bullist numlist outdent indent',
        }}
      />
    </div>
  )
}

export default TinyEditor
