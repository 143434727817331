import React, { useEffect, useState } from 'react'
import {Button, Form, Input, Modal, Space, Table, Tabs, Select} from 'antd'
import {
  DeleteOutlined, DollarOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import {ProductService, AuthService, PageService, PageServiceService} from 'services'
import { get } from 'lodash'
import moment from 'moment'
import {useNavigate} from "react-router-dom";

const { TabPane } = Tabs
const { Option } = Select

export default function NetWealthElement({ preview, netwealth_type }) {
  const data_config = {
    type_id: netwealth_type == "asset" ? "9021be88-1c47-4ca2-88db-db88bbff227f" : "f53af184-becd-40b5-b12d-b6c60077c287",
    subtype_id: netwealth_type == "asset" ? "1272529a-27b0-4482-8e9d-970fde5258fa" : "6fadc5ea-095f-48a7-ad1f-2aa5a84432d5"
  }
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [investment_options_list, setInvestmentOptionsList] = useState([])
  const [entity_data, setEntityData] = useState({})
  const [product_list, setProductList] = useState([])
  const [provider, setProvider] = useState("")
  const [advise_for_option, setAdviseForOption] = useState({})
  const [type_option, setTypeOption] = useState({})
  const [subtype_option, setSubtypeOption] = useState({})

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let entity = null
  if (entity_type == 'client') {
    entity = params.get('entity')
  }

  const getType = async (id) => {
      const res = await PageServiceService.FieldDetail(data_config.type_id)
      setTypeOption(get(res, 'data.data', {}))
  }

  const getsubType = async (id) => {
      const res = await PageServiceService.FieldDetail(data_config.subtype_id)
     setSubtypeOption(get(res, 'data.data', {}))
  }

  const getEntityData = async () => {
    const res = await AuthService.detailEntity(entity)
    const new_data = get(res, 'data.data', {})
    getInvestmentOptionList(new_data.business, false)
    setEntityData(new_data)
    return new_data
  }

  useEffect(() => {
    getData()
    getEntityData()
    GetAdviseForOptionsData()
    getType()
    getsubType()
  }, [])

   useEffect(() => {
     getProductList(entity_data.business, false,provider)
  }, [entity_data, provider])

  const getInvestmentOptionList = async (business, is_for_investment) => {
    const res = await ProductService.getProvider({business, is_for_investment, netwealth_type})
    setInvestmentOptionsList(get(res, 'data.data', []))
  }

  const getProductList = async (business, is_for_investment, provider) => {
    const res = await ProductService.getProduct({business, is_for_investment, provider, netwealth_type})
    setProductList(get(res, 'data.data', []))
  }

  let navigate = useNavigate()

  const onClickOption = (record) => {
      navigate(`/net-wealth-option/?account=${get(record, 'account.id')}&account_number=${record.account_number}&entity=${entity}&entity_type=${entity_type}&netwealth_type=${netwealth_type}&obj_id=${record.id}&provider=${get(record, 'provider.id')}`)
  }

  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.type.label}
        </div>
      ),
    },
    {
      title: 'Subtype',
      dataIndex: 'subtype',
      key: 'subtype',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.subtype.label}
        </div>
      ),
    },
    {
      title: 'Provider',
      dataIndex: 'provider',
      key: 'provider',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.provider && e.provider.subject}
        </div>
      ),
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.account && e.account.subject}
        </div>
      ),
    },
    {
      title: 'Account number',
      dataIndex: 'account_number',
      key: 'account_number',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.account_number}
        </div>
      ),
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<DollarOutlined />} size="medium" onClick={() => onClickOption(record)} />
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
        </Space>
      ),
    },
  ]

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
    setProvider("")
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    let res;
    await setId(id)
    await setIsModalVisible(true)
    if (netwealth_type === "asset") {
      res = await AuthService.getDetailAsset(id)
    }else {
      res = await AuthService.getDetailLiability(id)
    }
    setProvider(get(res, 'data.data.provider'))
    formik.setValues(get(res, 'data.data'))
    formik.setFieldValue("ownerValues",  Object.keys(get(res, 'data.data.owners')))
  }

  const deleteData = async (id) => {
    const result = window.confirm(`Are you sure to delete this ${netwealth_type}?`)
    if (result) {
      if (netwealth_type === "asset") {
        await AuthService.deleteAsset(id)
      }else {
        await AuthService.deleteLiability(id)
      }
      getData()
    }
  }

  const getData = async () => {
      let res_netwealth_data; // Declare res outside the conditional block

      if (netwealth_type === "asset") {
        res_netwealth_data = await AuthService.getAsset({
          user: entity,
        });
      } else {
        res_netwealth_data = await AuthService.getLiability({
          user: entity,
        });
      }

      // Assuming you are using lodash's get method to safely access the data
      setData(get(res_netwealth_data, 'data.data', []));
    };

  let schema = yup.object().shape({
    type: yup.object().required("type required"),
    subtype: yup.object().required("subtype required"),
    provider: yup.string().required("provider required"),
    account: yup.string().required("account required"),
    account_number: yup.string().required("account_number required"),
    balance: yup.number().required("gross_amount required"),
  })

  const initialValues = {
    provider: "",
    account: "",
    balance: 0,
    owners: {},
    ownerValues:[],
    type: {},
    subtype: {}
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['user'] = entity
      if (netwealth_type === "asset") {
        if (id) {
            await AuthService.updateAsset(values, id)
        } else {
          await AuthService.saveAsset(values)
        }
      }else {
        if (id) {
            await AuthService.updateLiability(values, id)
        } else {
          await AuthService.saveLiability(values)
        }
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const GetAdviseForOptionsData = async () => {
    const res = await PageService.getAdviseForOption(entity)
    const res_data =  get(res, 'data.data')
    setAdviseForOption(res_data)
  }


  const onChangeAccount  = (value)=>{
    formik.setFieldValue("account", value)
  }

  const onChangeInvestmentOption  = (value)=>{
    formik.setFieldValue("provider", value)
    setProvider(value)
    // formik.setFieldValue("price", option.latest_price)
  }

  const onChangeOwners  = (value)=>{
    formik.setFieldValue("ownerValues", value)
    // formik.setFieldValue("price", option.latest_price)
  }

  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add {netwealth_type}
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? `Edit ${netwealth_type}` : `Add ${netwealth_type}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Type">
                <Select
                  showSearch
                  mode="single"
                  name="type"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select type"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.type.value}
                  onChange={(value,option) => formik.setFieldValue("type", {"label": option.children, "value": value})}
                >
                  {get(subtype_option, 'options',[]).map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                  {formik.touched.type && formik.errors.type && (
                    <span className="input-error-message">{formik.errors.type}</span>
                  )}
              </Form.Item>
              <Form.Item label="Subtype">
                <Select
                  showSearch
                  mode="single"
                  name="subtype"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select subtype"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.subtype.value}
                  onChange={(value,option) => formik.setFieldValue("subtype", {"label": option.children, "value": value})}
                >
                  {get(type_option, 'options',[]).map((item) => (
                    <Option key={item.id} value={item.value}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
                  {formik.touched.subtype && formik.errors.subtype && (
                    <span className="input-error-message">{formik.errors.subtype}</span>
                  )}
              </Form.Item>
              <Form.Item label="Provider">
                <Select
                  showSearch
                  mode="single"
                  name="provider"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select provider"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.provider}
                  onChange={(value) => onChangeInvestmentOption(value)}
                >
                  {investment_options_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.provider && formik.errors.provider && (
                  <span className="input-error-message">{formik.errors.provider}</span>
                )}
              </Form.Item>
              <Form.Item label="Account">
                <Select
                  showSearch
                  mode="single"
                  name="account"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select account"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.account}
                  onChange={(value) => onChangeAccount(value)}
                >
                  {product_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.account && formik.errors.account && (
                  <span className="input-error-message">{formik.errors.account}</span>
                )}
              </Form.Item>
              <Form.Item label="Account number">
                <Input
                  style={{ width: '100%' }}
                  placeholder="account_number"
                  name="account_number"
                  onChange={formik.handleChange}
                  value={formik.values.account_number}
                />
                {formik.touched.account_number && formik.errors.account_number && (
                  <span className="input-error-message">{formik.errors.account_number}</span>
                )}
              </Form.Item>
              <Form.Item label="Owners">
                <Select
                  showSearch
                  mode="multiple"
                  name="ownerValues"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select owners"
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase().includes(input.toLowerCase())
                  }
                  value={formik.values.ownerValues}
                  onChange={(value) => onChangeOwners(value)}
                >
                  {Object.entries(advise_for_option).map(([key, value]) => (
                    <Option key={key} value={key}>
                      {value}
                    </Option>
                  ))}
                </Select>
                 {formik.values.ownerValues && formik.values.ownerValues.map((ownerKey) => (
                    <div key={ownerKey} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      {/* Label showing the owner key */}
                      <Form.Item label={get(advise_for_option, ownerKey, "")}>
                      </Form.Item>
                      <Form.Item>
                        <Input
                          type="number"
                          value={formik.values.owners[ownerKey]}  // Assuming values are in formik.values.ownerValues
                          onChange={(e) => {
                            const updatedValues = { ...formik.values.owners, [ownerKey]: e.target.value };
                            formik.setFieldValue('owners', updatedValues);  // Update formik state with the new value
                          }}
                        />
                      </Form.Item>
                    </div>
                  ))}
              </Form.Item>
              <Form.Item label="Balance">
                <Input
                  style={{ width: '100%' }}
                  placeholder="balance"
                  type="number"
                  name="balance"
                  onChange={formik.handleChange}
                  value={formik.values.balance}
                />
                {formik.touched.balance && formik.errors.balance && (
                  <span className="input-error-message">{formik.errors.balance}</span>
                )}
              </Form.Item>
            </Form>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}

// A  > main asset
// B > element asset
//
// if A is creating, then it also in B, vice versa, one tranction > summarising in option

