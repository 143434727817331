import { createSlice } from '@reduxjs/toolkit'
import { get } from 'lodash'

const initialState = {
  token: null,
  details: null,
  currentAddress: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.token = get(payload, 'token.access_token', null)
      state.details = get(payload, 'data', null)
    },
    deleteUser: (state) => {
      localStorage.clear()
      state.token = null
      state.details = null
    },
    updateuser: (state, { payload }) => {},
  },
})

// Action creators are generated for each case reducer function
export const { setUser, deleteUser, updateuser } = userSlice.actions

export default userSlice.reducer
