import { Tooltip, Checkbox, Form, Space, Select, Input } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { PageService } from 'services'
import { get } from 'lodash'
import SingleSelectChart from 'Components/Charts/selectCharts'
import BarMultiSelectChart from 'Components/Charts/barMultiSelect'
import { ReactSortable } from 'react-sortablejs'
import { getAdviseForOptions } from 'services/advise_for'

const { Option } = Select
const MultipleSelect = (props) => {
  const preview = document.URL.includes('preview')
  const [options, setOptions] = useState([])
  const [first, setFirst] = useState(true)

  // const entity = useSelector((state) => state.previewUser.details.id);
  const option_data = useSelector((state) => state.entityReducer)
  const [inList, setInList] = useState([])
  const [outList, setOutList] = useState([])
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity = params.get('entity')
    ? params.get('entity')
    : preview && previewUser?.details
      ? previewUser?.details?.id
      : user?.details?.id
  const business = preview ? previewUser?.details?.business : user?.details?.business
  let _entity = props.data_source_for == 'business' ? business : entity
  useEffect(() => {
    if (props.options_type && first) {
      setFirst(false)
      if (props.options_type === 'advise_for') {
        getAdviseForOptions(entity).then((res) => {
          const optionsObj = res?.data?.data || {}
          const objKeys = Object.keys(optionsObj)
          let options = objKeys.map((item) => ({
            value: item,
            name: optionsObj[item],
          }))
          setOptions(options)
        })
      } else if (props.options_type == 'user') {
        setOptions([
          ...option_data.user.map((item) => ({
            value: item.id,
            name: item.name,
          })),
        ])
      } else if (props.options_type == 'client') {
        setOptions([
          ...option_data.client.map((item) => ({
            value: item.id,
            name: item.name,
          })),
        ])
      } else if (props.options_type == 'business') {
        setOptions([
          ...option_data.business.map((item) => ({
            value: item.id,
            name: item.business_name,
          })),
        ])
      } else if (props.options_type == 'group') {
        PageService.getGroupDropdown({ entity: _entity, field: props.id }).then((res) => {
          setOptions(get(res, 'data.data', []))
        })
      } else if (props.options_type == 'form') {
        PageService.getFormDropdown({ entity: _entity, field: props.id }).then((res) => {
          setOptions(get(res, 'data.data', []))
        })
      }
    }
    // setInList(updatedOptions)
    // setOutList(updatedOptions)
  }, [props])
  useEffect(() => {
    if (props?.options_type == 'custom') {
      setOptions(
        props.dependsOn && props.dependantValue
          ? props.filtering.values[props.dependantValue].options
          : props.options
      )
    }
  }, [props, props.dependantValue])

  useEffect(() => {
    let inListOp = []
    let outListOp = []

    options?.map((item) => {
      if (options?.selectedOptions?.includes?.(item.value)) {
        inListOp.push(item)
      } else {
        outListOp.push(item)
      }
    })
    setInList(inListOp)
    setOutList(outListOp)
  }, [options])

  let optionsObj = {}
  options.map((item) => (optionsObj[item.value] = item.name))

  return (
    <Form.Item>
      {props.display === 'dropdown' &&
        (props.layout === 'horizontal' ? (
          <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
            {!props.exclude_label ? (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{ minWidth: '120px' }}
                placeholder="Please select"
                disabled={props.isReadOnly}
                value={props.value}
                onChange={props.onChange}
              >
                {options?.map?.((i, index) => {
                  return (
                    <Option key={index} value={i.value}>
                      {i.name} {props.showValues ? i.value : null}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
        ) : (
          <div className="form_field">
            {!props.exclude_label ? (
              <label style={{ display: 'block' }}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{ minWidth: '120px' }}
                placeholder="Please select"
                disabled={props.isReadOnly}
                value={props.value}
                onChange={props.onChange}
              >
                {options?.map?.((i, index) => {
                  return (
                    <Option key={index} value={i.value}>
                      {i.name} {props.showValues ? i.value : null}
                    </Option>
                  )
                })}
              </Select>
            </div>
          </div>
        ))}

      {props.display === 'button' &&
        (props.layout === 'horizontal' ? (
          <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
            {!props.exclude_label ? (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Checkbox.Group
                disabled={props.isReadOnly}
                value={props.value}
                onChange={props.onChange}
              >
                <Space direction="horizontal">
                  {options?.map?.((i, index) => {
                    return (
                      <Checkbox key={index} value={i.value}>
                        {i.name} {props.showValues ? i.value : null}
                      </Checkbox>
                    )
                  })}
                </Space>
              </Checkbox.Group>
            </div>
          </div>
        ) : (
          <div className="form_field">
            {!props.exclude_label ? (
              <label style={{ marginBottom: 10, display: 'block' }}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Checkbox.Group
                disabled={props.isReadOnly}
                value={props.value}
                onChange={props.onChange}
              >
                <Space direction="vertical">
                  {options?.map?.((i, index) => {
                    return (
                      <Checkbox key={index} value={i.value}>
                        {i.name} {props.showValues ? i.value : null}
                      </Checkbox>
                    )
                  })}
                </Space>
              </Checkbox.Group>
            </div>
          </div>
        ))}

      {(props.display === 'percentage' || props.options_type === 'advise_for') &&
        (props.layout === 'horizontal' ? (
          <div className={props.isGroup ? 'form_field center_flex' : 'form_field inline_field'}>
            {!props.exclude_label ? (
              <label style={!props.isGroup ? { width: '20%' } : null}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{ minWidth: '120px' }}
                placeholder="Please select"
                disabled={props.isReadOnly}
                value={props.value ? Object.keys(props.value) : []}
                onDeselect={(val) => {
                  let obj = { ...props.value }
                  delete obj[val]
                  props.onChange(obj)
                }}
                onChange={(val) => {
                  let obj = props.value ? { ...props.value } : {}
                  val.map((item) => {
                    if (!obj[item]) {
                      obj[item] = ''
                    }
                  })
                  props.onChange(obj)
                }}
              >
                {options?.map?.((i, index) => {
                  return (
                    <Option key={index} value={i.value}>
                      {i.name} {props.showValues ? i.value : null}
                    </Option>
                  )
                })}
              </Select>
              <div className="nested_values">
                {props.value &&
                  Object.keys(props.value).map((item) => (
                    <div style={{ display: 'flex', marginBottom: 4 }}>
                      <span style={{ width: '20%' }}>{optionsObj[item]}</span>
                      <Input
                        placeholder="percentage %"
                        value={props.value[item]}
                        onChange={(e) => {
                          let obj = { ...props.value }
                          obj[item] = e.target.value
                          props.onChange(obj)
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="form_field">
            {!props.exclude_label ? (
              <label style={{ marginBottom: 10, display: 'block' }}>
                {props.displayName ? props.displayName : props.name}{' '}
                {props.tooltip ? (
                  <Tooltip title={props.tooltip}>
                    <span>
                      <InfoCircleOutlined />
                    </span>
                  </Tooltip>
                ) : null}{' '}
                {props.isRequired ? <span className="red">*</span> : null}
              </label>
            ) : null}
            <div>
              <Select
                mode="multiple"
                allowClear
                style={{ minWidth: '120px' }}
                placeholder="Please select"
                disabled={props.isReadOnly}
                value={props.value ? Object.keys(props.value) : []}
                onDeselect={(val) => {
                  let obj = { ...props.value }
                  delete obj[val]
                  props.onChange(obj)
                }}
                onChange={(val) => {
                  let obj = props.value ? { ...props.value } : {}
                  val.map((item) => {
                    if (!obj[item]) {
                      obj[item] = ''
                    }
                  })
                  props.onChange(obj)
                }}
              >
                {options?.map?.((i, index) => {
                  return (
                    <Option key={index} value={i.value}>
                      {i.name} {props.showValues ? i.value : null}
                    </Option>
                  )
                })}
              </Select>
              <div className="nested_values">
                {props.value &&
                  Object.keys(props.value).map((item) => (
                    <div style={{ display: 'flex', marginBottom: 4 }}>
                      <span style={{ width: '20%' }}>{optionsObj[item]}</span>
                      <Input
                        placeholder="percentage %"
                        value={props.value[item]}
                        onChange={(e) => {
                          let obj = { ...props.value }
                          obj[item] = e.target.value
                          props.onChange(obj)
                        }}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ))}

      {props.display === 'pie' && (
        <SingleSelectChart chartType={'pie'} multi={true} dt={props.options} />
      )}

      {props.display === 'donut' && (
        <SingleSelectChart
          showScore={true}
          multi={true}
          chartType={'doughnut'}
          dt={props.options}
        />
      )}

      {props.display === 'bar' && (
        <BarMultiSelectChart showScore={props.showScore} dt={props.options} />
      )}
      {props.display === 'in_out_scope' && (
        <div className="scoped_wrapper">
          <div className="scoped_list">
            <h3>Out Scope</h3>
            <ReactSortable
              group="in_out_scope"
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={outList}
              setList={setOutList}
              className="in_out_scope_droppable_area"
            >
              {outList?.map((item) => {
                return (
                  <div key={item.id} className="in_out_item">
                    {item.name}
                  </div>
                )
              })}
            </ReactSortable>
          </div>
          <div className="scoped_list">
            <h3>In Scope</h3>
            <ReactSortable
              group="in_out_scope"
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              list={inList}
              setList={setInList}
              className="in_out_scope_droppable_area"
            >
              {inList?.map((item) => {
                return (
                  <div key={item.id} className="in_out_item">
                    {item.name}
                  </div>
                )
              })}
            </ReactSortable>
          </div>
        </div>
      )}
    </Form.Item>
  )
}

export default MultipleSelect
