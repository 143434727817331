import React, { Component, useEffect, useState } from 'react'
import { Checkbox, Progress } from 'antd'
import { useSelector } from 'react-redux'
import { ElementService } from '../../services'
import { FuncTool } from 'utils'
import { get } from 'lodash'
// import { CustomLine } from 'Components';
const ProgressBar = ({ element, preview, wizard_history, wizard_is_locked, show_title }) => {
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const entity = preview ? params.get('entity') : user?.details?.id

  const [data, setData] = useState([])
  const [all_data, setAllData] = useState([])
  const [total, setTotal] = useState([])
  const [primary_filter_list, setPrimaryFilterList] = useState([])
  const [secondary_filter_obj, setSecondaryFilterObj] = useState({})
  const [show_options_icon, SetShowOptionsIcon] = useState({
    primary: {},
    secondary: {},
  })

  const [primary_filter, setPrimaryFilter] = useState({})
  const [secondary_filter, setSecondaryFilter] = useState({})
  const [selected_filter, setSelected] = useState({})
  const [selected_checkbox, setSelectedCheckBox] = useState([])

  const ElementChartData = async () => {
    try {
      const res = await ElementService.ElementChartDataList({
        element: get(element, 'element.id'),
        entity: entity,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
      const primary_res_data = get(res, 'data.data.primary_filter', {})
      const secondary_res_data = get(res, 'data.data.secondary_filter', {})

      setPrimaryFilter(primary_res_data)
      setSecondaryFilter(secondary_res_data)

      setPrimaryFilterList(get(res, 'data.data.primary_filter_list', []))
      setSecondaryFilterObj(get(res, 'data.data.secondary_filter_obj', {}))
      const all_data = get(res, 'data.data.all_data', [])
      setData(all_data)
      setAllData(all_data)
      let current_total = 0
      all_data.map((item) => (current_total += item.value))
      setTotal(current_total)

      SetShowOptionsIcon(get(res, 'data.data.show_options_icon'))

      const first_primary_key = get(res, 'data.data.primary_filter_list', [])[0]

      if (first_primary_key) {
        let first_filtered_data = []
        if (primary_res_data[first_primary_key.id]) {
          for (const [key, value] of Object.entries(
            get(primary_res_data[first_primary_key.id], 'values', [])
          )) {
            first_filtered_data.push({ type: key, value: value })
          }
        }
        // await setConfig((prevState) => ({
        //   ...prevState,
        //   data: first_filtered_data,
        // }));
        setSelected({ type: 'primary', value: first_primary_key.id })
      }
    } catch (err) {}

    // for (const [key, value] of Object.entries(primary_res_data)) {
    //   console.log(`${key}: ${value}`);
    // }
  }

  useEffect(() => {
    ElementChartData()
  }, [])

  const hasAnyCommonItem = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item))
  }

  const isObjEmpty = (obj) => {
    return Object.keys(obj).length === 0
  }

  const get_icon = (item) => {
    if (isObjEmpty(show_options_icon.secondary)) {
      return get(show_options_icon, 'primary.' + item.primary[0])
    } else {
      return get(show_options_icon, 'secondary.' + item.secondary)
    }
  }

  const progress_bar_data = () => {
    return data.map((item) => (
      <div key={item.name} className="aspiration_item">
        <div className="expand_flex">
          <div className="spaced_div">
            <div>
              <span className="material-icons-outlined">{get_icon(item)}</span>
              <h6>{item.type}</h6>
              <span>{item.description}</span>
            </div>
            <div>
              <span>
                ${FuncTool.formatNumberWithCommasAndDecimals(item.value)} of $
                {FuncTool.formatNumberWithCommasAndDecimals(total)}
              </span>
            </div>
          </div>
          <Progress percent={(Number(item.value) * 100) / total} />
        </div>
      </div>
    ))
  }

  const handleCheckboxChange = (value) => {
    let filtered_data = all_data
    setSelectedCheckBox(value)
    if (value.length > 0) {
      filtered_data = all_data.filter((item) => hasAnyCommonItem(value, item.primary))
    }
    setData(filtered_data)
  }

  return (
    <div className="aspirations_list">
      {show_title !== false && (
        <h1 style={{ textAlign: 'center' }}>{get(element, 'element.title')}</h1>
      )}

      {selected_filter.type == 'primary' && primary_filter[selected_filter.value] && (
        <div>
          <Checkbox.Group
            options={Object.keys(primary_filter[selected_filter.value].options).map((key) => ({
              label: primary_filter[selected_filter.value].options[key],
              value: key,
            }))}
            onChange={handleCheckboxChange}
            value={selected_checkbox}
          />
        </div>
      )}

      {progress_bar_data()}
      {/*<div className="aspiration_item">*/}
      {/*  <div className="expand_flex">*/}
      {/*    <div className="spaced_div">*/}
      {/*      <div>*/}
      {/*        <h6>New Car</h6>*/}
      {/*        <span>2016</span>*/}
      {/*      </div>*/}
      {/*      <div>*/}
      {/*        <h6>Spent</h6>*/}
      {/*        <span>$31,213 of $55,000</span>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    <Progress percent={30} />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )
}

export default ProgressBar
