import React from 'react'
import { HomeLayout } from 'Components'
import './contact-us.scss'
import { useEffect, useState } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash/object'
export default function ContactUs() {
  const [company_email, SetEmail] = useState('')
  const [company_phone_number, SetPhone] = useState('')
  const [facebook, SetFacebook] = useState('')
  const [instagram, SetInstagram] = useState('')
  const [twitter, SetTwitter] = useState('')
  const [linkedin, SetLinkedin] = useState('')
  const [whatsapp, SetWhatapp] = useState('')
  const [youtube, SetYoutube] = useState('')
  const [address, SetAddress] = useState('')

  const [name, SetName] = useState('')
  const [email, SetContactEmail] = useState('')
  const [phone_number, SetContactPhone] = useState('')
  const [message, SetMessage] = useState('')

  const contactDetail = async () => {
    const res = await StaticPageService.CompanyContactGet()
    SetEmail(get(res, 'data.data.email'))
    SetPhone(get(res, 'data.data.phone_number'))
    SetFacebook(get(res, 'data.data.facebook'))
    SetInstagram(get(res, 'data.data.instagram'))
    SetLinkedin(get(res, 'data.data.linkedin'))
    SetTwitter(get(res, 'data.data.twitter'))
    SetWhatapp(get(res, 'data.data.whatsapp'))
    SetYoutube(get(res, 'data.data.youtube'))
    SetAddress(get(res, 'data.data.address'))
  }

  useEffect(() => {
    contactDetail()
  }, [])

  const OnSubmit = async (e) => {
    e.preventDefault()
    await StaticPageService.ContactUsPost({
      name,
      email,
      phone_number,
      message,
    })
  }

  return (
    <HomeLayout>
      <div className="public_pages">
        <div className="container">
          <div className="contact_card">
            <div className="contact_info">
              <h1>How can we help?</h1>
              <p>
                Get free professional support from <span>MetAdvise</span> support team, without
                wasting time in figuring out solutions yourself.
              </p>

              <div className="contact_methods">
                {company_phone_number && (
                  <a href={'tel:' + company_phone_number}>
                    <span className="material-icons-outlined">phone</span>
                    {company_phone_number}
                  </a>
                )}
                {company_email && (
                  <a href={'mailto:' + company_email}>
                    <span className="material-icons-outlined">email</span>
                    {company_email}
                  </a>
                )}
              </div>
              {address && <p>{address}</p>}
              <div className="contact_methods">
                {facebook && (
                  <a target="_blank" rel="noopener noreferrer" href={facebook}>
                    <span className="material-icons-outlined">facebook</span>
                  </a>
                )}
                {twitter && (
                  <a target="_blank" rel="noopener noreferrer" href={facebook}>
                    Twitter
                  </a>
                )}
                {whatsapp && (
                  <a target="_blank" rel="noopener noreferrer" href={'https://wa.me/' + whatsapp}>
                    <span className="material-icons-outlined">whatsapp</span>
                  </a>
                )}

                {youtube && (
                  <a target="_blank" rel="noopener noreferrer" href={youtube}>
                    <span className="material-icons-outlined">subscriptions</span>
                  </a>
                )}
                {instagram && (
                  <a target="_blank" rel="noopener noreferrer" href={instagram}>
                    Instagram
                  </a>
                )}
                {linkedin && (
                  <a target="_blank" rel="noopener noreferrer" href={linkedin}>
                    LinkedIn
                  </a>
                )}
              </div>
            </div>
            <form className="base_form" onSubmit={OnSubmit}>
              <div className="form_group" style={{ marginTop: 0 }}>
                <label className="form_label">Name</label>
                <input
                  name="name"
                  type="text"
                  required
                  value={name}
                  onChange={(e) => SetName(e.target.value)}
                />
              </div>
              <div className="form_group" style={{ marginTop: 20 }}>
                <label className="form_label">Email Address</label>
                <input
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => SetContactEmail(e.target.value)}
                />
              </div>
              <div className="form_group" style={{ marginTop: 20 }}>
                <label className="form_label">Phone</label>
                <input
                  name="phone"
                  type="text"
                  required
                  value={phone_number}
                  onChange={(e) => SetContactPhone(e.target.value)}
                />
              </div>
              <div className="form_group" style={{ marginTop: 20 }}>
                <label className="form_label">Message</label>
                <textarea
                  name="message"
                  rows={3}
                  required
                  value={message}
                  onChange={(e) => SetMessage(e.target.value)}
                />
              </div>
              <button style={{ marginTop: '10px' }} type="submit" className="primary_form">
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}
