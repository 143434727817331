import React, { useEffect, useState } from 'react'
import { Button, Form, Input, InputNumber, Select, Divider, Modal } from 'antd'
import './table.scss'
import { ThemeService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const borderStyles = [
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset',
  'hidden',
]

const fontFamilies = ['calibri', 'arial', 'roboto']

const SectionForm = ({ title, fieldPrefix }) => (
  <div className="table_config">
    <h3>{title}</h3>
    <Form.Item label="Background Color" name={[fieldPrefix, 'backgroundColor']}>
      <Input type="color" />
    </Form.Item>
    <Form.Item label="Font Color" name={[fieldPrefix, 'fontColor']}>
      <Input type="color" />
    </Form.Item>
    <Form.Item label="Font Family" name={[fieldPrefix, 'fontFamily']}>
      <Select>
        {fontFamilies.map((style) => (
          <Select.Option key={style} value={style}>
            {style}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
    <div className="config_group">
      <Form.Item label="Left Border Color" name={[fieldPrefix, 'leftBorderColor']}>
        <Input type="color" />
      </Form.Item>
      <Form.Item label="Left Border Size" name={[fieldPrefix, 'leftBorderSize']}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Left Border Style" name={[fieldPrefix, 'leftBorderStyle']}>
        <Select>
          {borderStyles.map((style) => (
            <Select.Option key={style} value={style}>
              {style}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
    <div className="config_group">
      <Form.Item label="Right Border Color" name={[fieldPrefix, 'rightBorderColor']}>
        <Input type="color" />
      </Form.Item>
      <Form.Item label="Right Border Size" name={[fieldPrefix, 'rightBorderSize']}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Right Border Style" name={[fieldPrefix, 'rightBorderStyle']}>
        <Select>
          {borderStyles.map((style) => (
            <Select.Option key={style} value={style}>
              {style}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
    <div className="config_group">
      <Form.Item label="Top Border Color" name={[fieldPrefix, 'topBorderColor']}>
        <Input type="color" />
      </Form.Item>
      <Form.Item label="Top Border Size" name={[fieldPrefix, 'topBorderSize']}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Top Border Style" name={[fieldPrefix, 'topBorderStyle']}>
        <Select>
          {borderStyles.map((style) => (
            <Select.Option key={style} value={style}>
              {style}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
    <div className="config_group">
      <Form.Item label="Bottom Border Color" name={[fieldPrefix, 'bottomBorderColor']}>
        <Input type="color" />
      </Form.Item>
      <Form.Item label="Bottom Border Size" name={[fieldPrefix, 'bottomBorderSize']}>
        <InputNumber />
      </Form.Item>
      <Form.Item label="Bottom Border Style" name={[fieldPrefix, 'bottomBorderStyle']}>
        <Select>
          {borderStyles.map((style) => (
            <Select.Option key={style} value={style}>
              {style}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  </div>
)

const TableConfig = ({ preview }) => {
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)

  const defaultFormValues = {
    header: {
      backgroundColor: '#ffffff',
      fontColor: '#000000',
      fontFamily: 'calibri',
      leftBorderColor: '#000000',
      leftBorderSize: 1,
      leftBorderStyle: 'solid',
      rightBorderColor: '#000000',
      rightBorderSize: 1,
      rightBorderStyle: 'solid',
      topBorderColor: '#000000',
      topBorderSize: 1,
      topBorderStyle: 'solid',
      bottomBorderColor: '#000000',
      bottomBorderSize: 1,
      bottomBorderStyle: 'solid',
    },
    subHeader: {
      backgroundColor: '#ffffff',
      fontColor: '#000000',
      fontFamily: 'calibri',
      leftBorderColor: '#000000',
      leftBorderSize: 1,
      leftBorderStyle: 'solid',
      rightBorderColor: '#000000',
      rightBorderSize: 1,
      rightBorderStyle: 'solid',
      topBorderColor: '#000000',
      topBorderSize: 1,
      topBorderStyle: 'solid',
      bottomBorderColor: '#000000',
      bottomBorderSize: 1,
      bottomBorderStyle: 'solid',
    },
    body: {
      backgroundColor: '#ffffff',
      fontColor: '#000000',
      fontFamily: 'calibri',
      leftBorderColor: '#000000',
      leftBorderSize: 1,
      leftBorderStyle: 'solid',
      rightBorderColor: '#000000',
      rightBorderSize: 1,
      rightBorderStyle: 'solid',
      topBorderColor: '#000000',
      topBorderSize: 1,
      topBorderStyle: 'solid',
      bottomBorderColor: '#000000',
      bottomBorderSize: 1,
      bottomBorderStyle: 'solid',
    },
  }

  const [currentStyleConfig, setCurrentStyleConfig] = useState(defaultFormValues)

  const onValuesChange = (changedValues, allValues) => {
    setCurrentStyleConfig(allValues)
  }

  const generateInlineStyles = (section) => {
    const styleConfig = currentStyleConfig[section]
    return {
      fontFamily: styleConfig.fontFamily,
      color: styleConfig.fontColor,
      backgroundColor: styleConfig.backgroundColor,
      borderLeft: `${styleConfig.leftBorderSize}px ${styleConfig.leftBorderStyle} ${styleConfig.leftBorderColor}`,
      borderRight: `${styleConfig.rightBorderSize}px ${styleConfig.rightBorderStyle} ${styleConfig.rightBorderColor}`,
      borderTop: `${styleConfig.topBorderSize}px ${styleConfig.topBorderStyle} ${styleConfig.topBorderColor}`,
      borderBottom: `${styleConfig.bottomBorderSize}px ${styleConfig.bottomBorderStyle} ${styleConfig.bottomBorderColor}`,
    }
  }

  const [form] = Form.useForm()

  const onSubmit = async (values) => {
    const data = { config: values, business: previewUser?.details?.id }
    const res = ThemeService.saveTableConfig(data)
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const res = await ThemeService.getTableConfig({
          business: previewUser?.details?.id,
        })
        const config = get(res, 'data.data.config', null) // Fallback to `null` if not found
        if (!config) {
          config = defaultFormValues
        }
        form.setFieldsValue(config)
        setCurrentStyleConfig(config)
      } catch (error) {
        console.error('Error fetching config:', error)
        // If there is an error fetching, set the default values
        form.setFieldsValue(defaultFormValues)
        setCurrentStyleConfig(defaultFormValues)
      }
    }

    loadData()
  }, [form, previewUser?.details?.id])

  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const handleOk = () => {
    setIsModalOpen(false)
  }

  const handleCancel = () => {
    setIsModalOpen(false)
  }

  return (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <Form form={form} onFinish={onSubmit} onValuesChange={onValuesChange}>
        <SectionForm title="Header" fieldPrefix="header" />
        <Divider />
        <SectionForm title="Sub Header" fieldPrefix="subHeader" />
        <Divider />
        <SectionForm title="Body" fieldPrefix="body" />
        <Divider />
        <Form.Item>
          <Button type="default" size="large" onClick={showModal} style={{ marginRight: 10 }}>
            Preview
          </Button>
          <Button type="primary" htmlType="submit" size="large">
            Update Table Config
          </Button>
        </Form.Item>
      </Form>
      <Modal title="Table Preview" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <div className="pdf_page">
          <table style={{ width: '100%' }}>
            <thead>
              <tr style={generateInlineStyles('header')}>
                <th style={generateInlineStyles('header')}>Name</th>
                <th style={generateInlineStyles('header')}>Age</th>
                <th style={generateInlineStyles('header')}>Gender</th>
                <th style={generateInlineStyles('header')}>Country</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={generateInlineStyles('subHeader')}>Ravi</td>
                <td style={generateInlineStyles('body')}>30</td>
                <td style={generateInlineStyles('body')}>Male</td>
                <td style={generateInlineStyles('body')}>India</td>
              </tr>
              <tr>
                <td style={generateInlineStyles('subHeader')}>Shyam</td>
                <td style={generateInlineStyles('body')}>32</td>
                <td style={generateInlineStyles('body')}>Male</td>
                <td style={generateInlineStyles('body')}>India</td>
              </tr>
              <tr>
                <td style={generateInlineStyles('subHeader')}>Shivani</td>
                <td style={generateInlineStyles('body')}>25</td>
                <td style={generateInlineStyles('body')}>Female</td>
                <td style={generateInlineStyles('body')}>India</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  )
}

export default TableConfig
