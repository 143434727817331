import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Row, Col, Select, Tabs, Checkbox } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { GoalService } from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'
import CustomTree from 'Components/CustomTree/CustomTree'

const { Option } = Select
const { TabPane } = Tabs
const CheckboxGroup = Checkbox.Group

export default function ObjectiveGoal({ preview, business_list }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [area_list, setAreaList] = useState([])
  const [webLinks, setWebLinks] = useState([])
  const [videoLinks, setVideoLinks] = useState([])
  const [permissions, setPermissions] = useState({})
  const [activeKey, setActiveKey] = useState('1')

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Area',
      dataIndex: 'area',
      key: 'area',
      sorter: (a, b) => {
        if (a.area && b.area) {
          return a.area.subject.localeCompare(b.area.subject)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Area</h6>
          {record.area ? record.area.subject : ''}
        </div>
      ),
    },
    {
      title: 'Objective',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Objective</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setWebLinks([])
    setVideoLinks([])
    setPermissions({})
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
    getAreaList()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    getAreaList()
    await setId(id)
    await setIsModalVisible(true)
    const res = await GoalService.getDetailGoal(id)
    formik.setValues(get(res, 'data.data'))
    setWebLinks(get(res, 'data.data.web_links', []))
    setVideoLinks(get(res, 'data.data.video_links', []))
    setPermissions(get(res, 'data.data.permissions', {}))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this objective?')
    if (result) {
      await GoalService.deleteGoal(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await GoalService.getGoal({ business: business })
    setData(get(res, 'data.data', []))
  }

  const getAreaList = async () => {
    const res = await GoalService.getArea({ business: business })
    setAreaList(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    area: yup.string().required('Area Required'),
    subject: yup.string().required('Subject Required'),
    is_financial: yup.string().nullable(),
    lumpsum_amount: yup.number(),
    lumpsum_date: yup.date(),
    regular_amount: yup.number(),
    regular_start_date: yup.date(),
    regular_end_date: yup.date(),
    frequency: yup.string().nullable(),
    timeframe: yup.string().nullable(),
    timeframe_date: yup.date(),
    identified_by: yup.string().nullable(),
    description: yup.string(),
    web_links: yup.array().of(
      yup.object().shape({
        web_link: yup.string().url('Invalid URL').required('Web link required'),
        web_name: yup.string().required('Web name required'),
      })
    ),
    video_links: yup.array().of(
      yup.object().shape({
        video_icon: yup.string().required('Video icon required'),
        video_link: yup.string().url('Invalid URL').required('Video link required'),
      })
    ),
  })

  const initialValues = {
    subject: '',
    description: '',
    is_financial: '',
    lumpsum_amount: 0.0,
    lumpsum_date: '',
    regular_amount: 0.0,
    regular_start_date: '',
    regular_end_date: '',
    frequency: '',
    timeframe: '',
    timeframe_date: '',
    identified_by: 'client',
    area: '',
    web_links: [],
    video_links: [],
    permissions: {},
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      values['permissions'] = permissions
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this objective?')
          if (result) {
            await GoalService.updateGoal(values, id)
          }
        } else {
          await GoalService.updateGoal(values, id)
        }
      } else {
        await GoalService.saveGoal(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const onChangeArea = (value) => {
    formik.setFieldValue('area', value)
  }

  const onCheckPerMission = (checkedKeys) => {
    // console.log(checkedKeys);
    setPermissions(checkedKeys)
  }

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this objective to global?')
    if (result) {
      const res = await GoalService.getResetGoal(id)
      formik.setValues(get(res, 'data.data'))
      setWebLinks(get(res, 'data.data.web_links', []))
      setVideoLinks(get(res, 'data.data.video_links', []))
    }
  }

  const handleAddWebLink = () => {
    setWebLinks([...webLinks, { web_link: '', web_name: '' }])
  }

  const handleAddVideoLink = () => {
    setVideoLinks([...videoLinks, { video_icon: '', video_link: '' }])
  }

  const handleWebLinkChange = (index, event) => {
    const { name, value } = event.target
    const newWebLinks = webLinks.slice()
    newWebLinks[index][name] = value
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleVideoLinkChange = (index, event) => {
    const { name, value } = event.target
    const newVideoLinks = videoLinks.slice()
    newVideoLinks[index][name] = value
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const handleRemoveWebLink = (index) => {
    const newWebLinks = webLinks.slice()
    newWebLinks.splice(index, 1)
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleRemoveVideoLink = (index) => {
    const newVideoLinks = videoLinks.slice()
    newVideoLinks.splice(index, 1)
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add Objective
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit Objective' : 'Add Objective'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Area">
                <Select
                  mode="single"
                  name="area"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select area"
                  value={formik.values.area}
                  onChange={onChangeArea}
                >
                  <Option value=""></Option>
                  {area_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.area && formik.errors.area && (
                  <span className="input-error-message">{formik.errors.area}</span>
                )}
              </Form.Item>
              <Form.Item label="Objective">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Objective"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>
              <Form.Item label="Is it financial objective?">
                <Select
                  mode="single"
                  name="is_financial"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Is it financial objective ?"
                  value={formik.values.is_financial}
                  onChange={(value) => formik.setFieldValue('is_financial', value)}
                >
                  <Option value=""></Option>
                  <Option value="yes">Yes</Option>
                  <Option value="no">No</Option>
                </Select>
                {formik.touched.is_financial && formik.errors.is_financial && (
                  <span className="input-error-message">{formik.errors.is_financial}</span>
                )}
              </Form.Item>
              {formik.values.is_financial === 'yes' && (
                <div>
                  <Form.Item label="Lumpsum amount">
                    <Input
                      type="number"
                      style={{ width: '100%' }}
                      placeholder="lumpsum amount"
                      name="lumpsum_amount"
                      onChange={formik.handleChange}
                      value={formik.values.lumpsum_amount}
                    />
                    {formik.touched.lumpsum_amount && formik.errors.lumpsum_amount && (
                      <span className="input-error-message">{formik.errors.lumpsum_amount}</span>
                    )}
                  </Form.Item>
                  <Form.Item label="Lumpsum date">
                    <Input
                      type="date"
                      style={{ width: '100%' }}
                      placeholder="lumpsum date"
                      name="lumpsum_date"
                      onChange={formik.handleChange}
                      value={formik.values.lumpsum_date}
                    />
                    {formik.touched.lumpsum_date && formik.errors.lumpsum_date && (
                      <span className="input-error-message">{formik.errors.lumpsum_date}</span>
                    )}
                  </Form.Item>
                  <Form.Item label="Regular amount">
                    <Input
                      type="number"
                      style={{ width: '100%' }}
                      placeholder="regular amount"
                      name="regular_amount"
                      onChange={formik.handleChange}
                      value={formik.values.regular_amount}
                    />
                    {formik.touched.regular_amount && formik.errors.regular_amount && (
                      <span className="input-error-message">{formik.errors.regular_amount}</span>
                    )}
                  </Form.Item>
                  <Form.Item label="Frequency">
                    <Select
                      mode="single"
                      name="is_financial"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="select frequency"
                      value={formik.values.frequency}
                      onChange={(value) => formik.setFieldValue('frequency', value)}
                    >
                      <Option value=""></Option>
                      <Option value="Monthly">Monthly</Option>
                      <Option value="Weekly">Weekly</Option>
                    </Select>
                    {formik.touched.frequency && formik.errors.frequency && (
                      <span className="input-error-message">{formik.errors.frequency}</span>
                    )}
                  </Form.Item>
                  <Form.Item label="Regular start date">
                    <Input
                      type="date"
                      style={{ width: '100%' }}
                      placeholder="Regular start date"
                      name="regular_start_date"
                      onChange={formik.handleChange}
                      value={formik.values.regular_start_date}
                    />
                    {formik.touched.regular_start_date && formik.errors.regular_start_date && (
                      <span className="input-error-message">
                        {formik.errors.regular_start_date}
                      </span>
                    )}
                  </Form.Item>
                  <Form.Item label="Regular end date">
                    <Input
                      type="date"
                      style={{ width: '100%' }}
                      placeholder="Regular end date"
                      name="regular_end_date"
                      onChange={formik.handleChange}
                      value={formik.values.regular_end_date}
                    />
                    {formik.touched.regular_end_date && formik.errors.regular_end_date && (
                      <span className="input-error-message">{formik.errors.regular_end_date}</span>
                    )}
                  </Form.Item>
                </div>
              )}
              {formik.values.is_financial === 'no' && (
                <div>
                  <Form.Item label="Timeframe">
                    <Select
                      mode="single"
                      name="timeframe"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Timeframe"
                      value={formik.values.timeframe}
                      onChange={(value) => formik.setFieldValue('timeframe', value)}
                    >
                      <Option value="immediate">Immediate</Option>
                      <Option value="at_retirement">At retirement</Option>
                      <Option value="at_target_date">At target date</Option>
                    </Select>
                    {formik.touched.timeframe && formik.errors.timeframe && (
                      <span className="input-error-message">{formik.errors.timeframe}</span>
                    )}
                  </Form.Item>
                  {formik.values.timeframe === 'at_target_date' && (
                    <Form.Item label="Timeframe date">
                      <Input
                        type="date"
                        style={{ width: '100%' }}
                        placeholder="Timeframe date"
                        name="timeframe_date"
                        onChange={formik.handleChange}
                        value={formik.values.timeframe_date}
                      />
                      {formik.touched.timeframe_date && formik.errors.timeframe_date && (
                        <span className="input-error-message">{formik.errors.timeframe_date}</span>
                      )}
                    </Form.Item>
                  )}
                </div>
              )}

              <Form.Item label="Identified by">
                <Select
                  mode="single"
                  name="identified_by"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="identified_by"
                  value={formik.values.identified_by}
                  onChange={(value) => formik.setFieldValue('identified_by', value)}
                >
                  <Option value="client">Client</Option>
                  <Option value="advisior">Advisior</Option>
                </Select>
                {formik.touched.identified_by && formik.errors.identified_by && (
                  <span className="input-error-message">{formik.errors.identified_by}</span>
                )}
              </Form.Item>
              <Form.Item label="Description">
                <QuillEditor
                  theme="snow"
                  name="description"
                  value={formik.values.description}
                  onChange={(value) => formik.setFieldValue('description', value)}
                />
              </Form.Item>
              {webLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Web Name ${index + 1}`}>
                      <Input
                        placeholder="Web Name"
                        name="web_name"
                        value={link.web_name}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Web Link ${index + 1}`}>
                      <Input
                        placeholder="Web Link"
                        name="web_link"
                        value={link.web_link}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_link}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveWebLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddWebLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Web Link
              </Button>

              {videoLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Video Icon ${index + 1}`}>
                      <Input
                        placeholder="Video Icon"
                        name="video_icon"
                        value={link.video_icon}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_icon}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Video Link ${index + 1}`}>
                      <Input
                        placeholder="Video Link"
                        name="video_link"
                        value={link.video_link}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_link}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveVideoLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddVideoLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Video Link
              </Button>
            </Form>
          </TabPane>
          <TabPane tab="Permissions" key="2">
            <CustomTree
              preview={preview}
              onCheckPerMission={onCheckPerMission}
              business_list={business_list}
              permissions={permissions}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
