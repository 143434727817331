import React, { useEffect, useState } from 'react'
import { Checkbox, DatePicker } from 'antd'
import { AuthService } from 'services'
import { get } from 'lodash'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { AuthUtils } from 'utils'
import moment from 'moment'
import { useSelector } from 'react-redux'

export default function AccessControl({ preview }) {
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const entity = preview ? previewUser?.details?.id : user?.details?.id
  const queryString = require('query-string')
  const query_obj = queryString.parse(window.location.search)
  const [expiry, setExpiry] = useState(null)
  const [show_password, setShowPassword] = useState('password')

  const onClickShowPass = (type) => {
    setShowPassword(type)
  }

  let schema = yup.object().shape({
    id: yup.string(),
    username: yup.string().required('This is required fields'),
    password: yup
      .string()
      .matches(
        AuthUtils.password_regex(),
        'Minimum 8 characters, at least 1 letter, 1 number and 1 special character'
      ),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match'),
    enabled: yup.boolean(),
  })

  const formik = useFormik({
    initialValues: {
      user_type: query_obj.entity_type ? query_obj.entity_type : '',
      id: query_obj.entity ? query_obj.entity : '',
      password: '',
      confirm_password: '',
      enabled: false,
    },
    // validationSchema: schema,
    onSubmit: async (values) => {
      const data = {}
      for (const key in values) {
        if (values[key] !== null && values[key] !== '') {
          data[key] = values[key]
        }
      }

      if (expiry) {
        data['expiry'] = expiry.format('YYYY-MM-DD')
      }

      AuthService.updateAccessUser(data, entity)

      // let response = await AuthService.signup(values);

      // const expire_in = await get(response, 'data.token.expires_in')/(3600*24) ;
      // Cookies.set('user_id', get(response, 'data.data.id'), {expires: expire_in});
      // Cookies.set('user_type', get(response, 'data.data.user_type'), {expires: expire_in});
      // Cookies.set('token', get(response, 'data.token.access_token'), {expires: expire_in});
      // Cookies.set('refresh_token', get(response, 'data.token.refresh_token'), {expires: expire_in});
      //alert(JSON.stringify(values, null, 2));
    },
    validationSchema: schema,
  })

  const onExpiryOk = (value) => {
    setExpiry(value)
  }

  const get_detail = async () => {
    const res = await AuthService.detailEntity(entity)
    formik.setValues(get(res, 'data.data'))
    const expiry_str = get(res, 'data.data.expiry')
    if (expiry_str) {
      setExpiry(moment(expiry_str))
    }
  }

  useEffect(() => {
    get_detail()
  }, [])

  return (
    <div className="base_form">
      <form>
        <div className="form_group">
          <label className="form_label">Username</label>
          <input
            type="text"
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
          />
          {formik.touched.username && formik.errors.username && (
            <span className="input-error-message">{formik.errors.username}</span>
          )}
        </div>
        <div>
          <Checkbox name="enabled" checked={formik.values.enabled} onChange={formik.handleChange}>
            Enable client access
          </Checkbox>
          {formik.touched.enabled && formik.errors.enabled && (
            <span className="input-error-message">{formik.errors.enabled}</span>
          )}
        </div>
        <div className="form_group">
          <label className="form_label">Expiry</label>
          <div className="input_mask">
            <DatePicker
              format="YYYY-MM-DD"
              showTime={true}
              value={expiry}
              onChange={onExpiryOk}
              allowClear={true}
            />
          </div>
        </div>
        <div className="form_group">
          <label className="form_label">Password</label>
          <div className="input_mask">
            <input
              type={show_password}
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            {show_password == 'password' && (
              <button onClick={() => onClickShowPass('text')}>
                <span className="material-icons-outlined">visibility</span>
              </button>
            )}
            {show_password == 'text' && (
              <button onClick={() => onClickShowPass('password')}>
                <span className="material-icons-outlined">visibility_off</span>
              </button>
            )}
            {formik.touched.password && formik.errors.password && (
              <span className="input-error-message">{formik.errors.password}</span>
            )}
          </div>
        </div>
        <div className="form_group">
          <label className="form_label">Confirm Password</label>
          <div className="input_mask">
            <input
              type="password"
              name="confirm_password"
              value={formik.values.confirm_password}
              onChange={formik.handleChange}
            />
            {formik.touched.confirm_password && formik.errors.confirm_password && (
              <span className="input-error-message">{formik.errors.confirm_password}</span>
            )}
          </div>
        </div>
        <button type="submit" className="primary_form" onClick={formik.handleSubmit}>
          Save
        </button>
      </form>
    </div>
  )
}
