import React, { useState } from 'react'
import produce from 'immer'
import { AdminLayout } from 'Components'
const variables = {
  'patient name': 'string',
  'has car': 'boolean',
}
const options = {
  string: [
    {
      condition_name: 'Is equal to',
      expression: '$eq',
    },
    {
      condition_name: 'Is not equal to',
      expression: '$neq',
    },
  ],
  boolean: [
    {
      condition_name: 'Equal',
      expression: '$eq',
    },
    {
      condition_name: 'Not Equal',
      expression: '$neq',
    },
  ],
}

const Config = () => {
  const [master, setMaster] = useState([
    {
      combinator: 'and',
      rules: [{ name: Object.keys(variables)[0], type: '' }],
      result: {
        action: 'show/hide',
        target: 'val1',
      },
    },
  ])

  const renderRules = (rules, masterIndex) => {
    return rules.map((item, i) => {
      return (
        <div>
          <select
            onChange={(e) => {
              const newState = produce(master, (draft) => {
                draft[masterIndex].rules[i].name = e.target.value
                draft[masterIndex].rules[i].type = variables[e.target.value]
              })
              setMaster(newState)
            }}
          >
            {Object.keys(variables).map((variable, index) => (
              <option value={variable}>{variable}</option>
            ))}
          </select>

          {master[masterIndex].rules[i].type && (
            <select
              onChange={(e) => {
                const newState = produce(master, (draft) => {
                  draft[masterIndex].rules[i].expression = e.target.value
                })
                setMaster(newState)
              }}
            >
              {options[master[masterIndex].rules[i].type].map((option, optionIndex) => (
                <option value={option.expression}>{option.condition_name}</option>
              ))}
            </select>
          )}
        </div>
      )
    })
  }
  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '35px 40px', backgroundColor: '#fff' }}>
        {master.map((item, masterIndex) => (
          <div>{renderRules(item.rules, masterIndex)}</div>
        ))}
      </div>
    </AdminLayout>
  )
}

export default Config
