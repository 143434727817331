import React, { useState } from 'react'
import './Portfolio.scss'

/*
const portfolioData = [
  {
    id: 'Portfolio A',
    investmentMix: { growth: '0', defensive: '100' },
    expectedReturn: { perYear: '4', compounded: '80' },
    negativeReturns: { frequency: '0', size: '0' },
    selectable: false,
  },
  {
    id: 'Portfolio B',
    investmentMix: { growth: '0', defensive: '100' },
    expectedReturn: { perYear: '4', compounded: '80' },
    negativeReturns: { frequency: '0', size: '0' },
    selectable: false,
  },
  {
    id: 'Portfolio C',
    investmentMix: { growth: '0', defensive: '100' },
    expectedReturn: { perYear: '4', compounded: '80' },
    negativeReturns: { frequency: '0', size: '0' },
    selectable: false,
  },
  {
    id: 'Portfolio D',
    investmentMix: { growth: '0', defensive: '100' },
    expectedReturn: { perYear: '4', compounded: '80' },
    negativeReturns: { frequency: '0', size: '0' },
    selectable: false,
  },
  {
    id: 'Portfolio E',
    investmentMix: { growth: '0', defensive: '100' },
    expectedReturn: { perYear: '4', compounded: '80' },
    negativeReturns: { frequency: '0', size: '0' },
    selectable: false,
  },

  // ... replicate this structure for other portfolio columns B, C, D, E, F with their respective data
];

 */

const Portfolio = ({ portfolioData, current_risk_profile }) => {
  // const portfolioData = chart_data;
  const [expanded, setExpanded] = useState(null)

  const handleExpand = (id) => {
    setExpanded(expanded === id ? null : id)
  }

  const investmentMix_first_obj =
    portfolioData && portfolioData.length > 0 ? portfolioData[0].investmentMix : {}
  const investmentMix_first_obj_keys = Object.keys(investmentMix_first_obj)

  return (
    <div className="portfolio-container">
      <div className={`portfolio-column`} style={{ flex: 1, textAlign: 'right' }}>
        <div className="portfolio-header">Risk profile</div>
        <div className="portfolio-section">
          <div className="portfolio-section-header">INVESTMENT MIX</div>
          {investmentMix_first_obj_keys.map((item) => (
            <div key={item}>{item}</div>
          ))}
        </div>
        <div className="portfolio-section">
          <div className="portfolio-section-header">EXPECTED AVERAGE RETURN</div>
          <div>Income</div>
          <div>Growth</div>
          <div>Total return</div>
          <div>Franking</div>
          <div>Standard dev</div>
        </div>
        <div className="portfolio-section">
          <div className="portfolio-section-header">NEGATIVE RETURNS</div>
          <div>Negative Returns</div>
          <div>Baker</div>
        </div>
      </div>
      {portfolioData.map((data, index) => (
        <div
          key={data.id}
          className={`portfolio-column data ${expanded === data.id ? 'expanded' : ''} ${index === 0 ? 'first' : ''}`}
        >
          <div
            className="portfolio-header"
            onClick={() => handleExpand(data.id)}
            style={data.id === current_risk_profile ? { backgroundColor: '#2cea0e' } : {}}
          >
            {data.id}
          </div>
          <div className="portfolio-section">
            <div className="portfolio-section-header">INVESTMENT MIX</div>
            {/*<div><label className='value_label'>Growth:</label> <input type='number' value={data.investmentMix.growth}/>%</div>*/}
            {/*<div><label className='value_label'>Defensive:</label> <input type='number' value={data.investmentMix.defensive}/>%</div>*/}
            {Object.values(data.investmentMix).map((item) => (
              <div>{item} %</div>
            ))}
          </div>
          <div className="portfolio-section">
            <div className="portfolio-section-header">EXPECTED AVERAGE RETURN</div>
            <div>
              <span>{data.expectedReturn['Income']} %</span>
            </div>
            <div>
              <span>{data.expectedReturn['Growth']} %</span>
            </div>
            <div>
              <span>{data.expectedReturn['Total return']} %</span>
            </div>
            <div>
              <span>{data.expectedReturn['Franking']} %</span>
            </div>
            <div>
              <span>{data.expectedReturn['Standard dev']} %</span>
            </div>
          </div>

          <div className="portfolio-section">
            <div className="portfolio-section-header">NEGATIVE RETURNS</div>
            <div>
              {data.negativeReturns['Negative Returns'] !== '0' ? (
                <span>{data.negativeReturns['Negative Returns']} %</span>
              ) : (
                <span style={{ display: 'inline-block', paddingLeft: 20 }}>None</span>
              )}
            </div>
            <div>
              <span>{data.negativeReturns['Baker']}%</span>
            </div>
          </div>
          {/* {index !== 0 && (
            <button className="select-button">SELECT</button>
          )} */}
        </div>
      ))}
    </div>
  )
}

export default Portfolio
