import { AdminLayout } from 'Components'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import { AuthUtils } from 'utils'
import { useFormik } from 'formik'
import { AuthService } from 'services'
import { useState } from 'react'

export default function ChangePassword() {
  // const navigate = useNavigate();
  let schema = yup.object().shape({
    old_password: yup.string().required('old password is required'),
    new_password: yup
      .string()
      .required('password is required fields')
      .matches(
        AuthUtils.password_regex(),
        'Minimum 8 characters, at least 1 letter, 1 number and 1 special character'
      ),
    confirm_password: yup.string().oneOf([yup.ref('new_password'), null], 'Passwords must match'),
  })

  const formik = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirm_password: '',
    },
    onSubmit: async (values) => {
      await AuthService.change_password(values)
    },
    validationSchema: schema,
  })
  const [showPassword, SetShowPassword] = useState(false)
  const [confirmShowPassword, SetConfirmShowPassword] = useState(false)
  const clickPassword = (type) => {
    if (type === 'old') {
      SetShowPassword(!showPassword)
    } else {
      SetConfirmShowPassword(!confirmShowPassword)
    }
  }
  return (
    <AdminLayout>
      <div className="auth">
        <div className="container">
          <div className="base_form login">
            <h1>Reset password</h1>
            <form onSubmit={formik.handleSubmit}>
              <div className="form_group">
                <label className="form_label">Old Password</label>
                <div className="input_mask">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    name="old_password"
                    value={formik.values.old_password}
                    onChange={formik.handleChange}
                  />
                  <button onClick={(e) => (e.preventDefault(), clickPassword('old'))}>
                    <span className="material-icons-outlined">visibility</span>
                  </button>
                  {formik.touched.old_password && formik.errors.old_password && (
                    <span className="input-error-message">{formik.errors.old_password}</span>
                  )}
                </div>
              </div>
              <div className="form_group">
                <label className="form_label">New Password</label>
                <div className="input_mask">
                  <input
                    type="password"
                    name="new_password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.new_password && formik.errors.new_password && (
                    <span className="input-error-message">{formik.errors.new_password}</span>
                  )}
                </div>
              </div>
              <div className="form_group">
                <label className="form_label">Confirm New Password</label>
                <div className="input_mask">
                  <input
                    type={confirmShowPassword ? 'text' : 'password'}
                    name="confirm_password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                  />
                  <button onClick={(e) => (e.preventDefault(), clickPassword('confirm'))}>
                    <span className="material-icons-outlined">visibility</span>
                  </button>
                  {formik.touched.confirm_password && formik.errors.confirm_password && (
                    <span className="input-error-message">{formik.errors.confirm_password}</span>
                  )}
                </div>
              </div>
              <button type="submit" className="primary_form">
                Change Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </AdminLayout>
  )
}
