import React from 'react'
import AdminLayout from '../../../Components/Layout/AdminLayout'
import './page.scss'
import { useEffect, useState } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash'
import { Button } from 'antd'
import { useQuill } from 'react-quilljs'

export default function AdminPrivacyPolicy(props) {
  const [privacy_and_policy, SetPrivacyPolicy] = useState('')

  const privacy_and_policyDetail = async () => {
    const res = await StaticPageService.PrivacyPolicyGet()
    await SetPrivacyPolicy(get(res, 'data.data.privacy_and_policy'))
  }

  useEffect(() => {
    privacy_and_policyDetail()
  }, [])

  const onClickSave = () => {
    StaticPageService.PrivacyPolicyUpdate({ privacy_and_policy })
  }

  const { quill, quillRef } = useQuill()
  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(privacy_and_policy)
      quill.on('text-change', (delta, oldDelta, source) => {
        SetPrivacyPolicy(quill.root.innerHTML)
      })
    }
  }, [quill, privacy_and_policy])

  const Main = (
    <>
      <div className="static_admin_setting">
        <h1>Privacy Policy</h1>
        <div ref={quillRef} />
        <Button onClick={onClickSave} type="primary">
          Save
        </Button>
      </div>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}
