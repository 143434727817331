import React, { useEffect, useState } from 'react'
import {
  Form,
  Input,
  Table,
  Modal,
  Button,
  Space,
  Select,
  Upload,
  Switch,
  Col,
  Row,
  Tabs,
  Tag,
  Checkbox,
} from 'antd'
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
  FilterOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { AuthService, BusinessService } from 'services'
import { get } from 'lodash'
import * as yup from 'yup'
import { AuthUtils } from 'utils'
import { useFormik } from 'formik'
import qs from 'query-string'
import queryString from 'query-string'
import { useNavigate } from 'react-router-dom'
import { setUser } from 'Store/reducers/previewUserSlice'
import { useDispatch } from 'react-redux'
import { setRoutes } from 'Store/reducers/routeSlice'
import { getFirstPage } from 'utils/helpers'

const { TabPane } = Tabs

const { Option } = Select
// const data = [
//   {
//     key: '1',
//     name: 'Ram Pandey',
//     phone_number: '9871006582',
//     email: 'abc@gmail.com',
//     business: 'MetAdvise'
//   },
//   {
//     key: '2',
//     name: 'Laxman Pandey',
//     phone_number: '9871006582',
//     email: 'abc@gmail.com',
//     business: 'MetAdvise'
//   },
//   {
//     key: '3',
//     name: 'Ravi Kumar',
//     phone_number: '9871006582',
//     email: 'abc@gmail.com',
//     business: 'MetAdvise'
//   },
//   {
//     key: '4',
//     name: 'ABC XYZ',
//     phone_number: '9871006582',
//     email: 'abc@gmail.com',
//     business: 'MetAdvise'
//   },
// ];

const UserList = (props) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const capabilities_default = [
    { id: 1, type: 'business' },
    { id: 2, type: 'client', add: true, edit: true, read: false, delete: true },
    { id: 3, type: 'capability' },
    { id: 4, type: 'user' },
  ]
  const queryString = require('query-string')
  const query_obj = queryString.parse(window.location.search)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [image_loading, SetImageLoading] = useState(false)
  const [imageUrl, SetImageUrl] = useState(false)
  const [id, SetId] = useState(null)
  const [imageError, SetImageError] = useState('')
  const [profile_pic_blob, SetPicBlob] = useState(null)
  const [data, SetData] = useState([])
  const [total, setTotal] = useState(0)
  const [businessList, SetBusinessList] = useState([])
  const [cap_data, CapSetData] = useState(capabilities_default)
  const [activeKey, setActiveKey] = React.useState('1')
  const [is_edit, setIsEdit] = useState(false)
  const onTabKeyChange = (key) => setActiveKey(key)

  const [active_params, SetActiveParams] = useState(
    query_obj.ac === undefined ? 'all' : query_obj.is_active
  )
  const [search_params, setSearchParam] = useState(query_obj.search ? query_obj.search : '')
  const [search_text, SetSearchText] = useState(query_obj.search ? query_obj.search : '')

  let schema = yup.object().shape({
    first_name: yup.string().required('First name Required'),
    last_name: yup.string().required('Last name Required'),
    business: yup.string().required('Business Required'),
    phone_number: yup
      .string()
      .required('This is required fields')
      .matches(AuthUtils.phone_regex(), 'Phone number is not valid'),
    is_active: yup.boolean(),
    email: yup.string().email('Invalid email').required('Required'),
  })

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    is_active: true,
    user_type: 'user',
    has_profile_pic: false,
    business: '',
  }

  function getBase64(img, callback) {
    const reader = new FileReader()
    reader.addEventListener('load', () => callback(reader.result))
    reader.readAsDataURL(img)
  }

  const uploadButton = (
    <div>
      {image_loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Avatar</div>
    </div>
  )

  const imageHandleAction = async (option) => {
    SetImageLoading(true)
    await getBase64(option.file, (imageUrl) => {
      SetImageUrl(imageUrl)
      SetImageLoading(false)
      SetPicBlob(option.file)
    })
  }

  const DeletePic = () => {
    SetImageUrl(null)
    SetPicBlob(null)
    SetImageError(null)
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
    SetImageError(null)
    if (!isJpgOrPng) {
      // message.error('You can only upload JPG/PNG file!');
      SetImageError('You can only upload JPG/PNG file!')
    }
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) {
      // message.error('Image must smaller than 2MB!');
      SetImageError('Image must smaller than 2MB!')
    }
    return isJpgOrPng && isLt2M
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    // validationSchema: schema,
    onSubmit: async (values) => {
      let form_data = new FormData()
      for (let key in values) {
        if (key != 'profile_pic' && key != 'id') {
          form_data.append(key, values[key])
        }
      }
      if (profile_pic_blob) {
        form_data.append('profile_pic', profile_pic_blob)
      }
      if (imageUrl) {
        form_data.append('has_profile_pic', true)
      }
      form_data.append('capabilities', JSON.stringify(cap_data))

      if (id) {
        await AuthService.updateEntity(id, form_data)
      } else {
        const res = await AuthService.createEntity(form_data)
        if (formik.values.edit) {
          const data = res.data.data
          onNameClick(data)
        }
      }

      setIsModalVisible(false)
      SetSearchText('')
      setSearchParam('')
      SetActiveParams('all')
      get_data()
    },
    validationSchema: schema,
  })

  const get_detail = async (id) => {
    setIsEdit(true)
    SetImageLoading(true)
    await get_business_data()
    await SetId(id)
    await setIsModalVisible(true)
    const res = await AuthService.detailEntity(id)
    formik.setValues(get(res, 'data.data'))
    SetImageUrl(get(res, 'data.data.profile_pic'))
    CapSetData(get(res, 'data.data.capabilities'))
    SetImageLoading(false)
  }

  const onNameClick = async (data) => {
    const res = await AuthService.detailEntity(data.id, { detail: true })
    let firstPage = getFirstPage(res?.data?.data?.current_page_config?.page_data)?.id
    dispatch(setUser(res.data.data))
    dispatch(setRoutes(res.data.data.current_page_config))
    let userType = data.user_type.toLowerCase()
    switch (userType) {
      case 'user':
        navigate(
          firstPage
            ? '/preview/user/page/' + firstPage + `?entity_type=${userType}&entity=${data.id}`
            : '/preview/user/dashboard'
        )
    }
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          <a onClick={() => onNameClick(e)}>{text}</a>
        </div>
      ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone_number',
      key: 'phone_number',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Phone</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Email</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Business',
      dataIndex: 'business',
      key: 'business',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Business</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size={'medium'} onClick={() => get_detail(record.id)} />
          {/*<Button  icon={<EyeOutlined />} size={'medium'} />*/}
          {/*<Button danger  icon={<DeleteOutlined />} size={'medium'} />*/}
        </Space>
      ),
    },
  ]

  const onchangeCheck = (e, type, record) => {
    let capability_data = cap_data
    if (type === 'add') {
      capability_data.find((item) => item.id === record.id).add = e.target.checked
    } else if (type === 'edit') {
      capability_data.find((item) => item.id === record.id).edit = e.target.checked
    } else if (type === 'read') {
      capability_data.find((item) => item.id === record.id).read = e.target.checked
    } else {
      capability_data.find((item) => item.id === record.id).delete = e.target.checked
    }
    CapSetData(capability_data)
  }

  const cap_columns = [
    {
      title: 'Capability Type',
      dataIndex: 'type',
      key: 'type',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Capability Type</h6>
          <b style={{ textTransform: 'capitalize' }}>{text}</b>
        </div>
      ),
    },
    {
      title: 'Add',
      key: 'add',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Add</h6>
          <Checkbox checked={record.add} onChange={(e) => onchangeCheck(e, 'add', record)} />
        </div>
      ),
    },
    {
      title: 'Read',
      key: 'read',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Read</h6>
          <Checkbox checked={record.read} onChange={(e) => onchangeCheck(e, 'read', record)} />
        </div>
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Edit</h6>
          <Checkbox checked={record.edit} onChange={(e) => onchangeCheck(e, 'edit', record)} />
        </div>
      ),
    },
    {
      title: 'Delete',
      key: 'delete',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Delete</h6>
          <Checkbox checked={record.delete} onChange={(e) => onchangeCheck(e, 'delete', record)} />
        </div>
      ),
    },
  ]

  const get_data = async (params = {}) => {
    //users
    const res = await AuthService.users({ user_type: 'user', ...params })
    SetData(get(res, 'data.data'))
    setTotal(get(res, 'data.count', 0))
  }

  const get_business_data = async () => {
    const res = await BusinessService.business()
    SetBusinessList(get(res, 'data.data'))
  }

  useEffect(() => {
    const params = make_params(search_params)
    get_data(params)
    const searchString = qs.stringify(params)
    // if (searchString) {
    //   window.history.replaceState({}, "", "?" + searchString);
    // } else {
    //   var newURL = window.location.href.split("?")[0];
    //   window.history.replaceState({}, "", newURL);
    // }
  }, [active_params, search_params])

  const SetInitialValue = () => {
    setIsEdit(false)
    formik.setValues(initialValues)
    SetId(null)
    SetImageUrl(null)
    SetPicBlob(null)
    SetImageError(null)
    CapSetData(capabilities_default)
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    SetInitialValue()
    get_business_data()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    SetInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    SetInitialValue()
  }

  const make_params = (search_text) => {
    let query = {}

    if (active_params || active_params === false) {
      if (active_params !== 'all') {
        query['is_active'] = active_params
      }
    }
    if (search_text) {
      query['search'] = search_text
    }
    return query
  }

  const [filter, setFilter] = useState(false)

  const toggleFilter = () => {
    setFilter(!filter)
  }

  const [isSearchModalVisible, setSearchIsModalVisible] = useState(false)

  const showSearchModal = () => {
    setSearchIsModalVisible(true)
  }

  const handleSearchOk = () => {
    setSearchIsModalVisible(false)
  }

  const handleSearchCancel = () => {
    setSearchIsModalVisible(false)
  }

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title"></span>
          <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
            Add User
          </Button>
        </h1>
        <Tabs type="card">
          <TabPane tab="List" key="1">
            <Tag closable color={'blue'}>
              All active/inactive users
            </Tag>
            <br />
            <Input.Group compact style={{ width: '100%' }}>
              <Input.Search
                allowClear
                style={{ width: 'calc(100% - 31px)' }}
                value={search_text}
                onChange={(e) => SetSearchText(e.target.value)}
                onSearch={(value) => setSearchParam(search_text)}
              />
              <Button onClick={toggleFilter} icon={<FilterOutlined />}></Button>
            </Input.Group>
            <div className={filter ? 'custom_panel show' : 'custom_panel'}>
              <Form layout="vertical">
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="First name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Last name">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Phone">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Email">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Business">
                      <Select
                        showSearch
                        placeholder="Select a business"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        name="business"
                      >
                        {businessList.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.business_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}>
                    <Form.Item label="Status">
                      <Select
                        defaultValue="all"
                        style={{ width: '100%' }}
                        value={active_params}
                        onChange={(value) => SetActiveParams(value)}
                      >
                        <Option value="all">All</Option>
                        <Option value={true}>Active</Option>
                        <Option value={false}>Inactive</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={6}></Col>
                  <Col className="gutter-row end_column" span={6}>
                    <Button onClick={showSearchModal} style={{ marginRight: 5 }}>
                      Save Filter
                    </Button>
                    <Button type="primary">Apply Filters</Button>
                  </Col>
                </Row>
              </Form>
            </div>
            <br />
            <Table
              className="custom_responsive_table"
              pagination={{
                total,
                onChange: (val) => get_data({ page: val }),
                defaultPageSize: 50,
                PageSize: 50,
              }}
              columns={columns}
              dataSource={data}
              rowKey="id"
            />
          </TabPane>
          <TabPane tab="Saved Searches" key="2">
            <div className="ant-table-content">
              <table style={{ width: '100%' }}>
                <thead className="ant-table-thead">
                  <tr>
                    <th className="ant-table-cell">Name</th>
                    <th style={{ width: '180px' }} className="ant-table-cell">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody className="ant-table-tbody">
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">All Members</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">Only Active Members</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">Members with ram in their name</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                  <tr className="ant-table-row">
                    <td className="ant-table-cell">All Inactive members</td>
                    <td className="ant-table-cell">
                      <Button type="primary" style={{ marginRight: 5 }}>
                        Use Criteria
                      </Button>
                      <Button danger icon={<DeleteOutlined />} size={'medium'} />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <Modal
        title="Add user"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
          !is_edit && ( // Check if 'is_edit' is true to render the Save and Edit button
            <Button
              key="submit-edit"
              type="primary"
              onClick={() => {
                formik.values['edit'] = true
                formik.handleSubmit(formik.values)
              }}
            >
              Save and Edit
            </Button>
          ),
        ]}
      >
        <Tabs defaultActiveKey="1" activeKey={activeKey} onChange={onTabKeyChange}>
          <TabPane tab={<span>Basic detail</span>} key="1">
            <Form layout="vertical">
              <div className="align_center">
                <Upload
                  name="profile_pic"
                  listType="picture-card"
                  className="avatar-uploader"
                  showUploadList={false}
                  // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  customRequest={imageHandleAction}
                  beforeUpload={beforeUpload}
                  showRemoveIcon={true}
                  // onChange={formik.handleChange}
                  style={{ backgroundImage: `url(${imageUrl})` }}
                >
                  {imageUrl ? (
                    <div
                      className="contained_image"
                      style={{ backgroundImage: `url(${imageUrl})` }}
                    >
                      <DeleteOutlined onClick={() => DeletePic()} />
                    </div>
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
              {imageError && (
                <span className="input-error-message" style={{ width: '50%' }}>
                  {imageError}
                </span>
              )}
              <Form.Item label="Name">
                <div className="inline_inputs">
                  <div className="half_input">
                    <Input
                      placeholder="First name"
                      style={{ width: '50%' }}
                      name="first_name"
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                    />
                    {formik.touched.first_name && formik.errors.first_name && (
                      <span className="input-error-message" style={{ width: '50%' }}>
                        {formik.errors.first_name}
                      </span>
                    )}
                  </div>
                  <div className="half_input">
                    <Input
                      placeholder="Last name"
                      style={{ width: '50%' }}
                      name="last_name"
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                    />
                    {formik.touched.last_name && formik.errors.last_name && (
                      <span className="input-error-message" style={{ width: '50%' }}>
                        {formik.errors.last_name}
                      </span>
                    )}
                  </div>
                </div>
              </Form.Item>
              <Form.Item label="Phone">
                <Input
                  placeholder="Enter phone"
                  name="phone_number"
                  onChange={formik.handleChange}
                  value={formik.values.phone_number}
                />
                {formik.touched.phone_number && formik.errors.phone_number && (
                  <span className="input-error-message">{formik.errors.phone_number}</span>
                )}
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  placeholder="Enter Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="input-error-message">{formik.errors.email}</span>
                )}
              </Form.Item>
              <Form.Item label="Business">
                <Select
                  showSearch
                  placeholder="Select a business"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  name="business"
                  value={formik.values.business}
                  onChange={(value) => formik.setFieldValue('business', value)}
                >
                  {/*<Option value="jack">ABC corp</Option>*/}
                  {/*<Option value="lucy">XYZ inc</Option>*/}
                  {/*<Option value="tom">PCI ltd</Option>*/}
                  {businessList.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.business_name}
                    </Option>
                  ))}
                </Select>
                {formik.touched.business && formik.errors.business && (
                  <span className="input-error-message">{formik.errors.business}</span>
                )}
              </Form.Item>
              <div>
                <label>
                  Active{' '}
                  <Switch
                    style={{ marginLeft: 15 }}
                    name="is_active"
                    onChange={(value) => formik.setFieldValue('is_active', value)}
                    checked={formik.values.is_active}
                  />
                </label>
              </div>
            </Form>
          </TabPane>
          <TabPane tab={<span>Capability</span>} key="2">
            <h3>Please enter capability operations</h3>
            <Table
              className="custom_responsive_table"
              columns={cap_columns}
              dataSource={cap_data}
              pagination={false}
              rowKey="id"
            />
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        title="Save Search Criteria"
        visible={isSearchModalVisible}
        onOk={handleSearchOk}
        onCancel={handleSearchCancel}
      >
        <Input placeholder="Enter search criteria name" />
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default UserList
