import React, { useEffect, useState } from 'react'
import { Bar, G2 } from '@ant-design/plots'
import { useSelector } from 'react-redux'
import { ElementService } from '../../services'
import { get } from 'lodash'
import { Button, Checkbox, Select } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'
const { Option } = Select

const BarStackedChart = ({ element, preview, wizard_history, wizard_is_locked, show_title }) => {
  const data1 = [
    { label: '2013', type: 'Kernenergie', value: 92.1 },
    { label: '2013', type: 'Braunkohle', value: 145.1 },
    { label: '2013', type: 'Steinkohle', value: 110.6 },
    { label: '2013', type: 'Gas', value: 39.4 },
    { label: '2013', type: 'Wasserkraft', value: 21.0 },
    { label: '2013', type: 'Biomasse', value: 48.3 },
    { label: '2013', type: 'Wind', value: 47.2 },
    { label: '2013', type: 'Solar', value: 29.7 },
    { label: '2014', type: 'Kernenergie', value: 91.8 },
    { label: '2014', type: 'Braunkohle', value: 200.9 },
    { label: '2014', type: 'Steinkohle', value: 99.0 },
    { label: '2014', type: 'Gas', value: 33.2 },
    { label: '2014', type: 'Wasserkraft', value: 18.5 },
    { label: '2014', type: 'Biomasse', value: 53.9 },
    { label: '2014', type: 'Wind', value: 51.4 },
    { label: '2014', type: 'Solar', value: 32.8 },
    { label: '2015', type: 'Kernenergie', value: 87.1 },
    { label: '2015', type: 'Braunkohle', value: 139.4 },
    { label: '2015', type: 'Steinkohle', value: 503.9 },
    { label: '2015', type: 'Gas', value: 30.0 },
    { label: '2015', type: 'Wasserkraft', value: 20.2 },
    { label: '2015', type: 'Biomasse', value: 56.5 },
    { label: '2015', type: 'Wind', value: 84.6 },
    { label: '2015', type: 'Solar', value: 36.8 },
    { label: '2016', type: 'Kernenergie', value: 80.0 },
    { label: '2016', type: 'Braunkohle', value: 134.8 },
    { label: '2016', type: 'Steinkohle', value: 100.0 },
    { label: '2016', type: 'Gas', value: 45.2 },
    { label: '2016', type: 'Wasserkraft', value: 20.8 },
    { label: '2016', type: 'Biomasse', value: 49.3 },
    { label: '2016', type: 'Wind', value: 77.8 },
    { label: '2016', type: 'Solar', value: 37.5 },
  ]
  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const entity = preview ? params.get('entity') : user?.details?.id

  const [data, setData] = useState([])
  const [primary_filter_list, setPrimaryFilterList] = useState([])
  const [secondary_filter_obj, setSecondaryFilterObj] = useState({})

  const [primary_filter, setPrimaryFilter] = useState({})
  const [secondary_filter, setSecondaryFilter] = useState({})
  const [selected_filter, setSelected] = useState({})
  const [selected_checkbox, setSelectedCheckBox] = useState({})

  const ElementChartData = async () => {
    try {
      const res = await ElementService.ElementChartDataList({
        element: get(element, 'element.id'),
        entity: entity,
        wizard_history: wizard_is_locked && wizard_history ? wizard_history : '',
      })
      const primary_res_data = get(res, 'data.data.primary_filter', {})
      const secondary_res_data = get(res, 'data.data.secondary_filter', {})

      setPrimaryFilter(primary_res_data)
      setSecondaryFilter(secondary_res_data)
      const primary_filter_list_res = get(res, 'data.data.primary_filter_list', [])
      setSecondaryFilterObj(get(res, 'data.data.secondary_filter_obj', {}))

      const all_data = get(res, 'data.data.all_data', [])
      setData(all_data)

      let primary_filter_list_objects = {}
      for (let i of primary_filter_list_res) {
        if (primary_filter_list_objects[i.label]) {
          primary_filter_list_objects[i.label].push(i)
        } else {
          primary_filter_list_objects[i.label] = [i]
        }
      }
      setPrimaryFilterList(primary_filter_list_objects)

      const first_primary_key = get(res, 'data.data.primary_filter_list', [])[0]

      if (first_primary_key) {
        let first_filtered_data = []
        if (primary_res_data[first_primary_key.id]) {
          for (const [key, value] of Object.entries(
            get(primary_res_data[first_primary_key.id], 'values', [])
          )) {
            first_filtered_data.push({
              label: primary_res_data[first_primary_key.id].label,
              type: key,
              value: value,
            })
          }
        }
        await setConfig((prevState) => ({
          ...prevState,
          // data: first_filtered_data,
          data: all_data,
          // data: data2,
        }))
        // setSelected({type:'primary', value: first_primary_key.id});
      }
    } catch (err) {}

    // for (const [key, value] of Object.entries(primary_res_data)) {
    //   console.log(`${key}: ${value}`);
    // }
  }

  useEffect(() => {
    ElementChartData()
  }, [])

  G2.registerInteraction('element-link', {
    start: [
      {
        trigger: 'interval:mouseenter',
        action: 'element-link-by-color:link',
      },
    ],
    end: [
      {
        trigger: 'interval:mouseleave',
        action: 'element-link-by-color:unlink',
      },
    ],
  })
  var theme = useSelector((state) => state.theme.current_theme)
  const default_config = {
    data,
    color: [
      theme.chart_color.chart1,
      theme.chart_color.chart8,
      theme.chart_color.chart2,
      theme.chart_color.chart3,
      theme.chart_color.chart4,
      theme.chart_color.chart5,
      theme.chart_color.chart6,
      theme.chart_color.chart7,
      theme.chart_color.chart9,
      theme.chart_color.chart10,
    ],
    colorField: 'type',
    isStack: true,
    xField: 'value',
    yField: 'label',
    seriesField: 'type',
    label: {
      // 可手动配置 label 数据标签位置
      position: 'middle',
      // 'top', 'bottom', 'middle'
      // 可配置附加的布局方法
      layout: [
        // 柱形图数据标签位置自动调整
        {
          type: 'interval-adjust-position',
        }, // 数据标签防遮挡
        {
          type: 'interval-hide-overlap',
        }, // 数据标签文颜色自动调整
        {
          type: 'adjust-color',
        },
      ],
    },
  }

  const [config, setConfig] = useState(default_config)
  const onClickPrimary = (id, label) => {
    let filtered_data = []
    if (primary_filter[id]) {
      for (const [key, value] of Object.entries(get(primary_filter[id], 'values', []))) {
        filtered_data.push({
          label: get(primary_filter[id], 'label'),
          type: key,
          value: value,
        }) //label:primary_res_data[first_primary_key.id].label,
      }
    }

    setConfig((prevState) => ({
      ...prevState,
      data: [...prevState.data.filter((item) => item.label !== label), ...filtered_data],
    }))
  }

  const onClickSecondary = (id, label) => {
    let filtered_data = []
    if (secondary_filter[id]) {
      for (const [key, value] of Object.entries(get(secondary_filter[id], 'values', []))) {
        filtered_data.push({
          label: secondary_filter[id].label,
          type: key,
          value: value,
        })
      }
    }
    setConfig((prevState) => ({
      ...prevState,
      data: [...filtered_data, ...prevState.data.filter((item) => item.label !== label)],
    }))
  }

  const onChangeFilter = (value, e) => {
    // setSelectedCheckBox([]) //set reset with empty array
    // setSelected({[e.label]: {type:e.filter_type, value: value, label:e.label}});

    setSelected((prevState) => ({
      ...prevState,
      [e.label]: { type: e.filter_type, value: value, label: e.label },
    }))
    if (!value) {
      setConfig((prevState) => ({
        ...prevState,
        data: [
          ...data.filter((item) => item.label === e.label),
          ...prevState.data.filter((item) => item.label !== e.label),
        ],
      }))
    } else {
      if (e.filter_type == 'primary') {
        onClickPrimary(value, e.label)
      } else {
        onClickSecondary(value, e.label)
      }
    }
  }
  const hasAnyCommonItem = (arr1, arr2) => {
    return arr1.some((item) => arr2.includes(item))
  }

  const handleCheckboxChange = (value, label) => {
    let filtered_data = []
    setSelectedCheckBox((prevState) => ({
      ...prevState,
      [label]: value,
    }))

    if (selected_filter[label].type === 'primary') {
      filtered_data = data
        .filter((item) => hasAnyCommonItem(value, item.primary))
        .filter((item) => item.label === label)
    } else {
      filtered_data = data
        .filter((item) => value.includes(item.secondary))
        .filter((item) => item.label === label)
    }

    // setConfig((prevState) => ({
    //   ...prevState,
    //   data: [...filtered_data, ...prevState.data.filter(item => item.label !== label)],
    // }));

    setConfig((prevState) => {
      // console.log(JSON.stringify([...filtered_data, ...prevState.data.filter(item => item.label !== label)]))
      return {
        ...prevState,
        data: [...filtered_data, ...prevState.data.filter((item) => item.label !== label)],
      }
    })
  }
  const resetCheckboxClick = (label) => {
    // setSelectedCheckBox([])
    if (selected_filter[label] == 'primary') {
      onClickPrimary(selected_filter[label].value, label)
    } else {
      onClickSecondary(selected_filter.value, label)
    }
  }
  return (
    <div>
      {show_title !== false && (
        <h1 style={{ textAlign: 'center' }}>{get(element, 'element.title')}</h1>
      )}
      <Bar {...config} />
      <div style={{ textAlign: 'center' }}>
        <div>
          {Object.keys(primary_filter_list).map((data_source) => {
            return (
              <span style={{ padding: '0px 5px' }} key={data_source}>
                {data_source}
                <Select onChange={onChangeFilter}>
                  <Option label={data_source}></Option>
                  {primary_filter_list[data_source].map((item) => (
                    <Option key={item.id} value={item.id} label={data_source} filter_type="primary">
                      {item.name}
                    </Option>
                  ))}
                </Select>
                <Button
                  style={{ marginLeft: '5px' }}
                  icon={<ReloadOutlined />}
                  type="link"
                  onClick={() => resetCheckboxClick(data_source)}
                >
                  Reset
                </Button>

                {selected_filter[data_source]?.type == 'primary' &&
                  primary_filter[get(selected_filter, data_source + '.value')] && (
                    <div>
                      <Checkbox.Group
                        options={Object.keys(
                          primary_filter[get(selected_filter, data_source + '.value')]?.options
                        ).map((key) => ({
                          label:
                            primary_filter[get(selected_filter, data_source + '.value')]?.options[
                              key
                            ],
                          value: key,
                        }))}
                        onChange={(value) => handleCheckboxChange(value, data_source)}
                        value={selected_checkbox[data_source]}
                      />
                    </div>
                  )}
              </span>
            )
          })}
          {/*<Select value={selected_filter.value} onChange={onChangeFilter}>*/}
          {/*    {primary_filter_list.map((item) => (*/}
          {/*        <Option key={item.id} value={item.id} filter_type='primary'>{item.name}</Option>*/}
          {/*  ))}*/}
          {/*    {secondary_filter_obj.id && (<Option key={secondary_filter_obj.id} value={secondary_filter_obj.id} filter_type='secondary'>{secondary_filter_obj.name}</Option>)}*/}
          {/*</Select>*/}
          {/*<Button style={{ marginLeft: "5px" }} icon={<ReloadOutlined />} type="link" onClick={resetCheckboxClick}>Reset</Button>*/}
        </div>
        {/*
            {selected_filter.type == 'primary' && primary_filter[selected_filter.value] && (
                <div>
                    <Checkbox.Group options={Object.keys(primary_filter[selected_filter.value].options).map(key => ({label: primary_filter[selected_filter.value].options[key], value:key}))}
                                    onChange={handleCheckboxChange} value={selected_checkbox}/>
                </div>
            )}

            {selected_filter.type == 'secondary' && secondary_filter[selected_filter.value] && (
                <div>
                    <Checkbox.Group options={Object.keys(secondary_filter[selected_filter.value].options).map(key => ({label: secondary_filter[selected_filter.value].options[key], value:key}))}
                                    onChange={handleCheckboxChange} value={selected_checkbox}/>
                </div>
            )}
            */}
      </div>
    </div>
  )
}
export default BarStackedChart
