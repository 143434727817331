import React, { useEffect, useState } from 'react'
import { Tabs, InputNumber, Radio, Space, Table, Modal, Button, Input } from 'antd'
import { PageServiceService } from 'services'
import get from 'lodash/get'

const FieldModal = ({ visible, setFieldModal, addField, index, tabIndex, addNestedField }) => {
  const [fields, setFields] = useState([])
  const [allFields, setAllFields] = useState([])
  const [count, setCount] = useState(0)
  useEffect(() => {
    getFields()
  }, [visible])
  const getFields = async (page) => {
    if (visible && !fields.length) {
      try {
        let res = await PageServiceService.FieldList()
        setCount(get(res, 'data.count', 0))
        setAllFields(get(res, 'data.data', []))
        setFields(get(res, 'data.data', []))
      } catch (err) {}
    }
  }
  return (
    <Modal
      onOk={() => setFieldModal(false)}
      onCancel={() => setFieldModal(false)}
      visible={visible}
    >
      <Input.Group compact style={{ width: '100%' }}>
        <Input.Search
          onChange={(e) => {
            let arr = allFields.filter((item) =>
              item.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
            setFields(arr)
          }}
          allowClear
          style={{ width: '100%' }}
        />
      </Input.Group>
      <Table
        className="custom_responsive_table"
        dataSource={fields}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (index != undefined && tabIndex != undefined) {
                addNestedField(index, tabIndex, record)
              } else {
                addField(record)
              }
              setFieldModal(false)
            },
          }
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'id',
            render: (text, e) => (
              <div className="table_responsive_cell">
                <h6>Name</h6>
                <b>{e.display_name || e.name}</b>
              </div>
            ),
          },
          {
            title: 'Category',
            dataIndex: 'category',
            key: 'id',
            render: (text, e) => (
              <div className="table_responsive_cell">
                <h6>Category</h6>
                {text}
              </div>
            ),
          },
        ]}
      />
    </Modal>
  )
}

export default FieldModal
