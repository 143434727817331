import React, { useState, useEffect } from 'react'
import { AdminLayout } from 'Components'
import { Table, Select, Button, Space, Input } from 'antd'
import { useNavigate } from 'react-router-dom'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { SubscriptionService } from 'services'
import { get } from 'lodash'
const { TextArea } = Input
const { Option } = Select

export default function PricingPlan(props) {
  const [data, SetPlan] = useState([])
  let navigate = useNavigate()

  const columns = [
    {
      title: 'Plan Name',
      dataIndex: 'plan_name',
      key: 'plan_name',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Type',
      dataIndex: 'plan_type',
      key: 'plan_type',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/setting/pricing-plan/edit/' + record.id)}
          />
          <Button icon={<EyeOutlined />} size={'medium'} />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={() => PricingPlanDeleteClick(record.id, record.plan_name)}
          />
        </Space>
      ),
    },
  ]

  const PricingPlanList = async () => {
    const res = await SubscriptionService.GetPricingPlanList()
    await SetPlan(get(res, 'data.data'))
  }
  useEffect(() => {
    PricingPlanList()
  }, [])

  const PricingPlanDeleteClick = async (id, plan_name) => {
    const result = await window.confirm(`Are you sure to delete ${plan_name} plan ?`)
    if (result) {
      await SubscriptionService.PricingPlanDelete(id)
      await PricingPlanList()
    }
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }
  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Manage Pricing Plans</span>{' '}
          <Button
            onClick={() => {
              navigate('/admin/setting/pricing-plan/add', { push: true })
            }}
            icon={<PlusOutlined />}
            type="primary"
          >
            Add Plan
          </Button>
        </h1>
        <Table columns={columns} dataSource={data} />
      </div>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}
