import React, { useEffect, useState } from 'react'
import {Button, Form, Input, Modal, Space, Table, Row, Col, Select, Tabs, Checkbox} from 'antd'
import {
  CreditCardOutlined,
  DeleteOutlined,
  DollarOutlined,
  EditOutlined,
  PieChartOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ProductService } from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'
import CustomTree from 'Components/CustomTree/CustomTree'
import { useNavigate } from 'react-router-dom'

const { Option } = Select
const { TabPane } = Tabs

export default function Product({ preview, business_list }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [provider_list, setProviderList] = useState([])
  const [product_type_list, setProductTypeList] = useState([])
  const [investment_option_type_list, setInvestmentOptionTypeList] = useState([])
  const [investment_options_list, setInvestmentOptionsList] = useState([])
  const [exchange_list, setExchangeList] = useState([])
  const [webLinks, setWebLinks] = useState([])
  const [videoLinks, setVideoLinks] = useState([])
  const [permissions, setPermissions] = useState({})
  const [activeKey, setActiveKey] = useState('1')

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')

  let navigate = useNavigate()

  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Provider name',
      dataIndex: 'provider',
      key: 'provider',
      sorter: (a, b) => {
        if (a.provider && b.provider) {
          return a.provider.subject.localeCompare(b.provider.subject)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Provider name</h6>
          {record.provider ? record.provider.subject : ''}
        </div>
      ),
    },
    {
      title: 'Product name',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Product name</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Product code',
      dataIndex: 'product_code',
      key: 'product_code',
      sorter: (a, b) => {
        if (a.product_code && b.product_code) {
          return a.product_code.localeCompare(b.product_code)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Provider code</h6>
          {record.product_code}
        </div>
      ),
    },
    {
      title: 'Product type',
      dataIndex: 'product_type',
      key: 'product_type',
      sorter: (a, b) => {
        if (a.product_type && b.product_type) {
          return a.product_type.subject.localeCompare(b.product_type.subject)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Provider name</h6>
          {record.product_type ? record.product_type.subject : ''}
        </div>
      ),
    },
    {
      title: 'Exchange',
      dataIndex: 'exchange',
      key: 'exchange',
      sorter: (a, b) => {
        if (a.exchange && b.exchange) {
          return a.exchange.subject.localeCompare(b.exchange.subject)
        }
        return 0
      },
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Provider name</h6>
          {record.exchange ? record.exchange.subject : ''}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<DollarOutlined />} size="medium" onClick={() => onClickFee(record.id)} />
          <Button
            icon={<PieChartOutlined />}
            size="medium"
            onClick={() => onClickAssetAllocation(record.id)}
          />
          <Button
            icon={<CreditCardOutlined />}
            size="medium"
            onClick={() => onClickFeeCharges(record.id)}
          />
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setWebLinks([])
    setVideoLinks([])
    setPermissions({})
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
    getProviderList(true)
    getInvestmentOptionList(false)
    getProductTypeList()
    getInvestmentOptionTypeList()
    getExchangeList()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    getProviderList(true)
    getInvestmentOptionList(false)
    getProductTypeList()
    getInvestmentOptionTypeList()
    getExchangeList()
    await setId(id)
    await setIsModalVisible(true)
    const res = await ProductService.getDetailProduct(id)
    formik.setValues(get(res, 'data.data'))
    setWebLinks(get(res, 'data.data.web_links', []))
    setVideoLinks(get(res, 'data.data.video_links', []))
    setPermissions(get(res, 'data.data.permissions', {}))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this product?')
    if (result) {
      await ProductService.deleteProduct(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await ProductService.getProduct({ business: business })
    setData(get(res, 'data.data', []))
  }

  const getProviderList = async (is_account_provider) => {
    const res = await ProductService.getProvider({ business: business , is_account_provider})
    setProviderList(get(res, 'data.data', []))
  }

  const getInvestmentOptionList = async (is_for_investment) => {
    const res = await ProductService.getProvider({ business: business , is_for_investment})
    setInvestmentOptionsList(get(res, 'data.data', []))
  }

  const getProductTypeList = async () => {
    const res = await ProductService.getProductType({ business: business })
    setProductTypeList(get(res, 'data.data', []))
  }

  const getInvestmentOptionTypeList = async () => {
    const res = await ProductService.getInvestmentOptionType({
      business: business,
    })
    setInvestmentOptionTypeList(get(res, 'data.data', []))
  }

  const getExchangeList = async () => {
    const res = await ProductService.getExchange({ business: business })
    setExchangeList(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    provider: yup.string().required('Provider Required'),
    subject: yup.string().required('Subject Required'),
    product_code: yup.string(),
    product_type: yup.string(),
    investment_option_type: yup.array(),
    investment_option: yup.array(),
    exchange: yup.string(),
    description: yup.string(),
    intro: yup.string(),
    body_text: yup.string(),
    summary: yup.string(),
    pros: yup.string(),
    cons: yup.string(),
    is_asset_account: yup.boolean(),
    is_general_insurance_product: yup.boolean(),
    is_health_insurance_product: yup.boolean(),
    is_life_insurance_product: yup.boolean(),
    is_liability_account: yup.boolean(),
    web_links: yup.array().of(
      yup.object().shape({
        web_link: yup.string().url('Invalid URL').required('Web link required'),
        web_name: yup.string().required('Web name required'),
      })
    ),
    video_links: yup.array().of(
      yup.object().shape({
        video_icon: yup.string().required('Video icon required'),
        video_link: yup.string().url('Invalid URL').required('Video link required'),
      })
    ),
  })

  const initialValues = {
    subject: '',
    description: '',
    intro: '',
    body_text: '',
    summary: '',
    provider: '',
    product_code: '',
    product_type: '',
    investment_option_type: [],
    investment_options: [],
    exchange: '',
    pros: '',
    cons: '',
    is_asset_account:false,
    is_liability_account: false,
    is_general_insurance_product:false,
    is_health_insurance_product:false,
    is_life_insurance_product:false,
    web_links: [],
    video_links: [],
    permissions: {},
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      values['permissions'] = permissions
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this product?')
          if (result) {
            await ProductService.updateProduct(values, id)
          }
        } else {
          await ProductService.updateProduct(values, id)
        }
      } else {
        await ProductService.saveProduct(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const onChangeProvider = (value) => {
    formik.setFieldValue('provider', value)
  }

  const onChangeProductType = (value) => {
    formik.setFieldValue('product_type', value)
  }

  const onChangeExchange = (value) => {
    formik.setFieldValue('exchange', value)
  }

  const onCheckPerMission = (checkedKeys) => {
    setPermissions(checkedKeys)
  }

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this product to global?')
    if (result) {
      const res = await ProductService.getResetProduct(id)
      formik.setValues(get(res, 'data.data'))
      setWebLinks(get(res, 'data.data.web_links', []))
      setVideoLinks(get(res, 'data.data.video_links', []))
    }
  }

  const handleAddWebLink = () => {
    setWebLinks([...webLinks, { web_link: '', web_name: '' }])
  }

  const handleAddVideoLink = () => {
    setVideoLinks([...videoLinks, { video_icon: '', video_link: '' }])
  }

  const handleWebLinkChange = (index, event) => {
    const { name, value } = event.target
    const newWebLinks = webLinks.slice()
    newWebLinks[index][name] = value
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleVideoLinkChange = (index, event) => {
    const { name, value } = event.target
    const newVideoLinks = videoLinks.slice()
    newVideoLinks[index][name] = value
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const handleRemoveWebLink = (index) => {
    const newWebLinks = webLinks.slice()
    newWebLinks.splice(index, 1)
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleRemoveVideoLink = (index) => {
    const newVideoLinks = videoLinks.slice()
    newVideoLinks.splice(index, 1)
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const onClickFee = (id) => {
    if (business) {
      navigate(
        `/product-price/?price_parent_id=${id}&price_type=product&entity=${business}&entity_type=${entity_type}`
      )
    } else {
      navigate(`/product-price/?price_parent_id=${id}&price_type=product`)
    }
  }

  const onClickAssetAllocation = (id) => {
    if (business) {
      navigate(
        `/product-asset-allocation/?price_parent_id=${id}&price_type=product&entity=${business}&entity_type=${entity_type}`
      )
    } else {
      navigate(`/product-asset-allocation/?price_parent_id=${id}&price_type=product`)
    }
  }

  const onClickFeeCharges = (id) => {
    if (business) {
      navigate(
        `/product-fee-charge/?price_parent_id=${id}&price_type=product&entity=${business}&entity_type=${entity_type}`
      )
    } else {
      navigate(`/product-fee-charge/?price_parent_id=${id}&price_type=product`)
    }
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add Product
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit Product' : 'Add Product'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Provider">
                <Select
                  mode="single"
                  name="provider"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select provider"
                  value={formik.values.provider}
                  onChange={onChangeProvider}
                >
                  <Option value=""></Option>
                  {provider_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.provider && formik.errors.provider && (
                  <span className="input-error-message">{formik.errors.provider}</span>
                )}
              </Form.Item>
              <Form.Item label="Product name">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Product name"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_asset_account"
                    onChange={formik.handleChange}
                    checked={formik.values.is_asset_account}
                >Is this asset account?
               </Checkbox>
                {formik.touched.is_asset_account && formik.errors.is_asset_account && (
                  <span className="input-error-message">{formik.errors.is_asset_account}</span>
                )}

              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_liability_account"
                    onChange={formik.handleChange}
                    checked={formik.values.is_liability_account}
                >Is this liability account?
               </Checkbox>
                {formik.touched.is_liability_account && formik.errors.is_liability_account && (
                  <span className="input-error-message">{formik.errors.is_liability_account}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_general_insurance_product"
                    onChange={formik.handleChange}
                    checked={formik.values.is_general_insurance_product}
                >Is this general insurance account?
               </Checkbox>
                {formik.touched.is_general_insurance_product && formik.errors.is_general_insurance_product && (
                  <span className="input-error-message">{formik.errors.is_general_insurance_product}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_life_insurance_product"
                    onChange={formik.handleChange}
                    checked={formik.values.is_life_insurance_product}
                >Is this life insurance account?
               </Checkbox>
                {formik.touched.is_life_insurance_product && formik.errors.is_life_insurance_product && (
                  <span className="input-error-message">{formik.errors.is_life_insurance_product}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_health_insurance_product"
                    onChange={formik.handleChange}
                    checked={formik.values.is_health_insurance_product}
                >Is this health insurance account?
               </Checkbox>
                {formik.touched.is_health_insurance_product && formik.errors.is_health_insurance_product && (
                  <span className="input-error-message">{formik.errors.is_health_insurance_product}</span>
                )}
              </Form.Item>
              <Form.Item label="Product code">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Product code"
                  name="product_code"
                  onChange={formik.handleChange}
                  value={formik.values.product_code}
                />
                {formik.touched.product_code && formik.errors.product_code && (
                  <span className="input-error-message">{formik.errors.product_code}</span>
                )}
              </Form.Item>
              <Form.Item label="Product type">
                <Select
                  mode="single"
                  name="product_type"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select product type"
                  value={formik.values.product_type}
                  onChange={onChangeProductType}
                >
                  <Option value=""></Option>
                  {product_type_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.product_type && formik.errors.product_type && (
                  <span className="input-error-message">{formik.errors.product_type}</span>
                )}
              </Form.Item>
              <Form.Item label="Exchange">
                <Select
                  mode="single"
                  name="exchange"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select exchange"
                  value={formik.values.exchange}
                  onChange={onChangeExchange}
                >
                  <Option value=""></Option>
                  {exchange_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.exchange && formik.errors.exchange && (
                  <span className="input-error-message">{formik.errors.exchange}</span>
                )}
              </Form.Item>
              <Form.Item label="Description">
                <QuillEditor
                  theme="snow"
                  name="description"
                  value={formik.values.description}
                  onChange={(value) => formik.setFieldValue('description', value)}
                />
              </Form.Item>
              <Form.Item label="Intro">
                <QuillEditor
                  theme="snow"
                  name="intro"
                  value={formik.values.intro}
                  onChange={(value) => formik.setFieldValue('intro', value)}
                />
              </Form.Item>
              <Form.Item label="Body text">
                <QuillEditor
                  theme="snow"
                  name="body_text"
                  value={formik.values.body_text}
                  onChange={(value) => formik.setFieldValue('body_text', value)}
                />
              </Form.Item>
              <Form.Item label="Summary">
                <QuillEditor
                  theme="snow"
                  name="summary"
                  value={formik.values.summary}
                  onChange={(value) => formik.setFieldValue('summary', value)}
                />
              </Form.Item>
              <Form.Item label="Pros">
                <QuillEditor
                  theme="snow"
                  name="pros"
                  value={formik.values.pros}
                  onChange={(value) => formik.setFieldValue('pros', value)}
                />
              </Form.Item>
              <Form.Item label="Cons">
                <QuillEditor
                  theme="snow"
                  name="cons"
                  value={formik.values.cons}
                  onChange={(value) => formik.setFieldValue('cons', value)}
                />
              </Form.Item>
              {webLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Web Name ${index + 1}`}>
                      <Input
                        placeholder="Web Name"
                        name="web_name"
                        value={link.web_name}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Web Link ${index + 1}`}>
                      <Input
                        placeholder="Web Link"
                        name="web_link"
                        value={link.web_link}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_link}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveWebLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddWebLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Web Link
              </Button>

              {videoLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Video Icon ${index + 1}`}>
                      <Input
                        placeholder="Video Icon"
                        name="video_icon"
                        value={link.video_icon}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_icon}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Video Link ${index + 1}`}>
                      <Input
                        placeholder="Video Link"
                        name="video_link"
                        value={link.video_link}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_link}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveVideoLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddVideoLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Video Link
              </Button>
            </Form>
          </TabPane>
          <TabPane tab="Investment Options" key="2">
            <Form.Item label="">
              <Select
                mode="multiple"
                name="investment_option_type"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select investment type"
                value={formik.values.investment_option_type}
                onChange={(value) => formik.setFieldValue('investment_option_type', value)}
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
              >
                {investment_option_type_list.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.subject}
                  </Option>
                ))}
              </Select>
              {formik.touched.investment_option_type && formik.errors.investment_option_type && (
                <span className="input-error-message">{formik.errors.investment_option_type}</span>
              )}
            </Form.Item>
            <Form.Item label="">
              <Select
                showSearch
                mode="multiple"
                name="investment_options"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select investment option"
                filterOption={(input, option) =>
                  option?.children?.toLowerCase().includes(input.toLowerCase())
                }
                value={formik.values.investment_options}
                onChange={(value) => formik.setFieldValue("investment_options", value)}
              >
                {investment_options_list.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.subject}
                  </Option>
                ))}
              </Select>
              {formik.touched.investment_options && formik.errors.investment_options && (
                <span className="input-error-message">
                  {formik.errors.investment_options}
                </span>
              )}
            </Form.Item>
          </TabPane>
          <TabPane tab="Documents" key="5">
            Documents
          </TabPane>
          <TabPane tab="Permissions" key="6">
            <CustomTree
              preview={preview}
              onCheckPerMission={onCheckPerMission}
              business_list={business_list}
              permissions={permissions}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
