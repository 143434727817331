import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Tabs, Checkbox } from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ProductService } from 'services'
import { get } from 'lodash'
import { AdminLayout } from '../../Components'
import moment from 'moment'
// Adjust the import path as needed

const { TabPane } = Tabs

export default function ProviderPrice() {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  const price_parent_id = params.get('price_parent_id')
  const price_type = params.get('price_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Date</h6>
          {moment(text).format('DD-MM-YYYY')}
        </div>
      ),
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Price</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Is price override?',
      dataIndex: 'is_price_override',
      key: 'is_price_override',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Is price override?</h6>
          {e.is_price_override ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: 'Price override',
      dataIndex: 'price_override',
      key: 'price_override',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Price override</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Source</h6>
          {text}
        </div>
      ),
    },
  ]

  if (!business) {
    columns.push({
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          {!business && (
            <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          )}
          {!business && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    let res = {}
    if (price_type === 'provider') {
      res = await ProductService.getDetailProviderPrice(id)
    } else {
      res = await ProductService.getDetailProductPrice(id)
    }
    formik.setValues(get(res, 'data.data'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this price?')
    if (result) {
      if (price_type === 'provider') {
        await ProductService.deleteProviderPrice(id)
      } else {
        await ProductService.deleteProductPrice(id)
      }
      getData()
    }
  }

  const getData = async () => {
    let res = {}
    if (price_type === 'provider') {
      res = await ProductService.getProviderPrice({
        business: business,
        [price_type]: price_parent_id,
      })
    } else {
      res = await ProductService.getProductPrice({
        business: business,
        [price_type]: price_parent_id,
      })
    }
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    date: yup.date().required('date Required'),
    price: yup.number().required('price Required'),
    is_price_override: yup.boolean(),
    source: yup.string(),
  })

  const initialValues = {
    date: '',
    price: 0.0,
    price_override: 0.0,
    is_price_override: false,
    source: '',
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values[price_type] = price_parent_id
      if (id) {
        if (price_type === 'provider') {
          await ProductService.updateProviderPrice(values, id)
        } else {
          await ProductService.updateProductPrice(values, id)
        }
      } else {
        if (price_type === 'provider') {
          await ProductService.saveProviderPrice(values)
        } else {
          await ProductService.saveProductPrice(values)
        }
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <div>
          <h1 className="flex_title">
            <span className="title">Price</span>
            <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
              Add Price
            </Button>
          </h1>
          <Table
            className="custom_responsive_table"
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={{ defaultPageSize: 50 }}
          />
          <Modal
            title={id ? 'Edit Price' : 'Add Price'}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={'100%'}
            footer={[
              <Button key="back" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="submit" type="primary" onClick={formik.handleSubmit}>
                Save
              </Button>,
            ]}
          >
            <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
              <TabPane tab="Main" key="1">
                <Form layout="vertical" className={'custom_modal_form'}>
                  <Form.Item label="Date">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="date"
                      name="date"
                      type="date"
                      onChange={formik.handleChange}
                      value={formik.values.date}
                    />
                    {formik.touched.date && formik.errors.date && (
                      <span className="input-error-message">{formik.errors.date}</span>
                    )}
                  </Form.Item>
                  <Form.Item label="Price">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="price"
                      name="price"
                      type="number"
                      onChange={formik.handleChange}
                      value={formik.values.price}
                    />
                    {formik.touched.price && formik.errors.price && (
                      <span className="input-error-message">{formik.errors.price}</span>
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      name="is_price_override"
                      checked={formik.values.is_price_override}
                      onChange={formik.handleChange}
                    >
                      Is override?
                    </Checkbox>
                    {formik.touched.is_price_override && formik.errors.is_price_override && (
                      <span className="input-error-message">{formik.errors.is_price_override}</span>
                    )}
                  </Form.Item>
                  {formik.values.is_price_override && (
                    <Form.Item label="Price override">
                      <Input
                        style={{ width: '100%' }}
                        placeholder="price_override"
                        name="price_override"
                        type="number"
                        onChange={formik.handleChange}
                        value={formik.values.price_override}
                      />
                      {formik.touched.price && formik.errors.price_override && (
                        <span className="input-error-message">{formik.errors.price_override}</span>
                      )}
                    </Form.Item>
                  )}
                  <Form.Item label="Source">
                    <Input
                      style={{ width: '100%' }}
                      placeholder="source"
                      name="source"
                      onChange={formik.handleChange}
                      value={formik.values.source}
                    />
                    {formik.touched.source && formik.errors.source && (
                      <span className="input-error-message">{formik.errors.source}</span>
                    )}
                  </Form.Item>
                </Form>
              </TabPane>
            </Tabs>
          </Modal>
        </div>
      </div>
    </>
  )

  return <AdminLayout>{Main}</AdminLayout>
}
