import api from './api'

const endpoints = {
  business: 'business/',
}

const business = (params = {}) => {
  return api.get(endpoints.business, { params })
}

const createBusiness = (data) => {
  return api.post(endpoints.business, data)
}

const updateBusiness = (id, data) => {
  return api.put(endpoints.business + id + '/', data)
}

const detailBusiness = (id, params) => {
  return api.get(endpoints.business + id + '/', { params })
}

export default {
  business,
  createBusiness,
  updateBusiness,
  detailBusiness,
}
