import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  showLoader: false,
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleLoader: (state, { payload, type }) => {
      state.showLoader = payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { toggleLoader } = appSlice.actions

export default appSlice.reducer
