import { Input, Table, Button, Space, Modal } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { PdfConfigService } from 'services'
import { get } from 'lodash'

// const data = [
//   {
//     key: '1',
//     name: 'Default Layout 1',
//   },
//   {
//     key: '2',
//     name: 'Default Layout 2',
//   },
// ];
const PDFPageLayoutList = (props) => {
  let navigate = useNavigate()
  const [data, SetData] = useState([])
  const columns = [
    {
      title: 'Layout Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Layout Name</h6>
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => {
              navigate(
                `/admin/pdf-config/default-page/edit/${params.page_config_id}/${params.page_name}/${record.id}`,
                { push: true }
              )
            }}
          />
          <Button
            onClick={(e) => DeletePageLayout(record.id, record.name)}
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
          />
        </Space>
      ),
    },
  ]
  const params = useParams()
  const get_data = async () => {
    const res = await PdfConfigService.getPageLayouts({
      default_page: params.page_config_id,
    })
    SetData(
      get(res, 'data.data', []).map((item, index) => {
        return { id: item.id, name: `Layout ${index + 1}` }
      })
    )
  }

  useEffect(() => {
    get_data()
  }, [])

  const DeletePageLayout = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} layout ?`)
    if (result) {
      await PdfConfigService.deleteDefaultPage(id)
      await get_data()
    }
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">{params.page_name}</span>{' '}
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => {
              navigate(
                `/admin/pdf-config/default-page/add/${params.page_config_id}/${params.page_name}`,
                { push: true }
              )
            }}
          >
            Add New layout
          </Button>
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table className="table_responsive_cell" columns={columns} dataSource={data} rowKey="id" />
      </div>
    </AdminLayout>
  )
}

export default PDFPageLayoutList
