import React, { useState, useEffect } from 'react'
import './Chatbox.scss'
import { FormService, PageServiceService } from 'services'
import produce from 'immer'
import { get } from 'lodash'
import { Option } from 'antd/lib/mentions'

import { DatePicker, Select } from 'antd'
import moment from 'moment'
const { RangePicker } = DatePicker
const getHundredYears = () => {
  let year = moment().year()
  let years = []
  for (let i = 0; i < 100; i++) {
    years.push(year - i)
  }
  return years
}

// Mock data for demonstration
const mockData = [
  {
    questionText: 'What is MetAdviise?',
    questionId: '1',
    questionApi: 'api/react',
    actions: ['filter', 'download'],
    subQuestions: [
      {
        questionText: 'Subquestion about React',
        questionId: '1-1',
        questionApi: 'api/react-sub',
      },
    ],
  },
  {
    questionText: 'What is Profile?',
    questionId: '1',
    questionApi: 'api/react',
    actions: ['filter', 'download'],
    subQuestions: [
      {
        questionText: 'Subquestion about React',
        questionId: '1-1',
        questionApi: 'api/react-sub',
      },
    ],
  },
  // ... other top-level questions
]
const entityTypes = [
  { value: 'entity', label: 'Entity' },
  { value: 'business', label: 'Entity Business' },
  { value: 'current_user', label: 'Current User' },
  { value: 'current_business', label: 'Current Business' },
]
const vars = [
  {
    type: 'string',
    label: 'Email',
    value: 'email',
    children: [{ type: 'string', label: 'String 2', value: 'string 2' }],
  },
  { type: 'boolean', label: 'Has Car', value: true },
  { type: 'number', label: 'Number', value: 'number' },
  { type: 'attachment', label: 'Attachment', value: 'doc' },
  { type: 'date', label: 'Date', value: 'date' },
  { type: 'multi', label: 'Multi', value: 'multi' },
  { type: 'multi', label: 'Multi 2', value: 'multi 2' },
]
const fieldTypes = [
  { value: 'standalone', label: 'Standalone' },
  { value: 'group', label: 'Group' },
  { value: 'form', label: 'Form' },
]
const conditions = {
  date: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'date',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'date',
    },
    {
      label: 'Is blank',
      value: '$ib',
      type: 'date',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'date',
    },
    {
      label: 'Greater than (date)',
      value: '$gt',
      type: 'date',
    },
    {
      label: 'Greater than or equal (date)',
      value: '$gte',
      type: 'date',
    },
    {
      label: 'Less than (date)',
      value: '$lt',
      type: 'date',
    },
    {
      label: 'Less than or equal (date)',
      value: '$lte',
      type: 'date',
    },
    {
      label: 'Between (date)',
      value: '$bt',
      type: 'date range',
    },
    {
      label: 'Greater than (day)',
      value: '$dgt',
      type: 'day',
    },
    {
      label: 'Greater than or equal (day)',
      value: '$dgte',
      type: 'day',
    },
    {
      label: 'Less than (day)',
      value: '$dlt',
      type: 'day',
    },
    {
      label: 'Less than or equal (day)',
      value: '$dlte',
      type: 'day',
    },
    {
      label: 'Between (day)',
      value: '$dbt',
      type: 'day range',
    },
    {
      label: 'Greater than (month)',
      value: '$mgt',
      type: 'month',
    },
    {
      label: 'Greater than or equal (month)',
      value: '$mgte',
      type: 'month',
    },
    {
      label: 'Less than (month)',
      value: '$mlt',
      type: 'month',
    },
    {
      label: 'Less than or equal (month)',
      value: '$mlte',
      type: 'month',
    },
    {
      label: 'Between (month)',
      value: '$mbt',
      type: 'month range',
    },
    {
      label: 'Greater than (year)',
      value: '$ygt',
      type: 'year',
    },
    {
      label: 'Greater than or equal (year)',
      value: '$ygte',
      type: 'year',
    },
    {
      label: 'Less than (year)',
      value: '$ylt',
      type: 'year',
    },
    {
      label: 'Less than or equal (year)',
      value: '$ylte',
      type: 'year',
    },
    {
      label: 'Between (year)',
      value: '$ybt',
      type: 'year range',
    },
  ],
  multiple_select: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'select',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'select',
    },
    {
      label: 'In list',
      value: '$il',
      type: 'multi',
    },
    {
      label: 'Not in list',
      value: '$nil',
      type: 'multi',
    },
    {
      label: 'Is blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'none',
    },
  ],
  multi: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'select',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'select',
    },
    {
      label: 'In list',
      value: '$il',
      type: 'multi',
    },
    {
      label: 'Not in list',
      value: '$nil',
      type: 'multi',
    },
    {
      label: 'Is blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'none',
    },
    // {
    //   label: "Match to other field",
    //   value: "$mf",
    //   type: "other multi",
    // },
    // {
    //   label: "Not match to other field",
    //   value: "$nmf",
    //   type: "other multi",
    // },
  ],
  single_select: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'select',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'select',
    },
    {
      label: 'In list',
      value: '$il',
      type: 'multi',
    },
    {
      label: 'Not in list',
      value: '$nil',
      type: 'multi',
    },
    {
      label: 'Is blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'none',
    },
    // {
    //   label: "Match to other field",
    //   value: "$mf",
    //   type: "other multi",
    // },
    // {
    //   label: "Not match to other field",
    //   value: "$nmf",
    //   type: "other multi",
    // },
  ],
  attachment: [
    {
      label: 'Is blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'none',
    },
  ],
  textarea: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'text',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'text',
    },
    {
      label: 'Contains',
      value: '$c',
      type: 'text',
    },
    {
      label: 'Not Contain',
      value: '$nc',
      type: 'text',
    },
    {
      label: 'Is blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'none',
    },
    {
      label: 'Starts with',
      value: '$sw',
      type: 'text',
    },
    {
      label: 'Ends With',
      value: '$ew',
      type: 'text',
    },
  ],
  text: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'text',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'text',
    },
    {
      label: 'Contains',
      value: '$c',
      type: 'text',
    },
    {
      label: 'Not Contain',
      value: '$nc',
      type: 'text',
    },
    {
      label: 'Is blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is not blank',
      value: '$inb',
      type: 'none',
    },
    {
      label: 'Starts with',
      value: '$sw',
      type: 'text',
    },
    {
      label: 'Ends With',
      value: '$ew',
      type: 'text',
    },
  ],
  number: [
    {
      label: 'Is equal to',
      value: '$eq',
      type: 'number',
    },
    {
      label: 'Is not equal to',
      value: '$neq',
      type: 'number',
    },
    {
      label: 'Greater than',
      value: '$gt',
      type: 'number',
    },
    {
      label: 'Greater than or Equal',
      value: '$gte',
      type: 'number',
    },
    {
      label: 'Less than',
      value: '$lt',
      type: 'number',
    },
    {
      label: 'Less than or Equal',
      value: '$lte',
      type: 'number',
    },
    {
      label: 'Between',
      value: '$bt',
      type: 'number range',
    },
  ],
  boolean: [
    {
      label: 'Equal',
      value: '$eq',
      type: 'boolean',
    },
    {
      label: 'Not Equal',
      value: '$neq',
      type: 'boolean',
    },
    {
      label: 'Is Blank',
      value: '$ib',
      type: 'none',
    },
    {
      label: 'Is Not Blank',
      value: '$inb',
      type: 'none',
    },
  ],
}

// Simulate API call
const fetchApiData = (apiEndpoint) => {
  // Replace with actual API call logic
  return Promise.resolve([
    { name: 'Data 1', value: 'Value 1' },
    { name: 'Data 2', value: 'Value 2' },
  ])
}

const Chatbox = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [groups, setGroups] = useState([])
  const [forms, setFormList] = useState([])
  const [standaloneFields, setStandaloneFieldList] = useState([])
  const [messages, setMessages] = useState([])
  const [suggestions, setSuggestions] = useState(mockData)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [master, setMaster] = useState({})
  const htmlResolver = (html, masterIndex, rulesIndex) => {
    let rule = {}
    switch (html) {
      case 'text':
        return (
          <input
            onChange={(e) => setMaster((prev) => ({ ...prev, value: e.target.value }))}
            value={master.value}
          />
        )
      case 'number':
        return (
          <input
            type={'number'}
            onChange={(e) => {
              setMaster((prev) => ({ ...prev, value: e.target.value }))
            }}
            value={master.value}
          />
        )
      case 'number range':
        return (
          <span>
            <input
              placeholder="from"
              type={'number'}
              onChange={(e) => {
                let curr = master.value ? [...master.value] : []
                curr[0] = e.target.value
                setMaster((prev) => ({ ...prev, value: curr }))
              }}
              value={master?.value?.[0]}
            />
            <input
              placeholder="to"
              type={'number'}
              onChange={(e) => {
                let curr = master.value ? [...master.value] : []
                curr[1] = e.target.value
                setMaster((prev) => ({ ...prev, value: curr }))
              }}
              value={master?.value?.[1]}
            />
          </span>
        )
      case 'boolean':
        return (
          <select
            onChange={(e) => {
              setMaster((prev) => ({ ...prev, value: e.target.value }))
            }}
            value={master.value}
          >
            <option value={true}>true</option>
            <option value={false}>false</option>
          </select>
        )
      case 'date':
        return (
          <DatePicker
            format={'DD/MM/YYYY'}
            onChange={(date) => {
              setMaster((prev) => ({
                ...prev,
                value: moment(date).format('YYYY-MM-DD'),
              }))
            }}
            value={master.value ? moment(master.value) : null}
          />
        )
      case 'date range':
        return (
          <span>
            <RangePicker
              onChange={(date) => {
                let arr = master.value || []
                arr = [...arr]
                arr[0] = moment(date[0]).format('YYYY-MM-DD')
                arr[1] = moment(date[1]).format('YYYY-MM-DD')

                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={[
                master.value ? moment(master.value[0]) : null,
                master.value ? moment(master.value[1]) : null,
              ]}
            />
          </span>
        )
      case 'day':
        return (
          <Select
            mode={
              master.condition == '$dgt' ||
              master.condition == '$dgte' ||
              master.condition == '$dlt' ||
              master.condition == '$dlte'
                ? undefined
                : 'multiple'
            }
            value={master.value || []}
            onChange={(val) => {
              setMaster((prev) => ({ ...prev, value: val }))
            }}
            allowClear
            defaultValue={[]}
          >
            {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
              <Option value={item}>{item}</Option>
            ))}
          </Select>
        )
      case 'day range':
        return (
          <span>
            <select
              onChange={(e) => {
                let arr = master.value || []
                arr = [...arr]
                arr[0] = e.target.value
                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={master?.value?.[0]}
            >
              <option></option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <select
              onChange={(e) => {
                let arr = master.value || []
                arr = [...arr]
                arr[1] = e.target.value
                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={master?.value?.[1]}
            >
              <option></option>
              {Array.from({ length: 31 }, (_, i) => i + 1).map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </span>
        )
      case 'month range':
        return (
          <span>
            <select
              onChange={(e) => {
                let arr = master.value || []
                arr = [...arr]
                arr[0] = e.target.value
                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={master?.value?.[0]}
            >
              <option></option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((item) => (
                <option value={item}>
                  {moment()
                    .month(item - 1)
                    .format('MMM')}
                </option>
              ))}
            </select>
            <select
              onChange={(e) => {
                let arr = master.value || []
                arr = [...arr]
                arr[1] = e.target.value
                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={master?.value?.[1]}
            >
              <option></option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((item) => (
                <option value={item}>
                  {moment()
                    .month(item - 1)
                    .format('MMM')}
                </option>
              ))}
            </select>
          </span>
        )
      case 'month':
        return (
          <span>
            <select
              onChange={(e) => {
                setMaster((prev) => ({ ...prev, value: e.target.value }))
              }}
              value={master.value}
            >
              <option></option>
              {Array.from({ length: 12 }, (_, i) => i + 1).map((item) => (
                <option value={item}>
                  {moment()
                    .month(item - 1)
                    .format('MMM')}
                </option>
              ))}
            </select>
          </span>
        )
      case 'year':
        return (
          <span>
            <select
              onChange={(e) => {
                setMaster((prev) => ({ ...prev, value: e.target.value }))
              }}
              value={master.value}
            >
              <option></option>
              {getHundredYears().map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </span>
        )
      case 'year range':
        return (
          <span>
            <select
              onChange={(e) => {
                let arr = master.value || []
                arr = [...arr]
                arr[0] = e.target.value
                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={master.value?.[0]}
            >
              <option></option>
              {getHundredYears().map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
            <select
              onChange={(e) => {
                let arr = master.value || []
                arr = [...arr]
                arr[1] = e.target.value
                setMaster((prev) => ({ ...prev, value: arr }))
              }}
              value={master.value?.[1]}
            >
              <option></option>
              {getHundredYears().map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </span>
        )
      case 'multi':
        return (
          <Select
            mode="multiple"
            allowClear
            defaultValue={[]}
            onChange={(val) => setMaster((prev) => ({ ...prev, value: val }))}
            value={master.value}
          >
            {master?.fieldDetails?.options?.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        )
      case 'select':
        return (
          <Select
            allowClear
            defaultValue={[]}
            onChange={(val) => setMaster((prev) => ({ ...prev, value: val }))}
            value={master.value}
          >
            {master?.fieldDetails?.options?.map((item) => (
              <Option value={item.id}>{item.name}</Option>
            ))}
          </Select>
        )
      case 'none':
        return null
      default:
        return null
    }
  }
  const getGroupList = () => {
    PageServiceService.GroupList().then((res) => {
      setGroups(
        res?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      )
    })
  }
  const getGroup = (id) => {
    PageServiceService.GroupDetail(id, { detail: true }).then((res) => {
      setStandaloneFieldList(
        res?.data?.data?.fields?.map((item) => ({
          ...item.field,
          label: item.field.name,
          value: item.field.id,
          options: item.field.options,
        })) || []
      )
    })
  }
  const getFormList = () => {
    FormService.getForms().then((res) => {
      setFormList(
        res?.data?.data?.map((item) => ({
          value: item.id,
          label: item.name,
        })) || []
      )
    })
  }
  const getForm = (id) => {
    PageServiceService.FormDetail(id, { detail: true }).then((res) => {
      setStandaloneFieldList(
        res?.data?.data?.items
          ?.filter((item) => item.type === 'field')
          ?.map((item) => ({
            ...item.field,
            label: item.field.name,
            value: item.field.id,
            options: item.field.options,
          })) || []
      )
    })
  }
  const getStandaloneFieldList = () => {
    PageServiceService.FieldList('?detail=true').then((res) => {
      setStandaloneFieldList(
        res?.data?.data?.map((item) => ({
          ...item,
          value: item.id,
          label: item.name,
        })) || []
      )
    })
  }
  const toggleChatbox = () => {
    setIsOpen(!isOpen)
    if (!isOpen) {
      setMessages([])
      setSuggestions(mockData)
    }
  }

  const onInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const onSelectSuggestion = (question) => {
    setMessages(messages.concat({ type: 'sent', text: question.questionText }))
    setCurrentQuestion(question)
    setInputValue('')

    fetchApiData(question.questionApi).then((data) => {
      setMessages((prevMessages) => prevMessages.concat({ type: 'received', data }))
      setSuggestions(question.subQuestions.concat(question.actions))
    })
  }

  const renderSuggestions = () => {
    if (!master.entityType) {
      return (
        <>
          {entityTypes.map((item) => (
            <div
              className="suggestion"
              value="entity"
              onClick={() => {
                setMaster((prev) => ({ ...prev, entityType: item.value }))
              }}
            >
              {item.label}
            </div>
          ))}
        </>
      )
    }
    if (master.entityType && !master.fieldType) {
      return (
        <>
          {fieldTypes.map((item) => (
            <div
              className="suggestion"
              value="entity"
              onClick={() => {
                setMaster((prev) => ({ ...prev, fieldType: item.value }))
                if (item.value === 'group') {
                  getGroupList()
                }
                if (item.value === 'form') {
                  getFormList()
                }
                if (item.value === 'standalone') {
                  getStandaloneFieldList()
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </>
      )
    }
    if (
      master.fieldType &&
      (master.fieldType === 'form' || master.fieldType === 'group') &&
      !master.form &&
      !master.group &&
      !master.field
    ) {
      let list = []
      if (master.fieldType === 'form') {
        list = forms
      } else if (master.fieldType === 'group') {
        list = groups
      }
      return (
        <>
          {list.map((item) => (
            <div
              className="suggestion"
              value="entity"
              onClick={() => {
                if (master.fieldType === 'group') {
                  setMaster((prev) => ({ ...prev, group: item.value }))
                  getGroup(item.value)
                }
                if (master.fieldType === 'form') {
                  setMaster((prev) => ({ ...prev, form: item.value }))
                  getForm(item.value)
                }
              }}
            >
              {item.label}
            </div>
          ))}
        </>
      )
    }
    if ((master.fieldType === 'standalone' || master.form || master.group) && !master.field) {
      return (
        <>
          {standaloneFields.map((item) => (
            <div
              className="suggestion"
              value="entity"
              onClick={() => {
                setMaster((prev) => ({
                  ...prev,
                  field: item.value,
                  type: item.type,
                  fieldDetails: item,
                }))
              }}
            >
              {item.label + '-------   ' + item.type}
            </div>
          ))}
        </>
      )
    }
    if (master.field && !master.condition) {
      return (
        <>
          {conditions[master.type].map((item) => (
            <div
              className="suggestion"
              value="entity"
              onClick={() => {
                setMaster((prev) => ({
                  ...prev,
                  condition: item.value,
                  html: item.type,
                }))
              }}
            >
              {item.label}
            </div>
          ))}
        </>
      )
    }
    if (master.condition) {
      return htmlResolver(master.html, 1, 1)
    }
  }

  const renderTableCellValue = (key) => {
    switch (key) {
      case 'entityType':
        return entityTypes.filter((item) => item.value === master[key])[0]?.label
      case 'fieldType':
        return fieldTypes.filter((item) => item.value === master[key])[0]?.label
      case 'form':
        return forms.filter((item) => item.value === master[key])[0]?.label
      case 'group':
        return groups.filter((item) => item.value === master[key])[0]?.label
      case 'field':
        return standaloneFields.filter((item) => item.value === master[key])[0]?.label
      case 'condition':
        return conditions[master.type].filter((item) => item.value === master.condition)[0].label
      case 'value':
        return renderConditionValue(master.html)
      default:
        return master[key]
    }
  }
  const renderConditionValue = (html) => {
    console.log('html', html)
    switch (html) {
      case 'number range':
        return `${master.value[0]} to ${master.value[1]}`
      case 'date range':
        return `${master.value[0]} to ${master.value[1]}`
      case 'day range':
        return `${master.value[0]} to ${master.value[1]}`
      case 'year range':
        return `${master.value[0]} to ${master.value[1]}`
      case 'month range':
        return `${moment()
          .month(master.value[0] - 1)
          .format('MMM')} to ${moment()
          .month(master.value[1] - 1)
          .format('MMM')}`
      case 'month':
        return `${moment()
          .month(master.value - 1)
          .format('MMM')}`
      case 'multi':
        return getMultiFieldValueLabel(master.value)
      case 'select':
        return getSingleFieldValueLabel(master.value)
      default:
        return master.value
    }
  }
  const getMultiFieldValueLabel = (value = []) => {
    let obj = {}
    value.forEach((item) => (obj[item] = true))
    let options = master?.fieldDetails?.options || []
    return options
      .filter((item) => (obj[item.id] ? true : false))
      .map((item) => item.name)
      .join(', ')
  }
  const getSingleFieldValueLabel = (value = '') => {
    let options = master?.fieldDetails?.options || []

    return options.filter((item) => (item.id === value ? true : false))[0].name
  }

  const handleDeleteRow = (item) => {
    console.log('item', item)
    const obj = { ...master }
    if (item === 'entityType') {
      setMaster({})
      return
    }
    if (item === 'fieldType') {
      delete obj.fieldType
      delete obj.condition
      delete obj.field
      delete obj.fieldDetails
      delete obj.group
      delete obj.type
      delete obj.html
      delete obj.value
      setMaster(obj)
      return
    }
    if (item === 'form' || item === 'group') {
      delete obj.condition
      delete obj.field
      delete obj.fieldDetails
      delete obj.group
      delete obj.form
      delete obj.type
      delete obj.html
      delete obj.value
      setMaster(obj)
      return
    }
    if (item === 'field') {
      delete obj.condition
      delete obj.field
      delete obj.fieldDetails
      delete obj.type
      delete obj.html
      delete obj.value
      setMaster(obj)
      return
    }
    if (item === 'condition') {
      delete obj.condition
      delete obj.html
      delete obj.value
      setMaster(obj)
      return
    }
    if (item === 'value') {
      delete obj.value
      setMaster(obj)
      return
    }
  }
  const renderOutputTable = () => {
    return (
      <div className={`message `}>
        <table>
          <tbody>
            {Object.keys(master)
              .filter((item) => item !== 'fieldDetails' && item !== 'type' && item !== 'html')
              .map((item) => (
                <tr>
                  <td>{item}</td>
                  <td>{renderTableCellValue(item)}</td>
                  <td>
                    <button onClick={() => handleDeleteRow(item)}>Delete</button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    )
  }

  const clearChat = () => {
    setMaster({})
    setMessages([])
    setSuggestions(mockData)
    setCurrentQuestion(null)
  }

  return (
    <div className="chatbox-container">
      <div className={`chatbox-icon ${isOpen ? 'hidden' : ''}`} onClick={toggleChatbox}>
        Chat
      </div>
      {isOpen && (
        <div className="chatbox">
          <div className="chat-header">
            <button onClick={toggleChatbox}>Close</button>
            <button onClick={clearChat}>Clear</button>
          </div>
          <div className="chat-body">{renderOutputTable()}</div>
          <div className="chat-footer">
            <input type="text" value={inputValue} onChange={onInputChange} />
            <div className="suggestions-container" style={{ maxHeight: 200, overflow: 'scroll' }}>
              {renderSuggestions()}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default Chatbox
