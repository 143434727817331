import React from 'react'
import { AdminLayout } from 'Components'
import { Button, Input, Select } from 'antd'
import Flow from 'Components/Flow/flow'

const { Option } = Select
const { TextArea } = Input

const TaskFlow = (props) => {
  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Task Mapper</span>
          <div>
            {' '}
            <Button type="primary">Save</Button>
          </div>
        </h1>
        <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ flex: 1, marginLeft: 0 }} className="form_field">
            <label style={{ marginBottom: 5, display: 'block' }}>Thread name</label>
            <div>
              <Input required={true} />
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 10 }} className="form_field">
            <label style={{ marginBottom: 5, display: 'block' }}>Display name</label>
            <div>
              <Input required={true} />
            </div>
          </div>
          <div style={{ flex: 1, marginLeft: 10 }} className="form_field">
            <label style={{ marginBottom: 5, display: 'block' }}>Owner</label>
            <div>
              <Select style={{ width: '100%' }} placeholder={'Select owner'}>
                <Option value="ravi">Ravi</Option>
                <Option value="ram">Ram</Option>
              </Select>
            </div>
          </div>
        </div>

        <div style={{ width: '100%', marginBottom: 15 }} className="form_field">
          <label style={{ marginBottom: 5, display: 'block' }}>Description</label>
          <div>
            <TextArea rows={2} />
          </div>
        </div>

        <div className="task_flow">
          <Flow />
        </div>
      </div>
    </AdminLayout>
  )
}

export default TaskFlow
