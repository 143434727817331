import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, InputNumber, Select, Button, Modal, Table, Checkbox, Switch } from 'antd'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import * as yup from 'yup'
import { BusinessService, PageServiceService, PdfConfigService } from 'services'
import _, { get } from 'lodash'
const { Option } = Select
const { Search } = Input
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, e) => (
      <div className="table_responsive_cell">
        <h6>Name</h6>
        <b>{e.name}</b>
      </div>
    ),
  },
]

const AddPDFTemplate = (props) => {
  let navigate = useNavigate()
  let params = useParams()

  const [name, setName] = useState('')
  const [display_name, setDisplayName] = useState('')
  const [short_name, setShortName] = useState('')
  const [visibility, setVisibility] = useState([])
  const [business_list, setBusinessList] = useState([])
  const [data, SetData] = useState([]) // for page list

  const [allPages, setAllPages] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [state, setState] = useState([])

  const get_business_list = async (params = { is_active: true }) => {
    const res = await BusinessService.business(params)
    setBusinessList(get(res, 'data.data'))
  }

  const GetDefaultPageList = async () => {
    const res = await PdfConfigService.getPageConfigs()
    SetData(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    setAllPages(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    get_detail(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
  }

  useEffect(() => {
    GetDefaultPageList()
    get_business_list()
  }, [])

  const get_detail = async (data) => {
    if (params.template_id) {
      const res = await PdfConfigService.getPageTemplate(params.template_id)
      setName(get(res, 'data.data.name', ''))
      setDisplayName(get(res, 'data.data.display_name', ''))
      setShortName(get(res, 'data.data.short_name', ''))
      setVisibility(get(res, 'data.data.visibility', []).map((item) => item.id))
      setState(get(res, 'data.data.pages', []))
      const selected_rows = get(res, 'data.data.pages')
      const selected_keys = get(res, 'data.data.pages').map((item) => item.id)
      await setSelectedRows(selected_rows)
      await setSelectedRowKeys(selected_keys)

      const selectedObj = {}
      selected_rows.map((item) => (selectedObj[item.id] = item))

      let arr = data.filter((item) => (selectedObj[item.id] ? false : true))
      setSelectedRowKeys(selected_keys.filter((item) => (selectedObj[item] ? false : true)))
      setSelectedRows(selected_rows.filter((item) => (selectedObj[item] ? false : true)))
      SetData(_.orderBy(arr, ['name'], ['asc']))
      setSelectedRows([])
      setSelectedRowKeys([])
    }
  }

  const removeItem = (id) => {
    setState(state.filter((item) => item.id !== id))
  }

  // const includeToc = (e) => {
  //     if (e.target.checked){
  //         const pages =[...state,{
  //             id: "toc",
  //             display_name: "Table of contents",
  //             name: "Table of contents",
  //             default_page:{
  //                 require_signature:false
  //             }
  //         }]
  //         setState(pages);
  //     }else{
  //         removeItem("toc")
  //     }
  // };

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRows);
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const onSaveTemplate = async (e) => {
    e.preventDefault()
    if (params.template_id) {
      await PdfConfigService.updatePageTemplate(
        {
          name,
          display_name,
          short_name,
          visibility,
          ...{ pages: state },
        },
        params.template_id
      )
    } else {
      await PdfConfigService.savePageTemplate({
        name,
        display_name,
        visibility,
        short_name,
        ...{ pages: state },
      })
    }
    navigate('/admin/pdf-config')
  }

  const onClickSavePage = () => {
    const selectedObj = {}
    selectedRows.map((item) => (selectedObj[item.id] = item))

    let arr = data.filter((item) => (selectedObj[item.id] ? false : true))
    setSelectedRowKeys(selectedRowKeys.filter((item) => (selectedObj[item] ? false : true)))
    setSelectedRows(selectedRows.filter((item) => (selectedObj[item] ? false : true)))

    SetData(_.orderBy(arr, ['name'], ['asc']))
    setState((prev) => [...prev, ...selectedRows])
    setSelectedRows([])
    setSelectedRowKeys([])
    handleOk()
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Add/Edit Template</span>
          <div>
            {' '}
            <Button type="primary" onClick={onSaveTemplate}>
              Save Template
            </Button>
          </div>
        </h1>
        <div>
          <Form layout="vertical">
            <Form.Item label="Template Name">
              <Input
                style={{ width: '100%' }}
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Display Name">
              <Input
                style={{ width: '100%' }}
                placeholder="Display name"
                name="display_name"
                value={display_name}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Short Name">
              <Input
                style={{ width: '100%' }}
                placeholder="Short name"
                name="short_name"
                value={short_name}
                onChange={(e) => setShortName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Visibility to businesses">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select businesses"
                onChange={(value) => setVisibility(value)}
                value={visibility}
              >
                {business_list.map((item) => (
                  <Option key={item.id}>{item.business_name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          <div className="field_preview" style={{ marginLeft: 0 }}>
            <h2
              style={{
                marginBottom: 35,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div>
                Pages{' '}
                <Button
                  style={{ marginLeft: 50 }}
                  onClick={showModal}
                  icon={<PlusOutlined />}
                  size={'small'}
                  type="primary"
                >
                  Add Pages
                </Button>
              </div>
            </h2>
            <ReactSortable className="sortable_fields" tag="ul" list={state} setList={setState}>
              {state.map((item, i) => (
                <li className="draggable_list group_field" key={item.id}>
                  <span className="item_name">{item.name}</span>
                  <div>
                    <span>
                      {get(item, 'default_page.toc') && get(item, 'default_page.heading')
                        ? get(item, 'default_page.heading')
                        : ''}
                    </span>
                    <Checkbox
                      defaultChecked
                      disabled
                      checked={get(item, 'default_page.require_signature')}
                      title="Signature setting is controlled by admin for each page"
                    >
                      Signature required?
                    </Checkbox>
                    <Button
                      title="Manage conditions"
                      icon={<QuestionCircleOutlined />}
                      size={'medium'}
                      onClick={() => {
                        navigate(
                          `/admin/conditions?pdf=${params.template_id}&fieldId=${item.template_id}`,
                          { push: true }
                        )
                      }}
                    />{' '}
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size={'medium'}
                      onClick={() => {
                        let arr = [...data, item]
                        SetData(_.orderBy(arr, ['name'], ['asc']))
                        removeItem(item.id)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ReactSortable>
          </div>
        </div>
        <Modal
          title="Search and select page config"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" onClick={onClickSavePage}>
              Add Selected Pages
            </Button>,
          ]}
        >
          <Search
            onChange={(e) =>
              SetData(
                allPages.filter((item) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
              )
            }
            placeholder="Search fields"
            style={{ width: '100%' }}
          />
          <Table
            className="custom_responsive_table"
            rowSelection={rowSelection}
            rowKey="id"
            columns={columns}
            dataSource={data}
          />
        </Modal>
      </div>
    </AdminLayout>
  )
}

export default AddPDFTemplate
