import { Input, Table, Button, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { useEffect, useState } from 'react'
import { FormService } from 'services'
import { get } from 'lodash'

const FormList = (props) => {
  let navigate = useNavigate()
  const [forms, setForms] = useState([])
  const [allForms, setAllForms] = useState([])
  useEffect(() => {
    getForms()
  }, [])
  const getForms = async () => {
    try {
      let res = await FormService.getForms()
      setForms(get(res, 'data.data', []).map((item) => ({ ...item, key: item.id })))
      setAllForms(get(res, 'data.data', []).map((item) => ({ ...item, key: item.id })))
    } catch (err) {
      console.log('err', err)
    }
  }
  const columns = [
    {
      title: 'Form Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, e) => {
        return (
          <div className="table_responsive_cell">
            <h6>Form Name</h6>
            <b>{e.name}</b>
          </div>
        )
      },
    },
    {
      title: 'Display Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Display Name</h6>
          <b>{e.display_name || '-'}</b>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => navigate('/admin/forms/edit/' + record.id)}
          />
          <Button icon={<EyeOutlined />} size={'medium'} />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={() => DeleteFields(record.id, record.name)}
          />
        </Space>
      ),
    },
  ]

  const DeleteFields = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} form ?`)
    if (result) {
      const res = await FormService.deleteForm(id)
      await getForms()
    }
  }
  const Main = (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">Manage Forms</span>{' '}
        <Button
          onClick={() => {
            navigate('/admin/forms/add', { push: true })
          }}
          icon={<PlusOutlined />}
          type="primary"
        >
          Add Form
        </Button>
      </h1>
      <Input.Group compact style={{ width: '100%' }}>
        <Input.Search
          onChange={(e) => {
            let arr = allForms.filter((item) =>
              item.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
            setForms(arr)
          }}
          allowClear
          style={{ width: '100%' }}
        />
      </Input.Group>
      <br />
      {forms && forms.length && (
        <Table className="custom_responsive_table" columns={columns} dataSource={forms} />
      )}
    </div>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default FormList
