import React, { useState, useEffect } from 'react'
import { AdminLayout } from 'Components'
import { Form, Table, Button, Space, Modal, Input } from 'antd'
import { SubscriptionService } from 'services'
import moment from 'moment'

import { EditOutlined, EyeOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import { get } from 'lodash'
const { TextArea } = Input
// const data = [
//   {
//     key: '1',
//     name: 'User management',
//     description: "Can create/edit/delete users",
//     created_on:"20/12/2021"
//   },
//   {
//     key: '2',
//     name: 'Manage Assets',
//     description: "Can manage assets in entire system",
//     created_on:"25/12/2021"
//   },
//   {
//     key: '3',
//     name: 'Reporting',
//     description: "Print and send reports",
//     created_on:"25/12/2021"
//   },
// ];

export default function Capability(props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [name, SetName] = useState('')
  const [description, SetDescription] = useState('')
  const [current_id, SetCurrent_id] = useState('')

  const columns = [
    {
      title: 'Capability Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Capability Name</h6>
          <a>{text}</a>
        </div>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Description</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Added on',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Added on</h6>
          {moment(text).local().format('MM/DD/YYYY')}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size={'medium'} onClick={() => showModal(record.id)} />

          {/*<Button  icon={<EyeOutlined />} size={'medium'} />
                        <Button danger  icon={<DeleteOutlined />} size={'medium'} />*/}
        </Space>
      ),
    },
  ]
  const [data, SetData] = useState([])

  const RenderCapabilityListData = async () => {
    const res = await SubscriptionService.GetCapabilityList()
    await SetData(get(res, 'data.data'))
  }

  useEffect(() => {
    RenderCapabilityListData()
  }, [])

  const showModal = async (id) => {
    SetName('')
    SetDescription('')
    SetCurrent_id('')
    setIsModalVisible(true)
    const res = await SubscriptionService.GetCapabilityDetail(id)
    await SetName(get(res, 'data.data.name'))
    await SetDescription(get(res, 'data.data.description'))
    await SetCurrent_id(get(res, 'data.data.id'))
  }

  const handleOk = async () => {
    setIsModalVisible(false)
    const res = await SubscriptionService.UpdateCapability(current_id, {
      name,
      description,
    })
    await RenderCapabilityListData()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Manage Capabilities</span>
        </h1>
        <Table className="custom_responsive_table" columns={columns} dataSource={data} />
      </div>
      <Modal
        title="Add/Edit Capability"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Name">
            <Input placeholder="Loading..." disabled value={name} />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              placeholder="Describe this capability"
              rows={4}
              value={description}
              onChange={(e) => SetDescription(e.target.value)}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}
