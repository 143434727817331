import { Input, Table, Button, Modal, Space } from 'antd'
import { AdminLayout } from 'Components'
import React, { useEffect, useState } from 'react'
import { InvestmentOptionService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'

const InvestmentElement = ({ element }) => {
  const [data, setData] = useState([])
  const [total, setCount] = useState(0)
  let navigate = useNavigate()

  const columns = [
    {
      title: 'Ticker',
      dataIndex: 'ticker',
      key: 'ticker',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Ticker</h6>
          {record.ticker.ticker}
        </div>
      ),
    },
    {
      title: 'Exchange',
      dataIndex: 'exchange',
      key: 'exchange',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Exchange</h6>
          {record.ticker.exchange}
        </div>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          {record.ticker.name}
        </div>
      ),
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      key: 'industry',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Industry</h6>
          {record.ticker.industry}
        </div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Date</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Open</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'High',
      dataIndex: 'high',
      key: 'high',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>High</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Low',
      dataIndex: 'low',
      key: 'low',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Low</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Close',
      dataIndex: 'close',
      key: 'close',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Close</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      key: 'volume',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Volume</h6>
          {text}
        </div>
      ),
    },
  ]

  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)

  const InvestmentOptionRecords = async (page = 1) => {
    const res = await InvestmentOptionService.getInvestmentOption({ page })
    setData(get(res, 'data.data', []))
    setCount(get(res, 'data.count', 0))
  }

  useEffect(() => {
    InvestmentOptionRecords()
  }, [])

  return (
    <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
      <h1 className="flex_title">
        <span className="title">{element.title}</span>{' '}
      </h1>
      <Input.Group compact style={{ width: '100%' }}>
        <Input.Search allowClear style={{ width: '100%' }} />
      </Input.Group>
      <br />
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{
          showSizeChanger: false,
          total: total,
          defaultPageSize: 50,
          PageSize: 50,
          onChange: (current_page) => InvestmentOptionRecords(current_page),
        }}
      />
    </div>
  )
}

export default InvestmentElement
