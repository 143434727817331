import React from 'react'
import { HomeLayout } from 'Components'
import { StaticPageService } from 'services'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
export default function PrivacyPolicy() {
  const [privacy_and_policy, SetPrivacyPolicy] = useState('')

  const privacy_and_policyDetail = async () => {
    const res = await StaticPageService.PrivacyPolicyGet()
    await SetPrivacyPolicy(get(res, 'data.data.privacy_and_policy'))
  }

  useEffect(() => {
    privacy_and_policyDetail()
  }, [])

  return (
    <HomeLayout>
      <div className="public_pages">
        <div className="container">
          <h1>Privacy Policy</h1>
          <div
            className="static_content"
            dangerouslySetInnerHTML={{ __html: privacy_and_policy }}
          ></div>
        </div>
      </div>
    </HomeLayout>
  )
}
