import React from 'react'
import AdminLayout from '../../../Components/Layout/AdminLayout'
import './page.scss'
import { useEffect, useState } from 'react'
import { StaticPageService } from 'services'
import { get } from 'lodash'
import { Button } from 'antd'
import { useQuill } from 'react-quilljs'

export default function AdminTermsAndConditions(props) {
  const [term_and_condition, SetTermCondition] = useState('')

  const termConditionDetail = async () => {
    const res = await StaticPageService.TermConditionGet()
    await SetTermCondition(get(res, 'data.data.term_and_condition'))
  }

  useEffect(() => {
    termConditionDetail()
  }, [])

  const onClickSave = () => {
    StaticPageService.TermConditionUpdate({ term_and_condition })
  }

  const { quill, quillRef } = useQuill()
  React.useEffect(() => {
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(term_and_condition)
      quill.on('text-change', (delta, oldDelta, source) => {
        SetTermCondition(quill.root.innerHTML)
      })
    }
  }, [quill, term_and_condition])

  const Main = (
    <>
      <div className="static_admin_setting">
        <h1>Terms &amp; Conditions</h1>
        <div ref={quillRef} />
        <Button onClick={onClickSave} type="primary">
          Save
        </Button>
      </div>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}
