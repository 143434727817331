import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Line } from '@ant-design/plots'

const StockLine = ({ chart_data }) => {
  /*
  const data = [
    {
      "year": "2010",
      "value": 20,
      "category": "Portfolio A"
    },
    {
      "year": "2011",
      "value": 30,
      "category": "Portfolio A"
    },
    {
      "year": "2012",
      "value": 50,
      "category": "Portfolio A"
    },
    {
      "year": "2013",
      "value": 80,
      "category": "Portfolio A"
    },
    {
      "year": "2013",
      "value": 100,
      "category": "Portfolio A"
    },


    {
      "year": "2010",
      "value": 50,
      "category": "Portfolio B"
    },
    {
      "year": "2011",
      "value": 50,
      "category": "Portfolio B"
    },
    {
      "year": "2012",
      "value": 50,
      "category": "Portfolio B"
    },
    {
      "year": "2013",
      "value": 190,
      "category": "Portfolio B"
    },
    {
      "year": "2013",
      "value": 100,
      "category": "Portfolio B"
    },



    {
      "year": "2010",
      "value": 80,
      "category": "Portfolio C"
    },
    {
      "year": "2011",
      "value": 30,
      "category": "Portfolio C"
    },
    {
      "year": "2012",
      "value": 100,
      "category": "Portfolio C"
    },
    {
      "year": "2013",
      "value": 180,
      "category": "Portfolio C"
    },
    {
      "year": "2013",
      "value": 170,
      "category": "Portfolio C"
    },

    {
      "year": "2010",
      "value": 50,
      "category": "Portfolio D"
    },
    {
      "year": "2011",
      "value": 80,
      "category": "Portfolio D"
    },
    {
      "year": "2012",
      "value": 100,
      "category": "Portfolio D"
    },
    {
      "year": "2013",
      "value": 150,
      "category": "Portfolio D"
    },
    {
      "year": "2013",
      "value": 130,
      "category": "Portfolio D"
    },


    {
      "year": "2010",
      "value": 10,
      "category": "Portfolio E"
    },
    {
      "year": "2011",
      "value": 100,
      "category": "Portfolio E"
    },
    {
      "year": "2012",
      "value": 140,
      "category": "Portfolio E"
    },
    {
      "year": "2013",
      "value": 190,
      "category": "Portfolio E"
    },
    {
      "year": "2013",
      "value": 200,
      "category": "Portfolio E"
    },

  ]

   */

  const config = {
    data: chart_data,
    xField: 'year',
    yField: 'value',
    seriesField: 'category',
    smooth: true,
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ['#1979C9', '#D62A0D', '#FAA219'],
  }

  return <Line {...config} />
}

export default StockLine
