import api from './api'

const endpoints = {
  provider: 'provider/',
  reset_provider: 'reset-provider/',
  product: 'product/',
  reset_product: 'reset-product/',
  product_type: 'product-type/',
  investment_option_type: 'investment-option-type/',
  exchange: 'exchange/',
  provider_price: 'provider-price/',
  provider_asset_allocation: 'provider-asset-allocation/',
  provider_fee_charge: 'provider-fee-charge/',
  product_price: 'product-price/',
  product_asset_allocation: 'product-asset-allocation/',
  product_fee_charge: 'product-fee-charge/',
  asset_class: 'asset-class/',
}

const getProvider = async (params) => {
  return api.get(endpoints.provider, { params })
}

const getDetailProvider = async (id) => {
  return api.get(endpoints.provider + id + '/')
}

const getResetProvider = async (id) => {
  return api.get(endpoints.reset_provider + id + '/')
}

const saveProvider = async (data) => {
  return api.post(endpoints.provider, data)
}
const updateProvider = async (data, id) => {
  return api.put(endpoints.provider + id + '/', data)
}

const deleteProvider = async (id) => {
  return api.delete(endpoints.provider + id + '/')
}

const getProviderPrice = async (params) => {
  return api.get(endpoints.provider_price, { params })
}

const getDetailProviderPrice = async (id) => {
  return api.get(endpoints.provider_price + id + '/')
}

const saveProviderPrice = async (data) => {
  return api.post(endpoints.provider_price, data)
}
const updateProviderPrice = async (data, id) => {
  return api.put(endpoints.provider_price + id + '/', data)
}

const deleteProviderPrice = async (id) => {
  return api.delete(endpoints.provider_price + id + '/')
}

const getProviderAssetAllocation = async (params) => {
  return api.get(endpoints.provider_asset_allocation, { params })
}

const getDetailProviderAssetAllocation = async (id) => {
  return api.get(endpoints.provider_asset_allocation + id + '/')
}

const saveProviderAssetAllocation = async (data) => {
  return api.post(endpoints.provider_asset_allocation, data)
}
const updateProviderAssetAllocation = async (data, id) => {
  return api.put(endpoints.provider_asset_allocation + id + '/', data)
}

const deleteProviderAssetAllocation = async (id) => {
  return api.delete(endpoints.provider_asset_allocation + id + '/')
}

const getProviderFeeCharge = async (params) => {
  return api.get(endpoints.provider_fee_charge, { params })
}

const getDetailProviderFeeCharge = async (id) => {
  return api.get(endpoints.provider_fee_charge + id + '/')
}

const saveProviderFeeCharge = async (data) => {
  return api.post(endpoints.provider_fee_charge, data)
}
const updateProviderFeeCharge = async (data, id) => {
  return api.put(endpoints.provider_fee_charge + id + '/', data)
}

const deleteProviderFeeCharge = async (id) => {
  return api.delete(endpoints.provider_fee_charge + id + '/')
}

const getProduct = async (params) => {
  return api.get(endpoints.product, { params })
}

const getDetailProduct = async (id) => {
  return api.get(endpoints.product + id + '/')
}

const getResetProduct = async (id) => {
  return api.get(endpoints.reset_product + id + '/')
}

const saveProduct = async (data) => {
  return api.post(endpoints.product, data)
}
const updateProduct = async (data, id) => {
  return api.put(endpoints.product + id + '/', data)
}

const deleteProduct = async (id) => {
  return api.delete(endpoints.product + id + '/')
}

const getProductPrice = async (params) => {
  return api.get(endpoints.product_price, { params })
}

const getDetailProductPrice = async (id) => {
  return api.get(endpoints.product_price + id + '/')
}

const saveProductPrice = async (data) => {
  return api.post(endpoints.product_price, data)
}
const updateProductPrice = async (data, id) => {
  return api.put(endpoints.product_price + id + '/', data)
}

const deleteProductPrice = async (id) => {
  return api.delete(endpoints.product_price + id + '/')
}

const getProductAssetAllocation = async (params) => {
  return api.get(endpoints.product_asset_allocation, { params })
}

const getDetailProductAssetAllocation = async (id) => {
  return api.get(endpoints.product_asset_allocation + id + '/')
}

const saveProductAssetAllocation = async (data) => {
  return api.post(endpoints.product_asset_allocation, data)
}
const updateProductAssetAllocation = async (data, id) => {
  return api.put(endpoints.product_asset_allocation + id + '/', data)
}

const deleteProductAssetAllocation = async (id) => {
  return api.delete(endpoints.product_asset_allocation + id + '/')
}

const getProductFeeCharge = async (params) => {
  return api.get(endpoints.product_fee_charge, { params })
}

const getDetailProductFeeCharge = async (id) => {
  return api.get(endpoints.product_fee_charge + id + '/')
}

const saveProductFeeCharge = async (data) => {
  return api.post(endpoints.product_fee_charge, data)
}
const updateProductFeeCharge = async (data, id) => {
  return api.put(endpoints.product_fee_charge + id + '/', data)
}

const deleteProductFeeCharge = async (id) => {
  return api.delete(endpoints.product_fee_charge + id + '/')
}

const getProductType = async (params) => {
  return api.get(endpoints.product_type, { params })
}

const getDetailProductType = async (id) => {
  return api.get(endpoints.product_type + id + '/')
}

const saveProductType = async (data) => {
  return api.post(endpoints.product_type, data)
}
const updateProductType = async (data, id) => {
  return api.put(endpoints.product_type + id + '/', data)
}

const deleteProductType = async (id) => {
  return api.delete(endpoints.product_type + id + '/')
}

const getInvestmentOptionType = async (params) => {
  return api.get(endpoints.investment_option_type, { params })
}

const getDetailInvestmentOptionType = async (id) => {
  return api.get(endpoints.investment_option_type + id + '/')
}

const saveInvestmentOptionType = async (data) => {
  return api.post(endpoints.investment_option_type, data)
}
const updateInvestmentOptionType = async (data, id) => {
  return api.put(endpoints.investment_option_type + id + '/', data)
}

const deleteInvestmentOptionType = async (id) => {
  return api.delete(endpoints.investment_option_type + id + '/')
}

const getExchange = async (params) => {
  return api.get(endpoints.exchange, { params })
}

const getDetailExchange = async (id) => {
  return api.get(endpoints.exchange + id + '/')
}

const saveExchange = async (data) => {
  return api.post(endpoints.exchange, data)
}
const updateExchange = async (data, id) => {
  return api.put(endpoints.exchange + id + '/', data)
}

const deleteExchange = async (id) => {
  return api.delete(endpoints.exchange + id + '/')
}

const getAssetClass = async (params) => {
  return api.get(endpoints.asset_class, { params })
}

const getDetailAssetClass = async (id) => {
  return api.get(endpoints.asset_class + id + '/')
}

const saveAssetClass = async (data) => {
  return api.post(endpoints.asset_class, data)
}
const updateAssetClass = async (data, id) => {
  return api.put(endpoints.asset_class + id + '/', data)
}

const deleteAssetClass = async (id) => {
  return api.delete(endpoints.asset_class + id + '/')
}

export default {
  getProvider,
  getResetProvider,
  getDetailProvider,
  saveProvider,
  updateProvider,
  deleteProvider,
  getProduct,
  getDetailProduct,
  getResetProduct,
  saveProduct,
  updateProduct,
  deleteProduct,
  getProductType,
  getDetailProductType,
  saveProductType,
  updateProductType,
  deleteProductType,
  getExchange,
  getDetailExchange,
  saveExchange,
  updateExchange,
  deleteExchange,
  getProviderPrice,
  getDetailProviderPrice,
  saveProviderPrice,
  updateProviderPrice,
  deleteProviderPrice,
  getProviderAssetAllocation,
  getDetailProviderAssetAllocation,
  saveProviderAssetAllocation,
  updateProviderAssetAllocation,
  deleteProviderAssetAllocation,
  getProviderFeeCharge,
  getDetailProviderFeeCharge,
  saveProviderFeeCharge,
  updateProviderFeeCharge,
  deleteProviderFeeCharge,
  getProductPrice,
  getDetailProductPrice,
  saveProductPrice,
  updateProductPrice,
  deleteProductPrice,
  getProductAssetAllocation,
  getDetailProductAssetAllocation,
  saveProductAssetAllocation,
  updateProductAssetAllocation,
  deleteProductAssetAllocation,
  getProductFeeCharge,
  getDetailProductFeeCharge,
  saveProductFeeCharge,
  updateProductFeeCharge,
  deleteProductFeeCharge,
  getAssetClass,
  getDetailAssetClass,
  saveAssetClass,
  updateAssetClass,
  deleteAssetClass,
  getInvestmentOptionType,
  getDetailInvestmentOptionType,
  saveInvestmentOptionType,
  updateInvestmentOptionType,
  deleteInvestmentOptionType,
}
