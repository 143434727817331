import React, { Component } from 'react'
import { AdminLayout, CustomLine } from 'Components'
import { CustomDonutChart, CustomPieChart, CustomBarChart } from 'Components'
import { Avatar, Progress, Card, Radio } from 'antd'
import { CarOutlined } from '@ant-design/icons'
import './dashboard.scss'
// import { CustomLine } from 'Components';
import { Link } from 'react-router-dom'
import InvestmentElement from 'Pages/InvestmentElement'
import ProgressBar from 'Components/Charts/progressBar'
import BarStackedChart from '../../Components/Charts/barStacked'

export default function Dashboard({ preview, items }) {
  const renderComponent = (item) => {
    switch (item?.element?.type) {
      case 'pie':
        return <CustomPieChart element={item} preview={preview} />
      case 'investment':
        return <InvestmentElement element={item} preview={preview} />
      case 'bar':
        if (item?.element?.data_source.length > 1) {
          return <BarStackedChart element={item} preview={preview} show_title={false} />
        } else {
          return <CustomBarChart element={item} preview={preview} show_title={false} /> //show_title is used to avoid duplicate title
        }
      case 'line':
        return <CustomLine element={item} preview={preview} show_title={false} />
      case 'donut':
        return <CustomDonutChart element={item} preview={preview} show_title={false} />
      case 'progress_bar':
        return <ProgressBar element={item} preview={preview} show_title={false} />
      default:
        return null
    }
  }

  return (
    <AdminLayout preview={preview}>
      <div className="dashboard">
        <div className="row align-items-stretch">
          {/*col-md-12 col-lg-6 col-xl-4 for line, normal bar pie and progress*/}
          {/*col-md-12 col-lg-12 col-xl-12 for normal bar with multiple data source*/}
          {items?.map((item) => (
            <div
              key={item.id}
              className={
                item?.element?.data_source.length > 1
                  ? 'col-md-12 col-lg-12 col-xl-12'
                  : 'col-md-12 col-lg-6 col-xl-4'
              }
            >
              <Card
                title={item?.element?.title}
                bordered={false}
                extra={
                  <Radio.Group defaultValue="a">
                    <Radio.Button value="a">Current</Radio.Button>
                    <Radio.Button value="b">Projection</Radio.Button>
                  </Radio.Group>
                }
              >
                {renderComponent(item)}
                {/*
                <div className="card_footer">
                  <span>Data as of 21st Aug 2021</span>{" "}
                  <Link to="/">
                    View Data in Detail{" "}
                    <span className="material-icons">open_in_new</span>
                  </Link>
                </div>
                    */}
              </Card>
            </div>
          ))}
          {/* <div className="col-md-12 col-lg-6 col-xl-4">
            <Card
              title="My Net Worth"
              bordered={false}
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Current</Radio.Button>
                  <Radio.Button value="b">Projection</Radio.Button>
                </Radio.Group>
              }
            >
              <CustomDonutChart />
              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{" "}
                <Link to="/">
                  View Data in Detail{" "}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div> */}
          {/* <div className="col-md-12 col-lg-6 col-xl-4">
            <Card
              title="My Aspirations"
              bordered={false}
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Financial</Radio.Button>
                  <Radio.Button value="b">Non-Financial</Radio.Button>
                </Radio.Group>
              }
            >
              <div className="aspirations_list">
                <div className="aspiration_item">
                  <Avatar size={55} icon={<CarOutlined />} />
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Projected Funding</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
                <div className="aspiration_item">
                  <Avatar size={55} icon={<CarOutlined />} />
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Projected Funding</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
                <div className="aspiration_item">
                  <Avatar size={55} icon={<CarOutlined />} />
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Projected Funding</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
                <div className="aspiration_item">
                  <Avatar size={55} icon={<CarOutlined />} />
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Projected Funding</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
              </div>

              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{" "}
                <Link to="/">
                  View Data in Detail{" "}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div> */}
          {/* <div className="col-md-12 col-lg-6 col-xl-4">
            <Card
              title="My Cashflow"
              bordered={false}
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Current</Radio.Button>
                  <Radio.Button value="b">Projection</Radio.Button>
                </Radio.Group>
              }
            >
              <CustomPieChart />

              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{" "}
                <Link to="/">
                  View Data in Detail{" "}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div> */}
          {/* <div className="col-md-12 col-lg-6 col-xl-4">
            <Card
              title="My Investments"
              bordered={false}
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Summary</Radio.Button>
                  <Radio.Button value="b">Accounts</Radio.Button>
                </Radio.Group>
              }
            >
              <CustomBarChart />

              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{" "}
                <Link to="/">
                  View Data in Detail{" "}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div> */}
          {/* <div className="col-md-12 col-lg-6 col-xl-4">
            <Card
              title="My Protections"
              bordered={false}
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Current</Radio.Button>
                  <Radio.Button value="b">Projection</Radio.Button>
                </Radio.Group>
              }
            >
              <CustomLine />

              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{" "}
                <Link to="/">
                  View Data in Detail{" "}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div> */}
          {/* <div className="col-md-12 col-lg-6 col-xl-4">
            <Card
              title="My Budgets"
              bordered={false}
              extra={
                <Radio.Group defaultValue="a">
                  <Radio.Button value="a">Income</Radio.Button>
                  <Radio.Button value="b">Expenses</Radio.Button>
                  <Radio.Button value="c">Net</Radio.Button>
                </Radio.Group>
              }
            >
              <div className="aspirations_list">
                <div className="aspiration_item">
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Spent</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
                <div className="aspiration_item">
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Spent</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
                <div className="aspiration_item">
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Spent</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
                <div className="aspiration_item">
                  <div className="expand_flex">
                    <div className="spaced_div">
                      <div>
                        <h6>New Car</h6>
                        <span>2016</span>
                      </div>
                      <div>
                        <h6>Spent</h6>
                        <span>$31,213 of $55,000</span>
                      </div>
                    </div>
                    <Progress percent={30} />
                  </div>
                </div>
              </div>

              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{" "}
                <Link to="/">
                  View Data in Detail{" "}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div> */}
        </div>
      </div>
    </AdminLayout>
  )
}
