import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { Handle, Position } from 'react-flow-renderer'
import { EditFilled } from '@ant-design/icons'
import './task.scss'

function Task({ data }) {
  console.log('Data received', data)

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <>
      <div className="task">
        <Handle id="1" type="target" position={Position.Top} style={{ left: 0 }} />
        <Handle id="2" type="target" position={Position.Top} style={{ left: 40 }} />
        <Handle id="3" type="target" position={Position.Top} style={{ left: 80 }} />
        <Handle id="4" type="target" position={Position.Top} style={{ left: 120 }} />
        <Handle id="5" type="target" position={Position.Top} style={{ left: 160 }} />
        <Handle id="6" type="target" position={Position.Top} style={{ left: 200 }} />
        <Handle id="7" type="target" position={Position.Top} style={{ left: 240 }} />
        <Handle id="8" type="target" position={Position.Top} style={{ left: 280 }} />
        {/* <Handle id="9" type="target" position={Position.Left} style={{top: 0}}/> */}
        <Handle id="10" type="target" position={Position.Left} style={{ top: '50%' }} />
        <Handle id="11" type="target" position={Position.Left} style={{ top: '100%' }} />
        <div className="core_task_data">
          <h2 className="task_heading" title={data.name}>
            {data.name} <EditFilled onClick={showModal} />
          </h2>
          <div>
            <b>Required</b>: Yes
          </div>
          <br />
          <br />
          <div className="task_foot">
            <div>
              <b>Due Date</b>22nd Feb 2023
            </div>
            <div>
              <b>Assigned To</b>Ram Pandey
            </div>
          </div>
        </div>
        {/* <Handle id="12" type="source" position={Position.Bottom} style={{left: 0}}/> */}
        <Handle id="13" type="source" position={Position.Bottom} style={{ left: 40 }} />
        <Handle id="14" type="source" position={Position.Bottom} style={{ left: 80 }} />
        <Handle id="15" type="source" position={Position.Bottom} style={{ left: 120 }} />
        <Handle id="16" type="source" position={Position.Bottom} style={{ left: 160 }} />
        <Handle id="17" type="source" position={Position.Bottom} style={{ left: 200 }} />
        <Handle id="18" type="source" position={Position.Bottom} style={{ left: 240 }} />
        {/* <Handle id="19" type="source" position={Position.Bottom} style={{left: 280}}/> */}
        {/* <Handle id="20" type="source" position={Position.Right} style={{top: 0}}/> */}
        <Handle id="21" type="source" position={Position.Right} style={{ top: '50%' }} />
        <Handle id="22" type="source" position={Position.Right} style={{ top: '100%' }} />
      </div>
      <Modal
        title="Edit/Update Task"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <p>Dynamic Form goes here.</p>
      </Modal>
    </>
  )
}

export default Task
