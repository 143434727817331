import api from './api'

const endpoints = {
  default_page: 'default-page/',
  page_layout: 'page-layout/',
  pdf_config_page: 'pdf-config-page/',
  reset_pdf_config_page: 'reset-pdf-config-page/',
  pdf_config_template: 'pdf-config-template/',
  report_history: 'report-history/',
  report_history_latest: 'report-history-latest/',
  wizard_history_latest: 'wizard-history-latest/',
  report_history_signature: 'report-history-signature/',
  wizard_history: 'wizard-history/',
  questionnaire: 'questionnaire/',
  generatePDF: 'generate-pdf/',
}

const getDefaultPages = async (params) => {
  return api.get(endpoints.default_page, { params })
}

const getDefaultPage = async (id) => {
  return api.get(endpoints.default_page + id + '/')
}

const saveDefaultPage = async (data) => {
  return api.post(endpoints.default_page, data)
}
const updateDefaultPage = async (data) => {
  return api.put(endpoints.default_page + data.id + '/', data)
}

const deleteDefaultPage = async (id) => {
  return api.delete(endpoints.default_page + id + '/')
}

const getPageLayouts = async (params) => {
  return api.get(endpoints.page_layout, { params })
}

const getPageLayout = async (id) => {
  return api.get(endpoints.page_layout + id + '/')
}

const savePageLayout = async (data) => {
  return api.post(endpoints.page_layout, data)
}
const updatePageLayout = async (data, id) => {
  return api.put(endpoints.page_layout + id + '/', data)
}

const deletePageLayout = async (id) => {
  return api.delete(endpoints.page_layout + id + '/')
}

const getPageConfigs = async (params) => {
  return api.get(endpoints.pdf_config_page, { params })
}

const getPageConfig = async (id) => {
  return api.get(endpoints.pdf_config_page + id + '/')
}

const savePageConfig = async (data) => {
  return api.post(endpoints.pdf_config_page, data)
}
const updatePageConfig = async (data, id) => {
  return api.put(endpoints.pdf_config_page + id + '/', data)
}

const deletePageConfig = async (id) => {
  return api.delete(endpoints.pdf_config_page + id + '/')
}

const resetPageConfig = async (id) => {
  return api.get(endpoints.reset_pdf_config_page + id + '/')
}

//template
const getPageTemplates = async (params) => {
  return api.get(endpoints.pdf_config_template, { params })
}

const getPageTemplate = async (id, params) => {
  return api.get(endpoints.pdf_config_template + id + '/', { params })
}

const savePageTemplate = async (data) => {
  return api.post(endpoints.pdf_config_template, data)
}
const updatePageTemplate = async (data, id) => {
  return api.put(endpoints.pdf_config_template + id + '/', data)
}

const deletePageTemplate = async (id) => {
  return api.delete(endpoints.pdf_config_template + id + '/')
}

const createHistory = async (data) => api.post(endpoints.report_history, data)

const deleteReportHistory = async (id) => api.delete(endpoints.report_history + id + '/')

const getHistoryList = async (params) => api.get(endpoints.report_history, { params })

const getHistory = async (id) => api.get(endpoints.report_history + id + '/')

const getWizardHistoryList = async (params) => api.get(endpoints.wizard_history, { params })

const getQuestionnaire = async (id, params) =>
  api.get(endpoints.questionnaire + id + '/', { params })
const getQuestionnaires = async (params) => api.get(endpoints.questionnaire, { params })

const createWizardHistory = async (data) => api.post(endpoints.wizard_history, data)

const deleteWizardHistory = async (id) => api.delete(endpoints.wizard_history + id + '/')

const getLatestReportHistory = async (params) =>
  api.get(endpoints.report_history_latest, { params })

const getLatestWizardHistory = async (params) =>
  api.get(endpoints.wizard_history_latest, { params })

const createReportHistorySignature = async (data) =>
  api.post(endpoints.report_history_signature, data)

const getReportHistorySignatureList = async (params) =>
  api.get(endpoints.report_history_signature, { params })

const generatePDF = async (data) => api.post(endpoints.generatePDF, data)

export default {
  getQuestionnaires,
  getDefaultPages,
  getDefaultPage,
  saveDefaultPage,
  updateDefaultPage,
  deleteDefaultPage,
  getPageLayouts,
  getPageLayout,
  savePageLayout,
  updatePageLayout,
  deletePageLayout,
  getPageConfigs,
  getPageConfig,
  savePageConfig,
  updatePageConfig,
  deletePageConfig,
  resetPageConfig,
  getPageTemplates,
  getPageTemplate,
  savePageTemplate,
  updatePageTemplate,
  deletePageTemplate,
  createHistory,
  getHistoryList,
  getHistory,
  getWizardHistoryList,
  getQuestionnaire,
  createWizardHistory,
  deleteWizardHistory,
  deleteReportHistory,
  getLatestReportHistory,
  getLatestWizardHistory,
  createReportHistorySignature,
  getReportHistorySignatureList,
  generatePDF,
}
