import React, { useEffect, useState } from 'react'
import {Button, Form, Input, Modal, Space, Table, Row, Col, Tabs, Select, Checkbox} from 'antd'
import {
  DeleteOutlined,
  EditOutlined,
  DollarOutlined,
  PlusOutlined,
  PieChartOutlined,
  CreditCardOutlined,
  CheckOutlined,
  CloseOutlined
} from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { ProductService } from 'services'
import { get } from 'lodash'
import QuillEditor from 'Components/QuillEditor'
import CustomTree from 'Components/CustomTree/CustomTree'
import { useNavigate } from 'react-router-dom'

const { Option } = Select

const { TabPane } = Tabs

export default function Provider({ preview, business_list }) {
  let navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [webLinks, setWebLinks] = useState([])
  const [videoLinks, setVideoLinks] = useState([])
  const [permissions, setPermissions] = useState({})
  const [activeKey, setActiveKey] = useState('1')
  const [investment_option_type_list, setInvestmentOptionTypeList] = useState([])

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const getInvestmentOptionTypeList = async () => {
    const res = await ProductService.getInvestmentOptionType({
      business: business,
    })
    setInvestmentOptionTypeList(get(res, 'data.data', []))
  }

  const onClickFee = (id) => {
    if (business) {
      navigate(
        `/product-price/?price_parent_id=${id}&price_type=provider&entity=${business}&entity_type=${entity_type}`
      )
    } else {
      navigate(`/product-price/?price_parent_id=${id}&price_type=provider`)
    }
  }

  const onClickAssetAllocation = (id) => {
    if (business) {
      navigate(
        `/product-asset-allocation/?price_parent_id=${id}&price_type=provider&entity=${business}&entity_type=${entity_type}`
      )
    } else {
      navigate(`/product-asset-allocation/?price_parent_id=${id}&price_type=provider`)
    }
  }

  const onClickFeeCharges = (id) => {
    if (business) {
      navigate(
        `/product-fee-charge/?price_parent_id=${id}&price_type=provider&entity=${business}&entity_type=${entity_type}`
      )
    } else {
      navigate(`/product-fee-charge/?price_parent_id=${id}&price_type=provider`)
    }
  }

  const columns = [
    {
      title: 'Investment option name',
      dataIndex: 'subject',
      key: 'subject',
      sorter: (a, b) => a.subject.localeCompare(b.subject),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Investment option name</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Investment option code',
      dataIndex: 'provider_code',
      key: 'provider_code',
      width: 100,
      sorter: (a, b) => a.provider_code.localeCompare(b.provider_code),
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Investment option code</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Account provider?',
      dataIndex: 'is_account_provider',
      key: 'is_account_provider',
      width: 100,
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.is_account_provider ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: 'Insurance provider?',
      dataIndex: 'is_insurance_provider',
      key: 'is_insurance_provider',
      render: (text, e) => (
        <div className="table_responsive_cell">
          {[
    e.is_general_insurance_provider && 'General',
    e.is_health_insurance_provider && 'Health',
    e.is_life_insurance_provider && 'Life'
  ]
    .filter(Boolean)
    .join(', ')}
        </div>
      ),
    },
    {
      title: 'Not available as investment',
      dataIndex: 'is_for_investment',
      key: 'is_for_investment',
      width: 100,
      render: (text, e) => (
        <div className="table_responsive_cell">
          {e.is_for_investment ? <CheckOutlined /> : <CloseOutlined />}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<DollarOutlined />} size="medium" onClick={() => onClickFee(record.id)} />
          <Button
            icon={<PieChartOutlined />}
            size="medium"
            onClick={() => onClickAssetAllocation(record.id)}
          />
          <Button
            icon={<CreditCardOutlined />}
            size="medium"
            onClick={() => onClickFeeCharges(record.id)}
          />

          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
    setWebLinks([])
    setVideoLinks([])
    setPermissions({})
    setActiveKey('1')
  }

  const showModal = () => {
    setIsModalVisible(true)
    setInitialValue()
    getInvestmentOptionTypeList()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    await setId(id)
    await setIsModalVisible(true)
    await getInvestmentOptionTypeList()
    const res = await ProductService.getDetailProvider(id)
    formik.setValues(get(res, 'data.data'))
    setWebLinks(get(res, 'data.data.web_links', []))
    setVideoLinks(get(res, 'data.data.video_links', []))
    setPermissions(get(res, 'data.data.permissions', {}))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this provider?')
    if (result) {
      await ProductService.deleteProvider(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await ProductService.getProvider({ business: business })
    setData(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    subject: yup.string().required('Subject Required'),
    intro: yup.string(),
    body_text: yup.string(),
    summary: yup.string(),
    short_name: yup.string(),
    provider_code: yup.string(),
    contact_number: yup.string(),
    investment_option_type: yup.string(),
    is_account_provider: yup.boolean(),
    is_general_insurance_provider: yup.boolean(),
    is_health_insurance_provider: yup.boolean(),
    is_life_insurance_provider: yup.boolean(),
    is_for_investment: yup.boolean(),
    address: yup.string(),
    email: yup.string(),
    website: yup.string(),
    web_links: yup.array().of(
      yup.object().shape({
        web_link: yup.string().url('Invalid URL').required('Web link required'),
        web_name: yup.string().required('Web name required'),
      })
    ),
    video_links: yup.array().of(
      yup.object().shape({
        video_icon: yup.string().required('Video icon required'),
        video_link: yup.string().url('Invalid URL').required('Video link required'),
      })
    ),
  })

  const initialValues = {
    subject: '',
    intro: '',
    body_text: '',
    summary: '',
    short_name: '',
    provider_code: '',
    contact_number: '',
    investment_option_type: '',
    address: '',
    email: '',
    website: '',
    web_links: [],
    video_links: [],
    permissions: {},
    is_account_provider: false,
    is_general_insurance_provider:false,
    is_health_insurance_provider:false,
    is_life_insurance_provider:false,
    is_for_investment: false
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      values['permissions'] = permissions
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this investment option?')
          if (result) {
            await ProductService.updateProvider(values, id)
          }
        } else {
          await ProductService.updateProvider(values, id)
        }
      } else {
        await ProductService.saveProvider(values)
      }
      setIsModalVisible(false)
      getData()
      setInitialValue()
    },
    validationSchema: schema,
  })

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this investment option to global?')
    if (result) {
      const res = await ProductService.getResetProvider(id)
      formik.setValues(get(res, 'data.data'))
      setWebLinks(get(res, 'data.data.web_links', []))
      setVideoLinks(get(res, 'data.data.video_links', []))
    }
  }

  const handleAddWebLink = () => {
    setWebLinks([...webLinks, { web_link: '', web_name: '' }])
  }

  const handleAddVideoLink = () => {
    setVideoLinks([...videoLinks, { video_icon: '', video_link: '' }])
  }

  const handleWebLinkChange = (index, event) => {
    const { name, value } = event.target
    const newWebLinks = webLinks.slice()
    newWebLinks[index][name] = value
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleVideoLinkChange = (index, event) => {
    const { name, value } = event.target
    const newVideoLinks = videoLinks.slice()
    newVideoLinks[index][name] = value
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }

  const handleRemoveWebLink = (index) => {
    const newWebLinks = webLinks.slice()
    newWebLinks.splice(index, 1)
    setWebLinks(newWebLinks)
    formik.setFieldValue('web_links', newWebLinks)
  }

  const handleRemoveVideoLink = (index) => {
    const newVideoLinks = videoLinks.slice()
    newVideoLinks.splice(index, 1)
    setVideoLinks(newVideoLinks)
    formik.setFieldValue('video_links', newVideoLinks)
  }
  //
  //  useEffect(() => {
  //   console.log('Formik Errors:', formik.errors);
  // }, [formik.errors]);

  const onCheckPerMission = (checkedKeys) => {
    setPermissions(checkedKeys)
    // console.log(checkedKeys);
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add investment option
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit investment option' : 'Add investment option'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Tabs type="card" activeKey={activeKey} onChange={setActiveKey}>
          <TabPane tab="Main" key="1">
            <Form layout="vertical" className={'custom_modal_form'}>
              <Form.Item label="Investment option name">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Investment option name"
                  name="subject"
                  onChange={formik.handleChange}
                  value={formik.values.subject}
                />
                {formik.touched.subject && formik.errors.subject && (
                  <span className="input-error-message">{formik.errors.subject}</span>
                )}
              </Form.Item>
              <Form.Item label="Investment option code">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Investment option code"
                  name="provider_code"
                  onChange={formik.handleChange}
                  value={formik.values.provider_code}
                />
                {formik.touched.provider_code && formik.errors.provider_code && (
                  <span className="input-error-message">{formik.errors.provider_code}</span>
                )}
              </Form.Item>
              <Form.Item label="Short name">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Short name"
                  name="short_name"
                  onChange={formik.handleChange}
                  value={formik.values.short_name}
                />
                {formik.touched.short_name && formik.errors.short_name && (
                  <span className="input-error-message">{formik.errors.short_name}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_account_provider"
                    onChange={formik.handleChange}
                    checked={formik.values.is_account_provider}
                >Is this account provider?
               </Checkbox>
                {formik.touched.is_account_provider && formik.errors.is_account_provider && (
                  <span className="input-error-message">{formik.errors.is_account_provider}</span>
                )}

              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_general_insurance_provider"
                    onChange={formik.handleChange}
                    checked={formik.values.is_general_insurance_provider}
                >Is this general insurance provider?
               </Checkbox>
                {formik.touched.is_general_insurance_provider && formik.errors.is_general_insurance_provider && (
                  <span className="input-error-message">{formik.errors.is_general_insurance_provider}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_life_insurance_provider"
                    onChange={formik.handleChange}
                    checked={formik.values.is_life_insurance_provider}
                >Is this life insurance provider?
               </Checkbox>
                {formik.touched.is_life_insurance_provider && formik.errors.is_life_insurance_provider && (
                  <span className="input-error-message">{formik.errors.is_life_insurance_provider}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_health_insurance_provider"
                    onChange={formik.handleChange}
                    checked={formik.values.is_health_insurance_provider}
                >Is this health insurance provider?
               </Checkbox>
                {formik.touched.is_health_insurance_provider && formik.errors.is_health_insurance_provider && (
                  <span className="input-error-message">{formik.errors.is_health_insurance_provider}</span>
                )}
              </Form.Item>
              <Form.Item>
                <Checkbox
                    name="is_for_investment"
                    onChange={formik.handleChange}
                    checked={formik.values.is_for_investment}
                >Not available for investment
               </Checkbox>
                {formik.touched.is_for_investment && formik.errors.is_for_investment && (
                  <span className="input-error-message">{formik.errors.is_for_investment}</span>
                )}
              </Form.Item>
              <Form.Item label="Investment option type">
                <Select
                  mode="single"
                  name="investment_option_type"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="Please select investment type"
                  value={formik.values.investment_option_type}
                  onChange={(value) => formik.setFieldValue('investment_option_type', value)}
                >
                  <Option value=""></Option>
                  {investment_option_type_list.map((item) => (
                    <Option key={item.id} value={item.id}>
                      {item.subject}
                    </Option>
                  ))}
                </Select>
                {formik.touched.investment_option_type && formik.errors.investment_option_type && (
                  <span className="input-error-message">
                    {formik.errors.investment_option_type}
                  </span>
                )}
              </Form.Item>
              <Form.Item label="Contact number">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Contact number"
                  name="contact_number"
                  onChange={formik.handleChange}
                  value={formik.values.contact_number}
                />
                {formik.touched.contact_number && formik.errors.contact_number && (
                  <span className="input-error-message">{formik.errors.contact_number}</span>
                )}
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email && (
                  <span className="input-error-message">{formik.errors.email}</span>
                )}
              </Form.Item>
              <Form.Item label="Website">
                <Input
                  style={{ width: '100%' }}
                  placeholder="Website"
                  name="website"
                  onChange={formik.handleChange}
                  value={formik.values.website}
                />
                {formik.touched.website && formik.errors.website && (
                  <span className="input-error-message">{formik.errors.website}</span>
                )}
              </Form.Item>
              <Form.Item label="Address">
                <QuillEditor
                  theme="snow"
                  name="address"
                  value={formik.values.address}
                  onChange={(value) => formik.setFieldValue('address', value)}
                />
              </Form.Item>
              <Form.Item label="Intro">
                <QuillEditor
                  theme="snow"
                  name="intro"
                  value={formik.values.intro}
                  onChange={(value) => formik.setFieldValue('intro', value)}
                />
              </Form.Item>
              <Form.Item label="Body text">
                <QuillEditor
                  theme="snow"
                  name="body_text"
                  value={formik.values.body_text}
                  onChange={(value) => formik.setFieldValue('body_text', value)}
                />
              </Form.Item>
              <Form.Item label="Summary">
                <QuillEditor
                  theme="snow"
                  name="summary"
                  value={formik.values.summary}
                  rows={4}
                  onChange={(value) => formik.setFieldValue('summary', value)}
                />
              </Form.Item>

              {webLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Web Name ${index + 1}`}>
                      <Input
                        placeholder="Web Name"
                        name="web_name"
                        value={link.web_name}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_name}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Web Link ${index + 1}`}>
                      <Input
                        placeholder="Web Link"
                        name="web_link"
                        value={link.web_link}
                        onChange={(event) => handleWebLinkChange(index, event)}
                      />
                      {formik.touched.web_links && get(formik.errors, 'web_links', [])[index] && (
                        <span className="input-error-message">
                          {formik.errors.web_links[index].web_link}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveWebLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddWebLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Web Link
              </Button>

              {videoLinks.map((link, index) => (
                <Row key={index} gutter={16} className={'compact_rows'}>
                  <Col span={11}>
                    <Form.Item label={`Video Icon ${index + 1}`}>
                      <Input
                        placeholder="Video Icon"
                        name="video_icon"
                        value={link.video_icon}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_icon}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={11}>
                    <Form.Item label={`Video Link ${index + 1}`}>
                      <Input
                        placeholder="Video Link"
                        name="video_link"
                        value={link.video_link}
                        onChange={(event) => handleVideoLinkChange(index, event)}
                      />
                      {formik.touched.video_links &&
                        get(formik.errors, 'video_links', [])[index] && (
                          <span className="input-error-message">
                            {formik.errors.video_links[index].video_link}
                          </span>
                        )}
                    </Form.Item>
                  </Col>
                  <Col span={2} className={'delete_btn_column'}>
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size="medium"
                      onClick={() => handleRemoveVideoLink(index)}
                    />
                  </Col>
                </Row>
              ))}
              <Button
                type="dashed"
                onClick={handleAddVideoLink}
                style={{ width: '100%', marginBottom: '20px' }}
              >
                Add Video Link
              </Button>
            </Form>
          </TabPane>
          <TabPane tab="Asset Allocation" key="3">
            Asset Allocation date , price , price override, source>text , i will on daily basis
          </TabPane>
          <TabPane tab="Fees and charges" key="4">
            Fees and charges
          </TabPane>
          <TabPane tab="Documents" key="5">
            Documents
          </TabPane>
          <TabPane tab="Permissions" key="6">
            <CustomTree
              preview={preview}
              onCheckPerMission={onCheckPerMission}
              business_list={business_list}
              permissions={permissions}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  )
}
