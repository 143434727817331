import Task from 'Pages/Flow/task'
import React, { useState, useCallback } from 'react'
import ReactFlow, {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
} from 'react-flow-renderer'

import ButtonEdge from './connectionMaker.js'

const rfStyle = { backgroundColor: 'transparent' }
const nodeTypes = { customTask: Task }

function Flow() {
  const [nodes, setNodes] = useState([
    {
      id: '1',
      type: 'customTask',
      data: { name: 'Generate Annual Report' },
      position: { x: 250, y: 25 },
    },
    {
      id: '2',
      type: 'customTask',
      data: { name: 'Create a new form' },
      position: { x: 330, y: 350 },
    },
    {
      id: '3',
      type: 'customTask',
      data: { name: 'Any random long task which is truncated' },
      position: { x: 950, y: 675 },
    },
  ])

  const [edges, setEdges] = useState([])

  const onNodesChange = useCallback(
    (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
    [setNodes]
  )

  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  )

  const edgeTypes = { buttonedge: ButtonEdge }

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge({ ...params, type: 'buttonedge' }, eds)),
    []
  )

  const defaultEdgeOptions = { animated: true, buttonedge: ButtonEdge }

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      nodeTypes={nodeTypes}
      defaultEdgeOptions={defaultEdgeOptions}
      style={rfStyle}
    >
      <Controls />
    </ReactFlow>
  )
}

export default Flow
