import { Tree } from 'antd'
import { useEffect } from 'react'

export default function CustomTree({ preview, onCheckPerMission, business_list, permissions }) {
  const treeData = [
    {
      name: 'Business',
      id: '1',
      users: [
        {
          name: 'User 1',
          id: '3',
        },
        {
          name: 'User 2',
          id: '2',
        },
      ],
    },

    {
      name: 'Business2',
      id: '90',
      users: [
        {
          name: 'User 1',
          id: '6',
        },
        {
          name: 'User 2',
          id: '8',
        },
      ],
    },
  ]

  const checked_permissions = Object.keys(permissions)

  //   const onSelect = (selectedKeys, info) => {
  //     console.log("info",JSON.stringify(info))
  //      console.log('selected', selectedKeys, info);
  // };

  const handleCheck = (checkedKeys, info) => {
    const checkedInfo = {}
    // console.log("info",JSON.stringify(info))

    // Process fully checked nodes
    info.checkedNodes.forEach((node) => {
      if (node.users) {
        checkedInfo[node.id] = node.users.map((child) => child.id)
      }
    })

    // Process half-checked nodes
    info.halfCheckedKeys.forEach((halfCheckedKey) => {
      const parentNode = business_list.find((node) => node.id === halfCheckedKey)
      if (parentNode && parentNode.users) {
        const checkedChildren = parentNode.users
          .filter((child) => checkedKeys.includes(child.id.toString()))
          .map((child) => child.id)
        if (checkedChildren.length > 0) {
          checkedInfo[halfCheckedKey] = checkedChildren
        }
      }
    })

    // console.log('onCheck', checkedInfo);
    if (onCheckPerMission) {
      onCheckPerMission(checkedInfo)
    }
  }

  return (
    <Tree
      checkable
      // defaultExpandedKeys={['0-0-0', '0-0-1']}
      // defaultSelectedKeys={['0-0-0', '0-0-1']}
      // defaultCheckedKeys={['0-0-0', '0-0-1']}
      fieldNames={{ title: 'name', key: 'id', children: 'users' }}
      // onSelect={onSelect}
      onCheck={handleCheck}
      checkedKeys={checked_permissions}
      treeData={business_list}
    />
  )
}
