import { HomeLayout } from 'Components'
import { useEffect, useState } from 'react'
import { AuthService } from 'services'
import { useNavigate, useParams } from 'react-router-dom'

export default function ConfirmEmail() {
  let { email, token, user_type } = useParams()
  const navigate = useNavigate()
  const [message, SetMessage] = useState('Please wait Confirming email ...')
  const confirmEmailApI = (data) => {
    AuthService.verify_account(data)
      .then((res) => {
        if (res.status === 200) {
          navigate('/login')
        }
      })
      .catch(() => {
        SetMessage('Email link invalid or expired or already verified')
      })
  }
  useEffect(() => {
    confirmEmailApI({ email, token, user_type })
  })
  return (
    <HomeLayout>
      <div className="auth">
        <div className="container">
          <div className="base_form login">
            <h1>{message}</h1>
          </div>
        </div>
      </div>
    </HomeLayout>
  )
}
