import React, { Component } from 'react'
import { NavHashLink } from 'react-router-hash-link'
import './foot.scss'

export default class HomeFoot extends Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer_body">
            <span>Copyright &copy; 2021 MetAdvice</span>
            <ul>
              <li>
                <NavHashLink activeClassName="active" to="/about-us">
                  About Us
                </NavHashLink>
              </li>
              <li>
                <NavHashLink activeClassName="active" to="/contact-us">
                  Contact Us
                </NavHashLink>
              </li>
              <li>
                <NavHashLink activeClassName="active" to="/terms-and-conditions">
                  Terms &amp; Conditions
                </NavHashLink>
              </li>
              <li>
                <NavHashLink activeClassName="active" to="/privacy-policy">
                  Privacy Policy
                </NavHashLink>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    )
  }
}
