import { Input, Table, Button, Modal, Space } from 'antd'
import { AdminLayout } from 'Components'
import React, { useEffect, useState } from 'react'
import { InvestmentOptionService } from 'services'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useParams } from 'react-router-dom'
import queryString from 'query-string'

const InvestmentOptionHistoryList = (props) => {
  const [data, setData] = useState([])
  const [total, setCount] = useState(0)

  const queryString = require('query-string')
  const query_obj = queryString.parse(window.location.search)

  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Date</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Open',
      dataIndex: 'open',
      key: 'open',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Open</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'High',
      dataIndex: 'high',
      key: 'high',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>High</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Low',
      dataIndex: 'low',
      key: 'low',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Low</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Close',
      dataIndex: 'close',
      key: 'close',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Close</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Volume',
      dataIndex: 'volume',
      key: 'volume',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Volume</h6>
          {text}
        </div>
      ),
    },
  ]

  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)

  const InvestmentOptionRecords = async (page = 1) => {
    const res = await InvestmentOptionService.getInvestmentOptionHistory({
      ticker: query_obj.ticker,
      page,
    })
    setData(get(res, 'data.data', []))
  }

  useEffect(() => {
    InvestmentOptionRecords()
  }, [])

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">
            Pricing history of {query_obj.name} ({query_obj.ticker_name})
          </span>{' '}
          {/*
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate('/reports/add')}
          >
            Add Report
          </Button>
           */}
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table
          className="custom_responsive_table"
          columns={columns}
          dataSource={data}
          rowKey="id"
          pagination={{
            showSizeChanger: false,
            total: total,
            defaultPageSize: 50,
            PageSize: 50,
            onChange: (current_page) => InvestmentOptionHistoryList(current_page),
          }}
        />
      </div>
    </AdminLayout>
  )
}

export default InvestmentOptionHistoryList
