import { PageService } from 'services'
import { get } from 'lodash'

const fetchPdfConfig = async (previewUser, user) => {
  const entity = previewUser.details?.business || user.details?.business
  //for images and icons
  try {
    if (!entity) throw 400
    const res = await PageService.getPageData({
      entity,
      page: 'd1c1b901-870b-4c87-9f88-54e3bb1e6a9b',
    })
    const pdf_config_data = get(res, 'data.data.data', {})
    return PdfConfigData(pdf_config_data)
  } catch {
    return {}
  }
}

const PdfConfigData = (pdfData) => {
  const pdf_config = {
    images: {
      '023220d4-d67f-4cb6-a2b1-3eb413818799': pdfData['023220d4-d67f-4cb6-a2b1-3eb413818799']
        ? pdfData['023220d4-d67f-4cb6-a2b1-3eb413818799']
        : null,
      '702e024b-b141-424b-acfb-92ed69634b26': pdfData['702e024b-b141-424b-acfb-92ed69634b26']
        ? pdfData['4702e024b-b141-424b-acfb-92ed69634b26']
        : null,
      'e92042fe-be0f-4332-9da0-9bdaf87ffcea': pdfData['e92042fe-be0f-4332-9da0-9bdaf87ffcea']
        ? pdfData['e92042fe-be0f-4332-9da0-9bdaf87ffcea']
        : null,
      '8848a907-c18e-4226-9b78-4edc3e4ed037': pdfData['8848a907-c18e-4226-9b78-4edc3e4ed037']
        ? pdfData['8848a907-c18e-4226-9b78-4edc3e4ed037']
        : '#52c41a',
    },
    icons: {
      '519a5508-5188-4ee4-abb9-d127f572df23': pdfData['519a5508-5188-4ee4-abb9-d127f572df23']
        ? pdfData['519a5508-5188-4ee4-abb9-d127f572df23']
        : '#1890ff',
      '98fe66bb-70ea-4fed-8406-cde03a9acdba': pdfData['98fe66bb-70ea-4fed-8406-cde03a9acdba']
        ? pdfData['98fe66bb-70ea-4fed-8406-cde03a9acdba']
        : '#417505',
      '804627f9-2e61-46cb-9047-1e6ede5a5e90': pdfData['804627f9-2e61-46cb-9047-1e6ede5a5e90']
        ? pdfData['804627f9-2e61-46cb-9047-1e6ede5a5e90']
        : '#1466b5',
      '6ea9b08b-de24-4d30-a084-97006692b176': pdfData['6ea9b08b-de24-4d30-a084-97006692b176']
        ? pdfData['6ea9b08b-de24-4d30-a084-97006692b176']
        : '#52c41a',
      '9050ffd4-c637-4f38-b3d5-9f6ebaa73d1c': pdfData['9050ffd4-c637-4f38-b3d5-9f6ebaa73d1c']
        ? pdfData['9050ffd4-c637-4f38-b3d5-9f6ebaa73d1c']
        : '#52c41a',
    },
  }
  return pdf_config
}

export default {
  fetchPdfConfig,
}
