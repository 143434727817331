import { Input, Modal, Button, Space, Form, Select } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { PageService, PageServiceService, PdfConfigService } from 'services'
import React, { useRef, useState, useCallback, useEffect } from 'react'
import Nestable from 'react-nestable'
import ReactQuill from 'react-quill'
import 'quill-mention'
import { get } from 'lodash'
import TinyEditor from 'Components/TinyEditor'
import { ReactSortable } from 'react-sortablejs'
const atValues = [
  { id: 1, value: 'Fredrik Sundqvist' },
  { id: 2, value: 'Patrik Sjölin' },
]

const { Option } = Select

const AddPDFPageLayout = (props) => {
  let navigate = useNavigate()
  const params = useParams()
  const [fields, SetFields] = useState([])
  const [attachment_bg_field, SetAttachmentBgField] = useState('')
  const [image, SetImage] = useState(null)
  const [has_image, SetHasImage] = useState(false)
  const [list, setList] = useState([])
  const [specialCharData, SetSpecialCharData] = useState({
    user: [],
    client: [],
    business: [],
  })

  const myRefs = useRef([])
  const mentionModule = {
    allowedChars: /^[A-Za-z\s]*$/,
    mentionDenotationChars: ['@', '#'],
    source: function (searchTerm, renderList, mentionChar) {
      let values

      if (mentionChar === '@') {
        values = atValues
      }

      if (searchTerm.length === 0) {
        renderList(values, searchTerm)
      } else {
        const matches = []
        for (let i = 0; i < values.length; i++)
          if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
            matches.push(values[i])
        renderList(matches, searchTerm)
      }
    },
  }

  const modules = {
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@', '#'],
      source: useCallback((searchTerm, renderItem, mentionChar) => {
        let values

        if (mentionChar === '@') {
          values = atValues
        }

        if (searchTerm.length === 0) {
          renderItem(values, searchTerm)
        } else if (values) {
          const matches = []
          for (let i = 0; i < values.length; i += 1)
            if (values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
              matches.push(values[i])
          renderItem(matches, searchTerm)
        }
      }, []),
    },

    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'link'],
      [{ color: [] }, { background: [] }],
      [{ align: [] }],
      ['clean'],
      ['image'],
      ['table'],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ],
  }

  const [bgImage, setBackgroundImage] = useState('')

  const onChangePicture = (e) => {
    SetImage(e.target.files[0])
    setBackgroundImage(URL.createObjectURL(e.target.files[0]))
    SetHasImage(true)
  }

  const removeBgImage = (e) => {
    setBackgroundImage('')
    SetImage(null)
    SetHasImage(true)
  }

  const handleRemoveItem = (index) => {
    const items = [...list]
    items.splice(index, 1)
    setList(items)
  }

  const addItem = (id) => {
    var item = {
      value: '',
    }
    const items = [...list, item]
    setList(items)
  }

  const handleChange = (index, value) => {
    let items = [...list]
    items[index].value = value
    setList(items)
    console.warn('Inside onchange', items, index, value)
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const GetFieldList = async () => {
    const res = await PageServiceService.FieldList()
    SetFields(get(res, 'data.data', []))
  }

  const GetVariableList = async () => {
    const res = await PageServiceService.getVariableList()
    SetSpecialCharData(get(res, 'data.data'))
  }

  const get_detail = async () => {
    const res = await PdfConfigService.getPageLayout(params.layout_id)
    SetAttachmentBgField(get(res, 'data.data.attachment_bg_field', ''))
    setList(get(res, 'data.data.children', []))
    setBackgroundImage(get(res, 'data.data.image', ''))
  }

  const load_data = async () => {
    await GetVariableList()
    await GetFieldList()
    if (params.layout_id) {
      await get_detail()
    }
  }

  useEffect(() => {
    load_data()
  }, [])

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const SaveClick = async () => {
    let formData = new FormData()
    if (image) {
      formData.append('image', image)
    }
    formData.append('attachment_bg_field', attachment_bg_field)
    formData.append('children', JSON.stringify(list))
    formData.append('has_image', has_image)
    formData.append('default_page', params.page_config_id)

    if (params.layout_id) {
      await PdfConfigService.updatePageLayout(formData, params.layout_id)
    } else {
      await PdfConfigService.savePageLayout(formData)
    }
    navigate(`/admin/pdf-config/default-page/list/${params.page_config_id}/${params.page_name}`)
  }

  return (
    <AdminLayout>
      {/*{console.log("list",list)}*/}
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">{params.page_name}</span>{' '}
          <div>
            <Button style={{ marginRight: 5 }} onClick={showModal}>
              Preview Page
            </Button>
            <Button type="primary" onClick={SaveClick}>
              Save Page
            </Button>
          </div>
        </h1>
        <div className="form_builder_layout">
          <div className="cover_bg_controls">
            <label className="bd_set_btn" htmlFor="bgImage">
              Select bg image
            </label>
            <input id="bgImage" type="file" onChange={onChangePicture} hidden />
            <Button
              danger
              type="link"
              icon={<DeleteOutlined />}
              size={'medium'}
              onClick={removeBgImage}
            />
          </div>
          <Form.Item label="Background image" labelAlign="vertical">
            <Select
              defaultValue=""
              style={{ width: '300px' }}
              value={attachment_bg_field}
              onChange={(value) => SetAttachmentBgField(value)}
            >
              <Option value=""></Option>
              {fields
                .filter((item) => item.type === 'attachment')
                .map((item) => (
                  <Option key={item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <div
            className="form_builder_layout pdf_page"
            style={bgImage ? { backgroundImage: `url(${bgImage})` } : null}
          >
            <div
              className="form_builder"
              style={bgImage ? { backgroundColor: 'transparent' } : { backgroundColor: '#F8F8F8' }}
            >
              <ReactSortable list={list} setList={(list) => setList(list)}>
                {list.map((item, index) => {
                  return (
                    <div key={index} className="pdf_element">
                      {console.log('item', item)}
                      <TinyEditor
                        width={100}
                        height={250}
                        value={item.value}
                        onChange={(val) => handleChange(index, val)}
                        special_char_data={specialCharData}
                      />
                      <div className="elements_control">
                        <Button
                          className="conditions"
                          type="link"
                          icon={<QuestionCircleOutlined />}
                          size={'medium'}
                          onClick={() => {
                            navigate('/admin/conditions', { push: true })
                          }}
                        />
                        <Button
                          danger
                          type="link"
                          icon={<DeleteOutlined />}
                          size={'medium'}
                          onClick={() => {
                            handleRemoveItem(index)
                          }}
                        />
                      </div>
                      Use @ to access variables
                    </div>
                  )
                })}
              </ReactSortable>
            </div>
          </div>
          <div className="form_tools">
            <button className="button_tool" title="Element" onClick={addItem}>
              <span className="icon material-icons-outlined">add</span>
              <span className="text">Add</span>
            </button>
          </div>
        </div>
        <br />
      </div>
      <Modal
        title="Preview"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1300}
      >
        <div
          style={
            bgImage ? { backgroundImage: `url(${bgImage})`, padding: '30px' } : { padding: '30px' }
          }
        >
          {list.map((item, index) => {
            return <div key={index} dangerouslySetInnerHTML={{ __html: item.value }} />
          })}
        </div>
      </Modal>
    </AdminLayout>
  )
}

export default AddPDFPageLayout
