import React, { Component } from 'react'
import Nestable from 'react-nestable'
import { Select, Tabs, InputNumber, Radio, Space, Input, Modal, Cascader, Button } from 'antd'
import {
  AdminLayout,
  Boolean,
  Date,
  MultiLineText,
  MultipleSelect,
  Number,
  SingleLineText,
} from 'Components'
import '../../form.scss'
import Attachment from 'Components/Fields/attachment'
import RichText from 'Components/Fields/richText'
import SingleSelect from 'Components/Fields/singleSelect'
import Heading from 'Components/Fields/heading'
import { get } from 'lodash'
import ColorSelector from 'Components/Fields/color'
import ProviderField from 'Components/Fields/providerField'
const { TabPane } = Tabs

function FormPreview({ visible, handleOk, handleCancel, items, name, title, displayName }) {
  const _renderFields = (formData) => {
    switch (formData.type) {
      case 'text':
        return <SingleLineText {...formData} />
      case 'textarea':
        return <MultiLineText {...formData} />
      case 'attachment':
        return <Attachment {...formData} />
      case 'rich_text':
        return <RichText {...formData} />
      case 'multiple_select':
        return <MultipleSelect {...formData} />
      case 'single_select': {
          const PROVIDER_TYPES = [
            'product_provider',
            'asset_provider',
            'liability_provider',
            'general_insurance_provider',
            'life_insurance_provider',
            'health_insurance_provider'
          ];

          return PROVIDER_TYPES.includes(formData.options_type)
            ? <ProviderField {...formData} />
            : <SingleSelect {...formData} />;
        }
      case 'heading':
        return <Heading {...formData} />
      case 'boolean':
        return <Boolean {...formData} />
      case 'date':
        return <Date {...formData} />
      case 'number':
        return <Number {...formData} />
      case 'color':
        return <ColorSelector {...formData} />
      default:
        break
    }
  }
  const renderElementsOfForm = (items) => {
    return items.map((item) => {
      if (item.type == 'field') {
        return _renderFields(item.field)
      }
      if (item.type == 'group') {
        return renderGroup(item)
      }
      if (item.type == 'tab_group') {
        return renderTabs(item)
      }
      if (item.type == 'form') {
        return renderElementsOfForm(item.form.items)
      }
    })
  }
  const renderGroup = (item) => {
    console.log('hello', item.group.fields)
    return get(item, 'group.fields', []).map((field) =>
      _renderFields(typeof field.field == 'string' ? field : field.field)
    )
  }
  const renderTabs = (item) => {
    let tabs = item.tabs
    return (
      <Tabs defaultActiveKey="1" type="card" size={'small'}>
        {tabs.map((tab, i) => (
          <TabPane tab={tab.name} key={i}>
            <p>{tab.description}</p>
            {renderElementsOfForm(tab.children)}
          </TabPane>
        ))}
      </Tabs>
    )
  }
  return (
    <Modal
      visible={visible}
      title={title || 'Form preview'}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={1000}
    >
      <h2>{displayName || name}</h2>
      {renderElementsOfForm(items)}
    </Modal>
  )
}

export default FormPreview
