import React from 'react'
import { useDrop } from 'react-dnd'
import { get } from 'lodash'
import Item from './item'

const Box = ({ items, title, description, setItems, canDropItems, moveItem, label, data }) => {
  const [, drop] = useDrop({
    accept: canDropItems,
    drop: (item, monitor) => moveItem(item.id, item.from, label.toLowerCase().replace(' box', '')),
  })

  return (
    <div className="stratergy_box" ref={drop}>
      {description ? <span className="drop_instructions">{description}</span> : null}
      {title ? <span className="box_title">{title}</span> : null}
      {items.map((item) => (
        <Item
          key={item}
          id={item}
          from={label.toLowerCase().replace(' box', '')}
          moveItem={moveItem}
          obj={get(data, item, {})}
        />
      ))}
    </div>
  )
}

export default Box
