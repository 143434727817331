import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Space, Table, Row, Col, Select } from 'antd'
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { StrategyService } from 'services'
import { get } from 'lodash'
// Adjust the import path as needed

const { Option } = Select

export default function StrategyAction({ preview }) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [attribute_list, setAttributeList] = useState([])

  const queryString = window.location.search
  const params = new URLSearchParams(queryString)
  const entity_type = params.get('entity_type')
  let business = null
  if (entity_type == 'business') {
    business = params.get('entity')
  }

  useEffect(() => {
    getData()
  }, [])

  const columns = [
    {
      title: 'Attribute',
      dataIndex: 'attribute',
      key: 'attribute',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Attribute</h6>
          {record.attribute && (
            <span>{record.attribute.map((item) => item.subject).join(', ')}</span>
          )}
        </div>
      ),
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      render: (text, e) => (
        <div className="table_responsive_cell">
          <h6>Subject</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '90px',
      render: (text, record) => (
        <Space size="middle">
          <Button icon={<EditOutlined />} size="medium" onClick={() => getDetail(record.id)} />
          {!record.parent && (
            <Button
              danger
              icon={<DeleteOutlined />}
              size="medium"
              onClick={() => deleteData(record.id)}
            />
          )}
        </Space>
      ),
    },
  ]

  // Conditionally add the override  2nd last column if business is present
  if (business) {
    const secondLastIndex = columns.length - 1
    columns.splice(secondLastIndex, 0, {
      title: 'Override',
      dataIndex: 'is_override',
      key: 'is_override',
      render: (text, record) => (
        <div className="table_responsive_cell">
          <h6>Override</h6>
          {record.is_override ? 'Yes' : 'No'}
        </div>
      ),
    })
  }

  const setInitialValue = () => {
    formik.resetForm()
    formik.setValues(initialValues)
    setId(null)
  }

  const showModal = () => {
    setIsModalVisible(true)
    getAttributeList()
    setInitialValue()
  }

  const handleOk = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setInitialValue()
  }

  const getDetail = async (id) => {
    getAttributeList()
    await setId(id)
    await setIsModalVisible(true)
    const res = await StrategyService.getDetailStrategyAction(id)
    formik.setValues(get(res, 'data.data'))
  }

  const deleteData = async (id) => {
    const result = window.confirm('Are you sure to delete this action?')
    if (result) {
      await StrategyService.deleteStrategyAction(id)
      getData()
    }
  }

  const getData = async () => {
    const res = await StrategyService.getStrategyAction({ business: business })
    setData(get(res, 'data.data', []))
  }

  const getAttributeList = async () => {
    const res = await StrategyService.getStrategyAttribute({
      business: business,
    })
    setAttributeList(get(res, 'data.data', []))
  }

  let schema = yup.object().shape({
    attribute: yup
      .array()
      .of(yup.string())
      .min(1, 'Attribute must contain at least one item')
      .required('Attribute Required'),
    subject: yup.string().required('Subject Required'),
    icon: yup.string(),
  })

  const initialValues = {
    subject: '',
    icon: '',
    attribute: [],
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    onSubmit: async (values) => {
      values['business'] = business
      if (id) {
        if (business) {
          const result = window.confirm('Are you sure to override this action?')
          if (result) {
            await StrategyService.updateStrategyAction(values, id)
          }
        } else {
          await StrategyService.updateStrategyAction(values, id)
        }
      } else {
        await StrategyService.saveStrategyAction(values)
      }
      setIsModalVisible(false)
      getData()
    },
    validationSchema: schema,
  })

  const handleReset = async () => {
    await setIsModalVisible(true)
    const result = window.confirm('Are you sure to reset this action to global?')
    if (result) {
      const res = await StrategyService.getResetStrategyAction(id)
      formik.setValues(get(res, 'data.data'))
    }
  }

  return (
    <div>
      <h1 className="flex_title">
        <span className="title"></span>
        <Button onClick={showModal} icon={<PlusOutlined />} type="primary">
          Add Action
        </Button>
      </h1>
      <Table
        className="custom_responsive_table"
        columns={columns}
        dataSource={data}
        rowKey="id"
        pagination={{ defaultPageSize: 50 }}
      />
      <Modal
        title={id ? 'Edit Action' : 'Add Action'}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={'100%'}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          business && id && formik.values.is_override && (
            <Button key="reset" type="primary" onClick={handleReset}>
              Reset
            </Button>
          ),
          <Button key="submit" type="primary" onClick={formik.handleSubmit}>
            Save
          </Button>,
        ]}
      >
        <Form layout="vertical">
          <Form.Item label="Subject">
            <Input
              style={{ width: '100%' }}
              placeholder="Subject"
              name="subject"
              onChange={formik.handleChange}
              value={formik.values.subject}
            />
            {formik.touched.subject && formik.errors.subject && (
              <span className="input-error-message">{formik.errors.subject}</span>
            )}
          </Form.Item>
          <Form.Item label="Icon">
            <Input
              style={{ width: '100%' }}
              placeholder="Icon"
              name="icon"
              onChange={formik.handleChange}
              value={formik.values.icon}
            />
            {formik.touched.icon && formik.errors.icon && (
              <span className="input-error-message">{formik.errors.icon}</span>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
