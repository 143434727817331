import React, { useEffect, useState } from 'react'
import { Tabs, InputNumber, Radio, Space, Table, Modal, Button, Input } from 'antd'
import { FormService, PageServiceService } from 'services'
import get from 'lodash/get'

const FormModal = ({ visible, setFormModal, addForm, index, tabIndex, addNestedForm }) => {
  const [forms, setForms] = useState([])
  const [count, setCount] = useState(0)
  const [allForms, setAllForms] = useState([])
  useEffect(() => {
    getForms()
  }, [visible])
  const getForms = async (page) => {
    if (visible && !forms.length) {
      try {
        let res = await FormService.getForms()
        setCount(get(res, 'data.count', 0))
        setForms(get(res, 'data.data', []))
        setAllForms(get(res, 'data.data', []))
      } catch (err) {}
    }
  }
  return (
    <Modal onOk={() => setFormModal(false)} onCancel={() => setFormModal(false)} visible={visible}>
      <Input.Group compact style={{ width: '100%' }}>
        <Input.Search
          onChange={(e) => {
            let arr = allForms.filter((item) =>
              item.name.toLowerCase().includes(e.target.value.toLowerCase())
            )
            setForms(arr)
          }}
          allowClear
          style={{ width: '100%' }}
        />
      </Input.Group>
      <Table
        className="custom_responsive_table"
        dataSource={forms}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              if (index != undefined && tabIndex != undefined) {
                addNestedForm(index, tabIndex, record)
              } else {
                addForm(record)
              }
              setFormModal(false)
            },
          }
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'id',
            render: (text, e) => (
              <div className="table_responsive_cell">
                <h6>Name</h6>
                <b>{e.display_name || e.name}</b>
              </div>
            ),
          },
          {
            title: 'Category',
            dataIndex: 'category',
            key: 'id',
            render: (text) => (
              <div className="table_responsive_cell">
                <h6>Category</h6>
                {text}
              </div>
            ),
          },
        ]}
      />
    </Modal>
  )
}

export default FormModal
