import { Input, Table, Button, Modal, Space, Select, Checkbox } from 'antd'
import {DeleteOutlined, EditOutlined, PlusOutlined, SaveOutlined} from '@ant-design/icons';
import { AdminLayout } from 'Components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {AuthService, ProductService, StrategyService} from "../../services";
import {get} from "lodash";

const { Option } = Select

const InvestmentOptionTable = ({ preview, netwealth_type, advice_strategy_id, product_type, strategy_history, entity_data}) => {
  const data_config = {
    type_id: netwealth_type == "asset" ? "9021be88-1c47-4ca2-88db-db88bbff227f" : "f53af184-becd-40b5-b12d-b6c60077c287",
    subtype_id: netwealth_type == "asset" ? "1272529a-27b0-4482-8e9d-970fde5258fa" : "6fadc5ea-095f-48a7-ad1f-2aa5a84432d5"
  }
  let navigate = useNavigate()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [id, setId] = useState(null)
  const [data, setData] = useState([])
  const [activeKey, setActiveKey] = useState('1')
  const [investment_options_list, setInvestmentOptionsList] = useState([])
  const [product_list, setProductList] = useState([])
  const [provider, setProvider] = useState("")
  const [advise_for_option, setAdviseForOption] = useState({})
  const [type_option, setTypeOption] = useState({})
  const [subtype_option, setSubtypeOption] = useState({})
  const [current_history, setCurrentHistory] = useState(null)


  const [totalHistory, setTotalHistory] = useState({
    total_balance: 0,
    total_proposed_balance: 0,
  });

  const [totalOptionHistory, setTotalOptionHistory] = useState({
    total_balance: 0,
    total_proposed_balance: 0,
  });


  const calculateTotals = (dataArray) => {
  return dataArray.reduce(
    (acc, item) => {
      acc.total_balance += Number(item.balance);
      acc.total_proposed_balance += Number(item.proposed_balance);
      return acc;
    },
    { total_balance: 0, total_proposed_balance: 0 }
  );
  };


  const getInvestmentOptionList = async (is_for_investment) => {
    if (entity_data){
      const res = await ProductService.getProvider({ business: entity_data.business , is_for_investment})
      setInvestmentOptionsList(get(res, 'data.data', []))
    }
  }


  const [isModalOpen, setIsModalOpen] = useState(false);

  const strategy_option_list = ['Auto', 'Open', 'Maintain', 'Deposit']


  const getProductList = async () => {
    //add business
    let product_type_for = {'product_type': product_type}
    if (netwealth_type === "asset") {
      product_type_for = {'product_type': product_type}
    }
    const res = await ProductService.getProduct({...product_type_for, netwealth_type})
    setProductList(get(res, 'data.data', []))
  }


  // State to manage table data
  const [dataSource, setDataSource] = useState([
    {
      id: 1,
      position: 'Existing',
      account: 'Account 1',
      current_balance: '5000',
      proposed_balance: '7000',
      strategy: 'Auto',
      drp: true,
      option: ''
    },
    {
      id: 2,
      position: 'New',
      account: 'Account 2',
      current_balance: '8000',
      proposed_balance: '9000',
      strategy: 'Open deposit',
      drp: false,
      option: ''
    },
  ])

  // State to manage table data
  const [dataSource1, setDataSource1] = useState([
    {
      id: 1,
      position: 'Position 1',
      investment_option: 'Account 1',
      current_balance: '5000',
      changed_balance: '5000',
      proposed_balance: '7000',
      strategy: 'Auto',
      drp: true,
      option: ''
    },
    {
      id: 2,
      position: 'Position 2',
      investment_option: 'Account 2',
      current_balance: '8000',
      changed_balance: '8000',
      proposed_balance: '9000',
      strategy: 'Open deposit',
      drp: false,
      option: ''
    },
  ])

  const getData = async () => {
      let res_netwealth_data; // Declare res outside the conditional block

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.getAssetHistory({
          advice_strategy_id: advice_strategy_id,
          strategy_history: strategy_history
        });
      } else {
        res_netwealth_data = await StrategyService.getLiabilityHistory({
          advice_strategy_id: advice_strategy_id,
          strategy_history: strategy_history
        });
      }
      const all_data = get(res_netwealth_data, 'data.data', [])
      setDataSource(all_data);

      const newTotals = calculateTotals(all_data);
      setTotalHistory(newTotals);
    };

  function isInteger(str) {
      return /^-?\d+$/.test(str);
    }


  const saveData = async (data) => {
    let res_netwealth_data; // Declare res outside the conditional block
    if (isInteger(data.id)){
      data['advice_strategy_id'] = advice_strategy_id
      data['strategy_history'] = strategy_history

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['provider'] && typeof data['provider'] === 'object') {
          data['provider'] = data['provider'].id;
      }

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.saveAssetHistory(data);
      } else {
        res_netwealth_data = await StrategyService.saveLiabilityHistory(data);
      }
    }else{

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['provider'] && typeof data['provider'] === 'object') {
          data['provider'] = data['provider'].id;
      }

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.updateAssetHistory(data,data.id);
      } else {
        res_netwealth_data = await StrategyService.updateLiabilityHistory(data, data.id);
      }
    }
    getData()
  };


  const saveOptionData = async (data) => {
    let res_netwealth_data = [] // Declare res outside the conditional block
    if (isInteger(data.id)){
      data['advice_strategy_id'] = advice_strategy_id
      data['strategy_history'] = strategy_history

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['option'] && typeof data['option'] === 'object') {
          data['option'] = data['option'].id;
      }

      if (netwealth_type === "asset") {
        data['asset_history'] = current_history
        res_netwealth_data = await StrategyService.saveAssetHistoryOption(data);
      } else {
        data['liability_history'] = current_history
        res_netwealth_data = await StrategyService.updateAssetHistoryOption(data);
      }
    }else{

      if (data['account'] && typeof data['account'] === 'object') {
          data['account'] = data['account'].id;
      }

      if (data['option'] && typeof data['option'] === 'object') {
          data['option'] = data['option'].id;
      }

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.updateAssetHistoryOption(data,data.id);
      } else {
        res_netwealth_data = await StrategyService.updateAssetHistoryOption(data, data.id);
      }
    }
    getOptionData(current_history)
  };

  const saveBulkBOptionData = async () => {
    let payload_data = {'data': dataSource1}

      payload_data['advice_strategy_id'] = advice_strategy_id
      payload_data['strategy_history'] = strategy_history
      if (netwealth_type === "asset") {
        payload_data['asset_history'] = current_history
      }else {
        payload_data['liability_history'] = current_history
      }
      await StrategyService.saveBulkAssetHistoryOption(payload_data);
    // getOptionData(current_history)
  };

  const getOptionData = async (obj_id) => {
    //datasource1 data
      let res_netwealth_data = [] // Declare res outside the conditional block

      if (netwealth_type === "asset") {
        res_netwealth_data = await StrategyService.getAssetHistoryOption({
          asset_history: obj_id
        });
      } else {
        res_netwealth_data = await StrategyService.getAssetHistoryOption({
          liability_history: obj_id
        });
      }

      const all_data = get(res_netwealth_data, 'data.data', [])
      setDataSource1(all_data);

      const newTotals = calculateTotals(all_data);
      setTotalOptionHistory(newTotals);
    };

  useEffect(() => {
    getData()
    getProductList()
    getInvestmentOptionList(false)
  }, [])



  const onclickRetain = (obj_id) =>{
    getOptionData(obj_id)
    setCurrentHistory(obj_id)
  }

  // Function to add a new record
  const handleAddRecord = () => {
    const newRecord = {
      id: dataSource.length + 1,
      exising_new: `new`,
      account: {},
      balance: 0,
      proposed_balance: 0,
      strategy: evaluateHistoryStrategy(0, 0),
      drp: false,
      option: ''
    }
    setDataSource([...dataSource, newRecord])
  }

  const handleSaveHistory = (record) => {
    saveData(record)
  }

  const handleSaveOptionHistory = (record) => {
    saveOptionData(record)
  }

  const handleDeleteHistory = async (id, index) => {
    const result = window.confirm(`Are you sure to delete this ${netwealth_type} history?`)
    if (result) {
      if (isInteger(id)){
        const updatedDataSource = [...dataSource];
            updatedDataSource.splice(index, 1); // Remove the item at the specified index
            setDataSource(updatedDataSource); // Update the state
      } else {
        if (netwealth_type === "asset") {
        await StrategyService.deleteAssetHistory(id)
        }else {
          await StrategyService.deleteLiabilityHistory(id)
        }
        getData()
      }
    }
  }

  const handleDeleteOption = async (id, index) => {
    const result = window.confirm(`Are you sure to delete this ${netwealth_type} option?`)
    if (result) {
      if (isInteger(id)){
        const updatedDataSource = [...dataSource];
            updatedDataSource.splice(index, 1); // Remove the item at the specified index
            setDataSource(updatedDataSource); // Update the state
      }else {
        await StrategyService.deleteAssetHistoryOption(id)
        getOptionData(current_history)
      }
    }
  }

  const handleAddRecord1 = () => {
    const newRecord = {
      id: dataSource.length + 1,
      exising_new: `new`,
      investment_option: {},
      changed_balance:0,
      balance: 0,
      proposed_balance: 0,
      strategy: evaluateOptionStrategy(0, 0),
      drp: false,
      option: ''
    }
    setDataSource1([...dataSource1, newRecord])
  }

  const showModal = (obj_id) => {
    setIsModalOpen(true);
    onclickRetain(obj_id)
  };

  const handleOk = () => {
    setIsModalOpen(false);
    saveBulkBOptionData()
    getData()
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    getData()
  };

  const handleChangedBalanceChange = (event, index) => {
  const updatedDataSource = [...dataSource1];
  updatedDataSource[index].changed_balance = event.target.value;
  updatedDataSource[index].proposed_balance = Number(updatedDataSource[index].balance) + Number(updatedDataSource[index].changed_balance);
  updatedDataSource[index].strategy = evaluateOptionStrategy(Number(updatedDataSource[index].balance), Number(updatedDataSource[index].changed_balance))
  setDataSource1(updatedDataSource);
  const newTotals = calculateTotals(updatedDataSource);
  setTotalOptionHistory(newTotals);
  };

  const evaluateHistoryStrategy = (current_balance, proposed_balance) => {

    //if current_balance and proposed is equal , then Maintain, if both are zero then show dash
  //if current_balance > proposed , then Close, proposed > 0, it will "Withdraw"
  //if current_balance < proposed , then Open, current > 0, it will be Contribute


    // need to show total current and proposed balance for each asset/liability if only one record then no need to display total

    if (current_balance === 0 && proposed_balance === 0) {
        return "-";
    }

    if (current_balance === proposed_balance) {
        return "Maintain";
    }

    if (current_balance > proposed_balance) {
        return proposed_balance > 0 ? "Withdraw" : "Close";
    }

    if (current_balance < proposed_balance) {
        return current_balance > 0 ? "Contribute" : "Open";
    }

    return "NA"; // Fallback for unexpected cases
 };


  const evaluateOptionStrategy = (current_balance, proposed_balance) => {
    //if current_balance and proposed is equal , then hold, if both are zero then show dash
  //if current_balance > proposed , then sell, proposed > 0, it will "Decrease"
  //if current_balance < proposed , then buy, current > 0, it will be Increase
    if (current_balance === 0 && proposed_balance === 0) {
        return "-";
    }

    if (current_balance === proposed_balance) {
        return "Hold";
    }

    if (current_balance > proposed_balance) {
        return proposed_balance > 0 ? "Decrease" : "Sell";
    }

    if (current_balance < proposed_balance) {
        return current_balance > 0 ? "Increase" : "Buy";
    }

    return "NA";
};


  const evaluateInvestmentOption = (current_balance, proposed_balance) => {
    //investment option if current_balance and proposed is equal and no changes, then show Retain, if both are zero then Recommend
  // if there is changes, then it will Review
    if (current_balance === 0 && proposed_balance === 0) {
        return "Recommend";
    }

    if (current_balance === proposed_balance) {
        return "Retain";
    }

    return "Review"; // If there's any change
};

  const handleDRPChange = (value, index) => {
  const updatedDataSource = [...dataSource];
  updatedDataSource[index].is_drp = value;
  setDataSource(updatedDataSource);
  };

  const handleAccountChange = (value, index) => {
  const updatedDataSource = [...dataSource];
  updatedDataSource[index].account =value;
  setDataSource(updatedDataSource);
  };

  const handleInvestmentOptionChange = (value, index) => {
  const updatedDataSource = [...dataSource1];
  updatedDataSource[index].option =value;
  setDataSource1(updatedDataSource);
  };

  const columns = [
    {
      title: 'Position',
      dataIndex: 'exising_new',
      key: 'exising_new',
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      render: (text, e, index) => (
        <div className="table_responsive_cell">
          {e.exising_new === 'existing' || !isInteger(e.id) ? (
            e.account && e.account.subject
          ) : (
            <Select
              showSearch
              mode="single"
              name="account"
              style={{ width: '100%' }}
              placeholder="Please select account"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              value={e.account && e.account.id}
              onChange={(value) => handleAccountChange(value, index)}
            >
              {product_list.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.subject}
                </Option>
              ))}
            </Select>
          )}
        </div>
        ),
    },
    {
      title: (
    <div style={{ textAlign: 'right' }}>Current Balance</div>
    ),
      dataIndex: 'balance',
      key: 'balance',
      render: (text, e) => (
        <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
          {e.balance}
          </div>
        ),
    },
    {
      title: (
    <div style={{ textAlign: 'right' }}>Proposed Balance</div>
  ),
      dataIndex: 'proposed_balance',
      key: 'proposed_balance',
       render: (text, e, index) => (
        <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
          {e.proposed_balance}
          {/*<Input*/}
          {/*        style={{ width: '100%' }}*/}
          {/*        placeholder="balance"*/}
          {/*        type="number"*/}
          {/*        name="proposed_balance"*/}
          {/*        onChange={(event) => handleProposedBalanceChange(event, index)}*/}
          {/*        value={e.proposed_balance}*/}
          {/*  />*/}
        </div>
        ),
    },
    {
      title: 'Strategy',
      dataIndex: 'option',
      key: 'option',
      render: (text, record, index) => (
        <div className="table_responsive_cell">
          {record.strategy}
        </div>
      ),
    },
    {
      title: 'Investment Option',
      key: 'investment_option',
      render: (text, record) => (<>
            {!isInteger(record.id) &&
            <div>
              <Button type="link" size='small' onClick={()=>showModal(record.id)}>{evaluateInvestmentOption(Number(record.balance), Number(record.proposed_balance))}</Button>
              {/*<Button type="text" size='small'>Review</Button>*/}
            </div>
            }
      </>
      ),
    },
    {
      title: 'DRP',
      dataIndex: 'drp',
      key: 'drp',
      render: (_, record, index) => (
        <Checkbox
          checked={record.is_drp}
          onChange={(e) => handleDRPChange(e.target.checked, index)}
        >
          ON
        </Checkbox>
      ),
    },
    {
      title: 'Action',
      key: 'handle_action',
      render: (text, record, index) => (
          <>
          {record.exising_new == 'new' &&
          <Space size="middle">
            {isInteger(record.id) &&
            <Button onClick={() => handleSaveHistory(record)} icon={<SaveOutlined />} />
            }
                <Button
                    danger
                    icon={<DeleteOutlined />}
                    size="medium"
                    onClick={() => handleDeleteHistory(record.id, index)}
                  />
          </Space>
          }
          </>
      )
    },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (text, record, index) => (
    //     // Add button only on the last row
    //     index === dataSource.length - 1 ? (
    //       <Button onClick={handleAddRecord}  shape="circle" icon={<PlusOutlined />} />
    //     ) : null
    //   ),
    // },
  ]

  const columns1 = [
    {
      title: 'Position',
      dataIndex: 'exising_new',
      key: 'exising_new'
    },
    {
      title: 'Investment Option',
      dataIndex: 'option',
      key: 'option',
      render: (text, e, index) => (
        <div className="table_responsive_cell">
          {e.exising_new === 'existing' || !isInteger(e.id) ? (
            e.option && e.option.subject
          ) : (
            <Select
              showSearch
              mode="single"
              name="option"
              style={{ width: '100%' }}
              placeholder="Please select option"
              filterOption={(input, option) =>
                option?.children?.toLowerCase().includes(input.toLowerCase())
              }
              value={e.option && e.option.id}
              onChange={(value) => handleInvestmentOptionChange(value, index)}
            >
              {investment_options_list.map((item) => (
                <Option key={item.id} value={item.id}>
                  {item.subject}
                </Option>
              ))}
            </Select>
          )}
        </div>
        ),
    },
    {
      title: (
      <div style={{ textAlign: 'right' }}>Current Balance</div>
    ),
      dataIndex: 'balance',
      key: 'balance',
      render: (text, e) => (
        <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
          {e.balance}
          </div>
        ),
    },
    {
      title: 'Change',
      width: 150,
      render: (text, e, index) => (
        <div className="table_responsive_cell">
          <Input
                style={{ width: '100%' }}
                placeholder="balance"
                type="number"
                name="changed_balance"
                onChange={(event) => handleChangedBalanceChange(event, index)}
                value={e.changed_balance}
            />
        </div>
        )
    },
    {
      title: (
      <div style={{ textAlign: 'right' }}>Proposed Balance</div>
    ),
      dataIndex: 'proposed_balance',
      key: 'proposed_balance',
       render: (text, e) => (
        <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
          {e.proposed_balance}
        </div>
        ),
    },
    {
      title: 'Strategy',
      dataIndex: 'option',
      key: 'option',
      render: (text, record, index) => (
        <div className="table_responsive_cell">
          {record.strategy}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'handle_action',
      render: (text, record, index) => (
          <>
          {record.exising_new == 'new' &&
          <Space size="middle">
            {isInteger(record.id) &&
            <Button onClick={() => handleSaveOptionHistory(record)} icon={<SaveOutlined/>}/>
            }
              <Button
                danger
                icon={<DeleteOutlined />}
                size="medium"
                onClick={() => handleDeleteOption(record.id, index)}
              />

          </Space>
            }
            </>
      )
    },
    // {
    //   title: '',
    //   key: 'action',
    //   render: (text, record, index) => (
    //     // Add button only on the last row
    //     index === dataSource1.length - 1 ? (
    //       <Button onClick={handleAddRecord1}  shape="circle" icon={<PlusOutlined />} />
    //     ) : null
    //   ),
    // },
  ]
  

  return (
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        {/*<h1 className="flex_title">*/}
        {/*  <span className="title">Investment Options</span>{' '}*/}
        {/*</h1>*/}
        {/*<br />*/}

        <h1 className="flex_title">
            <span className="title"></span>
            <Button onClick={handleAddRecord}  type="primary" icon={<PlusOutlined />} >
              Add account
            </Button>
          </h1>
        <Table
          className="custom_responsive_table"
          rowKey="id"
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          // pagination={{
          //   showSizeChanger: false,
          //   defaultPageSize: 50,
          //   PageSize: 50,
          // }}
            summary={() => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={2}>
            <strong>Total</strong>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2}>
            <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
              <strong>{totalHistory.total_balance.toFixed(2)}</strong>
            </div>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3}>
            <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
            <strong>{totalHistory.total_proposed_balance.toFixed(2)}</strong>
            </div>
          </Table.Summary.Cell>
          {/* Empty cells for remaining columns */}
          {columns.slice(4).map((_, index) => (
            <Table.Summary.Cell key={index} />
          ))}
        </Table.Summary.Row>
      )}
        />
      {/*  <div style={{ marginTop: "16px", fontWeight: "bold" }}>*/}
      {/*  Total Current Balance: {totalHistory.total_balance} | Total*/}
      {/*  Proposed Balance: {totalHistory.total_proposed_balance}*/}
      {/*</div>*/}
        <Modal title="Retain/Review" visible={isModalOpen}
               onOk={handleOk}
               onCancel={handleCancel}
               width={'100%'}
               footer={[
                  <Button key="cancel" size="medium" onClick={handleCancel}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="primary" onClick={handleOk}>
                    Save
                  </Button>
              ]}
        >
          <h1 className="flex_title">
            <span className="title"></span>
            <Button onClick={handleAddRecord1}  type="primary" icon={<PlusOutlined />} >
              Add option
            </Button>
          </h1>
          <Table
            className="custom_responsive_table"
            rowKey="id"
            columns={columns1}
            dataSource={dataSource1}
            pagination={false}
            // pagination={{
            //   showSizeChanger: false,
            //   defaultPageSize: 50,
            //   PageSize: 50,
            // }}
            summary={() => (
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0} colSpan={2}>
                    <strong>Total</strong>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
                      <strong>{totalOptionHistory.total_balance.toFixed(2)}</strong>
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell key={3} />
                  <Table.Summary.Cell index={4}>
                    <div className="table_responsive_cell" style={{ textAlign: 'right' }}>
                    <strong>{totalOptionHistory.total_proposed_balance.toFixed(2)}</strong>
                    </div>
                  </Table.Summary.Cell>
                  {/* Empty cells for remaining columns */}
                  {columns.slice(5).map((_, index) => (
                    <Table.Summary.Cell key={index} />
                  ))}
                </Table.Summary.Row>
              )}
                  />
        </Modal>
      </div>
  )
}

export default InvestmentOptionTable
