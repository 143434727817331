import { combineReducers } from 'redux'
import user from './userSlice'
import theme from './themeReducer'
import previewUser from './previewUserSlice'
import routeSlice from './routeSlice'
import entityReducer from './entityReducer'
import app from './appReducer'
export default combineReducers({
  user,
  theme,
  previewUser,
  entityReducer,
  app,
  routeSlice,
})
