import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import CanvasJSReact from '../../lib/canvasjs.react'
var CanvasJS = CanvasJSReact.CanvasJS
var CanvasJSChart = CanvasJSReact.CanvasJSChart

const SingleSelectChart = (props) => {
  var theme = useSelector((state) => state.theme.current_theme)

  CanvasJS.addColorSet('chartThemeColors', [
    theme.chart_color.chart1,
    theme.chart_color.chart2,
    theme.chart_color.chart3,
    theme.chart_color.chart4,
    theme.chart_color.chart5,
    theme.chart_color.chart6,
    theme.chart_color.chart7,
    theme.chart_color.chart8,
    theme.chart_color.chart9,
    theme.chart_color.chart10,
  ])

  const highlightDataPoint = (e) => {
    if (!props.multi) {
      for (var i = 0; i < e.chart.data[0].dataPoints.length; i++) {
        e.chart.data[0].dataPoints[i].exploded = false
        e.chart.data[0].dataPoints[e.dataPointIndex].exploded = true
      }
    }
    var selected = 0
    for (var i = 0; i < e.dataSeries.dataPoints.length; i++) {
      e.dataSeries.dataPoints[i].color = e.dataSeries.dataPoints[i].exploded ? '#242424' : null
      e.dataSeries.dataPoints[i].indexLabelFontColor = e.dataSeries.dataPoints[i].exploded
        ? '#ffffff'
        : null
      if (e.dataSeries.dataPoints[i].exploded) {
        selected = selected + e.dataSeries.dataPoints[i].value
      }
    }

    if (props.showScore) {
      e.chart.options.title.text = 'Score : ' + selected
    } else {
      e.chart.options.title.text = ''
    }
    e.chart.render()
  }

  const opts = {
    backgroundColor: 'transparent',
    title: {
      text: props.showScore ? 'Score : 0' : '',
      verticalAlign: 'center',
      dockInsidePlotArea: true,
      fontSize: 19,
    },
    colorSet: 'chartThemeColors',
    data: [
      {
        type: props.chartType,
        click: highlightDataPoint,
        toolTipContent: '{indexLabel}-{value}',
        indexLabelPlacement: 'inside',
        indexLabelWrap: true,
        indexLabelMaxWidth: 60,
        indexLabelFontSize: 12,
        innerRadius: '50%',
        dataPoints: [],
      },
    ],
  }

  const [options, setOptions] = useState(opts)

  useEffect(() => {
    var activeData = []
    props.dt.map((d) => {
      activeData.push({ value: parseInt(d.value), y: 4, indexLabel: d.name })
    })
    // setData(activeData)
    // console.log("Constructed data",activeData)

    var updatedOpt = { ...options }
    updatedOpt.data[0].dataPoints = activeData

    setOptions(updatedOpt)
  }, [props])

  return (
    <div style={{ maxWidth: 650, marginLeft: 'auto', marginRight: 'auto' }}>
      <CanvasJSChart options={options} />
    </div>
  )
}
export default SingleSelectChart
