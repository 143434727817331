import { Tabs, Table, Button, Modal, Space } from 'antd'
import { EditOutlined, EyeOutlined, DeleteOutlined, LockOutlined } from '@ant-design/icons'
import { AdminLayout } from 'Components'
import TinyEditor from 'Components/TinyEditor'
import React, { useEffect, useState, useRef } from 'react'
import { PageService, PdfConfigService } from 'services'
import { useSelector } from 'react-redux'
import { ThemeService } from 'services'
import { get } from 'lodash'
import moment from 'moment'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

import SignatureCanvas from 'react-signature-canvas'
import './index.scss'
import { pdfConfigData } from 'utils'

const { TabPane } = Tabs
const ReportHistory = ({ preview }) => {
  const historyListColumns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (data) => (
        <div className="table_responsive_cell">
          <h6>Name</h6>
          <b>{data}</b>
        </div>
      ),
    },
    {
      title: 'Modified By/On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Modified By/On</h6>
          <span className="sep_time">{moment(text).format('ddd , MMMM , YYYY')}</span>
          <span className="sep_time">{moment(text).format('h:mm:ss A')}</span>
        </div>
      ),
      width: '190px',
    },

    {
      title: 'Created By/On',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (data, record) => (
        <div className="table_responsive_cell">
          <h6>Created By/On</h6>
          <b>{data.name}</b>
          <div>
            <span className="sep_time">
              {moment(record.created_on).format('ddd , MMMM , YYYY')}
            </span>
            <span className="sep_time">{moment(record.created_on).format('h:mm:ss A')}</span>
          </div>
        </div>
      ),
      width: '190px',
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (text, record) => (
        <Space size="middle">
          {!record.is_locked ? (
            <Button
              icon={<EditOutlined />}
              size={'medium'}
              onClick={() => {
                PdfConfigService.getHistory(record.id).then((res) => {
                  setHistoryDetail(get(res, 'data.data', {}))
                  setEditModal(true)
                })
              }}
            />
          ) : (
            <Button icon={<LockOutlined />} size={'medium'} />
          )}
          <Button
            onClick={() => {
              PdfConfigService.getHistory(record.id).then((res) => {
                setHistoryDetail(get(res, 'data.data', {}))
                setViewHistory(true)
                setCurrentReportHistory(record.id)
              })
              ReportHistorySignatureList(record.id)
            }}
            icon={<EyeOutlined />}
            size={'medium'}
          />
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={() =>
              PdfConfigService.deleteReportHistory(record.id).then((res) => getHistoryList())
            }
          />
        </Space>
      ),
    },
  ]
  const params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  })
  const [historyList, setHistoryList] = useState([])
  const [signatureList, setSignatureList] = useState([])
  const [historyDetail, setHistoryDetail] = useState({})
  const [editModal, setEditModal] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [template, setTemplate] = useState({})
  const [viewHistory, setViewHistory] = useState(false)
  const [report_name, setReportName] = useState(false)
  const previewUser = useSelector((state) => state.previewUser)
  const user = useSelector((state) => state.user)
  const [signatureData, setSignatureData] = useState({
    signature: '',
    signature_type: 'image',
    ip: '',
    default_page_id: '',
  })
  const [current_report_history, setCurrentReportHistory] = useState('')
  const [loading, setLoading] = useState(false)
  const [images, setImages] = useState({})

  const sigCanvas = useRef({})

  const clear_sign = () => {
    sigCanvas.current.clear()
    setSignatureData((prevState) => ({
      ...prevState,
      signature: '',
    }))
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const load_data = async () => {
    const images_obj = await pdfConfigData.fetchPdfConfig(previewUser, user)
    setImages(images_obj)
    await getTemplateThemeRecords('aac9f32f-08e4-4c01-8509-2bf079fa4ad6')
    await getHistoryList()
    await loadTableData()
    get_ip_address()
  }

  const [formats, setFormats] = useState([
    { title: 'Paragraph', block: 'p', attributes: { class: 'p' } },
    { title: 'Heading 1', block: 'h1', attributes: { class: 'h1' } },
    { title: 'Heading 2', block: 'h2', attributes: { class: 'h2' } },
    { title: 'Heading 3', block: 'h3', attributes: { class: 'h3' } },
    { title: 'Heading 4', block: 'h4', attributes: { class: 'h4' } },
    { title: 'Heading 5', block: 'h5', attributes: { class: 'h5' } },
    { title: 'Heading 6', block: 'h6', attributes: { class: 'h6' } },
  ])
  const [style, setStyle] = useState('')
  const [tableStyle, settableStyle] = useState('')

  const getTemplateThemeRecords = async (form) => {
    const res = await PageService.getFormData({
      form,
      entity: previewUser.details?.business || user.details?.business,
      page: 'd1c1b901-870b-4c87-9f88-54e3bb1e6a9b',
      field: '68353128-076a-49b6-bd2c-93e21794daf8',
    })
    const data = get(res, 'data')
    let a = []
    data?.options_data?.map((item, index) => {
      var formats = {
        name: item.name,
        title: item.name,
        block: item.value[0] === 'h' ? item.value : 'p',
        attributes: { class: item.value },
      }
      a.push(formats)
    })
    var styles = ''
    data?.data?.map((item) => {
      Object.keys(item.group_data).map(function (key) {
        var styleItem = `
            .${item.group_data[key]['68353128-076a-49b6-bd2c-93e21794daf8']}{
              color: ${
                item.group_data[key]['6f17d93e-1ad6-42bd-be8e-889d9dcc685b']
                  ? item.group_data[key]['6f17d93e-1ad6-42bd-be8e-889d9dcc685b']
                  : '#000000'
              };
              font-family: ${
                item.group_data[key]['7987bb64-50ee-4436-a7e4-3dd90022d1a9']
                  ? item.group_data[key]['7987bb64-50ee-4436-a7e4-3dd90022d1a9']
                  : 'Roboto'
              };
              padding-top: ${
                item.group_data[key]['38711d58-4f6b-48c6-b644-492455d35b7a']?.single
                  ? item.group_data[key]['38711d58-4f6b-48c6-b644-492455d35b7a'].single + 'px'
                  : '14px'
              };
              font-style: ${
                item.group_data[key]['4e6c6bc9-e8d5-4251-b141-abf03e1ed699']
                  ? item.group_data[key]['4e6c6bc9-e8d5-4251-b141-abf03e1ed699']
                  : 'normal'
              };
              font-weight: ${
                item.group_data[key]['aa8d082b-95cc-4d21-a878-3e3a7cbe71fc']
                  ? item.group_data[key]['aa8d082b-95cc-4d21-a878-3e3a7cbe71fc']
                  : '400'
              };
              text-decoration: ${
                item.group_data[key]['982971c9-8106-4ee0-a506-799c41f7eb79']
                  ? item.group_data[key]['982971c9-8106-4ee0-a506-799c41f7eb79']
                  : 'none'
              };
              font-size: ${
                item.group_data[key]['b0d779f7-6332-42e8-9c6a-bcd1c8ce0bbf']?.single
                  ? item.group_data[key]['b0d779f7-6332-42e8-9c6a-bcd1c8ce0bbf'].single + 'px'
                  : '0px'
              };
              padding-bottom: ${
                item.group_data[key]['cc536f9f-8b40-4d49-a3c5-1109bc796135']?.single
                  ? item.group_data[key]['cc536f9f-8b40-4d49-a3c5-1109bc796135'].single + 'px'
                  : '0px'
              };
          }
          `
        styles = styles + styleItem
      })
    })
    setFormats(a)
    console.log(styles)
    setStyle(styles)

    const styleElement = document.createElement('style')
    styleElement.innerText = styles
    styleElement.id = 'dynamicStyles' // Assign a unique id for easy reference

    // Append the style element to the document's head
    document.head.appendChild(styleElement)
  }

  const loadTableData = async () => {
    try {
      const res = await ThemeService.getTableConfig({
        business: previewUser.details?.business || user.details?.business,
      })
      const config = get(res, 'data.data.config', null) // Fallback to `null` if not found
      console.log(config)
      const configStyle = `
          .pdf_page td {
              font-family: ${config.subHeader.fontFamily};
              color: ${config.subHeader.fontColor};
              background-color: ${config.subHeader.backgroundColor};
              border-left-color: ${config.subHeader.leftBorderColor};
              border-left-width: ${config.subHeader.leftBorderSize}px;
              border-left-style: ${config.subHeader.leftBorderStyle};
              border-right-color: ${config.subHeader.rightBorderColor};
              border-right-width: ${config.subHeader.rightBorderSize}px;
              border-right-style: ${config.subHeader.rightBorderStyle};
              border-top-color: ${config.subHeader.topBorderColor};
              border-top-width: ${config.subHeader.topBorderSize}px;
              border-top-style: ${config.subHeader.topBorderStyle};
              border-bottom-color: ${config.subHeader.bottomBorderColor};
              border-bottom-width: ${config.subHeader.bottomBorderSize}px;
              border-bottom-style: ${config.subHeader.bottomBorderStyle};
          }

          .pdf_page .table_header_custom{
            font-family: ${config.header.fontFamily};
            color: ${config.header.fontColor};
            background-color: ${config.header.backgroundColor};
            border-left-color: ${config.header.leftBorderColor};
            border-left-width: ${config.header.leftBorderSize}px;
            border-left-style: ${config.header.leftBorderStyle};
            border-right-color: ${config.header.rightBorderColor};
            border-right-width: ${config.header.rightBorderSize}px;
            border-right-style: ${config.header.rightBorderStyle};
            border-top-color: ${config.header.topBorderColor};
            border-top-width: ${config.header.topBorderSize}px;
            border-top-style: ${config.header.topBorderStyle};
            border-bottom-color: ${config.header.bottomBorderColor};
            border-bottom-width: ${config.header.bottomBorderSize}px;
            border-bottom-style: ${config.header.bottomBorderStyle};
          }

          .pdf_page .table_sub_header_custom{
            font-family: ${config.subHeader.fontFamily};
            color: ${config.subHeader.fontColor};
            background-color: ${config.subHeader.backgroundColor};
            border-left-color: ${config.subHeader.leftBorderColor};
            border-left-width: ${config.subHeader.leftBorderSize}px;
            border-left-style: ${config.subHeader.leftBorderStyle};
            border-right-color: ${config.subHeader.rightBorderColor};
            border-right-width: ${config.subHeader.rightBorderSize}px;
            border-right-style: ${config.subHeader.rightBorderStyle};
            border-top-color: ${config.subHeader.topBorderColor};
            border-top-width: ${config.subHeader.topBorderSize}px;
            border-top-style: ${config.subHeader.topBorderStyle};
            border-bottom-color: ${config.subHeader.bottomBorderColor};
            border-bottom-width: ${config.subHeader.bottomBorderSize}px;
            border-bottom-style: ${config.subHeader.bottomBorderStyle};
        }

      `
      settableStyle(configStyle)

      const styleElement = document.createElement('style')
      styleElement.innerText = configStyle
      styleElement.id = 'tableStyles' // Assign a unique id for easy reference

      // Append the style element to the document's head
      document.head.appendChild(styleElement)
    } catch (error) {
      console.error('Error fetching config:', error)
      // If there is an error fetching, set the default values
    }
  }

  useEffect(() => {
    load_data()

    return () => {
      // Remove the style element when the component unmounts
      const styleElement = document.getElementById('dynamicStyles')
      if (styleElement) {
        styleElement.remove()
      }
      const tableStyleElement = document.getElementById('tableStyles')
      if (tableStyleElement) {
        tableStyleElement.remove()
      }
    }
  }, [])

  const getHistoryList = () => {
    PdfConfigService.getHistoryList({
      pdf: params.report,
      entity: previewUser?.details?.id || user?.details?.id,
    }).then((res) => {
      setHistoryList(get(res, 'data.data', []))
      setReportName(get(res, 'data.report_name', ''))
    })
  }

  const ReportHistorySignature = (signature) => {
    const payload = signatureData
    if (payload.signature_type == 'image' && !sigCanvas.current.isEmpty()) {
      payload['signature'] = signature
    }
    payload['report_history'] = current_report_history
    const result = window.confirm(`Are you sure to sign this document ?`)
    if (result) {
      PdfConfigService.createReportHistorySignature(payload)
    }
  }

  const getPdfTemplate = (id) => {
    PdfConfigService.getPageTemplate(params.report, { detail: true })
      .then((res) => {
        setTemplate(get(res, 'data.data', {}))
        showModal()
      })
      .catch((err) => {})
  }

  const ReportHistorySignatureList = async (report_history) => {
    const res = await PdfConfigService.getReportHistorySignatureList({
      report_history: report_history,
    })
    setSignatureList(get(res, 'data.data'))
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const [isSignatureVisible, setIsSignatureVisible] = useState(false)

  const showSignature = (page_data) => {
    setIsSignatureVisible(true)
    setSignatureData((prevState) => ({
      ...prevState,
      ...page_data,
    }))
  }

  const handleSignOk = () => {
    // setIsSignatureVisible(false);
    const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')
    ReportHistorySignature(signature)
  }

  const get_ip_address = async () => {
    const response = await fetch('https://geolocation-db.com/json/')
    const ip_data = await response.json()
    const ip = get(ip_data, 'IPv4', '')
    setSignatureData((prevState) => ({
      ...prevState,
      ip,
    }))
  }

  const onChangeSignTab = (signature_type) => {
    if (signature_type == 'text') {
      clear_sign()
    }

    setSignatureData((prevState) => ({
      ...prevState,
      signature_type: signature_type,
      signature: '',
    }))
  }

  const onChangeSignText = (e) => {
    setSignatureData((prevState) => ({
      ...prevState,
      signature: e.target.value,
    }))
  }

  const handleSignCancel = () => {
    setIsSignatureVisible(false)
  }

  const columns = [
    {
      title: 'Signed by',
      dataIndex: 'signed_by',
      key: 'signed_by',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Signed by</h6>
          <span>
            {' '}
            {text.name} ({text.user_type})
          </span>
        </div>
      ),
    },
    {
      title: 'Page Number',
      dataIndex: 'page_number',
      key: 'page_number',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Page Number</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Signature',
      dataIndex: 'signature',
      key: 'signature',
      render: (value, record) => (
        <div className="table_responsive_cell">
          <h6>Signature</h6>
          {record.signature_type == 'image' ? (
            <Space size="middle">
              <img height={40} src={value} />
            </Space>
          ) : (
            <span>{value}</span>
          )}
        </div>
      ),
    },
    {
      title: 'Date Time',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Date Time</h6>
          {moment(text).local().format('MM/DD/YYYY HH:mm:ss')}
        </div>
      ),
    },
    {
      title: 'IP Address',
      dataIndex: 'ip',
      key: 'ip',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>IP Address</h6>
          {text}
        </div>
      ),
    },
  ]

  const data = [
    {
      key: '1',
      user_type: 'Client',
      page_number: '1',
      date_time: 'Monday, June 15, 2009',
      ip_address: '103.68.216.168',
      signature:
        'https://www.signwell.com/assets/vip-signatures/muhammad-ali-signature-6a40cd5a6c27559411db066f62d64886c42bbeb03b347237ffae98b0b15e0005.svg',
    },
    {
      key: '2',
      user_type: 'User',
      page_number: '2',
      date_time: 'Sunday, June 14, 2009',
      ip_address: '103.68.216.169',
      signature:
        'https://www.signwell.com/assets/vip-signatures/muhammad-ali-signature-6a40cd5a6c27559411db066f62d64886c42bbeb03b347237ffae98b0b15e0005.svg',
    },
  ]

  const getFormattedDate = () => {
    const now = new Date()
    const year = now.getFullYear()
    const month = String(now.getMonth() + 1).padStart(2, '0') // +1 because months are 0-indexed
    const day = String(now.getDate()).padStart(2, '0')
    const hours = String(now.getHours()).padStart(2, '0')
    const minutes = String(now.getMinutes()).padStart(2, '0')
    const seconds = String(now.getSeconds()).padStart(2, '0')

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`
  }

  const generatePDF = async () => {
    setLoading(true) // Start the loader
    const pdf = new jsPDF('p', 'pt', 'a4', true)

    // Select the specific modal by its title
    const modalWithTitle = document.querySelector('.pdf_download')

    if (modalWithTitle) {
      // Then select all divs with class "each_pdf_page" within that specific modal
      const pdfPages = modalWithTitle.querySelectorAll('.each_pdf_page')

      for (let i = 0; i < pdfPages.length; i++) {
        const canvas = await html2canvas(pdfPages[i])
        const imgData = canvas.toDataURL('image/png')

        // If not the first page, add a new page in the PDF
        if (i > 0) {
          pdf.addPage()
        }

        pdf.addImage(
          imgData,
          'PNG',
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        )
      }

      const filename = `download_${getFormattedDate()}.pdf`
      pdf.save(filename)
      setLoading(false) // Start the loader
    } else {
      console.error('Modal with the specified title not found')
    }
  }

  const handlePDFDownload = async () => {
    generatePDF()
  }

  const setBackgroundImage = (page) => {
    // console.log("imagebehbeh",images, page.attachment_bg_field)
    let bg_image = get(images, `images.${page.attachment_bg_field}`)
    // console.log("sfjdjdfh", page.attachment_bg_field, page.image, bg_image)
    if (page.image) {
      bg_image = page.image
    }
    return bg_image
  }

  return (
    <AdminLayout preview={preview}>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          {/*<span className="title">{report_name}</span>*/}
          <span className="title">Template scenario</span>
          <Button type="primary" onClick={() => getPdfTemplate()}>
            Create scenario
          </Button>
        </h1>
        <Table
          className="custom_responsive_table"
          columns={historyListColumns}
          dataSource={historyList}
          rowKey="id"
        />
      </div>
      <Modal
        className="pdf_page_view_modal pdf_download"
        title="Template Quick Edit"
        visible={viewHistory}
        onOk={() => setViewHistory(false)}
        onCancel={() => setViewHistory(false)}
        footer={[
          <Button key="download" onClick={handlePDFDownload} loading={loading}>
            Download
          </Button>,
          // <Button key="ok" onClick={handlePDFDownload}>
          //     Ok
          // </Button>,
          <Button key="cancel" onClick={() => setViewHistory(false)}>
            Cancel
          </Button>,
        ]}
      >
        {get(historyDetail, 'data.pages', []).map((page) => (
          <div
            className="each_pdf_page pdf_page"
            style={{ backgroundImage: `url(${setBackgroundImage(page)})` }}
          >
            {page.children.map((child) => (
              <div dangerouslySetInnerHTML={{ __html: child.value }}></div>
            ))}
            {get(page, 'default_page.require_signature') &&
              !get(page, 'default_page.signature') && (
                <div style={{ marginTop: 30 }}>
                  <Button
                    type="primary"
                    onClick={(e) =>
                      showSignature({
                        page_id: page.id,
                        default_page_id: get(page, 'default_page.id'),
                      })
                    }
                  >
                    Sign Here
                  </Button>
                </div>
              )}
            {get(page, 'default_page.signature.signature_type') == 'image' ? (
              <Space size="middle">
                <img height={40} src={get(page, 'default_page.signature.signature')} />
              </Space>
            ) : (
              <span>{get(page, 'default_page.signature.signature')}</span>
            )}
          </div>
        ))}
        <div className="each_pdf_page pdf_page">
          <br />
          <br />
          <br />
          <h1 style={{ textAlign: 'center' }}>Signature Page Summary</h1>
          <br />
          <br />
          <Table
            className="custom_responsive_table"
            columns={columns}
            dataSource={signatureList}
            rowKey="id"
            pagination={false}
          />
        </div>
      </Modal>
      <Modal
        className="pdf_page_view_modal"
        title="Template Quick Edit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="save">Save as draft</Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              PdfConfigService.createHistory({
                entity: previewUser?.details?.id || user?.details.id,
                pdf: template.id,
                data: template,
              }).then((res) => {
                getHistoryList()
                handleOk()
              })
            }}
          >
            Create History
          </Button>,
        ]}
      >
        {get(template, 'pages', []).map((page) => (
          <div className="each_pdf_page pdf_page">
            {page.children.map((child) => (
              <div dangerouslySetInnerHTML={{ __html: child.value }}></div>
            ))}
            {get(page, 'default_page.require_signature') && (
              <div style={{ marginTop: 30 }}>
                <Button type="primary" onClick={showSignature}>
                  Sign Here
                </Button>
              </div>
            )}
          </div>
        ))}
      </Modal>
      <Modal
        className="pdf_page_view_modal"
        title="Edit History"
        visible={editModal}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
      >
        {get(historyDetail, 'data.pages', []).map((page) => (
          <div className="each_pdf_page pdf_page">
            {get(page, 'children', []).map((item) => (
              <TinyEditor
                customStyle={style}
                formats={formats}
                isInline={true}
                value={item.value || '<div></div>'}
              ></TinyEditor>
            ))}
          </div>
        ))}
      </Modal>
      <Modal
        className="signature_modal"
        title=""
        visible={isSignatureVisible}
        onCancel={handleSignCancel}
        okText="Sign"
        onOk={handleSignOk}
      >
        <Tabs type="card" onChange={(e) => onChangeSignTab(e)}>
          <TabPane tab="Sign" key="image">
            <SignatureCanvas
              ref={sigCanvas}
              penColor="black"
              canvasProps={{ width: 472, height: 102, className: 'sigCanvas' }}
            />
            <Button type="primary" onClick={clear_sign}>
              Clear
            </Button>
          </TabPane>
          <TabPane tab="Type Signature" key="text">
            <input
              value={signatureData.signature}
              className="signature_input"
              onChange={(e) => onChangeSignText(e)}
            />
          </TabPane>
        </Tabs>
      </Modal>
    </AdminLayout>
  )
}

export default ReportHistory
