import { AdminLayout } from 'Components'
import { Card } from 'antd'
import { Link } from 'react-router-dom'
import BarStackedPercentChart from 'Components/Charts/stackedPercentage'
import BarStackedChart from 'Components/Charts/barStacked'

export default function AdminDashboard({ preview }) {
  return (
    <AdminLayout preview={preview}>
      <div className="dashboard">
        <div className="row align-items-stretch">
          <div className="col-md-12 col-lg-12 col-xl-12">
            <Card title="New Chart Demo" bordered={false}>
              <BarStackedPercentChart />
              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{' '}
                <Link to="/">
                  View Data in Detail <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div>
          {/*
          <div className="col-md-12 col-lg-12 col-xl-12">
            <Card
              title="New Chart Demo"
              bordered={false}
            >
              <BarStackedChart />
              <div className="card_footer">
                <span>Data as of 21st Aug 2021</span>{' '}
                <Link to="/">
                  View Data in Detail{' '}
                  <span className="material-icons">open_in_new</span>
                </Link>
              </div>
            </Card>
          </div>
          */}
        </div>
      </div>
    </AdminLayout>
  )
}
