import { Input, Table, Button, Modal, Space } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import {
  ThunderboltOutlined,
  EditOutlined,
  EyeOutlined,
  UnorderedListOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { AdminLayout } from 'Components'
import { PdfConfigService } from 'services'
import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import ReactQuill from 'react-quill'
import TinyEditor from 'Components/TinyEditor'
import { useSelector } from 'react-redux'
import moment from 'moment'

// const data = [
//   {
//     key: '1',
//     name: 'Asset report',
//     type: '3',
//   },
//   {
//     key: '2',
//     name: 'Net worth report',
//     type: '5',
//   },
// ];

const PDFTemplateList = (props) => {
  let navigate = useNavigate()

  const [data, SetData] = useState([])
  const [template, setTemplate] = useState({})
  const [historyList, setHistoryList] = useState([])
  const [reportListVisible, setReportListVisible] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [historyDetail, setHistoryDetail] = useState({})
  const user = useSelector((state) => state?.user?.details)
  const previewUser = useSelector((state) => state?.previewUser?.details)

  const historyListColumns = [
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'created_on',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Created On</h6>
          <b>{moment(text).format('MM-DD-YYYY hh:mm A')}</b>
        </div>
      ),
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'created_by',
      render: (data) => (
        <div className="table_responsive_cell">
          <h6>Created By</h6>
          <b>{data.name}</b>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          {!record.is_locked && (
            <Button
              icon={<EditOutlined />}
              size={'medium'}
              onClick={() => {
                PdfConfigService.getHistory(record.id).then((res) => {
                  setHistoryDetail(get(res, 'data.data', {}))
                  setEditModal(true)
                })
              }}
            />
          )}
        </Space>
      ),
    },
  ]

  const columns = [
    {
      title: 'Template Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Template Name</h6>
          <b>{text}</b>
        </div>
      ),
    },
    {
      title: 'Display name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Display name</h6>
          {text || '-'}
        </div>
      ),
    },
    {
      title: 'Number of pages',
      dataIndex: 'page_count',
      key: 'page_count',
      render: (text) => (
        <div className="table_responsive_cell">
          <h6>Number of pages</h6>
          {text}
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '250px',
      render: (text, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            size={'medium'}
            onClick={() => {
              navigate(`/admin/pdf-config/edit/${record.id}`, { push: true })
            }}
          />

          <Button
            icon={<EyeOutlined />}
            size={'medium'}
            onClick={() => getPdfTemplate(record.id)}
            title="Quick Edit"
          ></Button>
          {/* <Button
            icon={<UnorderedListOutlined />}
            size={"medium"}
            onClick={() => getHistoryList(record.id)}
            title="Quick Edit"
          ></Button> */}

          {/*<Button icon={<EyeOutlined />} size={'medium'} />*/}
          <Button
            danger
            icon={<DeleteOutlined />}
            size={'medium'}
            onClick={(e) => DeleteTemplate(record.id, record.name)}
          />
        </Space>
      ),
    },
  ]

  const [isModalVisible, setIsModalVisible] = useState(false)

  const getHistoryList = (id) => {
    PdfConfigService.getHistoryList({
      pdf: id,
      entity: previewUser?.id || user?.id,
    }).then((res) => {
      setHistoryList(get(res, 'data.data', []))
      setReportListVisible(true)
    })
  }

  useEffect(() => {
    get_data()
  }, [])

  const get_data = async () => {
    const res = await PdfConfigService.getPageTemplates()
    SetData(get(res, 'data.data', []))
  }

  const DeleteTemplate = async (id, name) => {
    const result = await window.confirm(`Are you sure to delete ${name} template ?`)
    if (result) {
      await PdfConfigService.deletePageTemplate(id)
      await get_data()
    }
  }

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const getPdfTemplate = (id) => {
    PdfConfigService.getPageTemplate(id, { detail: true })
      .then((res) => {
        setTemplate(get(res, 'data.data', {}))
        showModal()
      })
      .catch((err) => {})
  }
  const renderTemplateHtml = () => {
    let arr = []
    get(template, 'pages', []).map((page) => page.children.map((child) => arr.push(child.value)))
    return arr
  }

  const Main = (
    <>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Manage PDF Templatesss</span>{' '}
          <Button
            icon={<PlusOutlined />}
            type="primary"
            onClick={() => navigate('/admin/pdf-config/add')}
          >
            Add Template
          </Button>
        </h1>
        <Input.Group compact style={{ width: '100%' }}>
          <Input.Search allowClear style={{ width: '100%' }} />
        </Input.Group>
        <br />
        <Table className="custom_responsive_table" columns={columns} dataSource={data} />
      </div>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title="Template Quick Edit"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => {
              PdfConfigService.createHistory({
                entity: previewUser?.id || user.id,
                pdf: template.id,
                data: { pages: template.pages },
              })
            }}
          >
            Create History
          </Button>,
        ]}
      >
        {renderTemplateHtml().map((item, i) => (
          <div key={i} dangerouslySetInnerHTML={{ __html: item }}></div>
        ))}
      </Modal>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title={`History`}
        visible={reportListVisible}
        onOk={() => setReportListVisible()}
        onCancel={() => setReportListVisible()}
      >
        <Table
          className="custom_responsive_table"
          columns={historyListColumns}
          dataSource={historyList}
        />
      </Modal>
      <Modal
        style={{ width: '1000px' }}
        width={1000}
        title="Edit History"
        visible={editModal}
        onOk={() => setEditModal(false)}
        onCancel={() => setEditModal(false)}
      >
        <div className="page_fake_cont">
          <div className="fake_page">
            {get(historyDetail, 'data.pages', []).map((page) => (
              <div>
                {get(page, 'children', []).map((item) => (
                  <TinyEditor value={item.value || '<div></div>'}></TinyEditor>
                ))}
              </div>
            ))}
          </div>
        </div>
      </Modal>
    </>
  )
  return props.noAdminLayout ? Main : <AdminLayout>{Main}</AdminLayout>
}

export default PDFTemplateList
