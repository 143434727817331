import React, { useRef, useEffect } from 'react'
import { NestedPieChart } from '@toast-ui/chart'
import '@toast-ui/chart/dist/toastui-chart.min.css'

const NestedPieChartComponent = ({ inner_chart, outer_chart }) => {
  const chartRef = useRef()
  const chartInstance = useRef(null)

  //   const data = {
  //   categories: ["", ""],
  //   series: [
  //     {
  //       name: "assets",
  //       data: [
  //         {
  //           name: "Assets",
  //           data: 100
  //         }
  //
  //       ]
  //     },
  //     {
  //       name: "versions",
  //       data: [
  //         {
  //           name: "Property",
  //           parentName: "Assets",
  //           data: 40
  //         },
  //         {
  //           name: "Stocks",
  //           parentName: "Assets",
  //           data: 10
  //         }
  //
  //
  //       ]
  //     }
  //   ]
  // };

  const data = {
    categories: ['', ''],
    series: [
      {
        name: 'assets',
        data: outer_chart,
      },
      {
        name: 'versions',
        data: inner_chart,
      },
    ],
  }
  /*
  const data = {
    categories: ["", ""],
    series: [
      {
        name: "assets",
        data: [
          {
            name: "Assets",
            data: 60
          },
          {
            name: "Liabilities",
            data: 40
          },

        ]
      },
      {
        name: "versions",
        data: [
          {
            name: "Property",
            parentName: "Assets",
            data: 40
          },
          {
            name: "Stocks",
            parentName: "Assets",
            data: 10
          },
          {
            name: "Gold",
            parentName: "Assets",
            data: 10
          },
          {
            name: "Loan",
            parentName: "Liabilities",
            data: 15
          },
          {
            name: "Car",
            parentName: "Liabilities",
            data: 5
          },
          {
            name: "Home",
            parentName: "Liabilities",
            data: 5
          },
          {
            name: "EMI",
            parentName: "Liabilities",
            data: 15
          },


        ]
      }
    ]
  };

   */

  const options = {
    chart: {
      height: 700,
      width: 700,
    },
    legend: {
      align: 'bottom',
    },
    series: {
      versions: {
        radiusRange: {
          inner: '20%',
          outer: '50%',
        },
        dataLabels: {
          visible: true,
          pieSeriesName: {
            visible: true,
          },
        },
      },
      assets: {
        radiusRange: {
          inner: '55%',
          outer: '85%',
        },
      },
    },
    exportMenu: {
      visible: false,
    },
  }

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    if (!chartInstance.current) {
      chartInstance.current = new NestedPieChart({
        el: chartRef.current,
        data,
        options,
      })
    } else {
      chartInstance.current.updateOptions(options)
      chartInstance.current.setData(data)
    }

    // Clean up the chart on component unmount
    return () => {
      chartInstance.current.destroy()
      chartInstance.current = null
    }
  }, [inner_chart]) // Depend on asset_data to reinitialize the chart if it changes

  return <div ref={chartRef} />
}

export default NestedPieChartComponent
