import { Button } from 'antd'
import { get } from 'lodash'
import React from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'

function Field({ field, handleEditCondition }) {
  return (
    <div>
      <span className="element_type">{'Field'}</span>
      <div className={'group_item'} style={{ justifyContent: 'space-between', display: 'flex' }}>
        <span> {get(field, 'display_name', '') || get(field, 'name', '')}</span>
      </div>
    </div>
  )
}

export default Field
