import React, { useEffect, useState } from 'react'
import { AdminLayout } from 'Components'
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, InputNumber, Select, Button, Modal, Table, Checkbox, Switch } from 'antd'
import { DeleteOutlined, PlusOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import { ReactSortable } from 'react-sortablejs'
import * as yup from 'yup'
import {
  BusinessService,
  PageServiceService,
  PdfConfigService,
  QuestionnaireService,
} from 'services'
import _, { get } from 'lodash'
const { Option } = Select
const { Search } = Input
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    render: (text, e) => (
      <div className="table_responsive_cell">
        <h6>Name</h6>
        <b>{e.name}</b>
      </div>
    ),
  },
]

const AddQuestionnaire = (props) => {
  let navigate = useNavigate()
  let params = useParams()

  const [name, setName] = useState('')
  const [show_score, setShowScore] = useState(false)
  const [display_name, setDisplayName] = useState('')
  const [visibility, setVisibility] = useState([])
  const [business_list, setBusinessList] = useState([])
  const [data, SetData] = useState([])

  const [allPages, setAllPages] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  // const pageMockData = [
  //       {
  //           name:"Page 1"
  //       },
  //       {
  //           name:"Page 2"
  //       }
  //   ]
  const [state, setState] = useState([])
  const [allTemplates, setAllTemplates] = useState([])
  const [state2, setState2] = useState([]) // for second modal data
  const [selectedRowKeys2, setSelectedRowKeys2] = useState([])
  const [selectedRows2, setSelectedRows2] = useState([])
  const [template_data, setTemplateData] = useState([])

  const get_detail = async (data, template_data) => {
    if (params.questionnaire_id) {
      const res = await QuestionnaireService.getQuestionnaire(params.questionnaire_id)
      setName(get(res, 'data.data.name', ''))
      setDisplayName(get(res, 'data.data.display_name', ''))
      setVisibility(get(res, 'data.data.visibility', []).map((item) => item.id))
      setState(get(res, 'data.data.pages', []))
      const selected_rows = get(res, 'data.data.pages')
      const selected_keys = get(res, 'data.data.pages').map((item) => item.id)

      const selectedObj = {}
      selected_rows.map((item) => (selectedObj[item.id] = item))

      let arr = data.filter((item) => (selectedObj[item.id] ? false : true))
      setSelectedRowKeys(selected_keys.filter((item) => (selectedObj[item] ? false : true)))
      setSelectedRows(selected_rows.filter((item) => (selectedObj[item] ? false : true)))
      SetData(_.orderBy(arr, ['name'], ['asc']))

      setSelectedRows([])
      setSelectedRowKeys([])

      setState2(get(res, 'data.data.pdfs', []))
      const selected_rows2 = get(res, 'data.data.pdfs')
      const selected_keys2 = get(res, 'data.data.pdfs').map((item) => item.id)

      const selectedObj2 = {}
      selected_rows2.map((item) => (selectedObj2[item.id] = item))

      let arr2 = template_data.filter((item) => (selectedObj2[item.id] ? false : true))
      setSelectedRowKeys2(selected_keys2.filter((item) => (selectedObj2[item] ? false : true)))
      setSelectedRows2(selected_rows2.filter((item) => (selectedObj2[item] ? false : true)))
      setTemplateData(_.orderBy(arr2, ['name'], ['asc']))
      setSelectedRows2([])
      setSelectedRowKeys2([])
    }
  }

  const GetPageList = async () => {
    const res = await PageServiceService.PageList({ type: 'page' })
    SetData(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    setAllPages(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    get_template_data(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
  }

  const get_business_list = async (params = { is_active: true }) => {
    const res = await BusinessService.business(params)
    setBusinessList(get(res, 'data.data'))
  }

  const get_template_data = async (page_data) => {
    const res = await PdfConfigService.getPageTemplates()
    setTemplateData(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    setAllTemplates(_.orderBy(get(res, 'data.data'), ['name'], ['asc']))
    get_detail(page_data, _.orderBy(get(res, 'data.data'), ['name'], ['asc']))
  }

  useEffect(() => {
    get_business_list()
    GetPageList()
  }, [])

  const removeItem = (id) => {
    setState(state.filter((item) => item.id !== id))
  }

  const removeItemTemplateFile = (id) => {
    setState2(state2.filter((item) => item.id !== id))
  }

  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    // console.log('selectedRowKeys changed: ', selectedRows);
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  //modal handle for file
  const onSelectChange2 = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys2(selectedRowKeys)
    setSelectedRows2(selectedRows)
  }

  const rowSelection2 = {
    selectedRowKeys2,
    onChange: onSelectChange2,
  }
  const [isModalVisible2, setIsModalVisible2] = useState(false)

  const showModal2 = () => {
    setIsModalVisible2(true)
  }

  const handleOk2 = () => {
    setIsModalVisible2(false)
  }

  const handleCancel2 = () => {
    setIsModalVisible2(false)
  }

  const onSaveQuestionnaire = async (e) => {
    e.preventDefault()
    if (params.questionnaire_id) {
      await QuestionnaireService.updateQuestionnaire(
        {
          name,
          display_name,
          visibility,
          show_score,
          ...{ pages: state, pdfs: state2 },
        },
        params.questionnaire_id
      )
    } else {
      await QuestionnaireService.saveQuestionnaire({
        name,
        display_name,
        visibility,
        show_score,
        ...{ pages: state, pdfs: state2 },
      })
    }
    navigate('/admin/questionnaire')
  }

  const onClickSavePage = () => {
    const selectedObj = {}
    selectedRows.map((item) => (selectedObj[item.id] = item))

    let arr = data.filter((item) => (selectedObj[item.id] ? false : true))
    setSelectedRowKeys(selectedRowKeys.filter((item) => (selectedObj[item] ? false : true)))
    setSelectedRows(selectedRows.filter((item) => (selectedObj[item] ? false : true)))

    SetData(_.orderBy(arr, ['name'], ['asc']))

    setState((prev) => [...prev, ...selectedRows])
    setSelectedRows([])
    setSelectedRowKeys([])
    handleOk()
  }

  const onClickSaveTemplateFile = () => {
    const selectedObj = {}
    selectedRows2.map((item) => (selectedObj[item.id] = item))

    let arr = template_data.filter((item) => (selectedObj[item.id] ? false : true))
    setSelectedRowKeys2(selectedRowKeys2.filter((item) => (selectedObj[item] ? false : true)))
    setSelectedRows2(selectedRows2.filter((item) => (selectedObj[item] ? false : true)))

    setTemplateData(_.orderBy(arr, ['name'], ['asc']))
    setState2((prev) => [...prev, ...selectedRows2])
    setSelectedRows2([])
    setSelectedRowKeys2([])
    handleOk2()
  }

  return (
    <AdminLayout>
      <div style={{ margin: 10, padding: '15px 20px', backgroundColor: '#fff' }}>
        <h1 className="flex_title">
          <span className="title">Add/Edit Questionnaire</span>
          <div>
            {' '}
            <Button
              onClick={() => {
                navigate('/admin/questionnaire/wizard', { push: true })
              }}
            >
              Preview
            </Button>{' '}
            <Button type="primary" onClick={onSaveQuestionnaire}>
              Save Questionnaire
            </Button>
          </div>
        </h1>
        <div>
          <Form layout="vertical">
            <Form.Item label="Questionnaire Name">
              <Input
                style={{ width: '100%' }}
                placeholder="Name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Display Name">
              <Input
                style={{ width: '100%' }}
                placeholder="Display name"
                name="display_name"
                value={display_name}
                onChange={(e) => setDisplayName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Visibility to businesses">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select businesses"
                onChange={(value) => setVisibility(value)}
                value={visibility}
              >
                {business_list.map((item) => (
                  <Option key={item.id}>{item.business_name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <label>
                <span style={{ display: 'inline-block', width: '100px' }}>Show score ?</span>
                <Switch checked={show_score} onChange={(checked) => setShowScore(checked)} />
              </label>
            </Form.Item>
          </Form>
          <div className="field_preview" style={{ marginLeft: 0 }}>
            <h2 style={{ marginBottom: 35 }}>
              Pages{' '}
              <Button
                style={{ marginLeft: 50 }}
                onClick={showModal}
                icon={<PlusOutlined />}
                size={'small'}
                type="primary"
              >
                Add Pages
              </Button>
            </h2>
            <ReactSortable className="sortable_fields" tag="ul" list={state} setList={setState}>
              {state.map((item, i) => (
                <li className="draggable_list group_field" key={item.id}>
                  <span className="item_name">{item.name}</span>
                  <div>
                    {/*
                    <Button
                      title="Manage conditions"
                      icon={<QuestionCircleOutlined />}
                      size={"medium"}
                      onClick={() => {
                        navigate("/admin/conditions", { push: true });
                      }}
                    />{" "}
                    */}
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size={'medium'}
                      onClick={() => {
                        let arr = [...data, item]
                        SetData(_.orderBy(arr, ['name'], ['asc']))
                        removeItem(item.id)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ReactSortable>
          </div>
          <br />
          <br />
          <h2>Attach Files</h2>
          <div className="field_preview" style={{ marginLeft: 0 }}>
            <h2 style={{ marginBottom: 35 }}>
              Files{' '}
              <Button
                style={{ marginLeft: 50 }}
                onClick={showModal2}
                icon={<PlusOutlined />}
                size={'small'}
                type="primary"
              >
                Add File
              </Button>
            </h2>
            <ReactSortable className="sortable_fields" tag="ul" list={state2} setList={setState2}>
              {state2.map((item, i) => (
                <li className="draggable_list group_field" key={item.id}>
                  <span className="item_name">{item.name}</span>
                  <div>
                    <Button
                      title="Manage conditions"
                      icon={<QuestionCircleOutlined />}
                      size={'medium'}
                      onClick={() => {
                        navigate('/admin/conditions', { push: true })
                      }}
                    />{' '}
                    <Button
                      danger
                      icon={<DeleteOutlined />}
                      size={'medium'}
                      onClick={() => {
                        let arr = [...template_data, item]
                        setTemplateData(_.orderBy(arr, ['name'], ['asc']))
                        removeItemTemplateFile(item.id)
                      }}
                    />
                  </div>
                </li>
              ))}
            </ReactSortable>
          </div>
        </div>
        <Modal
          title="Search and select template"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="back" onClick={handleCancel}>
              Return
            </Button>,
            <Button key="submit" type="primary" onClick={onClickSavePage}>
              Add Selected Pages
            </Button>,
          ]}
        >
          <Search
            onChange={(e) =>
              SetData(
                allPages.filter((item) =>
                  item.name.toLowerCase().includes(e.target.value.toLowerCase())
                )
              )
            }
            placeholder="Search fields"
            style={{ width: '100%' }}
          />
          <Table
            className="custom_responsive_table"
            rowSelection={rowSelection}
            rowKey="id"
            columns={columns}
            dataSource={data}
          />
        </Modal>
        {/*model2 for pdf file template*/}
        {isModalVisible2 ? (
          <Modal
            title="Search and select pages"
            visible={isModalVisible2}
            onOk={handleOk2}
            onCancel={handleCancel2}
            footer={[
              <Button key="back" onClick={handleCancel2}>
                Return
              </Button>,
              <Button key="submit" type="primary" onClick={onClickSaveTemplateFile}>
                Add Selected template pdf
              </Button>,
            ]}
          >
            <Search
              onChange={(e) =>
                setTemplateData(
                  allTemplates.filter((item) =>
                    item.name.toLowerCase().includes(e.target.value.toLowerCase())
                  )
                )
              }
              placeholder="Search fields"
              style={{ width: '100%' }}
            />
            <Table
              className="custom_responsive_table"
              rowSelection={rowSelection2}
              rowKey="id"
              columns={columns}
              dataSource={template_data}
            />
            {console.log(template_data, 'temkaklsjksdjklj')}
          </Modal>
        ) : null}
      </div>
    </AdminLayout>
  )
}

export default AddQuestionnaire
