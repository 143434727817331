import React, { Component } from 'react'
import { NavHashLink, HashLink } from 'react-router-hash-link'
import './nav.scss'
export default class HomeNav extends Component {
  render() {
    return (
      <nav className="home_nav">
        <div className="container">
          <div className="nav_body">
            <NavHashLink to="/">
              <img width="120" src="/color_logo.svg" />
            </NavHashLink>
            <input id="menu" type="checkbox" hidden />
            <label htmlFor="menu" className="menu">
              <span className="material-icons-outlined">menu</span>
            </label>
            <div className="nav_right">
              <ul>
                <li>
                  <NavHashLink exact activeClassName="active" to="/">
                    Home
                  </NavHashLink>
                </li>
                <li>
                  <HashLink exact activeClassName="active" smooth to="/#features">
                    Features
                  </HashLink>
                </li>
                <li>
                  <HashLink exact activeClassName="active" smooth to="/#pricing">
                    Pricing
                  </HashLink>
                </li>
                <li>
                  <NavHashLink exact activeClassName="active" to="/about-us">
                    About Us
                  </NavHashLink>
                </li>
                <li>
                  <NavHashLink exact activeClassName="active" to="/contact-us">
                    Contact Us
                  </NavHashLink>
                </li>
              </ul>
              <div className="auth_btn">
                <NavHashLink exact activeClassName="active" className="btn default" to="/login">
                  Login
                </NavHashLink>
                <NavHashLink exact activeClassName="active" className="btn primary" to="/signup">
                  Sign Up
                </NavHashLink>
              </div>
            </div>
            <label htmlFor="menu" className="close_menu">
              <label htmlFor="menu">
                <span className="material-icons-outlined">close</span>
              </label>
            </label>
          </div>
        </div>
      </nav>
    )
  }
}
