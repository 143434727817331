import api from './api'

const endpoints = {
  capability: 'capability/',
  pricing_plan: 'pricing-plan/',
  currency: 'currency/',
}

const GetCapabilityList = async () => {
  return api.get(endpoints.capability)
}

const GetCapabilityDetail = async (id) => {
  return api.get(endpoints.capability + id + '/')
}

const UpdateCapability = async (id, data) => {
  return api.put(endpoints.capability + id + '/', data)
}

const GetCurrencyList = async () => {
  return api.get(endpoints.currency)
}

const CreatePricingPlan = async (data) => {
  return api.post(endpoints.pricing_plan, data)
}

const GetPricingPlanList = async (params = {}) => {
  return api.get(endpoints.pricing_plan, { params: params })
}

const GetPricingPlanDetail = async (id, params = {}) => {
  return api.get(endpoints.pricing_plan + id + '/', { params })
}

const PricingPlanDelete = async (id) => {
  return api.delete(endpoints.pricing_plan + id + '/')
}

const UpdatePricingPlan = async (id, data) => {
  return api.put(endpoints.pricing_plan + id + '/', data)
}

export default {
  GetCapabilityList,
  GetCapabilityDetail,
  UpdateCapability,
  GetCurrencyList,
  CreatePricingPlan,
  GetPricingPlanList,
  PricingPlanDelete,
  GetPricingPlanDetail,
  UpdatePricingPlan,
}
